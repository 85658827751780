import { baseRestApi } from "api/rest/baseRestApi";
import { ChatRawResponse, ChatResponse } from "types/Chat";

import { mapMessages } from "../utils/mapMessages";
import { mapParticipants } from "../utils/mapParticipants";

module.hot?.accept();

interface ConversationMessagesArgs {
  conversationId: number;
  after?: number;
  variation: "shipper" | "broker";
}

const getConversationMessagesApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["ChatConversation"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getConversationMessages: builder.query<ChatResponse, ConversationMessagesArgs>({
      query: ({ conversationId, after, variation }) => ({
        url: `${variation}/conversations/${conversationId}/messages/`,
        method: "GET",
        params: { after },
      }),
      providesTags: (_response, _error, args) => [{ type: "ChatConversation", id: args.conversationId }],
      transformResponse: (response: ChatRawResponse): ChatResponse => {
        const messages = response.conversation.messages || [];
        const participants = response.conversation.participants || [];
        const convertedMessages = mapMessages(messages);
        const conversationParticipants = mapParticipants(participants);

        return { messages: convertedMessages, participants: conversationParticipants };
      },
    }),
  }),
});

export default getConversationMessagesApi;
export const { useGetConversationMessagesQuery, useLazyGetConversationMessagesQuery } = getConversationMessagesApi;
