import { CSSProperties, FC } from "react";

import { Close } from "@material-ui/icons";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  DrawerProps,
  IconButton,
  makeStyles,
} from "@portex-pro/ui-components";
import classNames from "classnames";
import cx from "clsx";
import Text from "components/Text";

const useStyles = makeStyles(() => ({
  customDrawerWidth(props: Pick<PortexDrawerProps, "width">) {
    if (props.width) {
      return {
        "& .MuiDrawer-paper": {
          width: props.width,
        },
      };
    }

    return {};
  },
}));

interface PortexDrawerProps extends DrawerProps {
  width?: CSSProperties["maxWidth"];
  headerTitle?: string;
  disableCloseButton?: boolean;
  renderActions?: (props: { onClose: () => void }) => JSX.Element | null;
  contentClassName?: string;
}

const PortexDrawer: FC<PortexDrawerProps> = ({
  children,
  headerTitle,
  width = 500,
  disableCloseButton = false,
  anchor = "right",
  renderActions,
  contentClassName,
  ...drawerProps
}) => {
  const classes = useStyles({ width });

  return (
    <Drawer {...drawerProps} className={cx(classes.customDrawerWidth, drawerProps.className)} anchor={anchor}>
      {!!headerTitle && (
        <DialogTitle disableTypography>
          <Text size="large" weight="bold">
            {headerTitle}
          </Text>
          <IconButton
            onClick={() => drawerProps.onClose?.({}, "backdropClick")}
            disabled={disableCloseButton}
            className="Por-close"
          >
            <Close />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent dividers className={classNames("Por-p-0", contentClassName)}>
        {children}
      </DialogContent>
      {renderActions && (
        <DialogActions>
          <div className="flex w-full justify-between">
            {renderActions({ onClose: () => drawerProps.onClose?.({}, "backdropClick") })}
          </div>
        </DialogActions>
      )}
    </Drawer>
  );
};

export default PortexDrawer;
