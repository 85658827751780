import { ComponentType } from "react";

import { useGetShipmentDetailsQuery } from "api/rest/shipments/shipmentsApi";
import { useLoadStatusUpdate } from "app/pages/shipments/hooks/useLoadStatusUpdate";
import {
  useSetIsEditLoadStatusDrawerOpen,
  useSetSelectedLoadId,
} from "app/pages/shipments/store/shipmentManagementSlice";
import { useShipmentsSliceSelector } from "app/pages/shipments/store/shipmentsStore";
import Frame from "components/Frame";
import LoadSelectView from "components/loads/LoadSelectView";
import withAsync from "components/withAsync";
import compact from "lodash/compact";
import { useParams } from "react-router-dom";

import LoadScoresTableView, {
  LoadScoresTableItem,
  RenderLoadScoreTableCellItemProps,
} from "../views/LoadScoresTableView";

type WithLoadStatusUpdateHoC = (
  Component: ComponentType<LoadScoresTableItem>
) => (props: RenderLoadScoreTableCellItemProps) => JSX.Element;

const withLoadStatusUpdate: WithLoadStatusUpdateHoC = function (Component) {
  return (props) => {
    const { stop, stops, loadId, isLoading } = props;
    const loadStatusUpdate = useLoadStatusUpdate(stop.id, loadId, !!isLoading);

    return (
      <Component
        stop={stop}
        stops={stops}
        loadStatusUpdate={loadStatusUpdate}
        isLoading={!!isLoading || loadStatusUpdate === null}
      />
    );
  };
};

const LoadScoresContainer = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();
    return useGetShipmentDetailsQuery({ urlParams: { shipmentId } });
  },
  Component: ({ loadedData }) => {
    const shipment = loadedData.data;
    const selectedLoadId = useShipmentsSliceSelector((state) => state.shipmentManagementSlice.selectedLoadId);
    const setSelectedLoadId = useSetSelectedLoadId();
    const setIsEditLoadStatusDrawerOpen = useSetIsEditLoadStatusDrawerOpen();
    const loads = compact(shipment.trucks)
      .map(({ id, referenceNumber, trackingLink }) => ({ id: id ?? 0, referenceNumber, trackingLink }))
      .filter(({ id }) => !!id);

    return (
      <Frame
        style={{ height: "100%" }}
        title={<LoadSelectView value={selectedLoadId} loads={loads} onChange={setSelectedLoadId} />}
      >
        <LoadScoresTableView
          stops={shipment.stops}
          loadId={selectedLoadId}
          loading={loadedData.isLoading}
          onClickEdit={(stopId) => setIsEditLoadStatusDrawerOpen({ open: true, stopId })}
          renderLocationCell={withLoadStatusUpdate(LoadScoresTableView.LocationCell)}
          renderScheduledCell={withLoadStatusUpdate(LoadScoresTableView.ScheduledCell)}
          renderCompletedCell={withLoadStatusUpdate(LoadScoresTableView.CompletedCell)}
          renderScoreCell={withLoadStatusUpdate(LoadScoresTableView.ScoreCell)}
          renderInternalNotesCell={withLoadStatusUpdate(LoadScoresTableView.InternalNotesCell)}
        />
      </Frame>
    );
  },
});

export default LoadScoresContainer;
