import { VFC } from "react";

import { ChatStatusUpdates, StatusUpdateKeys } from "components/chat/types/ChatStatusUpdates";

import StatusUpdateDispatchConfirmedView from "./StatusUpdateDispatchConfirmedView";
import StatusUpdateInvalidateShipmentStatus from "./StatusUpdateInvalidateShipmentStatus";
import StatusUpdateLoadAtRiskView from "./StatusUpdateLoadAtRiskView";
import StatusUpdateLoadCompletedLatePartnerNotAtFaultView from "./StatusUpdateLoadCompletedLatePartnerNotAtFaultView";
import StatusUpdateLoadCompletedLateView from "./StatusUpdateLoadCompletedLateView";
import StatusUpdateLoadCompletedView from "./StatusUpdateLoadCompletedView";
import StatusUpdateLoadDelayedView from "./StatusUpdateLoadDelayedView";
import StatusUpdateLoadOnSiteView from "./StatusUpdateLoadOnSiteView";
import StatusUpdateLoadOnTrackView from "./StatusUpdateLoadOnTrackView";
import StatusUpdateLoadPendingView from "./StatusUpdateLoadPendingView";
import StatusUpdateLoadStatusRequestView from "./StatusUpdateLoadStatusRequestView";
import StatusUpdateLoadTrackingLinkView from "./StatusUpdateLoadTrackingLinkView";
import StatusUpdateQuoteBookedView from "./StatusUpdateQuoteBookedView";
import StatusUpdateQuoteSubmittedView from "./StatusUpdateQuoteSubmittedView";
import StatusUpdateShipmentCanceledView from "./StatusUpdateShipmentCanceledView";
import StatusUpdateShipmentDetailsUpdatedView from "./StatusUpdateShipmentDetailsUpdatedView";
import StatusUpdateShipmentStatusRequestView from "./StatusUpdateShipmentStatusRequestView";

type StatusUpdateComponentsMap = {
  [K in StatusUpdateKeys]: VFC<{
    params: Extract<ChatStatusUpdates, { notification_key: K }>["notification_params"];
  }>;
};

const statusUpdateComponentsMap: StatusUpdateComponentsMap = {
  "quote-submitted": StatusUpdateQuoteSubmittedView,
  "quote-booked": StatusUpdateQuoteBookedView,
  "shipment-details-updated": StatusUpdateShipmentDetailsUpdatedView,
  "shipment-canceled": StatusUpdateShipmentCanceledView,
  "dispatch-confirmed": StatusUpdateDispatchConfirmedView,
  "load-status-request": StatusUpdateLoadStatusRequestView,
  "load-pending": StatusUpdateLoadPendingView,
  "load-on-track": StatusUpdateLoadOnTrackView,
  "load-on-site": StatusUpdateLoadOnSiteView,
  "load-at-risk": StatusUpdateLoadAtRiskView,
  "load-delayed": StatusUpdateLoadDelayedView,
  "load-completed": StatusUpdateLoadCompletedView,
  "load-completed-late": StatusUpdateLoadCompletedLateView,
  "load-completed-late-partner-not-at-fault": StatusUpdateLoadCompletedLatePartnerNotAtFaultView,
  "shipment-status-request": StatusUpdateShipmentStatusRequestView,
  "shipment-status-invalidation": StatusUpdateInvalidateShipmentStatus,
  "load-tracking-link": StatusUpdateLoadTrackingLinkView,
  "shipment-delivered": () => null, // @todo
};

interface StatusUpdateViewProps {
  chatStatusUpdate: ChatStatusUpdates | undefined;
}

const StatusUpdateView: VFC<StatusUpdateViewProps> = ({ chatStatusUpdate }) => {
  if (!chatStatusUpdate) {
    return null;
  }

  const Component = statusUpdateComponentsMap[chatStatusUpdate.notification_key];
  if (!Component) {
    return null;
  }

  return (
    <Component
      // @ts-expect-error: This should TS-error, because TypeScript can't infer this has been already narrowed by the map above.
      params={chatStatusUpdate.notification_params}
    />
  );
};

export default StatusUpdateView;
