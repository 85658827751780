import { oneSecondInMilliseconds } from "constants/time/oneSecondInMilliseconds";

import { ReactNode, useEffect, useState, VFC } from "react";

import RefreshIcon from "@material-ui/icons/Refresh";
import { Box, ButtonTile, CircularProgress, Icon, Tooltip } from "@portex-pro/ui-components";
import { useBoolean } from "usehooks-ts";

type RefetchButtonProps = {
  onClick: () => void;
  loading?: boolean;
  tooltip?: NonNullable<ReactNode>;
};

const ALLOWED_REFETCH_INTERVAL_MS = 5 * oneSecondInMilliseconds;

const RefetchButton: VFC<RefetchButtonProps> = ({ onClick, loading, tooltip }) => {
  const refetchDisallowed = useBoolean(false);
  const [countdownMs, setCountdownMs] = useState<number>(0);

  const handleClick = () => {
    if (refetchDisallowed.value) {
      return;
    }

    refetchDisallowed.setTrue();
    setCountdownMs(ALLOWED_REFETCH_INTERVAL_MS);
    onClick();
  };

  useEffect(() => {
    // Do not start countdown until loading is finished
    if (loading) {
      return;
    }

    let interval: ReturnType<typeof setInterval>;

    if (countdownMs === 0) {
      refetchDisallowed.setFalse();
    } else if (countdownMs > 0) {
      const decrementByMs = 100; // smaller number = smoother progress indicator
      interval = setInterval(() => {
        setCountdownMs((prevCountdown) => prevCountdown - decrementByMs);
      }, decrementByMs);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownMs, loading]);

  return (
    <ButtonTile
      style={{ border: "1px solid", cursor: refetchDisallowed.value ? "not-allowed" : "pointer" }}
      disabled={loading || refetchDisallowed.value}
      onClick={handleClick}
    >
      <Tooltip
        arrow
        placement="left"
        title={tooltip ?? ""}
        disableHoverListener={!tooltip}
        enterDelay={500}
        enterNextDelay={500}
      >
        <Box position="relative" top={1}>
          <Icon
            as={RefreshIcon}
            variant="contained"
            style={{ visibility: loading || refetchDisallowed.value ? "hidden" : "visible" }}
          />
          {loading || refetchDisallowed.value ? (
            <CircularProgress
              variant={!loading && countdownMs ? "determinate" : "indeterminate"}
              value={(countdownMs / ALLOWED_REFETCH_INTERVAL_MS) * 100}
              size="1.75rem"
              style={{ position: "absolute", top: "10%", left: "10%", zIndex: 1 }}
            />
          ) : null}
          {refetchDisallowed.value && !loading && (
            <div className="absolute flex items-center justify-center top-0 left-0 bottom-0 right-0 opacity-70">
              {Math.ceil(countdownMs / oneSecondInMilliseconds)}
            </div>
          )}
        </Box>
      </Tooltip>
    </ButtonTile>
  );
};

export default RefetchButton;
