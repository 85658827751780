import { useMemo, VFC } from "react";

import { BrokerSourceStatus, SourceOrigin } from "api/rest/shipments/types";
import { Shipper } from "api/rest/shippers/types";
import DropdownFilterView from "components/DropdownFilterView";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import SimpleSearchView from "components/SimpleSearchView";
import BrokerShippersFilter from "features/broker-shippers/BrokerShippersFilter";
import ModeButtonGroup from "pages/shipper/components/ModeButtonGroup";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { useTranslation } from "react-i18next";
import FiltersAppBar from "utils/layout/FiltersAppBar";

import { BrokerRequestsIndexFilterState } from "../store/brokerRequestsIndexFilterSlice";

interface BrokerRequestsFilterBarViewProps {
  filters?: JSX.Element;
  transportationMode: TransportationMode;
  searchQuery: string;
  statusFilter: BrokerSourceStatus[];
  originFilter: SourceOrigin[];
  shippersFilter: Shipper[];
  lastRefreshedAt?: string;
  isLoading: boolean;

  onChangeFilter: (changes: Partial<BrokerRequestsIndexFilterState>) => void;
  onRefresh: () => void;
}

const BrokerRequestsFilterBarView: VFC<BrokerRequestsFilterBarViewProps> = ({
  filters,
  transportationMode,
  searchQuery,
  statusFilter,
  originFilter,
  shippersFilter,
  lastRefreshedAt,
  isLoading,
  onChangeFilter,
  onRefresh,
}) => {
  const { t } = useTranslation(["broker"]);

  const quotesOptions = useMemo(
    (): { label: string; value: BrokerSourceStatus }[] => [
      {
        label: t("broker:requestsFilterBar_quote_status_REQUESTED"),
        value: "QUOTES_REQUESTED" as const,
      },
      {
        label: t("broker:requestsFilterBar_quote_status_RETURNED"),
        value: "QUOTES_RETURNED" as const,
      },
      {
        label: t("broker:requestsFilterBar_quote_status_BOOKED"),
        value: "QUOTES_BOOKED" as const,
      },
      {
        label: t("broker:requestsFilterBar_quote_status_CLOSED"),
        value: "QUOTES_CLOSED" as const,
      },
      {
        label: t("broker:requestsFilterBar_quote_status_CANCELED"),
        value: "QUOTES_CANCELED" as const,
      },
    ],
    [t]
  );

  const dispatchOptions = useMemo(
    (): { label: string; value: BrokerSourceStatus }[] => [
      {
        label: t("broker:requestsFilterBar_tenderStatus_REQUESTED"),
        value: "DISPATCHES_REQUESTED" as const,
      },
      {
        label: t("broker:requestsFilterBar_tenderStatus_MODIFIED"),
        value: "DISPATCHES_MODIFIED" as const,
      },
      {
        label: t("broker:requestsFilterBar_tenderStatus_REJECTED"),
        value: "DISPATCHES_REJECTED" as const,
      },
      {
        label: t("broker:requestsFilterBar_tenderStatus_CONFIRMED"),
        value: "DISPATCHES_CONFIRMED" as const,
      },
      {
        label: t("broker:requestsFilterBar_tenderStatus_CANCELED"),
        value: "DISPATCHES_CANCELED" as const,
      },
    ],
    [t]
  );

  const statusOptions = useMemo(() => quotesOptions.concat(dispatchOptions), [quotesOptions, dispatchOptions]);

  const originOptions = useMemo(
    (): { label: string; value: SourceOrigin }[] => [
      {
        label: t("broker:quoteRequests"),
        value: "QUOTES",
      },
      {
        label: t("broker:tenderRequests"),
        value: "DISPATCHES",
      },
    ],
    [t]
  );

  return (
    <FiltersAppBar className="shadow">
      <div className="flex justify-between">
        <div className="flex flex-wrap items-center gap-x-2 gap-y-2">
          <ModeButtonGroup
            value={transportationMode}
            onChange={(transportationMode) => onChangeFilter({ transportationMode })}
            includeModes={[TransportationMode.INTERMODAL]}
            disabledModes={[TransportationMode.Trucking]}
          />
          <DropdownFilterView
            placeholder={"Filter by Status"}
            selected={statusFilter}
            options={statusOptions}
            onChange={(statuses) => onChangeFilter({ statusFilter: statuses })}
            minWidth={180}
            groups={[
              {
                label: t("broker:quoteRequests"),
                values: quotesOptions.map((option) => option.value),
              },
              {
                label: t("broker:tenderRequests"),
                values: dispatchOptions.map((option) => option.value),
              },
            ]}
          />
          <DropdownFilterView
            placeholder={
              originFilter.length === 1
                ? originOptions.filter((option) => option.value === originFilter[0])[0].label
                : t("broker:allRequests")
            }
            selected={originFilter}
            options={originOptions}
            onChange={(originFilter) => onChangeFilter({ originFilter })}
            minWidth={180}
          />
          <BrokerShippersFilter
            shippers={shippersFilter}
            onChange={(shippers) => onChangeFilter({ shippersFilter: shippers })}
          />
          <SimpleSearchView
            search={searchQuery}
            onChange={(searchQuery) => onChangeFilter({ searchQuery })}
            placeholder={"Search"}
          />
          {filters}
        </div>
        <div className="whitespace-nowrap flex grow items-center justify-end">
          {lastRefreshedAt && <LastRefreshedAtText value={lastRefreshedAt} />}
          <div>
            <RefetchButton
              loading={isLoading}
              onClick={onRefresh}
              tooltip={t("broker:requestsFilterBar_refresh_button")}
            />
          </div>
        </div>
      </div>
    </FiltersAppBar>
  );
};

export default BrokerRequestsFilterBarView;
