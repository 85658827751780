import { useEffect } from "react";

import NotFound404 from "components/errors/NotFound404";
import Loading from "components/Loading";
import UnsavedChangesDialog from "components/UnsavedChangesDialog";
import withAsync from "components/withAsync";
import { useBlockNavigation } from "hooks/useBlockNavigation";
import useLDFlag from "hooks/useLDFlag";
import { Sentry } from "sentry";
import { NumberParam, useQueryParam } from "use-query-params";
import { useBoolean } from "usehooks-ts";

import BottomActionBarContainer from "./containers/BottomActionBarContainer";
import BrokerLoadStatusTableContainer from "./containers/BrokerLoadStatusTableContainer";
import LoadStatusPageChatTab from "./containers/LoadStatusPageChatTab";
import LoadStatusPageHeaderContainer from "./containers/LoadStatusPageHeaderContainer";
import useBrokerShipment from "./hooks/useBrokerShipment";
import useLazyGetLoadStatusUpdatesForStops from "./hooks/useLazyGetLoadStatusUpdatesForStops";
import { useInitialize, useResetState, useSetSelectedLoad } from "./store/brokerLoadStatusSlice";
import { useBrokerLoadStatusSlices, useBrokerLoadStatusSliceSelector } from "./store/brokerLoadStatusStore";

const BrokerLoadStatusPage = withAsync({
  useHook: () => {
    useBrokerLoadStatusSlices();
    const resetState = useResetState();
    const setSelectedLoad = useSetSelectedLoad();
    const [loadIdQueryParam, setLoadIdQueryParam] = useQueryParam("loadId", NumberParam);
    const { selectedLoadId } = useBrokerLoadStatusSliceSelector((state) => state.brokerLoadStatusSlice);

    const brokerShipmentQuery = useBrokerShipment();
    const shipment = brokerShipmentQuery.data?.data.shipment;
    const loads = shipment?.trucks.map((truck) => ({
      id: truck.id,
      referenceNumber: truck.reference_number,
      trackingLink: truck.tracking_link,
    }));

    if (!selectedLoadId && loads) {
      setSelectedLoad({
        loadId: loadIdQueryParam,
        loads,
      });
    }

    useEffect(() => {
      if (selectedLoadId === loadIdQueryParam) {
        return;
      }

      if (!!selectedLoadId) {
        resetState();
      }

      if (!!selectedLoadId && !loadIdQueryParam) {
        setLoadIdQueryParam(selectedLoadId);
      }

      if (loads) {
        setSelectedLoad({ loadId: loadIdQueryParam, loads });
      }
    }, [loadIdQueryParam, resetState, selectedLoadId, setLoadIdQueryParam, setSelectedLoad, loads]);

    return brokerShipmentQuery;
  },
  useHandleError: () => Sentry.captureException,
  LoadingComponent: <Loading showPortexLogo />,
  Component: ({ loadedData }) => {
    const shipmentId = loadedData.shipmentId;
    const shipment = loadedData?.data?.data?.shipment;
    const lazyGetLoadStatusUpdatesForStops = useLazyGetLoadStatusUpdatesForStops({ shipment });
    const [loadIdQueryParam, setLoadIdQueryParam] = useQueryParam("loadId", NumberParam);
    const { selectedLoadId, dirtyStopIds, isUninitialized, isValidPayload } = useBrokerLoadStatusSliceSelector(
      (state) => state.brokerLoadStatusSlice
    );
    const { currentTab } = useBrokerLoadStatusSliceSelector((state) => state.brokerLoadStatusUiSlice);
    const unsavedChangesDialogOpen = useBoolean(false);
    const resetState = useResetState();
    const initialize = useInitialize();
    const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
    const releaseBrokerChat = useLDFlag("releaseBrokerChat");

    const { navigateToLastLocation } = useBlockNavigation({
      isBlocked: !!dirtyStopIds.length || !!isValidPayload,
      onBlock: unsavedChangesDialogOpen.setTrue,
    });

    // After the shipment has loaded, we initialize once + lazy load the status updates for each stop on the shipment
    useEffect(() => {
      if (!isUninitialized) {
        return;
      }

      if (loadIdQueryParam === selectedLoadId) {
        initialize();
        (async () => lazyGetLoadStatusUpdatesForStops())();
      }

      // Only happens when an invalid value for the `loadId` query param is provided
      if (!!selectedLoadId && !!loadIdQueryParam && loadIdQueryParam !== selectedLoadId) {
        setLoadIdQueryParam(selectedLoadId, "replaceIn"); // using 'replaceIn' here instead of default 'pushIn' to keep history so the user can use native back button navigation
      }
    }, [
      initialize,
      isUninitialized,
      lazyGetLoadStatusUpdatesForStops,
      loadIdQueryParam,
      selectedLoadId,
      setLoadIdQueryParam,
    ]);

    if (loadedData.isError || !shipment) {
      return <NotFound404 showAppBar={!enableBrokerAppBar} useMarketingUrl />;
    }

    return (
      <>
        <LoadStatusPageHeaderContainer />
        {currentTab === "loads" && <BrokerLoadStatusTableContainer />}
        {currentTab === "loads" && <BottomActionBarContainer />}
        {currentTab === "chat" && !!releaseBrokerChat && (
          <LoadStatusPageChatTab shipmentId={shipmentId} conversationId={shipment.conversation_id} />
        )}
        <UnsavedChangesDialog
          isOpen={unsavedChangesDialogOpen.value}
          close={unsavedChangesDialogOpen.setFalse}
          onConfirm={() => {
            unsavedChangesDialogOpen.setFalse();
            navigateToLastLocation();
            resetState();
          }}
        />
      </>
    );
  },
});

export default BrokerLoadStatusPage;
