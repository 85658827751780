import { FC } from "react";

import { DeleteOutlined, RestoreFromTrash } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import BackButtonView from "components/BackButtonView";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  useDeleteShipperContractLaneMutation,
  useGetShipperContractLaneQuery,
  useGetShipperContractQuery,
  useUpdateShipperContractLaneMutation,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";
import { useBidAwardSliceSelector } from "../store/bidAwardStore";
import ContractLaneSummaryView from "./ContractLaneSummaryView";

const ContractLaneSummaryContainer: FC = () => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const { contractId, laneId } = useParams<{ contractId: string; laneId: string }>();
  const { data: contract, isLoading: isContractLoading } = useGetShipperContractQuery({ urlParams: { contractId } });
  const { data: lane, isLoading } = useGetShipperContractLaneQuery({ urlParams: { contractId, laneId } });
  const [deleteLane] = useDeleteShipperContractLaneMutation();
  const [updateLane] = useUpdateShipperContractLaneMutation();
  const currentTab = useBidAwardSliceSelector((state) => state.bidAwardUiSlice.currentTab);

  const backCopy = t("backToLanes_button", { context: currentTab });
  const backButtonTo = `/shipper/award/${contractId}`;

  const { abort, dialogElement } = useConfirmationDialog();

  const handleDelete = async () => {
    const aborted = await abort({ isDangerous: true });

    if (aborted) {
      return;
    }

    await deleteLane({ urlParams: { contractId, laneId } }).unwrap();
  };

  const handleUndelete = async () => {
    const aborted = await abort();

    if (aborted) {
      return;
    }

    await updateLane({ urlParams: { contractId, laneId }, body: { undelete: true } }).unwrap();
  };

  return (
    <div className="flex flex-col px-4 pt-2">
      <div className="flex justify-between">
        <BackButtonView backCopy={backCopy} to={backButtonTo} />
        {dialogElement}
        {lane?.deleted_at !== undefined && (
          <div className="flex gap-2 items-center">
            {lane.deleted_at === null && (
              <Button
                className="Por-bg-red"
                size="small"
                startIcon={<DeleteOutlined style={{ fontSize: 28 }} />}
                onClick={handleDelete}
              >
                {t("deleteLane")}
              </Button>
            )}

            {!!lane.deleted_at && (
              <span className="italic text-small">
                {t("laneDeletedOn", {
                  date: DateTime.fromISO(lane.deleted_at).toLocaleString(DateTime.DATETIME_SHORT),
                })}
              </span>
            )}
            {!!lane.deleted_at && (
              <Button
                size="small"
                variant="outlined"
                startIcon={<RestoreFromTrash style={{ fontSize: 28 }} />}
                onClick={handleUndelete}
              >
                {t("undeleteLane")}
              </Button>
            )}
          </div>
        )}
      </div>
      <ContractLaneSummaryView
        lane={lane}
        requestedCount={contract?.requested_count}
        isLoading={isLoading || isContractLoading}
      />
    </div>
  );
};

export default ContractLaneSummaryContainer;
