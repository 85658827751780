import { ModeRfp } from "types/Mode";
import { isValidSizeForType } from "types/TrailerSize";

import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import BidRequestContractType from "../../types/BidRequestContractType";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import TrailerSelectionView from "./components/TrailerSelectionView";

const nextDisabledMap: Record<ModeRfp, (bidRequest: BidRequestContractType) => boolean> = {
  FTL: (bidRequest) => !bidRequest.trailer_type || !bidRequest.trailer_size,
  INTERMODAL: (bidRequest) => !bidRequest.trailer_type || !bidRequest.trailer_size,
  LTL_PARTIAL: (bidRequest) => !bidRequest.trailer_type,
};

const TrailerSelectionContainer = withBidRequest(() => {
  const bidRequestState = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);
  const [updateContractRequest] = useUpdateContractRequest();
  const updateBidRequest = useUpdateBidRequest();
  const changeBidRequestRoute = useChangeBidRequestRoute();

  const nextDisabled = nextDisabledMap[bidRequestState.mode](bidRequestState);

  const onNext = () => {
    updateContractRequest();
    changeBidRequestRoute({ step: BidRequestPageSteps.BidAttributes });
  };

  return (
    <BidRequestProgressionView
      fullPage
      onBack={() => changeBidRequestRoute({ step: "initial" })}
      onNext={onNext}
      nextProps={{ disabled: nextDisabled }}
    >
      <TrailerSelectionView
        mode={bidRequestState.mode}
        minTemp={bidRequestState.min_temp}
        maxTemp={bidRequestState.max_temp}
        type={bidRequestState.trailer_type}
        size={bidRequestState.trailer_size}
        onChangeType={(value) => {
          updateBidRequest({ trailer_type: value });
          if (!isValidSizeForType(value, bidRequestState.trailer_size)) {
            updateBidRequest({ trailer_size: undefined });
          }
        }}
        onChangeSize={(value) => updateBidRequest({ trailer_size: value })}
        onChangeMinTemp={(value) => updateBidRequest({ min_temp: value })}
        onChangeMaxTemp={(value) => updateBidRequest({ max_temp: value })}
      />
    </BidRequestProgressionView>
  );
});

export default TrailerSelectionContainer;
