import { AppBar } from "@portex-pro/ui-components";
import withAsync from "components/withAsync";
import useEnableIntermodal from "hooks/useEnableIntermodal";
import useLDFlag from "hooks/useLDFlag";
import { DateTime } from "luxon";

import { useShipmentsList } from "../hooks/useShipmentsList";
import { useSetFilters } from "../store/shipmentListSlice";
import ShipmentListFilterContainer from "./ShipmentListFilterContainer";
import ShipmentListTopBarView from "./ShipmentListTopBarView";

const ShipmentListTopBarContainer = withAsync({
  useHook: useShipmentsList,
  Component: ({ loadedData }) => {
    const { isFetching, fulfilledTimeStamp, refetch, shipmentState } = loadedData;
    const { stateFilter, mode, searchQuery } = shipmentState;

    const setFilters = useSetFilters();
    const enableIntermodal = useEnableIntermodal();
    const enableDrayage = useLDFlag("enableDrayage");
    const enableLtlPartialMode = useLDFlag("enableLtlPartialMode");

    return (
      <AppBar>
        <ShipmentListTopBarView
          isLoading={isFetching}
          lastRefreshedAt={
            fulfilledTimeStamp !== undefined
              ? DateTime.fromMillis(fulfilledTimeStamp).toLocaleString(DateTime.DATETIME_SHORT)
              : ""
          }
          stateFilter={stateFilter}
          transportationMode={mode}
          searchQuery={searchQuery}
          onChangeModeFilter={(mode) => setFilters({ mode })}
          onChangeStateFilter={(stateFilter) => setFilters({ stateFilter })}
          onChangeSearchQuery={(searchQuery) => setFilters({ searchQuery })}
          onRefresh={refetch}
          filters={<ShipmentListFilterContainer />}
          disableIntermodal={!enableIntermodal}
          disableDrayage={!enableDrayage}
          disableLtlPartial={!enableLtlPartialMode}
        />
      </AppBar>
    );
  },
});

export default ShipmentListTopBarContainer;
