import { FC } from "react";

import { CloudDownloadOutlined } from "@material-ui/icons";
import { Box, Button } from "@portex-pro/ui-components";
import { BrokerContractType } from "api/rest/rfp/brokerBidContractApi/types/BrokerContractType";
import { File } from "api/types/generated-types";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import Text from "components/Text";
import { useTranslation } from "react-i18next";
import { downloadBlobFromUrl } from "utils/downloadBlobFromUrl";
import { renderSerializedNotes } from "utils/renderSerializedNotes";

import { EM_DASH } from "../../constants";

type BrokerBidViewNotesAndAttachmentsViewProps = {
  contract: BrokerContractType | undefined;
  files?: Partial<File>[] | null;
  dialogProps: PortexDialogProps;
};

const BrokerBidViewNotesAndAttachmentsView: FC<BrokerBidViewNotesAndAttachmentsViewProps> = (props) => {
  const { t } = useTranslation("broker");
  const { t: tCommon } = useTranslation("common");

  const { contract, dialogProps, files } = props;

  const {
    files: _attachments = [],
    notes,
    is_hazardous: isHazardous,
    hazardous_goods_details: hazardousNotes,
    commodities,
  } = contract ?? {};

  return (
    <PortexDialog
      title={t("bidSubmit.dialogs.viewNotesAttachments.title")}
      hideDialogActions
      customDialogWidth="400px"
      {...dialogProps}
    >
      <Box p={3} display="flex" flexDirection="column" gridRowGap={15}>
        <Box>
          <Text size="small" weight="bold">
            {t("bidSubmit.dialogs.viewNotesAttachments.subtitle_attachments")}
          </Text>
          <Box display="flex" flexDirection="column">
            {files?.length ? (
              files?.map((file) => {
                const fileName = file.name;
                const fileUrl = file.url;
                if (!fileName || !fileUrl) {
                  return null;
                }
                return (
                  <Box>
                    <Button
                      key={fileName + fileUrl}
                      color="primary"
                      startIcon={<CloudDownloadOutlined style={{ fontSize: 20 }} />}
                      onClick={() => downloadBlobFromUrl(fileUrl, fileName)}
                    >
                      <Text size="small">{file.name}</Text>
                    </Button>
                  </Box>
                );
              })
            ) : (
              <Box p={1}>
                <Text size="small">{EM_DASH}</Text>
              </Box>
            )}
          </Box>
        </Box>

        <Box>
          <Text size="small" weight="bold">
            {t("bidSubmit.dialogs.viewNotesAttachments.subtitle_notes")}
          </Text>
          <Box p={1}>
            <Text size="small">{renderSerializedNotes(notes || EM_DASH)}</Text>
          </Box>
        </Box>

        <Box>
          <Text size="small" weight="bold">
            {tCommon("commodities")}
          </Text>
          <Box p={1}>
            <Text size="small">{commodities?.split(",").join(", ") || EM_DASH}</Text>
          </Box>
        </Box>
        {isHazardous && (
          <>
            <Box>
              <Text size="small" weight="bold">
                {tCommon("hazardousCommodities")}
              </Text>
              <Box p={1}>
                <Text size="small" weight="bold" typographyProps={{ style: { color: "red" } }}>
                  {tCommon("containsHazardous")}
                </Text>
              </Box>
            </Box>
            <Box>
              <Text size="small" weight="bold">
                {tCommon("hazardousDescription")}
              </Text>
              <Box p={1}>
                <Text size="small">{renderSerializedNotes(hazardousNotes || EM_DASH)}</Text>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </PortexDialog>
  );
};

export default BrokerBidViewNotesAndAttachmentsView;
