import { useCallback, VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import useEnableIntermodal from "hooks/useEnableIntermodal";
import useLDFlag from "hooks/useLDFlag";
import { useEffectOnce } from "usehooks-ts";

import { useCreateContractRequestMutation } from "../../api/bidRequestApi";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import { useResetBidRequest, useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import CreateNewBidView from "./components/CreateNewBidView";
import SelectBidType from "./components/SelectBidType";

const CreateBidContainer: VFC = () => {
  const bidRequestState = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);
  const updateBidRequest = useUpdateBidRequest();
  const resetBidRequest = useResetBidRequest();
  const [createContractRequest] = useCreateContractRequestMutation();
  const changeBidRequestRoute = useChangeBidRequestRoute();
  const enableIntermodal = useEnableIntermodal();
  const enableLtlPartialMode = useLDFlag("enableLtlPartialMode");

  const handleCreateNewBid = useCallback(async () => {
    const res = await createContractRequest(bidRequestState).unwrap();

    changeBidRequestRoute({ step: BidRequestPageSteps.Trailer, rfpId: res.id });
  }, [bidRequestState, createContractRequest, changeBidRequestRoute]);

  useEffectOnce(() => {
    resetBidRequest();
  });

  return (
    <Box p={2}>
      <SelectBidType
        value={bidRequestState.mode}
        onChange={(value) => updateBidRequest({ mode: value })}
        disableIntermodal={!enableIntermodal}
        disableLtlPartial={!enableLtlPartialMode}
      />
      <CreateNewBidView
        bidDeadline={bidRequestState.deadline}
        bidTitle={bidRequestState.title ?? ""}
        onBidDeadlineChange={(value) => updateBidRequest({ deadline: value?.toISO() })}
        onBidTitleChange={(value) => updateBidRequest({ title: value })}
        onSubmit={handleCreateNewBid}
        boxProps={{ width: "100%", py: "1rem" }}
      />
    </Box>
  );
};

export default CreateBidContainer;
