import { QuoteRequest } from "api/rest/quote-requests";
import { useGetBrokerQuoteRequestQuery } from "api/rest/quote-requests/getBrokerQuoteRequest";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import useLDFlag from "hooks/useLDFlag";
import first from "lodash/first";
import { StringParam, useQueryParam } from "use-query-params";

import { Conversation, useFindManyConversationsQuery } from "../api/findManyConversations";

type HookArgs = {
  quoteRequestGuid: QuoteRequest["guid"];
};

type HookResult = {
  conversationId: Conversation["id"] | null;
};

export const useConversationForQuoteRequest = ({ quoteRequestGuid }: HookArgs): HookResult => {
  const [uutQueryParam] = useQueryParam("uut", StringParam);
  const releaseBrokerChat = useLDFlag("releaseBrokerChat");
  const { quoteRequest } = useGetBrokerQuoteRequestQuery(
    { urlParams: { quoteRequestGuid: quoteRequestGuid ?? "should-skip" } },
    {
      skip: !quoteRequestGuid,
      selectFromResult: ({ data }) => ({ quoteRequest: data?.data.quoteRequest }),
    }
  );
  const { user } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({ user: data?.data.user }),
  });

  const result = useFindManyConversationsQuery(
    {
      queryParams: { quoteRequestId: quoteRequest?.id, take: 1 },
      variation: "broker",
    },
    {
      skip:
        !releaseBrokerChat ||
        !quoteRequest?.id ||
        !user ||
        !!user.shipper_id ||
        (!user.is_claimed && !user.shipper_id && !uutQueryParam),
      selectFromResult: ({ data }) => ({ conversationId: first(data?.data.conversations)?.id ?? null }),
    }
  );

  return {
    conversationId: result.conversationId,
  };
};
