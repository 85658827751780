import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse, UrlParam } from "api/rest/types/responses";

import { FeaturedBrokerQuoteRequestData } from "./types";

type UrlParams = {
  quoteRequestId: UrlParam;
};

type QueryArgs = {
  urlParams: UrlParams;
};

type ResponseType = ApiResponse<FeaturedBrokerQuoteRequestData>;

module.hot?.accept();

export const getFeaturedBrokerQuoteRequestDataApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getFeaturedBrokerQuoteRequestData: builder.query<ResponseType, QueryArgs>({
      query: ({ urlParams }) => ({
        method: "GET",
        url: `broker/featured/quote-requests/${urlParams.quoteRequestId}`,
      }),
    }),
  }),
});

export const { useGetFeaturedBrokerQuoteRequestDataQuery } = getFeaturedBrokerQuoteRequestDataApi;
