enum BidRequestPageSteps {
  Trailer = "trailer",
  BidAttributes = "attributes",
  Fields = "fields",
  Csv = "csv",
  Partners = "partners",
  Team = "team",
  Review = "review",
  Done = "done",
}

export default BidRequestPageSteps;
