import { VFC, useState } from "react";

import { useShipmentsSliceSelector } from "../store/shipmentsStore";
import ShipmentListFilterDialogContainer from "./ShipmentListFilterDialogContainer";
import ShipmentListFilterView from "./ShipmentListFilterView";

const ShipmentListFilterContainer: VFC = () => {
  const {
    mode: _mode,
    pagination: _pagination,
    searchQuery: _search,
    stateFilter: _stateFilters,
    ...filters
  } = useShipmentsSliceSelector((state) => state.shipmentListSlice);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <ShipmentListFilterView {...filters} openDialog={() => setIsDialogOpen(true)} />
      <ShipmentListFilterDialogContainer isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
    </>
  );
};

export default ShipmentListFilterContainer;
