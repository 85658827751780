import { ComponentProps, ReactElement, useMemo } from "react";

import { gql } from "@apollo/client";
import { Box, Paper, Typography } from "@material-ui/core";
import { TextInput } from "@portex-pro/ui-components";
import QuoteRequestFilesControlContainer from "app/pages/request-management/containers/quotes/QuoteRequestFilesControlContainer";
import AttachFileFromButtonContainer from "components/file-uploads/AttachFileFromButtonContainer";
import AttachmentsListContainer from "components/file-uploads/AttachmentsListContainer";
import useLDFlag from "hooks/useLDFlag";
import compact from "lodash/compact";
import { useTranslation } from "react-i18next";

import { OptionalMaybe, QuoteRequest } from "../../../../../../../api/types/generated-types";
import Loading from "../../../../../../../components/Loading";
import RecipientTags from "../../../../../../../components/RecipientTags";
import QuoteSubmissionDetails from "../../../../../components/QuoteSubmissionDetails";

const QUOTE_REQUEST = gql`
  fragment ReviewSendQuoteRequest_QuoteRequest on QuoteRequest {
    id
    recipients {
      id
      contact {
        id
        user {
          email
        }
        is_internal
      }
    }
    recipients_pending_response {
      id
      user {
        email
      }
    }
    user {
      id
      email
    }
  }
`;

interface ReviewSendQuoteRequestProps {
  quoteRequest?: OptionalMaybe<QuoteRequest>;
  publicQuoteRequest?: ComponentProps<typeof QuoteSubmissionDetails>["publicQuoteRequest"];
}

const ReviewSendQuoteRequest = ({ quoteRequest, publicQuoteRequest }: ReviewSendQuoteRequestProps): ReactElement => {
  const { t } = useTranslation(["shipper"]);
  const releaseQuoteRequestFiles = useLDFlag("releaseQuoteRequestFiles");

  const [from, to, cc, bcc] = useMemo<[string[], string[], string[], string[]]>(() => {
    const { recipients, user, recipients_pending_response } = quoteRequest || {};
    const contacts = recipients?.map((r) => r.contact);
    const team = contacts?.filter((c) => !!c.is_internal).map((c) => c.user.email) || [];

    const from = ["no-reply@email.portexpro.com"];
    const to = compact([user?.email]);
    const partners =
      compact(recipients_pending_response?.map((c) => (to.includes(c.user.email) ? null : c.user.email))) || [];

    return [from, to, team, partners];
  }, [quoteRequest]);

  if (!quoteRequest || !publicQuoteRequest) return <Loading spinnerOnly height="100%" />;

  return (
    <QuoteRequestFilesControlContainer quoteRequestId={Number(quoteRequest.id)}>
      <Paper
        variant="outlined"
        className="Por-bg-grey50"
        style={{
          flexGrow: 1,
          minHeight: 0,
          margin: "0 24px 16px",
          overflow: "auto",
        }}
      >
        <Box p={3}>
          <Box
            display="grid"
            gridTemplateColumns="max-content 1fr max-content 1fr"
            alignItems="center"
            mb={2}
            style={{ columnGap: 16, rowGap: 12 }}
          >
            <Typography style={{ minWidth: 60 }}>
              <b>{t("shipper:from")}</b>
            </Typography>
            <RecipientTags recipients={from} />
            <Typography style={{ minWidth: 0 }}>
              <b>{t("shipper:to")}</b>
            </Typography>
            <RecipientTags recipients={to} />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="max-content 1fr"
            alignItems="center"
            mb={2}
            style={{ columnGap: 16, rowGap: 12 }}
          >
            <Typography>
              <b>cc</b>
            </Typography>
            <RecipientTags recipients={cc} limit={3} />
            <Typography>
              <b>bcc</b>
            </Typography>
            <RecipientTags recipients={bcc} limit={3} />
            <Typography style={{ minWidth: 60 }}>
              <b>{t("shipper:subject")}</b>
            </Typography>
            <TextInput value={publicQuoteRequest?.subject_line ?? ""} fullWidth style={{ pointerEvents: "none" }} />
          </Box>
          {!!releaseQuoteRequestFiles && (
            <div className="flex pb-4 justify-end">
              <AttachFileFromButtonContainer />
            </div>
          )}
          <Paper variant="outlined" className="Por-outlined-light">
            <QuoteSubmissionDetails
              publicQuoteRequest={publicQuoteRequest}
              isBrokerPreview
              attachmentsList={!!releaseQuoteRequestFiles && <AttachmentsListContainer showDashOnNoAttachments />}
            />
          </Paper>
        </Box>
      </Paper>
    </QuoteRequestFilesControlContainer>
  );
};

ReviewSendQuoteRequest.fragments = {
  QuoteRequest: QUOTE_REQUEST,
};

export default ReviewSendQuoteRequest;
