import { useMemo, VFC } from "react";

import VerticalStepperView from "components/VerticalStepperView";
import { DateTime } from "luxon";
import { getFtlTrailerSize } from "pages/shipper/utils/getFtlTrailerSize";
import { useTranslation } from "react-i18next";
import { formatTrailerType } from "utils/formatTrailerType";

import { EN_DASH } from "../../../../constants";
import BidRequestContractType from "../types/BidRequestContractType";

type RequestBidSidebarViewProps = {
  bidRequest: BidRequestContractType;
};

const RequestBidSidebarView: VFC<RequestBidSidebarViewProps> = (props) => {
  const { t } = useTranslation("shipper", { keyPrefix: "bids" });

  const items = useMemo(() => {
    const formattedStart = DateTime.fromISO(props.bidRequest.start ?? "")?.toLocaleString(DateTime.DATE_SHORT);
    const formattedEnd = DateTime.fromISO(props.bidRequest.end ?? "")?.toLocaleString(DateTime.DATE_SHORT);
    const formattedContractPeriod = `${formattedStart} ${EN_DASH} ${formattedEnd}`;
    const formattedDeadline = DateTime.fromISO(props.bidRequest.deadline ?? "")?.toLocaleString(DateTime.DATE_FULL);
    const formattedTrailer = `${getFtlTrailerSize(props.bidRequest.trailer_size)} ${formatTrailerType(
      props.bidRequest.trailer_type
    )}`;
    const includeFuelCopy = props.bidRequest.include_fuel
      ? t("bidRequestPage.pageBidAttributes.withFuelButton_label")
      : t("bidRequestPage.pageBidAttributes.withoutFuelButton_label");
    const formattedCommodities = props.bidRequest.commodities?.split(",").join(", ");

    const summaryItems = [
      {
        title: t("summaryItems.title"),
        subtitle: props.bidRequest.title,
        hide: !props.bidRequest.title,
      },
      {
        title: t("summaryItems.mode"),
        subtitle: t(`bidRequestPage.modeOfTransporationSummary.${props.bidRequest.mode}`),
        hide: !props.bidRequest.mode,
      },
      {
        title: t("summaryItems.deadline"),
        subtitle: formattedDeadline,
        hide: !props.bidRequest.deadline,
      },
      {
        title: t("summaryItems.equipment"),
        subtitle: formattedTrailer,
        hide: !props.bidRequest.trailer_type || !props.bidRequest.trailer_size,
      },
      {
        title: t("summaryItems.contractPeriod"),
        subtitle: formattedContractPeriod,
        hide: !props.bidRequest.start || !props.bidRequest.end,
      },
      {
        title: t("summaryItems.commodities"),
        subtitle: formattedCommodities,
        hide: !props.bidRequest.commodities,
      },
      {
        title: t("summaryItems.rates"),
        subtitle: includeFuelCopy,
        hide: typeof props.bidRequest.include_fuel !== "boolean",
      },
      {
        title: t("summaryItems.lanes"),
        subtitle: String(props.bidRequest.lanes_count),
        hide: typeof props.bidRequest.lanes_count !== "number" || props.bidRequest.lanes_count <= 0,
      },
    ];

    return summaryItems.filter((item) => {
      return !item.hide;
    });
  }, [
    props.bidRequest.mode,
    props.bidRequest.start,
    props.bidRequest.end,
    props.bidRequest.deadline,
    props.bidRequest.trailer_size,
    props.bidRequest.trailer_type,
    props.bidRequest.include_fuel,
    props.bidRequest.commodities,
    props.bidRequest.title,
    props.bidRequest.lanes_count,
    t,
  ]);

  return <VerticalStepperView title={t("award.bidSummary")} items={items} boxProps={{ width: "100%" }} />;
};

export default RequestBidSidebarView;
