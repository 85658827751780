import { useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetBrokerLaneBenchmarkQuery } from "api/rest/lane-benchmark/getBrokerLaneBenchmarkApi";
import { BenchmarkLaneArg } from "api/rest/lane-benchmark/types";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import BrokerLaneBenchmarkView from "components/lane-benchmark/BrokerLaneBenchmarkView";
import withAsync from "components/withAsync";
import { withRequired } from "components/withRequired";
import useLDFlag from "hooks/useLDFlag";
import { Mode, ModeQuotes } from "types/Mode";
import { StringParam, useQueryParam } from "use-query-params";

type GenericGraphqlQuoteRequest = {
  mode: ModeQuotes | "LCL";
  shipper_id: string;
  locations: Array<
    | {
        position: number;
        city?: string | null | undefined;
        province_code?: string | null | undefined;
        province_name?: string | null | undefined;
      }
    | null
    | undefined
  >;
};

type GenericRestQuoteRequest = {
  mode: Mode;
  shipper_id: number;
  stops: Array<{
    address: {
      city?: string | null | undefined;
      province_code?: string | null | undefined;
      province_name?: string | null | undefined;
    } | null;
  }>;
};

type LaneBenchmarkProps = {
  quoteRequest: GenericGraphqlQuoteRequest | GenericRestQuoteRequest | undefined | null;
};

const isGraphqlQuoteRequest = (qr: LaneBenchmarkProps["quoteRequest"]): qr is GenericGraphqlQuoteRequest => {
  // @ts-expect-error if locations exists then this check is valid
  return !!qr.locations;
};

const isRestQuoteRequest = (qr: LaneBenchmarkProps["quoteRequest"]): qr is GenericRestQuoteRequest => {
  // @ts-expect-error if stops exists then this check is valid
  return !!qr.stops;
};

const useGenericStops = (quoteRequest: LaneBenchmarkProps["quoteRequest"]) =>
  useMemo(() => {
    if (isGraphqlQuoteRequest(quoteRequest)) {
      const lane: BenchmarkLaneArg = quoteRequest.locations
        .filter((stop) => !!stop)
        .sort((stopA, stopB) => (stopA?.position ?? 0) - (stopB?.position ?? 0))
        .map((stop, index) => ({
          city: stop?.city || undefined,
          province: stop?.province_code || stop?.province_name || undefined,
          position: index + 1,
        }));

      return lane;
    } else if (isRestQuoteRequest(quoteRequest)) {
      const lane: BenchmarkLaneArg = quoteRequest.stops
        .filter((stop) => !!stop)
        .map((stop, index) => ({
          city: stop?.address?.city || undefined,
          province: stop?.address?.province_code || stop?.address?.province_name || undefined,
          position: index + 1,
        }));

      return lane;
    }

    return;
  }, [quoteRequest]);

const BrokerLaneBenchmark = withRequired<LaneBenchmarkProps, "quoteRequest">({
  requiredKeys: ["quoteRequest"],
  Component: withAsync({
    useHook: ({ quoteRequest }) => {
      const mode = quoteRequest.mode;
      const lane = useGenericStops(quoteRequest);
      const getCurrentUserQuery = useGetCurrentUserQuery();
      const user = getCurrentUserQuery.data?.data.user;
      const [uutQueryParam] = useQueryParam("uut", StringParam);

      const isEnabled = useLDFlag("enableBrokerBenchmark");

      const results = useGetBrokerLaneBenchmarkQuery(
        !isEnabled || !lane || !user || (!user.is_claimed && !user.shipper_id && !uutQueryParam)
          ? skipToken
          : {
              queryParams: { lane, mode, shipperId: Number(quoteRequest.shipper_id) },
            }
      );

      if (!isEnabled) {
        return {
          isLoading: true,
        };
      }

      return results;
    },
    Component: ({ loadedData, quoteRequest }) => {
      const lane = useGenericStops(quoteRequest);
      const laneString = lane?.map((stop) => [stop.city, stop.province].filter(Boolean).join(", ")).join(" to ") ?? "";

      const { lastLost, lastQuote, lastWon } = loadedData.data.data;

      if (!lastLost && !lastQuote && !lastWon) {
        return null;
      }

      return <BrokerLaneBenchmarkView data={loadedData.data.data} laneString={laneString} />;
    },
  }),
});

export default BrokerLaneBenchmark;
