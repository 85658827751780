import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse, UrlParam } from "api/rest/types/responses";
import { QuoteStatus } from "types/QuoteStatus";

import { AllowedUpdateStatus, ShipperQuoteRequest, ShipperUpdateQuoteRequestBody } from "./types";

type UrlParams = {
  quoteRequestId: UrlParam;
};

type Body = Partial<ShipperQuoteRequest> & { bookedQuoteId?: number };

type QueryArgs = {
  urlParams: UrlParams;
  body: Body;
};

type ResponseType = ApiResponse<ShipperQuoteRequest>;

const allowedUpdateStatus: Record<AllowedUpdateStatus, AllowedUpdateStatus> = {
  BOOKED: "BOOKED",
  CLOSED: "CLOSED",
  SENT: "SENT",
};

const isAllowedStatus = (status: QuoteStatus | undefined): status is AllowedUpdateStatus =>
  !!status && status in allowedUpdateStatus;

const mapShipperQuoteRequestToUpdateBody = (quoteRequest: Body): ShipperUpdateQuoteRequestBody => ({
  additionalNotes: quoteRequest.note,
  deadline: quoteRequest.deadline_respond_at,
  hazardousGoodsDetails: quoteRequest.hazardous_goods_details,
  includeCustomsClearance: quoteRequest.include_customs_clearance,
  incoterms: quoteRequest.incoterms,
  insuranceRequired: quoteRequest.insurance_required,
  isHazardous: quoteRequest.is_hazardous,
  isOverweight: quoteRequest.is_overweight,
  partners: quoteRequest.partners?.map((partner) => ({ contactId: partner.contact_id })),
  referenceNumber: quoteRequest.reference_number,
  routingType: quoteRequest.routing_type,
  status: isAllowedStatus(quoteRequest?.status) ? quoteRequest.status : undefined,
  stops: quoteRequest.stops?.map<Exclude<ShipperUpdateQuoteRequestBody["stops"], undefined>[0]>((stop) => ({
    start: stop.start,
    end: stop.end,
    isTimeTbd: stop.is_time_tbd ?? false,
    address:
      !!stop.address && !!Object.values(stop.address).filter(Boolean).length
        ? {
            id: stop.address?.id,
            name: stop.address?.name,
            address1: stop.address?.address_1,
            address2: stop.address?.address_2,
            city: stop.address?.city,
            zip: stop.address?.zip,
            countryCode: stop.address?.country_code,
            countryName: stop.address?.country_name,
            provinceCode: stop.address?.province_code,
            provinceName: stop.address?.province_name,
            googlePlaceId: stop.address?.google_place_id,
            googlePlaceDescription: stop.address?.google_place_description,
            type: stop.address?.type,
          }
        : {},
  })),
  teammates: quoteRequest.teammates?.map((teammate) => ({ contactId: teammate.contact_id })),
  unloadingDetails: quoteRequest.unloading_details,
  bookedQuoteId: quoteRequest.bookedQuoteId,
  fileIds: quoteRequest.fileIds,
});

module.hot?.accept();

export const updateShipperQuoteRequestApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["QUOTE_REQUESTS"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      updateShipperQuoteRequest: builder.mutation<ResponseType, QueryArgs>({
        query: ({ urlParams, body }) => ({
          method: "PUT",
          url: `shipper/quote-requests/${urlParams.quoteRequestId}`,
          body: mapShipperQuoteRequestToUpdateBody(body),
        }),
        invalidatesTags: ["QUOTE_REQUESTS"],
      }),
    }),
  });

export const { useUpdateShipperQuoteRequestMutation } = updateShipperQuoteRequestApi;
