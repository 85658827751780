import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { useAuth0, User as Auth0User } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";

import { Maybe, Query, User } from "../../../api/types/generated-types";
import { Sentry } from "../../../sentry";
import { isPortexAuthorizationError } from "../../../utils/errors/isPortexAuthorizationError";
import { isPortexConnectionError } from "../../../utils/errors/isPortexConnectionError";
import { isUserAssociatedToCompany } from "../utils/isUserAssociatedToCompany";

export const USER = gql`
  fragment UserContext_User on User {
    id
    portex_admin
    portex_super_admin
    impersonating
    auth_0_user_id
    first_name
    last_name
    email
    is_active
    is_claimed
    shipper {
      id
      name
      has_made_quote_request
    }
    transportation_provider {
      id
      name
    }
    user_default {
      id
      per_truck_notes
    }
    feature_flag_demo
    feature_flag_internal
  }
`;

export const GET_CURRENT_USER: TypedDocumentNode<Pick<Query, "getCurrentUser">> = gql`
  query {
    getCurrentUser {
      ...UserContext_User
    }
  }
  ${USER}
`;

export interface UserContextType {
  auth0User: Maybe<Auth0User>;
  isAuthenticated: boolean;
  isCompanyAssociated: boolean;
  user: Maybe<User>;
  refreshUserInfo: () => void;
  isLoading: boolean;
  portexConnectionError: boolean;
  demoEnabledFeature: boolean;
  internalOnlyFeature: boolean;
  hasMadeQuoteRequest: boolean;
}

export const UserContext = createContext<UserContextType>({
  auth0User: null,
  isAuthenticated: false,
  isCompanyAssociated: false,
  user: null,
  refreshUserInfo: () => undefined,
  isLoading: true,
  portexConnectionError: false,
  demoEnabledFeature: false,
  internalOnlyFeature: false,
  hasMadeQuoteRequest: false,
});

UserContext.displayName = "UserContext";

const UserProvider = (props: Omit<React.ProviderProps<UserContextType>, "value">): JSX.Element | null => {
  const { user: auth0User, isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<UserContextType["user"]>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [loadFailed, setLoadFailed] = useState(false);
  const [isCompanyAssociated, setIsCompanyAssociated] = useState(false);
  const [portexConnectionError, setPortexConnectionError] = useState(false);

  const { refetch: getCurrentUser } = useQuery(GET_CURRENT_USER, {
    skip: true,
    fetchPolicy: "cache-and-network",
  });

  const getUser = useCallback(
    async (option?: { refresh: boolean }) => {
      try {
        if (!option?.refresh) {
          setUserLoading(true);
        }

        const {
          data: { getCurrentUser: currentUser },
        } = await getCurrentUser();

        if (currentUser) {
          setUser(currentUser);
          setIsCompanyAssociated(isUserAssociatedToCompany(currentUser));
        }
      } catch (e) {
        if (isPortexAuthorizationError(e)) return;
        else if (isPortexConnectionError(e)) {
          enqueueSnackbar("There is an issue connecting to Portex.  We may be experiencing some unexpected downtime.", {
            variant: "error",
            preventDuplicate: true,
            persist: true,
          });
        } else {
          enqueueSnackbar(e instanceof Error ? e.message || e.name : "An unknown error has occurred", {
            variant: "error",
            preventDuplicate: true,
            persist: true,
          });
        }

        setPortexConnectionError(true);

        console.error("Failed to getCurrentUser", e);
        Sentry.captureException(e, {
          extra: {
            component: "UserContext",
            functionName: "getCurrentUser",
            errorStack: e instanceof Error ? e?.stack : undefined,
          },
        });
        setLoadFailed(true);
      } finally {
        setUserLoading(false);
      }
    },
    [enqueueSnackbar, getCurrentUser]
  );

  useEffect(() => {
    if (!user && isAuthenticated && !isAuthLoading && !loadFailed) {
      (async () => {
        await getUser();
      })();
    } else {
      setUserLoading(false);
    }
  }, [
    user,
    isAuthenticated,
    isAuthLoading,
    getCurrentUser,
    setIsCompanyAssociated,
    enqueueSnackbar,
    getUser,
    loadFailed,
  ]);

  const loading = useMemo(() => {
    return isAuthLoading || userLoading;
  }, [isAuthLoading, userLoading]);

  const refreshUserInfo = useCallback(async () => {
    await getUser({ refresh: true });
  }, [getUser]);

  const value = useMemo(() => {
    return {
      auth0User: auth0User || null,
      isAuthenticated,
      isCompanyAssociated,
      user: isAuthenticated ? user : null,
      refreshUserInfo: refreshUserInfo,
      isLoading: loading,
      portexConnectionError,
      demoEnabledFeature: user?.feature_flag_demo ?? false,
      internalOnlyFeature: user?.feature_flag_internal ?? false,
      hasMadeQuoteRequest: user?.shipper?.has_made_quote_request ?? false,
    };
  }, [auth0User, isAuthenticated, isCompanyAssociated, loading, portexConnectionError, refreshUserInfo, user]);

  return <UserContext.Provider {...props} value={value} />;
};

export default UserProvider;
