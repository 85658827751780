import { useGetScoresForContactQuery } from "api/rest/scores";
import Frame from "components/Frame";
import withAsync from "components/withAsync";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";

import OverallScoreView from "../views/OverallScoreView";
import PartnerScoreView from "../views/PartnerScoreView";

const OverallScoreContainer = withAsync({
  useHook: () => {
    const { shipment } = useShipmentDetails();

    return useGetScoresForContactQuery({
      urlParams: { contactId: shipment.partner_contact_id },
      queryParams: { shipmentId: shipment.id },
    });
  },
  Component: ({ loadedData }) => {
    const historicalScore = loadedData.data.data;
    const shipmentScore = loadedData.data.shipmentScore;

    return (
      <>
        <Frame>
          <OverallScoreView
            overall={{
              score: shipmentScore?.overallScore ?? 0,
              TrendViewProps: {
                value: ((shipmentScore?.overallScore ?? 0) - historicalScore.overallScore) / 100,
                className: "font-bold",
              },
            }}
            pickup={{
              score: shipmentScore?.pickupScore ?? 0,
              TrendViewProps: {
                value: ((shipmentScore?.pickupScore ?? 0) - historicalScore.pickupScore) / 100,
                iconProps: { fontSize: "small" },
                className: "font-bold text-[10px] [&&]:gap-1",
              },
            }}
            delivery={{
              score: shipmentScore?.deliveryScore ?? 0,
              TrendViewProps: {
                value: ((shipmentScore?.deliveryScore ?? 0) - historicalScore.deliveryScore) / 100,
                iconProps: { fontSize: "small" },
                className: "font-bold text-[10px] [&&]:gap-1",
              },
            }}
          />
        </Frame>
        <Frame>
          <PartnerScoreView score={historicalScore.overallScore} />
        </Frame>
      </>
    );
  },
});

export default OverallScoreContainer;
