import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from "react";

import { gql, TypedDocumentNode, useLazyQuery, useMutation } from "@apollo/client";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import {
  Box,
  Button,
  Dropdown,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  portexColor,
  Toolbar,
  Typography,
  useTableRenderer,
} from "@portex-pro/ui-components";
import type { ShipperPartners } from "api/rest/shipperPartners";
import { useUploadPartnersMutation } from "api/rest/shipperPartners";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import Loading from "components/Loading";
import ThrottledTextInput from "components/ThrottledTextInput";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import DeleteAllPartnersContainer from "pages/shipper/components/DeleteAllPartnersContainer";
import PartnerDromoImport from "pages/shipper/components/PartnerDromoImport";
import TagsFilterContainer from "pages/shipper/components/tags/TagsFilterContainer";
import TagsLimitView from "pages/shipper/components/tags/TagsLimitView";
import { useTranslation } from "react-i18next";
import { DelimitedArrayParam, NumberParam, StringParam, useQueryParams } from "use-query-params";
import { useEffectOnce } from "usehooks-ts";

import {
  Contact,
  Ensure,
  Mutation,
  MutationDeleteContactArgs,
  MutationUpdatePartnerArgs,
  Query,
  QueryFindPartnersArgs,
  SortDirectionFilterEnum,
  Tag,
  UpdatePartnerInput,
} from "../../../../api/types/generated-types";
import { OffsetPagination, OffsetPaginationProps, TableStickyPaginationPage } from "../../../../components/pagination";
import { EMPTY_CELL_HYPHEN } from "../../../../constants";
import { useOnApolloError } from "../../../../hooks/useOnApolloError";
import { isValidOffsetQueryParam } from "../../../../utils/isValidOffsetQueryParam";
import BulkUploadDialog from "../../components/BulkUploadDialog";
import DeleteDialog from "../../components/DeleteDialog";
import { useAddPartnerDialog } from "../../hooks/useAddPartnerDialog";
import AddPartnerDialog from "./components/AddPartnerDialog";
import EditPartnerDialog from "./components/EditPartnerDialog";

const TAG = gql`
  fragment PartnersPage_Tag on Tag {
    id
    tag
    config {
      group
      color
    }
  }
`;

const CONTACT = gql`
  fragment PartnersPage_Contact on Contact {
    id
    first_name
    last_name
    company_name
    phone_number
    city
    state
    created_at
    user {
      email
    }
    tags {
      ...PartnersPage_Tag
    }
  }
  ${TAG}
`;

const UPDATE_CONTACT_RESPONSE = gql`
  fragment PartnersPage_Update_Contact on Contact {
    id
    first_name
    last_name
    company_name
    phone_number
    city
    state
    created_at
    tags {
      ...PartnersPage_Tag
    }
  }
  ${TAG}
`;

const FIND_PARTNERS: TypedDocumentNode<Pick<Query, "findPartners">, QueryFindPartnersArgs> = gql`
  query ($filter: PartnerFilter, $page: PageInput, $orderBy: OrderByFilterInput) {
    findPartners(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        ...PartnersPage_Contact
      }
      limit
      count
      offset
    }
  }
  ${CONTACT}
`;

const DELETE_CONTACT: TypedDocumentNode<Pick<Mutation, "deleteContact">, MutationDeleteContactArgs> = gql`
  mutation ($input: DeleteContactInput!) {
    deleteContact(input: $input)
  }
`;

type ContactFragmentFields =
  | "id"
  | "user"
  | "first_name"
  | "last_name"
  | "company_name"
  | "phone_number"
  | "city"
  | "state";

type PickedUpdateContact = Pick<Contact, ContactFragmentFields>;

const UPDATE_CONTACT: TypedDocumentNode<{ updatePartner: PickedUpdateContact }, MutationUpdatePartnerArgs> = gql`
  mutation ($input: UpdatePartnerInput!) {
    updatePartner(input: $input) {
      ...PartnersPage_Update_Contact
    }
  }
  ${UPDATE_CONTACT_RESPONSE}
`;

export enum SORT_MODE {
  Newest = "newest",
  NameAsc = "name_asc",
  NameDesc = "name_desc",
}

const uploadMutationCacheKey = "partner-upload-cache-key";

const PartnersPage: FC = () => {
  const { t } = useTranslation(["shipper"]);
  const bulkImport = useLDFlag("bulkImport");
  const [
    uploadPartners,
    {
      data: partnerUploadData,
      isLoading: isPartnerUploadInProgress,
      isSuccess: isPartnerUploadSuccess,
      isError: isPartnerUploadError,
    },
  ] = useUploadPartnersMutation({ fixedCacheKey: uploadMutationCacheKey });

  const { enqueueSnackbar } = useSnackbar();
  const { onApolloError } = useOnApolloError({ componentName: "PartnersPage" });
  const [queryParams, setQueryParams] = useQueryParams({
    offset: NumberParam,
    sort: StringParam,
    tags: DelimitedArrayParam,
    searchValue: StringParam,
  });
  const [offset, setOffset] = useState<number>(OffsetPagination.DEFAULT_INITIAL_OFFSET);
  const [count, setCount] = useState<number>(0);
  const [sortMode, setSortMode] = useState(SORT_MODE.NameAsc);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const [findPartners, { data, error, loading: contactsLoading, refetch }] = useLazyQuery(FIND_PARTNERS, {
    variables: {
      page: { limit: OffsetPagination.DEFAULT_LIMIT },
      orderBy: { field: "company_name", order: SortDirectionFilterEnum.Asc },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findPartners"),
  });

  const [deleteContact, { loading: deletingContact }] = useMutation(DELETE_CONTACT, {
    onError: onApolloError("deleteContact"),
  });

  const findPartnersOffset = useCallback(
    async (
      offset: number,
      { sort, search, tags }: { sort?: SORT_MODE; search?: string; tags?: typeof selectedTags } = {}
    ) => {
      const variables: Ensure<QueryFindPartnersArgs, "filter"> = {
        filter: {},
        page: { limit: OffsetPagination.DEFAULT_LIMIT, offset: offset },
      };
      const { field, order } = getSortFields(sort || sortMode);
      variables["orderBy"] = { field, order };

      const tagsValue = tags || selectedTags;

      if (tagsValue.length) {
        variables.filter.tags = tagsValue.map((tag) => tag.id);
      }

      variables.filter.or = [
        { company_name: { contains: search ?? searchValue } },
        { email: { contains: search ?? searchValue } },
        { first_name: { contains: search ?? searchValue } },
        { last_name: { contains: search ?? searchValue } },
        { phone_number: { contains: search ?? searchValue } },
        { city: { contains: search ?? searchValue } },
        { state: { contains: search ?? searchValue } },
      ];

      await findPartners({ variables });
    },
    [findPartners, searchValue, selectedTags, sortMode]
  );

  const getSortFields = (sortMode: SORT_MODE) => {
    let field = "";
    let order = SortDirectionFilterEnum.Asc;
    switch (sortMode) {
      case SORT_MODE.NameAsc:
        field = "company_name";
        order = SortDirectionFilterEnum.Asc;
        break;
      case SORT_MODE.NameDesc:
        field = "company_name";
        order = SortDirectionFilterEnum.Desc;
        break;
      case SORT_MODE.Newest:
        field = "created_at";
        order = SortDirectionFilterEnum.Desc;
        break;
    }

    return { field, order };
  };

  useEffectOnce(() => {
    const firstOffset = isValidOffsetQueryParam(queryParams.offset, OffsetPagination.DEFAULT_LIMIT)
      ? queryParams.offset
      : offset;
    const firstSort = queryParams.sort ? (queryParams.sort as SORT_MODE) : sortMode;
    const firstSearch = queryParams.searchValue ?? searchValue;
    const firstTags = queryParams.tags || [];

    setOffset(firstOffset);
    setSortMode(firstSort);
    setSearchValue(firstSearch);

    const tags = firstTags.map((id) => ({ id } as Tag));
    setSelectedTags(tags);

    (async () => {
      await findPartnersOffset(firstOffset, { sort: firstSort, search: firstSearch, tags });
    })();
  });

  const contacts = useMemo(() => {
    return (data?.findPartners.items ?? []).filter(Boolean) as Contact[];
  }, [data?.findPartners.items]);

  useEffect(() => {
    if (contactsLoading) return;
    setCount(data?.findPartners.count ?? 0);
  }, [data?.findPartners.count, contactsLoading]);

  const handleOffsetPaginationChange = useCallback<OffsetPaginationProps["onChange"]>(
    async (value: number) => {
      const originalOffset = offset;
      const newOffset = value;

      if (originalOffset === newOffset) return;

      setOffset(newOffset);
      setQueryParams({ offset: newOffset }, "pushIn");
      await findPartnersOffset(value);
    },
    [offset, setQueryParams, findPartnersOffset]
  );

  const [rowMenuAnchor, setRowMenuAnchor] = useState<null | HTMLElement>(null);
  const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);
  const [viewPartnerDialogOpen, setViewPartnerDialogOpen] = useState(false);
  const [editPartnerDialogOpen, setEditPartnerDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<Contact>();

  const {
    isOpen: addPartnerDialogOpen,
    onRequestOpen: openAddPartnerDialog,
    onRequestClose: closeAddPartnerDialog,
    loading: addPartnerLoading,
    onAddPartner,
  } = useAddPartnerDialog({
    componentName: "PartnersPage",
    onAddSuccess: async () => {
      setSortMode(SORT_MODE.Newest);
      setQueryParams({ sort: SORT_MODE.Newest }, "pushIn");
      setOffset(0);
      setQueryParams({ offset: 0 }, "pushIn");
      await findPartnersOffset(0, { sort: SORT_MODE.Newest });
    },
  });

  const [updatePartner, { loading: updateContactLoading }] = useMutation(UPDATE_CONTACT, {
    onError: onApolloError("updatePartner"),
  });

  const TABLE_COLUMNS = useMemo(
    () => [
      t("shipper:company"),
      t("shipper:firstName"),
      t("shipper:lastName"),
      t("shipper:email"),
      t("shipper:phone"),
      t("shipper:location"),
      t("shipper:tags"),
      "",
    ],
    [t]
  );

  const { renderHead, renderBody } = useTableRenderer(TABLE_COLUMNS, contacts);

  const renderPartnerOptions: Parameters<typeof renderBody>[0][number] = (item) => {
    return (
      <Button
        color="primary"
        endIcon={<MoreVertIcon />}
        onClick={(e) => {
          setSelectedPartner(item);
          setRowMenuAnchor(e.currentTarget);
        }}
        size="medium"
      >
        Options
      </Button>
    );
  };

  const handleView = () => {
    setViewPartnerDialogOpen(true);
    setRowMenuAnchor(null);
  };

  const handleEdit = () => {
    setEditPartnerDialogOpen(true);
    setRowMenuAnchor(null);
  };

  const handleEditPartner = async (partnerPatch: UpdatePartnerInput) => {
    const { errors } = await updatePartner({
      variables: {
        input: partnerPatch,
      },
    });

    if (!errors) {
      await refetch?.();
      setEditPartnerDialogOpen(false);
    }
  };

  const TableCellData: React.FC = ({ children }) => (
    <Box py="6px">
      <Typography>{children}</Typography>
    </Box>
  );

  const handlePartnerDelete = async () => {
    if (!selectedPartner) return;

    await deleteContact({
      variables: { input: { id: selectedPartner.id } },
    });
    await refetch?.();
    setDeleteDialogOpen(false);
  };

  const handleChangeTagsFilter = useCallback<ComponentProps<typeof TagsFilterContainer>["onChange"]>(
    (value) => {
      setSelectedTags(value);
      setQueryParams({ tags: value.map((tag) => tag.id) }, "replaceIn");
      findPartnersOffset(offset, { tags: value });
    },
    [findPartnersOffset, offset, setQueryParams]
  );

  useEffect(() => {
    if (isPartnerUploadError) {
      enqueueSnackbar(`Error uploading partners`, { variant: "error", preventDuplicate: true });
    }
    if (isPartnerUploadSuccess) {
      const importedCount = partnerUploadData?.data.imported_count ?? 0;
      const partnerCopy = importedCount !== 1 ? "partners" : "partner";
      enqueueSnackbar(`Uploaded ${importedCount} ${partnerCopy}`, {
        variant: "success",
        preventDuplicate: true,
      });
      refetch?.();
    }
  }, [isPartnerUploadError, isPartnerUploadSuccess, enqueueSnackbar, partnerUploadData, refetch]);

  if (isPartnerUploadInProgress) return <Loading />;

  return (
    <>
      <TableStickyPaginationPage
        hasData={!!contacts.length}
        stickyContentOffsetMultiplier={2}
        heightOffsetPx={10}
        loading={contactsLoading}
        error={error}
        OffsetPaginationProps={{
          count: count,
          limit: OffsetPagination.DEFAULT_LIMIT,
          offset: offset,
          onChange: handleOffsetPaginationChange,
        }}
        renderTop={() => (
          <>
            <Box bgcolor="background.paper">
              <Toolbar
                variant="dense"
                disableGutters
                style={{ boxShadow: `0 1px 0 ${portexColor.grey300}`, height: 50 }}
              >
                <BreadcrumbsContainer>
                  <BreadcrumbLink active to="partners">
                    Partners
                  </BreadcrumbLink>
                </BreadcrumbsContainer>
              </Toolbar>
            </Box>
            <Box
              bgcolor="background.paper"
              display="flex"
              justifyContent="flex-end"
              style={{ gap: 16, borderBottom: `1px solid ${portexColor.grey300}` }}
              px={2}
              py={1}
            >
              <Box flex="auto">
                <Box display="flex" gridColumnGap={20} alignItems="center">
                  <ThrottledTextInput
                    search
                    placeholder="Search"
                    margin="none"
                    fullWidth={false}
                    value={searchValue}
                    onChange={(value) => {
                      setSearchValue(value);
                      setQueryParams({ searchValue: value }, "pushIn");
                      findPartnersOffset(offset, { search: value });
                    }}
                    onClear={() => {
                      const clearedValue = "";
                      setSearchValue(clearedValue);
                      setQueryParams({ searchValue: clearedValue }, "pushIn");
                      findPartnersOffset(offset, { search: clearedValue });
                    }}
                  />
                  <TagsFilterContainer tags={selectedTags} onChange={handleChangeTagsFilter} />
                </Box>
              </Box>
              {!!bulkImport && (
                <PartnerDromoImport
                  onResults={async (data) => {
                    const partners = data as unknown as ShipperPartners;
                    await uploadPartners({ partners }).unwrap();
                  }}
                ></PartnerDromoImport>
              )}
              {!!bulkImport && (
                <DeleteAllPartnersContainer
                  refetch={() => {
                    setSortMode(SORT_MODE.Newest);
                    setQueryParams({ sort: SORT_MODE.Newest }, "pushIn");
                    setOffset(0);
                    setQueryParams({ offset: 0 }, "pushIn");
                    findPartnersOffset(0, { sort: SORT_MODE.Newest });
                  }}
                />
              )}
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon style={{ fontSize: 20 }} />}
                onClick={() => openAddPartnerDialog()}
              >
                {t("shipper:addPartner")}
              </Button>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="end" px={2} py={1}>
              <Dropdown
                placeholder={`Sort by ${
                  sortMode === SORT_MODE.Newest
                    ? "Newest"
                    : sortMode === SORT_MODE.NameAsc
                    ? "Company (A to Z)"
                    : "Company (Z to A)"
                }`}
              >
                {({ onClose }) =>
                  [
                    <MenuItem
                      className={sortMode === SORT_MODE.Newest ? "Por-selected" : ""}
                      onClick={() => {
                        setSortMode(SORT_MODE.Newest);
                        setQueryParams({ sort: SORT_MODE.Newest }, "pushIn");
                        findPartnersOffset(offset, { sort: SORT_MODE.Newest });
                        onClose();
                      }}
                    >
                      Newest
                    </MenuItem>,
                    <MenuItem
                      className={sortMode === SORT_MODE.NameAsc ? "Por-selected" : ""}
                      onClick={() => {
                        setSortMode(SORT_MODE.NameAsc);
                        setQueryParams({ sort: SORT_MODE.NameAsc }, "pushIn");
                        findPartnersOffset(offset, { sort: SORT_MODE.NameAsc });
                        onClose();
                      }}
                    >
                      Company (A to Z)
                    </MenuItem>,
                    <MenuItem
                      className={sortMode === SORT_MODE.NameDesc ? "Por-selected" : ""}
                      onClick={() => {
                        setSortMode(SORT_MODE.NameDesc);
                        setQueryParams({ sort: SORT_MODE.NameDesc }, "pushIn");
                        findPartnersOffset(offset, { sort: SORT_MODE.NameDesc });
                        onClose();
                      }}
                    >
                      Company (Z to A)
                    </MenuItem>,
                  ].map((element, idx) => <span key={idx}>{element}</span>)
                }
              </Dropdown>
            </Box>
          </>
        )}
      >
        {renderHead()}
        {!contactsLoading
          ? renderBody({
              0: (item) => (
                <TableCellData>
                  <strong>{item.company_name}</strong>
                </TableCellData>
              ),
              1: (item) => <TableCellData>{item.first_name || EMPTY_CELL_HYPHEN}</TableCellData>,
              2: (item) => <TableCellData>{item.last_name || EMPTY_CELL_HYPHEN}</TableCellData>,
              3: (item) => <TableCellData>{item.user.email}</TableCellData>,
              4: (item) => <TableCellData>{item.phone_number || EMPTY_CELL_HYPHEN}</TableCellData>,
              5: (item) => {
                const location = [item.city, item.state].filter(Boolean).join(", ") || EMPTY_CELL_HYPHEN;
                return <TableCellData>{location}</TableCellData>;
              },
              6: (item) => (item.tags.length ? <TagsLimitView tags={item.tags} limit={3} /> : EMPTY_CELL_HYPHEN),
              7: renderPartnerOptions,
            })
          : null}
      </TableStickyPaginationPage>
      <Menu
        anchorEl={rowMenuAnchor}
        getContentAnchorEl={null}
        keepMounted
        open={!!rowMenuAnchor}
        onClose={() => setRowMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{ disablePadding: true }}
        PaperProps={{ elevation: 10, style: { border: "none", margin: "0" } }}
      >
        <MenuItem dense={true} onClick={handleView}>
          <ListItemIcon style={{ marginRight: "8px", marginLeft: "-4px", minWidth: "unset" }}>
            <PermContactCalendarIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View Details" />
        </MenuItem>
        <MenuItem dense={true} onClick={handleEdit}>
          <ListItemIcon style={{ marginRight: "8px", marginLeft: "-4px", minWidth: "unset" }}>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit" />
        </MenuItem>
        <MenuItem
          dense={true}
          onClick={() => {
            setRowMenuAnchor(null);
            setDeleteDialogOpen(true);
          }}
        >
          <ListItemIcon style={{ marginRight: "8px", marginLeft: "-4px", minWidth: "unset" }}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>
      <BulkUploadDialog
        open={bulkUploadDialogOpen}
        onClose={() => setBulkUploadDialogOpen(false)}
        onSubmit={() => setBulkUploadDialogOpen(false)}
        uploadTerm="partners"
      />
      <AddPartnerDialog
        open={addPartnerDialogOpen}
        loading={addPartnerLoading}
        onClose={closeAddPartnerDialog}
        onSubmit={onAddPartner}
      />
      {selectedPartner ? (
        <>
          <EditPartnerDialog
            viewOnly
            partner={selectedPartner}
            open={viewPartnerDialogOpen}
            onClose={() => setViewPartnerDialogOpen(false)}
          />
          <EditPartnerDialog
            partner={selectedPartner}
            open={editPartnerDialogOpen}
            loading={updateContactLoading}
            onClose={() => setEditPartnerDialogOpen(false)}
            onSubmit={handleEditPartner}
          />
        </>
      ) : null}
      <DeleteDialog
        loading={deletingContact}
        title="partner"
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onDelete={() => handlePartnerDelete()}
      />
    </>
  );
};

export default PartnersPage;
