import { EM_DASH, EMPTY_CELL_HYPHEN } from "constants/index";

import { FC, Fragment, VFC } from "react";

import { AddBoxOutlined } from "@material-ui/icons";
import {
  CommoditiesView,
  DimensionsView,
  FreightClassView,
  NmfcCodeView,
  PackingCountView,
  PackingTypeView,
  WeightView,
  dimUnitDictionary,
  freightClassDictionary,
  palletPackagingTypeOptions,
} from "app/pages/shipments/components/EditableShipmentPackageGroupElements";
import { ShipmentDimUnit, ShipmentPackageGroup, ShipmentPackagingType } from "app/pages/shipments/types/domain";
import { ResourceKey } from "i18next";
import { useTranslation } from "react-i18next";
import { formatCommodities } from "utils/formatCommodities";
import { formatWeight } from "utils/formatUnit";

import { useShipmentDetails } from "../provider/ShipmentDetailsProvider";

const CargoDetailsHeader: VFC<{ index: number; onRemoveClick(index: number): void }> = ({ index, onRemoveClick }) => {
  const { t } = useTranslation("shipments");

  return (
    <div className="flex gap-2 justify-between p-[16px] bg-white text-[14px] items-center border-b border-border">
      <div className="font-bold text-[16px]">{t("shipmentDetails_packageGroup_itemNo", { number: index + 1 })}</div>
      <button type="button" className="text-gray-500 hover:text-brandBlue" onClick={() => onRemoveClick(index)}>
        {t("shipmentDetails_removePackageGroupButton_button")}
      </button>
    </div>
  );
};

const CargoDetailsRow: FC<{ alignItems?: "start" | "end" }> = ({ children, alignItems = "end" }) => (
  <div className={`flex flex-wrap gap-2 p-[16px] odd:bg-zebra text-[14px] items-${alignItems}`}>{children}</div>
);

const createPackageGroup = (): ShipmentPackageGroup => ({
  id: window.crypto.randomUUID(),
  dimUnit: ShipmentDimUnit.IN,
  heightPerPackage: 1,
  itemQuantity: 1,
  lengthPerPackage: 1,
  packagingType: ShipmentPackagingType.Other,
  weightPerPackage: 1,
  widthPerPackage: 1,
});

const ShipmentDetailsCargoDetailsView: VFC = () => {
  const { isEditing, patch, patchedShipment, errors } = useShipmentDetails();
  const { t } = useTranslation(["common", "shipper", "shipments"]);

  const handlePackageGroupChange =
    <K extends keyof ShipmentPackageGroup>(index: number, key: K) =>
    (value: ShipmentPackageGroup[K]) => {
      const packageGroups = structuredClone(patchedShipment?.loadSpec?.packageGroups) || [];
      packageGroups[index] = { ...(packageGroups[index] || {}), [key]: value };
      patch({ loadSpec: { packageGroups } });
    };

  const handlePackageGroupRemove = (index: number) => {
    patch({ loadSpec: { packageGroups: patchedShipment.loadSpec.packageGroups?.filter((_, i) => i !== index) } });
  };

  const handleAddPackageGroup = () => {
    patch({ loadSpec: { packageGroups: [...(patchedShipment.loadSpec.packageGroups || []), createPackageGroup()] } });
  };

  const [totalItemCount, totalWeight] = (patchedShipment.loadSpec.packageGroups || []).reduce(
    (acc, { itemQuantity, weightPerPackage }) => [acc[0] + itemQuantity, acc[1] + weightPerPackage * itemQuantity],
    [0, 0]
  );

  return (
    <>
      {isEditing ? (
        <>
          {patchedShipment.loadSpec.packageGroups?.map((packageGroup, index) => (
            <Fragment key={packageGroup.id}>
              <CargoDetailsHeader index={index} onRemoveClick={handlePackageGroupRemove} />
              <div className="border-b border-border">
                <CargoDetailsRow alignItems="start">
                  <PackingTypeView
                    onChange={handlePackageGroupChange}
                    packageGroup={packageGroup}
                    packageGroupIndex={index}
                  />
                  <PackingCountView
                    errors={errors}
                    onChange={handlePackageGroupChange}
                    packageGroup={packageGroup}
                    packageGroupIndex={index}
                  />
                </CargoDetailsRow>
                <CargoDetailsRow>
                  <DimensionsView
                    errors={errors}
                    onChange={handlePackageGroupChange}
                    packageGroup={packageGroup}
                    packageGroupIndex={index}
                  />
                </CargoDetailsRow>
                <CargoDetailsRow>
                  <WeightView
                    errors={errors}
                    onChange={handlePackageGroupChange}
                    packageGroup={packageGroup}
                    packageGroupIndex={index}
                  />
                </CargoDetailsRow>
                <CargoDetailsRow>
                  <FreightClassView
                    onChange={handlePackageGroupChange}
                    packageGroup={packageGroup}
                    packageGroupIndex={index}
                  />
                  <NmfcCodeView
                    onChange={handlePackageGroupChange}
                    packageGroup={packageGroup}
                    packageGroupIndex={index}
                  />
                </CargoDetailsRow>
                <CargoDetailsRow>
                  <CommoditiesView
                    onChange={handlePackageGroupChange}
                    packageGroup={packageGroup}
                    packageGroupIndex={index}
                  />
                </CargoDetailsRow>
              </div>
            </Fragment>
          ))}
          <div className="flex justify-end p-[16px]">
            <button
              type="button"
              onClick={handleAddPackageGroup}
              className="flex gap-1 items-center font-bold text-brandBlue"
            >
              <AddBoxOutlined />
              <span>{t("shipper:addAnotherItem")}</span>
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="border-b border-border grid grid-cols-7 font-bold uppercase px-[16px] py-[14px]">
            <span>item</span>
            <span>stackable</span>
            <span>class</span>
            <span>nmfc</span>
            <span>weight</span>
            <span>dimensions</span>
            <span>commodities</span>
          </div>
          {patchedShipment.loadSpec.packageGroups?.map(
            ({
              id,
              itemQuantity,
              packagingType,
              commodities,
              freightClass,
              dimUnit,
              nmfcCode,
              weightPerPackage,
              widthPerPackage,
              heightPerPackage,
              lengthPerPackage,
              isStackable,
            }) => (
              <div key={id} className="grid grid-cols-7 odd:bg-zebra px-[16px] py-[14px]">
                <span className="font-bold">{`${itemQuantity} x ${t(
                  ("shipper:packingTypeMap." + packagingType) as ResourceKey
                )}`}</span>
                <span>
                  {palletPackagingTypeOptions.includes(packagingType)
                    ? isStackable
                      ? t("common:yes")
                      : t("common:no")
                    : EM_DASH}
                </span>
                <span>{freightClass ? freightClassDictionary[freightClass] : EMPTY_CELL_HYPHEN}</span>
                <span>{nmfcCode || EMPTY_CELL_HYPHEN}</span>
                <span>{formatWeight(weightPerPackage * itemQuantity, "lb")}</span>
                <span>{`${lengthPerPackage}×${heightPerPackage}×${widthPerPackage} ${
                  dimUnit ? dimUnitDictionary[dimUnit || ""] : ""
                }`}</span>
                <span>{formatCommodities(commodities)}</span>
              </div>
            )
          )}
          <div className="grid grid-cols-7 px-[16px] py-[14px]">
            <span className="font-bold">
              {t("shipments:shipmentDetails_cargoDetails_totalItems", { count: totalItemCount })}
            </span>
            <span />
            <span />
            <span />
            <span>{formatWeight(totalWeight, "lb")}</span>
            <span />
            <span />
          </div>
        </>
      )}
    </>
  );
};

export default ShipmentDetailsCargoDetailsView;
