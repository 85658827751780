import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { Collapse, portexColor } from "@portex-pro/ui-components";
import { Response } from "api/rest/lane-benchmark/getBrokerLaneBenchmarkApi";
import Text from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

interface BrokerLaneBenchmarkViewProps {
  data: Response["data"];
  laneString: string;
}

const BrokerLaneBenchmarkView: VFC<BrokerLaneBenchmarkViewProps> = (props) => {
  const {
    data: { lastLost, lastQuote, lastWon },
    laneString,
  } = props;
  const { t } = useTranslation(["broker", "laneBenchmarking"]);

  return (
    <Collapse appear={true} in={true}>
      <div className="w-full border-2 rounded-sm border-green-500 flex flex-col">
        <div className="w-full bg-green-200 flex flex-col p-2">
          <Text size="small" weight="bold">
            {t("broker:internalBenchmark")}
          </Text>
          <div className="w-full flex flex-row justify-between">
            <Text size="x-small" weight="bold">
              {laneString}
            </Text>
            <Text size="x-small" style="italic">
              {t("broker:internalBenchmark_dataDisclosure")}
            </Text>
          </div>
        </div>
        <div className="w-full flex flex-row justify-evenly bg-green-100 [&>*:not(:first-child)]:border-l [&>*:not(:first-child)]:border-green-500">
          <div className="w-full flex p-3 space-x-2 justify-between">
            <div className="flex flex-col space-y-1.5 w-full">
              <Text size="x-small" weight="bold">
                {t("broker:lastWon")}
              </Text>
              {!!lastWon && (
                <>
                  <Text size="x-small" weight="bold" typographyProps={{ style: { color: portexColor.grey500 } }}>
                    {DateTime.fromISO(lastWon.date).toLocaleString({ month: "short", day: "numeric", year: "numeric" })}
                  </Text>
                  <Text size="x-small" typographyProps={{ style: { color: portexColor.grey500 } }}>
                    {t("broker:with_%s", { "%s": lastWon.shipperName ?? lastWon.shipperEmail })}
                  </Text>
                </>
              )}
            </div>
            <div className="flex w-full">
              <span className="inline-flex items-baseline">
                <Text size="medium" weight="bold">
                  {!!lastWon ? formatUSD(lastWon.amount) : EM_DASH}
                </Text>
                {!!lastWon && (
                  <Text size="x-small" typographyProps={{ color: "textSecondary", style: { whiteSpace: "pre" } }}>
                    {t(`laneBenchmarking:perUnitType_${lastWon.perUnitType}`)}
                  </Text>
                )}
              </span>
            </div>
          </div>

          <div className="w-full flex p-3 space-x-2 justify-between">
            <div className="flex flex-col space-y-1.5 w-full">
              <Text size="x-small" weight="bold">
                {t("broker:lastLost")}
              </Text>
              {!!lastLost && (
                <>
                  <Text size="x-small" weight="bold" typographyProps={{ style: { color: portexColor.grey500 } }}>
                    {DateTime.fromISO(lastLost.date).toLocaleString({
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </Text>
                  <Text size="x-small" typographyProps={{ style: { color: portexColor.grey500 } }}>
                    {t("broker:with_%s", { "%s": lastLost.shipperName ?? lastLost.shipperEmail })}
                  </Text>
                </>
              )}
            </div>
            <div className="flex w-full">
              <span className="inline-flex items-baseline">
                <Text size="medium" weight="bold">
                  {!!lastLost ? formatUSD(lastLost.amount) : EM_DASH}
                </Text>
                {!!lastLost && (
                  <Text size="x-small" typographyProps={{ color: "textSecondary", style: { whiteSpace: "pre" } }}>
                    {t(`laneBenchmarking:perUnitType_${lastLost.perUnitType}`)}
                  </Text>
                )}
              </span>
            </div>
          </div>

          <div className="w-full flex p-3 space-x-2 justify-between">
            <div className="flex flex-col space-y-1.5 w-full">
              <Text size="x-small" weight="bold">
                {t("broker:lastQuoted")}
              </Text>
              {!!lastQuote && (
                <>
                  <Text size="x-small" weight="bold" typographyProps={{ style: { color: portexColor.grey500 } }}>
                    {DateTime.fromISO(lastQuote.date).toLocaleString({
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    })}
                  </Text>
                  <Text size="x-small" typographyProps={{ style: { color: portexColor.grey500 } }}>
                    {t("broker:with_%s", { "%s": lastQuote.shipperName ?? lastQuote.shipperEmail })}
                  </Text>
                </>
              )}
            </div>
            <div className="flex w-full">
              <span className="inline-flex items-baseline">
                <Text size="medium" weight="bold">
                  {!!lastQuote ? formatUSD(lastQuote.amount) : EM_DASH}
                </Text>
                {!!lastQuote && (
                  <Text size="x-small" typographyProps={{ color: "textSecondary", style: { whiteSpace: "pre" } }}>
                    {t(`laneBenchmarking:perUnitType_${lastQuote.perUnitType}`)}
                  </Text>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Collapse>
  );
};

export default BrokerLaneBenchmarkView;
