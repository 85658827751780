import { VFC } from "react";

import { Box, DropdownFilter } from "@portex-pro/ui-components";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import ThrottledTextInput from "components/ThrottledTextInput";
import ModeButtonGroup from "pages/shipper/components/ModeButtonGroup";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { useTranslation } from "react-i18next";

import { ShipmentState } from "../types/domain";

interface ShipmentListTopBarProps {
  isLoading: boolean;
  lastRefreshedAt: string;
  stateFilter: ShipmentState[];
  transportationMode: TransportationMode;
  searchQuery: string;

  onChangeModeFilter(newMode: TransportationMode): void;
  onChangeStateFilter(newStateFilter: ShipmentState[]): void;
  onChangeSearchQuery(newSearchQuery: string): void;
  onRefresh(): void;
  filters: JSX.Element;
  disableIntermodal?: boolean;
  disableDrayage?: boolean;
  disableLtlPartial?: boolean;
}

const ShipmentListTopBarView: VFC<ShipmentListTopBarProps> = ({
  onChangeModeFilter,
  onChangeStateFilter,
  stateFilter,
  transportationMode,
  lastRefreshedAt,
  isLoading,
  onChangeSearchQuery,
  searchQuery,
  onRefresh,
  filters,
  disableIntermodal = false,
  disableDrayage = false,
  disableLtlPartial = false,
}) => {
  const { t } = useTranslation("shipments");

  const stateFilterItems = [
    {
      label: t("status_created"),
      value: ShipmentState.Created,
    },
    {
      label: t("status_inTransit"),
      value: ShipmentState.InTransit,
    },
    {
      label: t("status_delivered"),
      value: ShipmentState.Delivered,
    },
    {
      label: t("status_canceled"),
      value: ShipmentState.Canceled,
    },
  ];

  return (
    <Box bgcolor="background.paper">
      <Box pl={2}>
        <div className="flex">
          <Box py="0.5rem" display="flex" flexWrap="wrap" gridColumnGap="1rem" gridRowGap="0.5rem" alignItems="center">
            <ModeButtonGroup
              value={transportationMode}
              onChange={onChangeModeFilter}
              includeModes={[TransportationMode.INTERMODAL]}
              disabledModes={[
                TransportationMode.Air,
                TransportationMode.Ocean,
                TransportationMode.Trucking,
                TransportationMode.INTERMODAL,
                TransportationMode.DRAYAGE,
                TransportationMode.LTL_PARTIAL,
              ].filter((value) => {
                if (!disableIntermodal && value === "INTERMODAL") {
                  return false;
                }
                if (!disableDrayage && value === "DRAYAGE") {
                  return false;
                }
                if (!disableLtlPartial && value === "LTL_PARTIAL") {
                  return false;
                }
                return true;
              })}
            />
            <DropdownFilter
              multiple
              placeholder={t("statusFilter_placeholder")}
              options={stateFilterItems}
              onChange={(value) => onChangeStateFilter(value as ShipmentState[])}
              value={stateFilter}
            />
            <ThrottledTextInput
              search
              placeholder={t("searchShipments_placeholder")}
              margin="none"
              fullWidth={false}
              value={searchQuery}
              onChange={onChangeSearchQuery}
              onClear={() => onChangeSearchQuery("")}
            />
            {filters}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexGrow={1}
            pr="1rem"
            className="whitespace-nowrap"
          >
            {lastRefreshedAt && <LastRefreshedAtText value={lastRefreshedAt} />}
            <Box>
              <RefetchButton loading={isLoading} onClick={onRefresh} tooltip={t("refetchShipments_button")} />
            </Box>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default ShipmentListTopBarView;
