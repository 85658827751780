import { VFC } from "react";

import { useQuery } from "@apollo/client";
import AppBar from "components/AppBar";
import QuoteConversationsContainer from "components/chat/QuoteConversationsContainer";
import { useOnApolloError } from "hooks/useOnApolloError";
import AirQuoteRequestHeader from "pages/shipper/components/AirQuoteRequestHeader";
import FclQuoteRequestHeader from "pages/shipper/components/FclQuoteRequestHeader";
import LtlQuoteRequestHeader from "pages/shipper/components/LtlQuoteRequestHeader";
import QuoteRequestHeader from "pages/shipper/components/QuoteRequestHeader";
import ShipperAppHeader from "pages/shipper/components/ShipperAppHeader";
import { useParams } from "react-router-dom";
import { ModeEnum } from "types/Mode";

import { GET_QUOTE_REQUEST_AND_CONTACT } from "./QuoteRequestDetailsPage";

const QuoteConversationPage: VFC = () => {
  const { quoteRequestId } = useParams<{ quoteRequestId: string }>();

  const { onApolloError } = useOnApolloError({ componentName: "QuoteConversationPage" });
  const POLLING_QUOTE_INTERVAL_SECONDS = 60; // 1 minute
  const { data, error, loading, refetch } = useQuery(GET_QUOTE_REQUEST_AND_CONTACT, {
    variables: {
      id: quoteRequestId,
      filter: { quote_request_id: { eq: quoteRequestId } },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("getQuoteRequestAndContact"),
    pollInterval: POLLING_QUOTE_INTERVAL_SECONDS * 1000,
  });

  const quoteRequest = data?.getQuoteRequest;
  const mode = quoteRequest?.mode as unknown as ModeEnum | undefined;

  return (
    <>
      <AppBar>
        <ShipperAppHeader />
      </AppBar>
      <AppBar>
        {mode === ModeEnum.FTL && !loading && !error && !!quoteRequest && (
          <QuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />
        )}
        {mode === ModeEnum.LTL && !loading && !error && !!quoteRequest && (
          <LtlQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />
        )}
        {mode === ModeEnum.FCL && !loading && !error && !!quoteRequest && (
          <FclQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />
        )}
        {mode === ModeEnum.AIR && !loading && !error && !!quoteRequest && (
          <AirQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />
        )}
      </AppBar>
      <div className="px-4 py-4 w-full h-full flex flex-col overflow-hidden">
        <QuoteConversationsContainer variant="quotes" />
      </div>
    </>
  );
};

export default QuoteConversationPage;
