import { FC } from "react";

import { Paper } from "@portex-pro/ui-components";
import { isQuoteRequestStateTerminal } from "utils/isQuoteRequestStateTerminal";

import QuoteSubmissionClosed from "../../QuoteSubmissionClosed";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import QuoteSubmissionDetailsAIR from "./QuoteSubmissionDetailsAIR";
import QuoteSubmissionFlowAIR from "./QuoteSubmissionFlowAIR";

interface QuoteSubmissionAIRProps {
  attachmentsList: React.ReactElement | false | null;
}

const QuoteSubmissionAIR: FC<QuoteSubmissionAIRProps> = ({ attachmentsList }) => {
  const { hasStartedSubmitting, publicQuoteRequest } = useContextQuoteSubmissionAIR();
  const isTerminalState = isQuoteRequestStateTerminal(publicQuoteRequest.state);

  return hasStartedSubmitting.value ? (
    <QuoteSubmissionFlowAIR />
  ) : (
    <>
      {isTerminalState ? <QuoteSubmissionClosed publicQuoteRequest={publicQuoteRequest} /> : null}
      <Paper className={"Por-outlined-light"} elevation={8}>
        <QuoteSubmissionDetailsAIR publicQuoteRequest={publicQuoteRequest} attachmentsList={attachmentsList} />
      </Paper>
    </>
  );
};

export default QuoteSubmissionAIR;
