import React, { CSSProperties } from "react";

import { Badge, BadgeProps, Theme, styled } from "@portex-pro/ui-components";
import { useNotificationCount } from "hooks/notifications/useNotificationCount";
import { NotificationIdentifier } from "types/Notifications";

type BadgePositions = "topLeft" | "topRight" | "bottomRight" | "bottomLeft";

interface NotificationBadgeProps {
  BadgeProps?: BadgeProps;
  identifiers: NotificationIdentifier[];
  position?: BadgePositions | { top?: string; right?: string };
  size?: "large" | "medium" | "small" | "x-small";
}

const sizeScaleMap: Record<Exclude<NotificationBadgeProps["size"], undefined>, CSSProperties["scale"]> = {
  large: "1.4",
  medium: "1.2",
  small: "1",
  /** Formerly the "small" size. Changed to x-small because Brittany has consistently pushed back against this size. Use with caution @see https://portexpro.atlassian.net/browse/PR-2201  */
  "x-small": "0.8",
};

const mapPositionToVerticalLocation: Record<BadgePositions, "top" | "bottom"> = {
  bottomLeft: "bottom",
  bottomRight: "bottom",
  topLeft: "top",
  topRight: "top",
};

const mapPositionToHorizontalLocation: Record<BadgePositions, "left" | "right"> = {
  bottomLeft: "left",
  bottomRight: "right",
  topLeft: "left",
  topRight: "right",
};

const NotificationBadge = styled<React.FC<NotificationBadgeProps>>(({ ...props }) => {
  const { children, BadgeProps, identifiers, position = "topRight", ...remaining } = props;
  const count = useNotificationCount(identifiers);

  const vertical = typeof position === "string" ? mapPositionToVerticalLocation[position] : "top";
  const horizontal = typeof position === "string" ? mapPositionToHorizontalLocation[position] : "right";

  return (
    <Badge
      {...remaining}
      {...BadgeProps}
      style={{
        width: "100%",
        ...BadgeProps?.style,
      }}
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      badgeContent={count}
      color="error"
    >
      {children}
    </Badge>
  );
})<Theme, { position?: NotificationBadgeProps["position"]; size?: NotificationBadgeProps["size"] }>(
  ({ position, size = "large" }) => ({
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: typeof position === "string" ? undefined : position?.top,
      right: typeof position === "string" ? undefined : position?.right,
    },
    "& .MuiBadge-badge": {
      scale: sizeScaleMap[size],
      fontSize: "0.8rem",
    },
  })
);

export default NotificationBadge;
