import React, { useState } from "react";

import { Box, Collapse } from "@portex-pro/ui-components";
import FormView from "components/FormView";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import YesNoRadio from "components/YesNoRadio";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

interface StopDetailsViewProps {
  numberOfStops: number;
  onChangeStops: (numberOfStops: number) => void;
}

const StopsDetailsView: React.FC<StopDetailsViewProps> = (props) => {
  const { numberOfStops, onChangeStops } = props;
  const { t } = useTranslation(["shipper"]);
  const [isMultipleStops, setIsMultipleStops] = useState(numberOfStops > 1);

  return (
    <Box p={2} display="flex" flexDirection="column" alignItems="center">
      <Text size="x-large" weight="bold" typographyProps={{ style: { marginBottom: getSizeStr(24) } }}>
        {t("shipper:bids.bidRequestPage.pageBidAttributes.multipleStopsLabel")}
      </Text>

      <Box display="flex" marginBottom={getSizeStr(24)}>
        <YesNoRadio
          value={isMultipleStops}
          onChange={(yes) => {
            if (yes) {
              setIsMultipleStops(true);
            } else {
              onChangeStops(1);
              setIsMultipleStops(false);
            }
          }}
          radioGroupProps={{
            style: { flexDirection: "row-reverse", justifyContent: "flex-end", margin: "1rem" },
          }}
        />
      </Box>

      <Collapse in={isMultipleStops}>
        <FormView
          formLabelProps={{
            children: (
              <Text size="small" weight="bold">
                {t("shipper:bids.bidRequestPage.pageBidAttributes.maxStopsLabel")}
              </Text>
            ),
            error: !numberOfStops || numberOfStops < 1,
          }}
          formControlProps={{
            required: true,
          }}
        >
          <PositiveNumberInput value={numberOfStops} onChange={onChangeStops} allowEmpty disableError />
        </FormView>
      </Collapse>
    </Box>
  );
};

export default StopsDetailsView;
