import { VFC } from "react";

import { Alert } from "@portex-pro/ui-components";
import { BrokerContractType } from "api/rest/rfp/brokerBidContractApi/types/BrokerContractType";
import { useTranslation } from "react-i18next";
import { ModeEnum } from "types/Mode";
import { useBoolean } from "usehooks-ts";

interface BrokerSubmissionLtlPartialHintAlertViewProps {
  contract: BrokerContractType;
}

const BrokerSubmissionLtlPartialHintAlertView: VFC<BrokerSubmissionLtlPartialHintAlertViewProps> = ({ contract }) => {
  const { t } = useTranslation(["broker"]);
  const showHint = useBoolean(true);

  if (contract.mode !== ModeEnum.LTL_PARTIAL) return null;

  return (
    <Alert variant="standard" severity="warning" onClose={showHint.setFalse} style={{ alignItems: "center" }}>
      {t("broker:bidSubmit.ltlPartial_description")}
    </Alert>
  );
};

export default BrokerSubmissionLtlPartialHintAlertView;
