import { VFC } from "react";

import { QuoteRequest } from "api/rest/quote-requests";
import { useGetBrokerQuoteRequestQuery } from "api/rest/quote-requests/getBrokerQuoteRequest";
import useLDFlag from "hooks/useLDFlag";

import LiveQuoteRequestRatesContainer from "./containers/LiveQuoteRequestRatesContainer";
import { useIsCurrentUserFeatured } from "./hooks/useIsCurrentUserFeatured";

type FeaturedBrokerDashboardProps = {
  quoteRequestGuid: QuoteRequest["guid"];
};

const FeaturedBrokerDashboard: VFC<FeaturedBrokerDashboardProps> = ({ quoteRequestGuid }) => {
  const enableFeaturedBrokerDashboard = useLDFlag("enableFeaturedBrokerDashboard");
  const getBrokerQuoteRequestQuery = useGetBrokerQuoteRequestQuery(
    { urlParams: { quoteRequestGuid: quoteRequestGuid ?? "should-skip" } },
    { skip: !quoteRequestGuid }
  );
  const quoteRequest = getBrokerQuoteRequestQuery.data?.data.quoteRequest;
  const { isCurrentUserFeatured } = useIsCurrentUserFeatured();
  const isFeatured = isCurrentUserFeatured({ shipperId: quoteRequest?.shipper_id });

  if (!quoteRequest) {
    return null;
  }

  const displayDashboard = !!enableFeaturedBrokerDashboard && !!isFeatured;

  if (!displayDashboard) {
    return null;
  }

  return <LiveQuoteRequestRatesContainer quoteRequestId={quoteRequest.id} />;
};

export default FeaturedBrokerDashboard;
