import { FeaturedBrokerQuoteRequestData } from "api/rest/featured-brokers";
import { ReactComponent as LiveRateBenchmarkColdIcon } from "assets/live-rate-benchmark/0-cold.svg";
import { ReactComponent as LiveRateBenchmarkGoodIcon } from "assets/live-rate-benchmark/1-good.svg";
import { ReactComponent as LiveRateBenchmarkMildIcon } from "assets/live-rate-benchmark/2-mild.svg";
import { ReactComponent as LiveRateBenchmarkBadIcon } from "assets/live-rate-benchmark/3-bad.svg";
import first from "lodash/first";
import { useTranslation } from "react-i18next";

export type AssessmentType = "cold-no-quotes" | "cold-awaiting-owner-quote" | "good" | "mild" | "bad";

type Assessment = {
  type: AssessmentType;
  Icon: typeof LiveRateBenchmarkColdIcon;
  message: string;
};

export const useFeaturedBrokerQuoteAssessment = ({
  data,
}: {
  data: FeaturedBrokerQuoteRequestData;
}): {
  assessment: Assessment;
} => {
  const { t } = useTranslation(["featuredBroker"]);

  let assessment: Assessment;

  const bestQuote = first(data.quotes);
  const ownerQuote = data.ownerQuote;

  const difference = Math.abs((bestQuote?.amount ?? 0) - (ownerQuote?.amount ?? 0));
  const percentageDiff = (difference / (bestQuote?.amount ?? 0)) * 100;

  if (!data.quotes.length) {
    assessment = {
      type: "cold-no-quotes",
      Icon: LiveRateBenchmarkColdIcon,
      message: t("featuredBroker:assessmentColdNoQuotes"),
    };
  } else if (!ownerQuote) {
    assessment = {
      type: "cold-awaiting-owner-quote",
      Icon: LiveRateBenchmarkColdIcon,
      message: t("featuredBroker:assessmentColdAwaitingOwnerQuote"),
    };
  } else if (percentageDiff <= 30) {
    assessment = {
      type: "good",
      Icon: LiveRateBenchmarkGoodIcon,
      message: t("featuredBroker:assessmentGoodQuote"),
    };
  } else if (percentageDiff <= 50) {
    assessment = {
      type: "mild",
      Icon: LiveRateBenchmarkMildIcon,
      message: t("featuredBroker:assessmentMildQuote"),
    };
  } else {
    assessment = {
      type: "bad",
      Icon: LiveRateBenchmarkBadIcon,
      message: t("featuredBroker:assessmentBadQuote"),
    };
  }

  return { assessment };
};
