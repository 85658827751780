import { useCallback } from "react";

import { Shipper } from "api/rest/shippers/types";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import useLDFlag from "hooks/useLDFlag";

type UseIsCurrentUserFeaturedReturnValue = {
  isCurrentUserFeatured(args: { shipperId: Shipper["id"] | undefined }): boolean;
};

export const useIsCurrentUserFeatured = (): UseIsCurrentUserFeaturedReturnValue => {
  const featuredBrokers2 = useLDFlag("featuredBrokers2");
  const getCurrentUserQuery = useGetCurrentUserQuery();
  const user = getCurrentUserQuery.data?.data.user;

  const isCurrentUserFeatured = useCallback(
    ({ shipperId }: { shipperId: Shipper["id"] | undefined }): boolean => {
      if (!user || !user.email || !!user.shipper_id || !shipperId) {
        return false;
      }

      return Object.entries(featuredBrokers2 ?? {}).some(
        ([brokerEmail, shipperIds]) =>
          user.email.toLowerCase() === brokerEmail.toLowerCase() && shipperIds.includes(shipperId)
      );
    },
    [featuredBrokers2, user]
  );

  return {
    isCurrentUserFeatured,
  };
};
