import { ChatStatusUpdates } from "components/chat/types/ChatStatusUpdates";

export enum ChatMessageSource {
  Instant = "INSTANT",
  Email = "EMAIL",
  System = "SYSTEM",
}

export enum ChatMessageState {
  Delivered = "DELIVERED",
  Created = "CREATED",
}

export enum ChatMessageType {
  Text = "text",
  Attachment = "attachment",
  StatusUpdate = "status_update",
}

export interface Author {
  id: string;
  name: string;
  email?: string;
}

export interface ChatMessage {
  /** internal id used for displaying message */
  _id: string;
  /** original id returned by backend endpoint */
  id: number;
  author: Author;
  source: ChatMessageSource;
  deliveryMethod: ChatMessageSource;
  state: ChatMessageState;
  type: ChatMessageType;
  datetime: string;
  text?: string;
  attachments?: Attachment[];
  chatStatusUpdate?: ChatStatusUpdates;
}

export interface ChatParticipant {
  isInternal: boolean;
  /** fullName or fallsback to email which always exists */
  displayName: string;
  /** this will always exist */
  email: string;
  /** This can possibly be empty, so we are explicitly falling back to null */
  fullName: string | null;
  /** This can possibly be empty, so we are explicitly falling back to null */
  companyName: string | null;
}

export interface Attachment {
  url: string;
  url_preview: string;
  name: string;
  extension: string;
}

export interface DraftAttachment {
  filename: string;
  fileId: string;
}

export interface ChatMeta {
  conversationId: number | null;
}

export interface ChatConversation extends ChatMeta {
  messages: ChatMessage[];
}

export type ChatAttachmentResponse = {
  id: string;
  name: string;
  extension: string;
  url?: string;
  url_preview?: string;
};

export interface ChatMessageRawResponse {
  id: number;
  author: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
  source: ChatMessageSource;
  delivery_method: ChatMessageSource;
  state: ChatMessageState;
  created_at: string;
  conversation_id: number;
  email_message_content?: {
    text: string;
  };
  files: ChatAttachmentResponse[];
  notification_message_content?: ChatStatusUpdates;
}

export interface ChatParticipantsRawResponse {
  id: number;
  isInternal: boolean;
  /** fullName or fallsback to email which always exists */
  displayName: string;
  /** this will always exist */
  email: string;
  /** This can possibly be empty, so we are explicitly falling back to null */
  fullName: string | null;
  /** This can possibly be empty, so we are explicitly falling back to null */
  companyName: string | null;
}

export interface ChatRawResponse {
  conversation: {
    messages: ChatMessageRawResponse[];
    participants: ChatParticipantsRawResponse[];
  };
}

export type ChatResponse = {
  messages: ChatMessage[];
  participants: ChatParticipant[];
};

export type UploadStatus = "idle" | "uploading" | "ready";
