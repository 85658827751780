import { useGetBrokerShipmentQuery } from "api/rest/shipments/getBrokerShipment/api";
import { useParams } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useBrokerShipment = () => {
  const params = useParams<{ shipmentId: string }>();
  const shipmentId = Number(params.shipmentId);
  return { ...useGetBrokerShipmentQuery({ urlParams: { shipmentId } }, { skip: !shipmentId }), shipmentId };
};

export default useBrokerShipment;
