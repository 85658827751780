import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import useLDFlag from "hooks/useLDFlag";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import BidRequestProgressionView from "../../components/BidRequestProgressionView";
import withBidRequest from "../../components/withBidRequest";
import { deliveryWeekField } from "../../const";
import useChangeBidRequestRoute from "../../hooks/useChangeBidRequestRoute";
import useUpdateContractRequest from "../../hooks/useUpdateContractRequest";
import { useUpdateBidRequest } from "../../store/bidRequestContractSlice";
import {
  useRemoveOneField,
  useSetState,
  useSetStops,
  useUpdateOneField,
  useUpsertField,
} from "../../store/bidRequestFieldsSlice";
import { useBidRequestSliceSelector } from "../../store/bidRequestStore";
import { StaticFieldKeys } from "../../types";
import BidRequestPageSteps from "../../types/BidRequestPageSteps";
import BidAttributesView from "./components/BidAttributesView";

const BidAttributesContainer = withBidRequest(() => {
  const bidRequestState = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);
  const updateBidRequest = useUpdateBidRequest();
  const upsertField = useUpsertField();
  const updateField = useUpdateOneField();
  const removeOneField = useRemoveOneField();
  const enableRfpBulkSpotQuote = useLDFlag("enableRfpBulkSpotQuote");
  const changeBidRequestRoute = useChangeBidRequestRoute();
  const setStops = useSetStops();
  const setState = useSetState();
  const stopsCount = useBidRequestSliceSelector((state) => state.bidRequestFieldsSlice.stopsCount);
  const hidePoNumberTillDispatch = useBidRequestSliceSelector(
    (state) => state.bidRequestFieldsSlice.hidePoNumberTillDispatch
  );
  const [updateContractRequest] = useUpdateContractRequest();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("common");

  const onChangePricingFrequency = (value: ContractPricingFrequency) => {
    updateBidRequest({
      pricing_frequency: value,
      include_fuel:
        value === ContractPricingFrequency.BULK_SPOT_QUOTE
          ? true
          : bidRequestState.pricing_frequency === ContractPricingFrequency.BULK_SPOT_QUOTE
          ? undefined
          : bidRequestState.include_fuel,
      start: value === ContractPricingFrequency.BULK_SPOT_QUOTE ? undefined : bidRequestState.start,
      end: value === ContractPricingFrequency.BULK_SPOT_QUOTE ? undefined : bidRequestState.end,
    });
    if (value === ContractPricingFrequency.WEEKLY) {
      upsertField(deliveryWeekField);
    } else {
      removeOneField(StaticFieldKeys.DELIVERY_WEEK);
    }

    if (value === ContractPricingFrequency.BULK_SPOT_QUOTE) {
      updateField({
        id: "stop_0_date",
        changes: { selected: true, required: false },
      });
      updateField({
        id: "stop_1_date",
        changes: { selected: true, required: false },
      });
    } else {
      updateField({ id: "stop_0_date", changes: { selected: false, required: false, validators: undefined } });
      updateField({ id: "stop_1_date", changes: { selected: false, required: false, validators: undefined } });
    }
  };

  const invalidPricingFrequency = !bidRequestState.pricing_frequency;
  const invalidContractDates =
    bidRequestState.pricing_frequency !== ContractPricingFrequency.BULK_SPOT_QUOTE &&
    (!bidRequestState.start || !bidRequestState.end);
  const invalidFuel = bidRequestState.include_fuel === undefined;
  const invalidHazardous = bidRequestState.is_hazardous === undefined;
  const nextDisabled = invalidPricingFrequency || invalidContractDates || invalidFuel || invalidHazardous;

  const onNext = async () => {
    try {
      updateContractRequest();
      changeBidRequestRoute({ step: BidRequestPageSteps.Fields });
    } catch {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
    }
  };

  return (
    <BidRequestProgressionView
      fullPage
      onBack={() => changeBidRequestRoute({ step: BidRequestPageSteps.Trailer })}
      onNext={onNext}
      nextProps={{ disabled: nextDisabled }}
    >
      <BidAttributesView
        mode={bidRequestState.mode}
        enableRfpBulkSpotQuote={!!enableRfpBulkSpotQuote}
        //
        // pricing freq & contract length
        pricingFrequency={bidRequestState.pricing_frequency}
        onChangePricingFrequency={onChangePricingFrequency}
        startDate={bidRequestState.start ? DateTime.fromISO(bidRequestState.start) : null}
        endDate={bidRequestState.end ? DateTime.fromISO(bidRequestState.end) : null}
        onChangeStartDate={(value) => updateBidRequest({ start: value?.toISO() })}
        onChangeEndDate={(value) => updateBidRequest({ end: value?.toISO() })}
        //
        // extra details
        commodities={bidRequestState.commodities ?? ""}
        isHazardous={bidRequestState.is_hazardous}
        hazardousDetails={bidRequestState.hazardous_goods_details}
        includeFuel={bidRequestState.include_fuel}
        hidePoTillDispatch={hidePoNumberTillDispatch}
        onChangeCommodities={(value) => updateBidRequest({ commodities: value })}
        onChangeHazardous={(value) => updateBidRequest({ is_hazardous: value })}
        onChangeFuel={(value) => updateBidRequest({ include_fuel: value })}
        onChangeHazardousDetails={(value) => updateBidRequest({ hazardous_goods_details: value })}
        onChangeHidePoTillDispatch={(val) => setState({ hidePoNumberTillDispatch: val })}
        //
        // stop details
        numberOfStops={stopsCount}
        onChangeStops={(stopCount) => setStops({ stopCount, rfpPricingFrequency: bidRequestState.pricing_frequency })}
      />
    </BidRequestProgressionView>
  );
});

export default BidAttributesContainer;
