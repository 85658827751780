import { VFC } from "react";

import { BrokerQuoteRequest } from "api/rest/quote-requests";
import { useGetBrokerQuoteRequestQuery } from "api/rest/quote-requests/getBrokerQuoteRequest";
import AttachmentsListView from "components/file-uploads/AttachmentsListView";

const BrokerQuoteRequestAttachmentsListContainer: VFC<{ quoteRequestGuid: BrokerQuoteRequest["guid"] }> = ({
  quoteRequestGuid,
}) => {
  const { quoteRequestFiles } = useGetBrokerQuoteRequestQuery(
    { urlParams: { quoteRequestGuid: quoteRequestGuid ?? "should-skip" } },
    {
      skip: !quoteRequestGuid,
      selectFromResult: ({ data }) => ({ quoteRequestFiles: data?.data.quoteRequest.files ?? [] }),
    }
  );

  return <AttachmentsListView files={quoteRequestFiles} showDashOnNoAttachments={true} hideDeleteAction={true} />;
};

export default BrokerQuoteRequestAttachmentsListContainer;
