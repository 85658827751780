import React, { useRef, useState } from "react";

import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { makeStyles, MenuItem, Popover, SelectInput } from "@portex-pro/ui-components";
import { DateTime } from "luxon";
import { useTranslation, TFuncKey } from "react-i18next";

import { useInsightsV2SliceSelector } from "../../store/insightsV2Store";
import { useSetUiSlice } from "../../store/insightsV2UiSlice";

const timeRangeOptions = ["allTime", "last30days", "last90days", "last6months", "last12months", "custom"] as const;

type TimeRangeOptions = typeof timeRangeOptions[number];

const mapOptionsToI18nKeys: Record<TimeRangeOptions, TFuncKey<"insightsV2">> = {
  allTime: "filter_time_allTime",
  last30days: "filter_time_last30Days",
  last90days: "filter_time_last90Days",
  last6months: "filter_time_last6Months",
  last12months: "filter_time_last12Months",
  custom: "filter_time_custom",
};

const mapOptionsToRange: Record<TimeRangeOptions, () => { from?: string | undefined; to?: string | undefined }> = {
  allTime: () => ({ from: undefined, to: undefined }),
  custom: () => ({}),
  last30days: () => ({
    from: DateTime.now().minus({ days: 30 }).startOf("day").toISO(),
    to: DateTime.now().endOf("day").toISO(),
  }),
  last90days: () => ({
    from: DateTime.now().minus({ days: 90 }).startOf("day").toISO(),
    to: DateTime.now().endOf("day").toISO(),
  }),
  last6months: () => ({
    from: DateTime.now().minus({ months: 6 }).startOf("day").toISO(),
    to: DateTime.now().endOf("day").toISO(),
  }),
  last12months: () => ({
    from: DateTime.now().minus({ months: 12 }).startOf("day").toISO(),
    to: DateTime.now().endOf("day").toISO(),
  }),
};

const useStyles = makeStyles<undefined, { from?: string | undefined; to?: string | undefined }>(() => {
  return {
    picker: {
      "& .MuiPickersBasePicker-pickerView": {
        borderRadius: "0",
      },
      "& .MuiPickersCalendarHeader-iconButton:first-of-type": {
        order: 1,
      },
      "& .MuiPickersCalendarHeader-transitionContainer > *": {
        textAlign: "center",
        paddingLeft: "0",
      },
      "& .MuiPickersDay-current": {
        color: "unset",
        backgroundColor: "unset",
      },
    },
    fromPicker: ({ from }) => ({
      "& .MuiPickersDay-daySelected": {
        color: from ? "#fff" : "#000",
        backgroundColor: from ? "#0045ff" : "#fff",
      },
    }),
    toPicker: ({ to }) => ({
      "& .MuiPickersDay-daySelected": {
        color: to ? "#fff" : "#000",
        backgroundColor: to ? "#0045ff" : "#fff",
      },
    }),
  };
});

const ReportsDateRangeContainer: React.VFC = () => {
  const { t } = useTranslation("insightsV2");
  const { from, to } = useInsightsV2SliceSelector((state) => state.insightsV2UiSlice);

  const classes = useStyles({ from, to });

  const setUiSlice = useSetUiSlice();

  const [currentRange, setCurrentRange] = useState<TimeRangeOptions>(!!from || !!to ? "custom" : "allTime");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleChangeSelection = (timeRange: TimeRangeOptions) => {
    setCurrentRange(timeRange);

    const newFromTo = mapOptionsToRange[timeRange]();

    setUiSlice({ ...newFromTo });
  };

  const renderSelectCopy = (value: TimeRangeOptions): string => {
    if (value === "custom") {
      const start =
        !!from &&
        DateTime.fromISO(from).toLocaleString({
          month: "short",
          day: "2-digit",
          year: DateTime.fromISO(from).year === DateTime.now().year ? undefined : "2-digit",
        });
      const end =
        !!to &&
        DateTime.fromISO(to).toLocaleString({
          month: "short",
          day: "2-digit",
          year: DateTime.fromISO(to).year === DateTime.now().year ? undefined : "2-digit",
        });

      if (!!start && !end) {
        return t("filter_time_after", { date: start });
      }

      if (!start && !!end) {
        return t("filter_time_before", { date: end });
      }

      if (!start && !end) {
        return t(mapOptionsToI18nKeys[value]);
      }

      return t("filter_time_between", { start, end });
    }

    return t(mapOptionsToI18nKeys[value]);
  };

  const handleChangeDate = (value: MaterialUiPickersDate, target: "from" | "to"): void => {
    const date = target === "from" ? value?.startOf("day").toISO() : value?.endOf("day").toISO();
    const oldDate = target === "from" ? from : to;

    if (date === oldDate) {
      setUiSlice({ [target]: undefined });
      return;
    }

    setUiSlice({ [target]: date });
  };

  return (
    <div className="position-relative">
      <SelectInput
        value={currentRange}
        SelectProps={{ renderValue: (value) => renderSelectCopy(value as TimeRangeOptions), ref: popoverRef }}
        onChange={(event) => handleChangeSelection(event.target.value as TimeRangeOptions)}
      >
        {timeRangeOptions.map((option) => (
          <MenuItem key={option} value={option} onClick={() => option === "custom" && setIsPopoverOpen(true)}>
            {t(mapOptionsToI18nKeys[option])}
          </MenuItem>
        ))}
      </SelectInput>
      <div className="position-relative">
        <Popover
          open={isPopoverOpen}
          onClose={() => setIsPopoverOpen(false)}
          anchorEl={popoverRef.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className={`flex ${classes.picker}`}>
            <div className={`flex ${classes.fromPicker}`}>
              <DatePicker
                value={from ?? null}
                onChange={(value) => handleChangeDate(value, "from")}
                variant="static"
                disableFuture
                disableToolbar
                initialFocusedDate={DateTime.now().minus({ month: 1 }).set({ day: 1 }).toISO()} // When `from` date is unselected by the user, this prop opens the "from" calendar to the month prior
              />
            </div>
            <div className={`flex ${classes.toPicker}`}>
              <DatePicker
                value={to}
                minDate={from}
                onChange={(value) => handleChangeDate(value, "to")}
                variant="static"
                disableFuture
                disableToolbar
              />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default ReportsDateRangeContainer;
