import { VFC } from "react";

import { Info } from "@material-ui/icons";
import { ChatStatusUpdateQuoteSubmitted } from "components/chat/types/ChatStatusUpdates";
import { DateTime } from "luxon";
import { Trans } from "react-i18next";

const StatusUpdateQuoteSubmittedView: VFC<{
  params: ChatStatusUpdateQuoteSubmitted["notification_params"];
}> = ({ params }) => {
  const amount = params.amount;
  const email = params.email;
  const submittedAtLuxon = DateTime.fromISO(params.submittedAt);
  const requestedAtTime = submittedAtLuxon.toLocaleString(DateTime.TIME_SIMPLE);
  const requestedAtDate = submittedAtLuxon.toLocaleString({
    weekday: "short",
    month: "short",
    day: "2-digit",
    year: "numeric",
  });

  return (
    <div className="p-2 flex items-center border rounded bg-yellow-100 border-yellow-100">
      <div className="text-orange-500 my-0 mx-3">
        <Info />
      </div>
      <div className="flex flex-col gap-3 text-sm">
        <span>
          <Trans i18nKey="statusUpdates_quoteSubmitted_text" ns="chat">
            {{ amount, email, requestedAtTime, requestedAtDate }}
          </Trans>
        </span>
      </div>
    </div>
  );
};

export default StatusUpdateQuoteSubmittedView;
