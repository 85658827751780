import { VFC } from "react";

import { Alert, Box, Button, Paper, SvgPortexLogoBlue, Typography } from "@portex-pro/ui-components";
import Text from "components/Text";
import { useUserContext } from "hooks/useUserContext";
import { useTranslation } from "react-i18next";
import getSizeStr from "utils/styles/getSizeStr";

import { REACT_APP_MARKETING_SITE_URL } from "../env";
import PortexLink from "./PortexLink";

const SignupThankYou: VFC = () => {
  const { t } = useTranslation();
  const { user } = useUserContext();

  return (
    <Box
      maxWidth="100%"
      minWidth="600px"
      width="600px"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      margin="auto"
    >
      <Paper elevation={24}>
        <div className="p-6 flex flex-col gap-4 items-center">
          <SvgPortexLogoBlue width={135} height={40} />

          <Typography variant="h5" align="center">
            <strong>{t("signupThankYou.title")}</strong>
          </Typography>

          {user && (
            <Text size="large" typographyProps={{ align: "center" }}>
              {user.email}
            </Text>
          )}

          <Alert variant="standard" severity="info">
            <span>{t("signupThankYou.brokerMessaging")}</span>
          </Alert>

          <div className="w-[200px] mt-3">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              component="a"
              href={REACT_APP_MARKETING_SITE_URL}
              target="_blank"
              rel="noopener,noreferrer"
            >
              {t("signupThankYou.goHome")}
            </Button>
          </div>

          <Button component="a" href="mailto:support@portexpro.com" target="_blank" rel="noopener,noreferrer">
            <Typography color="textSecondary" variant="body1" align="center">
              {t("signupThankYou.contact")}
            </Typography>
          </Button>

          <PortexLink to="/logout">
            <Text
              size="small"
              typographyProps={{ color: "textSecondary", align: "center", style: { paddingTop: getSizeStr(16) } }}
            >
              {t("signupThankYou.logout")}
            </Text>
          </PortexLink>
        </div>
      </Paper>
    </Box>
  );
};

export default SignupThankYou;
