import { createSelector } from "@reduxjs/toolkit";
import { RootState, StatesFromSlicesMap } from "types/Store";
import { configureSlices } from "utils/store/configureSlices";

import { additionalFieldsSection1, additionalFieldsSection2, additionalFieldsSection3 } from "../const";
import { StaticFieldKeys } from "../types";
import bidRequestContractSlice from "./bidRequestContractSlice";
import bidRequestFieldsSlice, { fieldAdapter } from "./bidRequestFieldsSlice";

export const bidRequestSlicesMap = {
  bidRequestFieldsSlice,
  bidRequestContractSlice,
};

export const { useSlices: useBidRequestSlices, useSliceSelector: useBidRequestSliceSelector } =
  configureSlices(bidRequestSlicesMap);

export type BidRequestStateType = RootState<StatesFromSlicesMap<typeof bidRequestSlicesMap>>;

// Selectors
export const { selectAll: selectAllFields } = fieldAdapter.getSelectors<BidRequestStateType>(
  (state) => state.bidRequestFieldsSlice
);

export const poFieldSelector = createSelector(selectAllFields, (fields) =>
  fields.filter((field) => field.key === StaticFieldKeys.PO_REFERENCE_NUMBER)
);

export const quoteNumberFieldSelector = createSelector(selectAllFields, (fields) =>
  fields.filter((field) => field.key === StaticFieldKeys.QUOTE_NUMBER)
);

export const locationsFieldSelector = createSelector(selectAllFields, (fields) =>
  fields.filter(
    (field) =>
      field.key.startsWith("pickup") ||
      (field.key.startsWith("delivery") && field.key !== StaticFieldKeys.DELIVERY_WEEK) ||
      field.key.startsWith("stop")
  )
);

export const additionalFieldSelector1 = createSelector(selectAllFields, (fields) =>
  fields.filter((field) => additionalFieldsSection1.some((key) => key === field.key))
);

export const additionalFieldsSelector2 = createSelector(selectAllFields, (fields) =>
  fields.filter((field) => additionalFieldsSection2.some((key) => key === field.key))
);

export const additionalFieldsSelector3 = createSelector(selectAllFields, (fields) =>
  fields.filter((field) => additionalFieldsSection3.some((key) => key === field.key))
);
