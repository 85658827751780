import { oneSecondInMilliseconds } from "constants/time/oneSecondInMilliseconds";

import { ReactNode, useEffect, useState, VFC } from "react";

import { Avatar } from "@portex-pro/ui-components";
import { ReactComponent as NoMessagesIcon } from "assets/no-messages.svg";
import classNames from "classnames";
import NotificationBadge from "components/NotificationBadge";
import SimpleSearchView from "components/SimpleSearchView";
import Text from "components/Text";
import take from "lodash/take";
import { DateTime, ToRelativeOptions } from "luxon";
import { useTranslation } from "react-i18next";
import { DateIsoString } from "types/DateIsoString";

export type DisplayConversationType = {
  conversationId: number;
  lastMessageAt: DateIsoString | null;
  initials: string;
  renderableContent: ReactNode[];
};

const toRelOpts: ToRelativeOptions = { style: "short" };
const LastMessageAt: VFC<{ lastMessageAt: DateIsoString; lastRefreshedAt: number | undefined }> = ({
  lastMessageAt,
  lastRefreshedAt,
}) => {
  const [relativeTime, setRelativeTime] = useState(DateTime.fromISO(lastMessageAt).toRelative(toRelOpts));

  // Update the relative time every 60 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRelativeTime(DateTime.fromISO(lastMessageAt).toRelative(toRelOpts));
    }, oneSecondInMilliseconds * 60);

    return () => clearInterval(intervalId); // cleanup on component unmount
  }, [lastMessageAt]);

  useEffect(() => {
    if (!lastRefreshedAt) {
      return;
    }

    setRelativeTime(DateTime.fromISO(lastMessageAt).toRelative(toRelOpts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRefreshedAt]);

  return <div className="text-grey-600 text-xs">{relativeTime}</div>;
};

const NoMessages: VFC = () => {
  const { t } = useTranslation(["chat"]);
  return (
    <div className="text-grey-600 text-xs flex gap-1 items-center">
      <span>
        <NoMessagesIcon style={{ width: "1rem", height: "1.2rem" }} />
      </span>
      <span>{t("chat:noMessages")}</span>
    </div>
  );
};

interface ConversationsViewProps {
  chatContainer: ReactNode;
  search?: string;
  onChangeSearch?: (search: string) => void;
  conversations: Array<DisplayConversationType>;
  selectedConversationId?: number;
  onChangeConversation?: (conversationId: number) => void;
  lastRefreshedAt?: number | undefined;
}

const ConversationsView: VFC<ConversationsViewProps> = (props) => {
  const {
    chatContainer,
    search,
    onChangeSearch,
    conversations,
    selectedConversationId,
    onChangeConversation,
    lastRefreshedAt,
  } = props;
  const { t } = useTranslation(["chat"]);

  return (
    <div
      className={classNames(
        "grid grid-cols-[295px_1fr] xl:grid-cols-[330px_1fr] 2xl:grid-cols-[400px_1fr]", // 1st column fixed, 2nd column fills remaining space
        "h-full w-full", // fills 2nd column (chat container) to fill parent container
        "overflow-hidden" // important for if either column has scrollable content, otherwise it will spill vertically out of the parent container
      )}
    >
      <div
        className={classNames(
          "flex flex-col w-full bg-white",
          "border-y border-l border-grey-300",
          "overflow-auto" // important to explicitly allow overflow for the fixed 1st column, because there may be many conversations. overflow in the right column is handled already by the chat container
        )}
      >
        <div className="flex px-2 py-2.5">
          <SimpleSearchView
            placeholder={t("chat:searchChats")}
            search={search ?? ""}
            onChange={(search) => onChangeSearch?.(search)}
            TextInputProps={{ fullWidth: true }}
          />
        </div>

        <div className="flex flex-col h-full px-2 gap-y-1 overflow-y-auto overflow-x-hidden">
          {conversations.length === 0 ? (
            <Text size="small" typographyProps={{ color: "textSecondary", style: { alignSelf: "center" } }}>
              {t("chat:noConversations")}
            </Text>
          ) : (
            conversations.map((conversation) => {
              const { conversationId, renderableContent, initials } = conversation;

              const firstThreeContent = take(renderableContent, 3);

              return (
                <NotificationBadge
                  identifiers={[`conversation:${conversationId}`]}
                  position={{ right: "98%", top: "0.7rem" }}
                  size="small"
                >
                  <div
                    key={`conversation:${conversationId}`}
                    className={classNames("flex flex-row p-2 rounded cursor-pointer", {
                      "hover:bg-grey-100": selectedConversationId !== conversationId,
                      "hover:bg-blue-200": selectedConversationId === conversationId,
                      "bg-blue-100": selectedConversationId === conversationId,
                    })}
                    onClick={() => onChangeConversation?.(conversationId)}
                  >
                    <div className="flex mr-3.5">
                      <Avatar className="font-bold drop-shadow">{initials}</Avatar>
                    </div>

                    <div className="flex flex-col space-y-1 items-start justify-start">
                      {firstThreeContent.map((content, i) => (
                        <div
                          key={`conversation:${conversationId}:content:${i}`}
                          className={classNames(
                            "w-[200px] xl:w-[230px] 2xl:w-[300px] truncate",
                            i === 0 && "font-bold",
                            i > 0 && "text-xs",
                            i > 0 && "text-grey-600"
                          )}
                        >
                          {content}
                        </div>
                      ))}
                      {conversation.lastMessageAt ? (
                        <LastMessageAt lastMessageAt={conversation.lastMessageAt} lastRefreshedAt={lastRefreshedAt} />
                      ) : (
                        <NoMessages />
                      )}
                    </div>
                  </div>
                </NotificationBadge>
              );
            })
          )}
        </div>
      </div>

      {!!selectedConversationId ? (
        chatContainer
      ) : (
        <div className="flex bg-white border border-grey-300 text-x-large font-bold text-grey-300 items-center justify-center">
          {t("chat:noConversationSelected")}
        </div>
      )}
    </div>
  );
};

export default ConversationsView;
