import { ReactElement } from "react";

import { Box, DataRow, Typography } from "@portex-pro/ui-components";
import isNumber from "lodash/isNumber";
import { isTemperatureControlled } from "types/TrailerType";
import { formatTrailerType } from "utils/formatTrailerType";

import { LtlTrailerType, OptionalMaybe, TempUnit } from "../../../api/types/generated-types";

const makeEquipmentString = ({
  trailerType,
  minTemp,
  maxTemp,
  tempUnit,
}: {
  trailerType: LtlTrailerType;
  minTemp: OptionalMaybe<number>;
  maxTemp: OptionalMaybe<number>;
  tempUnit: OptionalMaybe<TempUnit>;
}): string => {
  let equipmentString = formatTrailerType(trailerType);
  if (isTemperatureControlled(trailerType)) {
    const minTempString = isNumber(minTemp) ? `${minTemp}˚ ${tempUnit}` : "";
    const maxTempString = isNumber(maxTemp) ? `${maxTemp}˚ ${tempUnit}` : "";
    equipmentString = `${equipmentString} ${minTempString} to ${maxTempString}`;
  }
  return equipmentString;
};

const EquipmentRowView = ({
  trailerType,
  minTemp,
  maxTemp,
  tempUnit,
}: {
  trailerType: LtlTrailerType;
  minTemp: OptionalMaybe<number>;
  maxTemp: OptionalMaybe<number>;
  tempUnit: OptionalMaybe<TempUnit>;
}): ReactElement => {
  return (
    <DataRow label={"Equipment"}>
      <Box ml="3rem" display="flex" alignItems="center">
        <Typography display={"inline"} style={{ marginLeft: "1rem" }}>
          <strong>{makeEquipmentString({ trailerType, minTemp, maxTemp, tempUnit })}</strong>
        </Typography>
      </Box>
    </DataRow>
  );
};

export default EquipmentRowView;
