import { useContext, VFC } from "react";

import { ShipmentPackagingType } from "app/pages/shipments/types/domain";

import { CargoGroupType } from "../api/types";
import { CargoGroupContext } from "../CargoGroupProvider";
import { selectManyCargoGroups } from "../store/cargoGroupsSlice";
import { useCargoGroupsSliceSelector } from "../store/cargoGroupsStore";
import HandlingUnitsView from "../views/HandlingUnitsView";
import PackingUnitsView from "../views/PackingUnitsView";

const PackingGroup: VFC<{ cargoGroupIds: Array<number | string>; parentId: string | number }> = ({
  cargoGroupIds,
  parentId,
}) => {
  const { addCargoGroup, removeCargoGroup, updateCargoGroup } = useContext(CargoGroupContext);
  const handlingGroups = useCargoGroupsSliceSelector((state) => selectManyCargoGroups(state, cargoGroupIds));

  return (
    <PackingUnitsView
      cargoGroups={handlingGroups}
      onAddPackingUnit={() =>
        addCargoGroup({
          cargo_group_type: CargoGroupType.PACKING,
          packaging_type: ShipmentPackagingType.Bags,
          handling_unit_id: parentId,
        })
      }
      onChangePackingUnit={updateCargoGroup}
      onRemovePackingUnit={removeCargoGroup}
    />
  );
};

const HandlingGroup: VFC<{ cargoGroupIds: Array<number | string>; parentId: string | number }> = ({
  cargoGroupIds,
  parentId,
}) => {
  const { addCargoGroup, removeCargoGroup, updateCargoGroup } = useContext(CargoGroupContext);
  const handlingGroups = useCargoGroupsSliceSelector((state) => selectManyCargoGroups(state, cargoGroupIds));

  return (
    <HandlingUnitsView
      cargoGroups={handlingGroups}
      onAddHandlingUnit={() =>
        addCargoGroup({
          cargo_group_type: CargoGroupType.HANDLING,
          packaging_type: ShipmentPackagingType.Pallets,
          cargo_unit_id: parentId,
        })
      }
      onChangeHandlingUnit={updateCargoGroup}
      onRemoveHandlingUnit={removeCargoGroup}
      renderPackingUnit={PackingGroup}
    />
  );
};

export default HandlingGroup;
