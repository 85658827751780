import { TFunction } from "react-i18next";

import { CargoGroup, NormalizedCargoGroup } from "../api/types";

export const getHandlingUnitString = (
  cargoGroup: CargoGroup | NormalizedCargoGroup,
  t: TFunction<["shipper"]>
): string => {
  const { count, packaging_type, width_per_count, length_per_count, height_per_count } = cargoGroup;

  let dimensionString = [
    width_per_count && t("shipper:width_in", { count: width_per_count }),
    length_per_count && t("shipper:length_in", { count: length_per_count }),
    height_per_count && t("shipper:height_in", { count: height_per_count }),
  ]
    .filter(Boolean)
    .join(" x ");

  if (dimensionString) {
    dimensionString = "(" + dimensionString + ")";
  }

  return [count, "x", t(`shipper:packingTypeMap.${packaging_type}`), dimensionString].filter(Boolean).join(" ");
};
