import { EN_DASH } from "constants/index";

import { VFC } from "react";

import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import { ContractRequest } from "app/pages/bid-award/types";
import Text from "components/Text";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface ContractsListPeriodViewProps {
  contract: ContractRequest;
}

const ContractsListPeriodView: VFC<ContractsListPeriodViewProps> = ({ contract }) => {
  const { t } = useTranslation(["shipper"]);
  const start = DateTime.fromISO(contract.start).setZone("UTC");
  const end = DateTime.fromISO(contract.end).setZone("UTC");
  let period = "";

  if (contract.pricing_frequency === ContractPricingFrequency.BULK_SPOT_QUOTE) {
    return <Text size="small">{t("shipper:bids.award.bulkSpotQuote")}</Text>;
  }

  if (start.year === end.year) {
    period = `${start.toFormat("LLL dd")} ${EN_DASH} ${end.toFormat("LLL dd, yyyy")}`;
  } else {
    period = `${start.toFormat("LLL dd, yyyy")} ${EN_DASH} ${end.toFormat("LLL dd, yyyy")}`;
  }

  return <Text size="small">{period}</Text>;
};

export default ContractsListPeriodView;
