import { useGetReportQuery, useGetReportTypeListQuery } from "../api/insightsApi";
import { useInsightsV2SliceSelector } from "../store/insightsV2Store";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetReport = () => {
  const getReportTypesResult = useGetReportTypeListQuery({});

  const { mode, from, to, selectedOwners, reportId, selectedLane, searchInput } = useInsightsV2SliceSelector(
    (state) => state.insightsV2UiSlice
  );

  const selectedOwnerIds = selectedOwners?.map((owner) => owner.id);

  const reportsWithLaneRequired = getReportTypesResult.currentData?.reports.filter((report) => report.isLaneRequired);
  const reportRequiresLanes = !!reportsWithLaneRequired?.find((report) => report.id === reportId);

  return useGetReportQuery(
    {
      queryParams: {
        mode,
        from,
        to,
        lane: selectedLane,
        ownerIds: selectedOwnerIds?.length ? selectedOwnerIds : undefined,
        search: searchInput,
      },
      urlParams: { id: reportId },
    },
    {
      skip: !getReportTypesResult.isLoading && !!reportRequiresLanes && !selectedLane,
    }
  );
};
