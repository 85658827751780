import { oneSecondInMilliseconds } from "./time/oneSecondInMilliseconds";

/** @see https://www.thepunctuationguide.com/em-dash.html */
export const EM_DASH = "—";
/** @see https://www.thepunctuationguide.com/en-dash.html */
export const EN_DASH = "–";
export const EMPTY_CELL_HYPHEN = EM_DASH;
export const NON_BREAKING_SPACE = "\u00A0";
export const RIGHT_TRIANGLE = "▶";
export const DOWN_TRIANGLE = "▼";
/** The value of the Material UI's dialog component z-index */
export const MUI_DIALOG_Z_INDEX = 1300;
export const PORTEX_SUPPORT_EMAIL = "support@portexpro.com";
export const CHAT_POLLING_INTERVAL_MS = oneSecondInMilliseconds * 10;
