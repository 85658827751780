import { useGetShipmentListQuery } from "api/rest/shipments/shipmentsApi";
import OptionalMaybe from "types/OptionalMaybe";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetQuoteRequestShipment = ({ portexId }: { portexId: OptionalMaybe<string> }) => {
  return useGetShipmentListQuery(
    {
      queryParams: { search: portexId || "should-skip-if-missing", take: 1 },
    },
    { skip: !portexId }
  );
};
