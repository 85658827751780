import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { ENVIRONMENT, IS_DEV, IS_PROD, IS_STAGING, REACT_APP_SENTRY_DSN, REACT_APP_VERSION } from "../../../env";

const initializeSentry = (): void => {
  return Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    /** @see https://docs.sentry.io/platforms/javascript/configuration/releases/#setting-a-release */
    release: `portex-web-app@${REACT_APP_VERSION}`,
    environment: ENVIRONMENT,
    enabled: IS_PROD || IS_STAGING,
    debug: IS_DEV || IS_STAGING,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    attachStacktrace: true,
    // https://github.com/getsentry/sentry-javascript/issues/3218#issuecomment-818321845
    autoSessionTracking: false,
    ignoreErrors: [
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/25
      "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
    ],
  });
};

export default initializeSentry;
