import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import { useDeleteAddressMutation } from "api/rest/address/deleteAddressApi";
import { useBaseLazyGetAddressesQuery } from "api/rest/address/getAddressesApi";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import chunk from "lodash/chunk";
import compact from "lodash/compact";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";

const DeleteAllLocations: VFC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { dialogElement, confirm } = useConfirmationDialog();
  const { t } = useTranslation(["shipper"]);

  const [getAddresses, { isLoading: isLoadingAddresses }] = useBaseLazyGetAddressesQuery();
  const [deleteAddress, { isLoading: isDeletingAddresses }] = useDeleteAddressMutation();

  const onDeleteAllLocations = async () => {
    const confirmed = await confirm({
      title: t("shipper:deleteAllLocations"),
      body: t("shipper:deleteAllLocations_warning"),
    });

    const locations = (await getAddresses({ queryParams: { take: undefined } }).unwrap()).data.addresses;

    if (!locations || !confirmed) {
      return;
    }

    try {
      const deleteGroups = chunk(locations, 25);

      while (deleteGroups.length > 0) {
        await Promise.all(
          compact(deleteGroups.pop())?.map((address) =>
            deleteAddress({ urlParams: { addressId: address.id.toString() } })
          ) ?? [Promise.resolve()]
        );
      }
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(e, { variant: "error" });
    }
  };

  return (
    <>
      {dialogElement}
      <Button
        onClick={onDeleteAllLocations}
        disabled={isLoadingAddresses || isDeletingAddresses}
        variant="outlined"
        className="Por-bg-red"
      >
        {t("shipper:deleteAllLocations")}
      </Button>
    </>
  );
};

export default DeleteAllLocations;
