import { useState, VFC } from "react";

import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { Collapse, TextInput } from "@portex-pro/ui-components";
import FormStringConcat from "@portex-pro/ui-components/Forms/FormStringConcat";
import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import classNames from "classnames";
import PositiveNumberInput from "components/PositiveNumberInput";
import SingleCalendarPicker from "components/SingleCalendarPicker";
import Text from "components/Text";
import YesNoRadio from "components/YesNoRadio";
import { useTranslation } from "react-i18next";
import { ModeRfp } from "types/Mode";
import OptionalMaybe from "types/OptionalMaybe";

import FormFuelButtonGroupView from "../../extra-details/components/FormFuelButtonGroupView";
import PricingFrequencyView from "./PricingFrequencyView";

type BidAttributesViewProps = {
  mode: ModeRfp;
  enableRfpBulkSpotQuote: boolean;

  pricingFrequency: OptionalMaybe<ContractPricingFrequency>;
  onChangePricingFrequency: (val: ContractPricingFrequency) => void;

  startDate: MaterialUiPickersDate;
  endDate: MaterialUiPickersDate;
  onChangeStartDate: (val: MaterialUiPickersDate) => void;
  onChangeEndDate: (val: MaterialUiPickersDate) => void;

  commodities: string;
  isHazardous: OptionalMaybe<boolean>;
  hazardousDetails: OptionalMaybe<string>;
  includeFuel: OptionalMaybe<boolean>;
  hidePoTillDispatch: OptionalMaybe<boolean>;
  onChangeCommodities: (val: string) => void;
  onChangeHazardous: (val: boolean) => void;
  onChangeFuel: (val: boolean) => void;
  onChangeHazardousDetails: (val: string) => void;
  onChangeHidePoTillDispatch: (val: boolean) => void;

  numberOfStops: number;
  onChangeStops: (numberOfStops: number) => void;
};

const sectionStyles = "grid grid-cols-subgrid col-span-3 px-4 py-4 space-y-3";
const greySectionAdditionalStyles = "bg-grey-50";
const whiteSectionAdditionalStyles = "bg-white";

const BidAttributesView: VFC<BidAttributesViewProps> = ({
  mode,
  enableRfpBulkSpotQuote,

  pricingFrequency,
  onChangePricingFrequency,

  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate,

  commodities,
  isHazardous,
  hazardousDetails,
  includeFuel,
  hidePoTillDispatch,
  onChangeCommodities,
  onChangeHazardous,
  onChangeFuel,
  onChangeHazardousDetails,
  onChangeHidePoTillDispatch,

  numberOfStops,
  onChangeStops,
}) => {
  const { t } = useTranslation(["shipper", "common"]);
  const [isMultipleStops, setIsMultipleStops] = useState(numberOfStops > 1);

  const displayContractDates = !!pricingFrequency && pricingFrequency !== ContractPricingFrequency.BULK_SPOT_QUOTE;
  const displayFuel = !!pricingFrequency && pricingFrequency !== ContractPricingFrequency.BULK_SPOT_QUOTE;

  return (
    <div className="w-full h-full">
      <div className="flex flex-col items-center py-4 gap-4">
        <Text size="x-large" weight="bold">
          {t("shipper:bids.bidRequestPage.pageBidAttributes.title")}
        </Text>
        <Text size="medium">{t("shipper:bids.bidRequestPage.pageBidAttributes.description")}</Text>
      </div>
      <div className={classNames("w-full grid grid-cols-3 gap-x-4 gap-y-1")}>
        <div className={classNames(sectionStyles, greySectionAdditionalStyles)}>
          <div className="col-span-3">
            <Text
              size="medium"
              weight="bold"
              typographyProps={{ color: "textSecondary", style: { textTransform: "uppercase" } }}
            >
              {t("shipper:bids.bidRequestPage.pageBidAttributes.contractLength")}
            </Text>
          </div>

          <div className="flex flex-col">
            <Text size="medium" weight="bold" required>
              {t("shipper:bids.bidRequestPage.pageBidAttributes.pricingFreq_label")}
            </Text>
            <PricingFrequencyView
              placeholder={t("shipper:bids.bidRequestPage.pageBidAttributes.pricingFreq_placeholder")}
              value={pricingFrequency}
              onChange={onChangePricingFrequency}
              enableRfpBulkSpotQuote={enableRfpBulkSpotQuote}
              mode={mode}
            />
          </div>

          <Collapse in={displayContractDates}>
            <div className="flex flex-col">
              <Text size="medium" weight="bold" required>
                {t("shipper:bids.bidRequestPage.pageBidAttributes.startDate_label")}
              </Text>
              <SingleCalendarPicker
                margin="normal"
                value={startDate}
                onChange={onChangeStartDate}
                placeholder={t("shipper:bids.bidRequestPage.pageBidAttributes.startDate_placeholder")}
                startIconPallete="grey"
              />
            </div>
          </Collapse>

          <Collapse in={displayContractDates}>
            <div className="flex flex-col">
              <Text size="medium" weight="bold" required>
                {t("shipper:bids.bidRequestPage.pageBidAttributes.endDate_label")}
              </Text>
              <SingleCalendarPicker
                margin="normal"
                value={endDate}
                onChange={onChangeEndDate}
                placeholder={t("shipper:bids.bidRequestPage.pageBidAttributes.endDate_placeholder")}
                startIconPallete="grey"
                minDate={startDate}
              />
            </div>
          </Collapse>
        </div>

        <div className={classNames(sectionStyles, whiteSectionAdditionalStyles)}>
          <div className="col-span-3">
            <Text
              size="medium"
              weight="bold"
              typographyProps={{ color: "textSecondary", style: { textTransform: "uppercase" } }}
            >
              {t("shipper:bids.bidRequestPage.pageBidAttributes.loadsAndEquipment")}
            </Text>
          </div>

          <div className="flex flex-col">
            <Text size="medium" weight="bold">
              {t("shipper:bids.bidRequestPage.pageBidAttributes.commodities_label")}
            </Text>
            <FormStringConcat margin="normal" value={commodities} onChange={onChangeCommodities} />
          </div>

          <div className="flex flex-col">
            <Text size="medium" weight="bold" required>
              {t("shipper:bids.bidRequestPage.pageBidAttributes.hazardous_label")}
            </Text>
            <YesNoRadio
              value={isHazardous}
              onChange={onChangeHazardous}
              radioGroupProps={{
                style: { flexDirection: "row-reverse", justifyContent: "flex-end", margin: "1rem" },
              }}
            />
          </div>

          <Collapse in={displayFuel}>
            <div className="flex flex-col">
              <Text size="medium" weight="bold" required>
                {t("shipper:bids.bidRequestPage.pageBidAttributes.fuel_label")}
              </Text>
              <FormFuelButtonGroupView hideFormLabel value={includeFuel} onChange={onChangeFuel} />
            </div>
          </Collapse>

          <Collapse className="col-span-3" in={!!isHazardous}>
            <div className="flex flex-col">
              <Text size="medium" weight="bold">
                {t("shipper:bids.bidRequestPage.pageBidAttributes.hazardousDetails_label")}
              </Text>
              <TextInput
                margin="normal"
                fullWidth
                multiline
                rows={2}
                rowsMax={8}
                value={hazardousDetails}
                onChange={(event) => onChangeHazardousDetails(event.target.value)}
                placeholder={t("shipper:bids.bidRequestPage.pageBidAttributes.hazardousDetails_placeholder")}
              />
            </div>
          </Collapse>
        </div>

        <div className={classNames(sectionStyles, greySectionAdditionalStyles)}>
          <div className="col-span-3">
            <Text
              size="medium"
              weight="bold"
              typographyProps={{ color: "textSecondary", style: { textTransform: "uppercase" } }}
            >
              {t("shipper:bids.bidRequestPage.pageBidAttributes.packingInformation")}
            </Text>
          </div>

          <div className="flex flex-col">
            <Text size="medium" weight="bold" required>
              {t("shipper:bids.bidRequestPage.pageBidAttributes.multipleStopsLabel")}
            </Text>
            <YesNoRadio
              value={isMultipleStops}
              onChange={(yes) => {
                if (yes) {
                  setIsMultipleStops(true);
                } else {
                  onChangeStops(1);
                  setIsMultipleStops(false);
                }
              }}
              radioGroupProps={{
                style: { flexDirection: "row-reverse", justifyContent: "flex-end", margin: "1rem" },
              }}
            />
          </div>

          <Collapse in={isMultipleStops}>
            <div className="flex flex-col">
              <Text size="medium" weight="bold">
                {t("shipper:bids.bidRequestPage.pageBidAttributes.maxStopsLabel")}
              </Text>
              <PositiveNumberInput value={numberOfStops} onChange={onChangeStops} allowEmpty disableError />
            </div>
          </Collapse>

          <div />

          <div className="col-span-3">
            <Text size="medium" weight="bold" required>
              {t("shipper:bids.bidRequestPage.pageBidAttributes.hidePoTillDispatch")}
            </Text>
            <YesNoRadio
              value={hidePoTillDispatch ?? false}
              onChange={(val) => onChangeHidePoTillDispatch(val)}
              radioGroupProps={{
                style: { flexDirection: "row-reverse", justifyContent: "flex-end", margin: "1rem" },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidAttributesView;
