import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import { BrokerQuoteRequest } from "api/rest/quote-requests";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import LoadTypeChip from "components/LoadTypeChip";
import ModeIcon from "components/ModeIcon";
import RowListView from "components/RowListView";
import SummaryPaperView from "components/SummaryPaperView";
import Text from "components/Text";
import TitleSummaryWithIconView from "components/TitleSummaryWithIconView";
import CargoGroupsOverviewView from "features/cargo-groups/views/CargoGroupsOverviewView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import VerticalStopsInfo from "views/stops/VerticalStopsInfo";

interface DrayageSummaryViewProps {
  quoteRequest: BrokerQuoteRequest;
  attachmentsList: React.ReactElement | false | null;
}

const DrayageSummaryView: VFC<DrayageSummaryViewProps> = (props) => {
  const { quoteRequest, attachmentsList } = props;
  const { t } = useTranslation(["broker", "common"]);

  return (
    <SummaryPaperView
      header={
        <TitleSummaryWithIconView
          icon={<ModeIcon svgProps={{ width: "100%", height: "100%" }} mode={quoteRequest.mode} />}
          title={t("common:quoteRequest")}
          desciption={quoteRequest.portex_id}
        />
      }
      description={
        <RowListView
          rows={[
            {
              label: t("broker:bidSubmit.shippersName"),
              value: quoteRequest.shipper_name,
            },
            {
              label: t("broker:responseDeadline"),
              value: quoteRequest.deadline_respond_at
                ? DateTime.fromISO(quoteRequest.deadline_respond_at).toFormat("LLLL d")
                : EM_DASH,
            },
          ]}
        />
      }
      PaperProps={{
        variant: "outlined",
      }}
      ColumnListViewProps={{
        rows: [
          {
            label: t("broker:shipper"),
            value: (
              <Text size="medium" weight="bold">
                {quoteRequest.shipper_name}
              </Text>
            ),
          },
          {
            label: t("broker:mode"),
            value: <LoadTypeChip mode={quoteRequest.mode} textProps={{ size: "medium", weight: "bold" }} />,
            BoxProps: { style: { alignItems: "center" } },
          },
          {
            label: t("common:incoterms"),
            value: quoteRequest.incoterms ? (
              <Text size="medium" weight="bold">
                {t(`common:incoterms_map.${quoteRequest.incoterms}`)}
              </Text>
            ) : (
              EM_DASH
            ),
          },
          {
            label: t("common:hazardousCommodities"),
            value: <Box color="red">{t("common:containsHazardous")}</Box>,
            hide: !quoteRequest.is_hazardous,
          },
          {
            label: t("common:hazardousDescription"),
            value: quoteRequest.hazardous_goods_details ?? "",
            hide: !quoteRequest.hazardous_goods_details,
          },
          {
            label: t("broker:customs"),
            value: (
              <Text size="medium" weight="bold">
                {quoteRequest.include_customs_clearance ? t("broker:required") : t("broker:notRequired")}
              </Text>
            ),
          },
          {
            label: t("broker:insurance"),
            value: (
              <Text size="medium" weight="bold">
                {quoteRequest.insurance_required ? t("broker:required") : t("broker:notRequired")}
              </Text>
            ),
          },
          {
            label: t("common:unloadingDetails"),
            value: quoteRequest.unloading_details ? (
              <Text size="medium" weight="bold">
                {t(`common:unloadingDetails_map.${quoteRequest.unloading_details}`)}
              </Text>
            ) : (
              EM_DASH
            ),
          },
          {
            label: t("common:additionalNotes"),
            value: !!quoteRequest.note ? quoteRequest.note : EM_DASH,
          },
          {
            label: t("common:containers"),
            value: <CargoGroupsOverviewView cargoGroups={quoteRequest.cargo_groups} />,
          },
          {
            label: t("common:routing"),
            value: (
              <VerticalStopsInfo
                // @ts-expect-error We can come back to these typing issues
                stops={quoteRequest.stops.map((stop) => ({
                  ...stop,
                  zip: stop.address?.zip ?? "",
                  iana_timezone: stop.address?.iana_timezone ?? "",
                  formatted_long_name: formatAddress.fromSnakeCase(
                    // @ts-expect-error We can circle around to fix these type issues
                    Object.entries(stop.address).reduce((add, [key, value]) => {
                      // @ts-expect-error same as above
                      add[key] = value ?? null;
                      return add;
                    }, {}),
                    "long"
                  ),
                }))}
              />
            ),
          },
          {
            label: t("common:attachments"),
            value: attachmentsList || EM_DASH,
            hide: !attachmentsList,
          },
        ],
      }}
    />
  );
};

export default DrayageSummaryView;
