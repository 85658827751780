import { CHAT_POLLING_INTERVAL_MS } from "constants/index";

import { useGetLoadStatusUpdatesShipperQuery } from "api/rest/load-status-updates/getLoadStatusUpdatesShipper";
import { LoadStatusUpdate } from "api/rest/load-status-updates/types";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import first from "lodash/first";
import inRange from "lodash/inRange";

export const useLoadStatusUpdate = (
  stopId: number,
  selectedLoadId: number | undefined,
  isLoadingShipment: boolean
): LoadStatusUpdate | null => {
  const isDocumentVisible = useDocumentVisibility();
  const loadStatusUpdateQuery = useGetLoadStatusUpdatesShipperQuery(
    {
      queryParams: { loadId: selectedLoadId ?? -1, stopId: stopId },
    },
    {
      // Becuase stopId and selectedLoadId come from different data sources (one from the api and on from the store)
      // it is important to make sure to wait until any loading is done so that we're not making request with stale data.
      skip: !selectedLoadId || !stopId || isLoadingShipment,
      pollingInterval: isDocumentVisible ? CHAT_POLLING_INTERVAL_MS : undefined, // this polls as frequently as chat does. This way, the load statuses will reflect any chat status updates
      refetchOnMountOrArgChange: true,
    }
  );

  const isFetchingFromPolling =
    loadStatusUpdateQuery.isFetching &&
    inRange(
      (loadStatusUpdateQuery?.startedTimeStamp ?? 0) - (loadStatusUpdateQuery?.fulfilledTimeStamp ?? 0),
      CHAT_POLLING_INTERVAL_MS,
      CHAT_POLLING_INTERVAL_MS + 100
    );

  const pendingLoadStatusUpdate: LoadStatusUpdate = {};
  const maybeLoadStatusUpdate =
    !loadStatusUpdateQuery.isLoading && (!loadStatusUpdateQuery.isFetching || isFetchingFromPolling)
      ? first(loadStatusUpdateQuery.data?.data) || pendingLoadStatusUpdate
      : null;

  return maybeLoadStatusUpdate;
};
