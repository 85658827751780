import { VFC } from "react";

import { Avatar, AvatarGroup, Tooltip, makeStyles, portexColor, Divider } from "@portex-pro/ui-components";
import { ChatParticipant } from "types/Chat";

import { getParticipantInitials } from "./utils/getParticipantInitials";

interface ChatParticipantsViewProps {
  participants: ChatParticipant[];
}
const AVATAR_SIZE = 32;
const MAX_AVATARS = 4;

const useStyles = makeStyles(() => ({
  avatar: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    marginRight: "12px",
    fontSize: 14,
    border: "none",
    fontWeight: "bold",
  },
  internalParticipant: {
    backgroundColor: portexColor.blue100,
    color: portexColor.blue800,
    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 14px;`,
  },
  externalParticipant: {
    color: "black",
    boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 14px;`,
  },
  hover: {
    opacity: 0.8,
  },
}));

const ParticipantTooltipView: VFC<{ participant: ChatParticipant }> = ({ participant }) => (
  <div className="flex flex-col">
    <span>{participant.displayName}</span>
    {!!participant.companyName && <span>{participant.companyName}</span>}
    {!!participant.fullName && <span>({participant.email})</span>}
  </div>
);

const ChatParticipantsView: VFC<ChatParticipantsViewProps> = ({ participants }: ChatParticipantsViewProps) => {
  const classes = useStyles();
  const visibleParticipants = participants.slice(0, MAX_AVATARS);
  const surplusParticipants = participants.slice(MAX_AVATARS);

  return (
    <div className="flex" style={{ margin: "0 10px" }}>
      <AvatarGroup classes={{ avatar: classes.avatar }}>
        {visibleParticipants.map((participant, i) => (
          <div style={{ border: "none" }} key={i}>
            <Tooltip
              title={<ParticipantTooltipView participant={participant} />}
              PopperProps={{ className: classes.hover }}
              interactive
            >
              <Avatar
                alt={participant.displayName}
                className={[
                  classes.avatar,
                  participant.isInternal ? classes.internalParticipant : classes.externalParticipant,
                ].join(" ")}
              >
                {getParticipantInitials(participant)}
              </Avatar>
            </Tooltip>
          </div>
        ))}
      </AvatarGroup>
      <Divider />
      {surplusParticipants.length > 0 && (
        <span style={{ border: "none", marginLeft: -8 }}>
          <Tooltip
            title={
              <div>
                {surplusParticipants.map((participant, i, arr) => (
                  <div className="flex flex-col gap-3" key={`${i}_${participant.email}`}>
                    <ParticipantTooltipView participant={participant} />
                    {arr.length > 1 && i !== arr.length - 1 && <hr className="pt-3" />}
                  </div>
                ))}
              </div>
            }
            PopperProps={{ className: classes.hover }}
            interactive
          >
            <Avatar className={[classes.avatar, classes.externalParticipant].join(" ")}>
              {`+${surplusParticipants.length}`}
            </Avatar>
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export default ChatParticipantsView;
