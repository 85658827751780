import { ComponentProps, FC, useEffect, VFC } from "react";

import { Help, NewReleases, OpenInNew } from "@material-ui/icons";
import { Alert, Button, Fade, makeStyles, Tooltip, TooltipProps } from "@portex-pro/ui-components";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import classNames from "classnames";
import AppBar from "components/AppBar";
import LoggedIn from "components/auth/LoggedIn";
import LoggedOut from "components/auth/LoggedOut";
import CheckboxView from "components/CheckboxView";
import ImpersonationBanner from "components/ImpersonationBanner";
import NotificationBadge from "components/NotificationBadge";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import PortexLogoView from "components/PortexLogoView";
import TooltipBackdrop from "components/TooltipBackdrop";
import VersionBanner from "components/VersionBanner";
import { useHintStore } from "hooks/useHintStore";
import useLDFlag from "hooks/useLDFlag";
import { useUserContext } from "hooks/useUserContext";
import first from "lodash/first";
import { useTranslation } from "react-i18next";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { useBoolean } from "usehooks-ts";

import BrokerUserAvatar from "./BrokerUserAvatar";

const useViewingTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.info.dark,
  },
  tooltip: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    borderColor: theme.palette.info.dark,
    maxWidth: 500,
  },
}));

const AccountActionLink: FC<LinkProps & { showAlert?: boolean }> = ({ showAlert, ...props }) => {
  return (
    <Link
      {...props}
      className={classNames(
        "rounded-lg px-4 py-2",
        "bg-white hover:bg-blue-100 cursor-pointer",
        "text-small text-brandBlue font-bold uppercase tracking-[2px]",
        props.className
      )}
    >
      {!!showAlert && (
        <div className="relative">
          <NewReleases className="absolute left-[100%] -top-3  text-red-500" />
        </div>
      )}
      {props.children}
    </Link>
  );
};

const LoggedInAs: VFC = () => {
  const { t } = useTranslation(["common"]);
  const { auth0User } = useUserContext();
  const { user } = useGetCurrentUserQuery(undefined, { selectFromResult: ({ data }) => ({ user: data?.data.user }) });

  return (
    <LoggedIn>
      <div className="opacity-70">{t("common:loggedInAs", { as: auth0User?.email ?? user?.email })}</div>
    </LoggedIn>
  );
};

const ViewingAsTooltip: FC<{
  open: TooltipProps["open"];
  onClickGotIt: () => void;
  dontShowAgainChecked: boolean;
  onChangeDontShowAgain: (checked: boolean) => void;
  tooltipProps?: TooltipProps;
}> = ({ open = false, children, onClickGotIt, dontShowAgainChecked, onChangeDontShowAgain, tooltipProps }) => {
  const { t } = useTranslation(["common", "broker"]);
  const classes = useViewingTooltipStyles();

  return (
    <>
      <TooltipBackdrop open={open} />
      <Tooltip
        classes={classes}
        interactive
        title={
          <div className="py-1">
            <div className="pb-3 text-large leading-snug">{t("broker:viewingAsTooltip")}</div>
            <div className="flex justify-between">
              <Button style={{ backgroundColor: "white", minWidth: 100 }} onClick={onClickGotIt}>
                {t("common:gotIt")}
              </Button>
              <CheckboxView
                checkboxProps={{ style: { color: "white" } }}
                label={<div className="text-medium text-white font-bold">{t("common:dontShowThisAgain")}</div>}
                value={dontShowAgainChecked}
                onChange={onChangeDontShowAgain}
              />
            </div>
          </div>
        }
        open={open}
        disableHoverListener
        placement="bottom"
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterDelay={0}
        {...tooltipProps}
      >
        <div>{children}</div>
      </Tooltip>
    </>
  );
};

const ViewingAsView: VFC<{
  email: string;
  onClick?: () => void;
  viewingAsTooltipProps: ComponentProps<typeof ViewingAsTooltip>;
}> = ({ email, onClick, viewingAsTooltipProps }) => {
  const { t } = useTranslation(["common"]);
  return (
    <ViewingAsTooltip {...viewingAsTooltipProps}>
      <button
        onClick={onClick}
        className={classNames("opacity-70 cursor-text select-text", {
          "cursor-help underline decoration-dotted hover:opacity-100": !!onClick,
        })}
      >
        {t("common:viewingAs", { as: email })}
      </button>
    </ViewingAsTooltip>
  );
};

const ViewingAsDialog: VFC<PortexDialogProps> = (props) => {
  const { t } = useTranslation(["broker"]);
  return (
    <PortexDialog hideDialogActions hideDialogTitle {...props}>
      <div className="text-large">
        <Alert severity="info">
          <LoggedInAs />
        </Alert>
        <div className="p-5">{t("broker:loggedInAndViewingAs.explainLoggedIn")}</div>
      </div>
      <div className="text-large">
        <Alert severity="info">
          <ViewingAs />
        </Alert>
        <div className="flex flex-col gap-5 p-5">
          {t("broker:loggedInAndViewingAs.explainViewingAs")}
          <div className="flex flex-col gap-2 items-center">
            <div>{t("broker:loggedInAndViewingAs.preferLoggedIn")}</div>
            <Button variant="outlined" href="/broker" target="_blank" startIcon={<OpenInNew />}>
              {t("broker:loggedInAndViewingAs.clickHereToStopViewing")}
            </Button>
          </div>
        </div>
      </div>
    </PortexDialog>
  );
};

const ViewingAs: VFC<{ onClick?: () => void }> = ({ onClick }) => {
  const [brokerTokenQueryParam] = useQueryParam("brokerToken", StringParam);
  const { user, refetch } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({ user: data?.data.user }),
  });
  const { dontShowAgainViewingAsHint, setHintStoreState } = useHintStore();
  const viewingAsHintOpen = useBoolean(!dontShowAgainViewingAsHint);

  useEffect(() => {
    /**
     * @todo PR-2179: currently, brokerToken takes precedence over uut in baseRestApi.
     * We need to move away from this because they are in conflict and use the same `Authorization` header.
     * brokerToken should be passed in the body or a separate header. This will require fullstack changes.
     * This useEffect refetches the user once the brokerTokenQueryParam is gone. This allows us to get the user associated with the `uut`
     */
    if (!brokerTokenQueryParam) {
      refetch();
    }
  }, [brokerTokenQueryParam, refetch]);

  if (!user) {
    return null;
  }

  const viewingAsTooltipProps: ComponentProps<typeof ViewingAsView>["viewingAsTooltipProps"] = {
    open: viewingAsHintOpen.value,
    onClickGotIt: viewingAsHintOpen.setFalse,
    onChangeDontShowAgain: (checked) => setHintStoreState({ dontShowAgainViewingAsHint: checked }),
    dontShowAgainChecked: dontShowAgainViewingAsHint,
  };

  return (
    <>
      <LoggedIn as={["uut"]}>
        <ViewingAsView email={user.email} onClick={onClick} viewingAsTooltipProps={viewingAsTooltipProps} />
      </LoggedIn>

      <LoggedOut withUut={true}>
        <ViewingAsView email={user.email} viewingAsTooltipProps={viewingAsTooltipProps} />
      </LoggedOut>
    </>
  );
};

const LoggedInAndViewingAs: VFC = () => {
  const isViewingAsHelpDialogOpen = useBoolean(false);

  return (
    <>
      <ViewingAsDialog open={isViewingAsHelpDialogOpen.value} onClose={isViewingAsHelpDialogOpen.setFalse} />

      <div className="flex items-center space-x-3">
        <div className="flex flex-col text-x-small items-end">
          <LoggedInAs />
          <ViewingAs onClick={isViewingAsHelpDialogOpen.setTrue} />
        </div>

        <LoggedIn as={["uut"]}>
          <LoggedIn>
            <button
              onClick={isViewingAsHelpDialogOpen.setTrue}
              className="opacity-70 hover:opacity-100  cursor-pointer"
            >
              <Help style={{ fontSize: "1.75rem" }} />
            </button>
          </LoggedIn>
        </LoggedIn>
      </div>
    </>
  );
};

const AccountSignupAndClaimActions: VFC = () => {
  const { t } = useTranslation(["broker"]);

  return (
    <>
      <LoggedIn as={["broker", "unclaimed"]}>
        <AccountActionLink to={{ pathname: "/broker/signup" }} showAlert>
          {t("broker:claimAccount")}
        </AccountActionLink>
      </LoggedIn>

      <LoggedOut withUut={false}>
        <AccountActionLink to={{ pathname: "/broker/signup" }} showAlert>
          {t("broker:signUp")}
        </AccountActionLink>
      </LoggedOut>

      <LoggedOut withUut={true}>
        <AccountActionLink to={{ pathname: "/broker/signup" }} showAlert>
          {t("broker:claimAccount")}
        </AccountActionLink>
      </LoggedOut>
    </>
  );
};

const BrokerLayout: FC = ({ children }) => {
  const { t } = useTranslation(["broker"]);
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
  const location = useLocation();
  const enableBrokerQuotesPage = useLDFlag("enableBrokerQuotesPage");

  const navigationItems: {
    pathnames: string[];
    label: string;
    hide?: boolean;
    Badge?: FC;
  }[] = [
    {
      pathnames: ["/broker/quotes", "/broker/requests", "/broker/quote", "/broker/dispatches"],
      label: t("broker:layout_navigationItem_quotes"),
      hide: !enableBrokerQuotesPage,
      Badge: (props) => (
        <NotificationBadge
          {...props}
          position={{ top: "20%", right: "0%" }}
          size="small"
          BadgeProps={{ style: { width: "unset" } }}
          identifiers={[`quoteRequest`]}
        />
      ),
    },
    {
      pathnames: ["/broker/bids"],
      label: t("broker:layout_navigationItem_bids"),
    },
    {
      pathnames: ["/broker/shipments", "/broker/loads"],
      label: t("broker:layout_navigationItem_shipments"),
      Badge: (props) => (
        <NotificationBadge
          {...props}
          position={{ top: "20%", right: "0%" }}
          size="small"
          BadgeProps={{ style: { width: "unset" } }}
          identifiers={[`shipment`, `loadStatusInvalidation`]}
        />
      ),
    },
    {
      pathnames: ["/broker/shippers"],
      label: t("broker:layout_navigationItem_shippers"),
    },
    {
      pathnames: ["/broker/insights"],
      label: t("broker:layout_navigationItem_insights"),
    },
  ];

  const isActiveItem = (pathnames: string[]): boolean => {
    return pathnames.some((pathname) => location.pathname.includes(pathname));
  };

  if (!enableBrokerAppBar) {
    return <>{children}</>;
  }

  return (
    <>
      <ImpersonationBanner fullWidth />
      <VersionBanner fullWidth />
      <AppBar className="min-h-[50px] shadow z-50">
        <div className="py-1 pr-2 pl-3 w-full h-full bg-grey-900 text-white flex items-center">
          <div className="flex items-center space-x-6">
            <PortexLogoView color="white" />

            <div className="flex items-center space-x-4">
              {navigationItems.map((navigationItem) => {
                if (navigationItem.hide) {
                  return null;
                }

                const Badge: FC = navigationItem.Badge ?? (({ children }) => <>{children}</>);

                return (
                  <Badge>
                    <Link
                      key={first(navigationItem.pathnames)}
                      to={{ pathname: first(navigationItem.pathnames) }}
                      className={classNames(
                        "rounded px-4 py-1.5 text-white font-bold text-medium hover:bg-white/20 cursor-pointer",
                        isActiveItem(navigationItem.pathnames) && "bg-white/10"
                      )}
                    >
                      {navigationItem.label}
                    </Link>
                  </Badge>
                );
              })}
            </div>
          </div>

          <div className="flex flex-1 justify-end font-bold items-center space-x-3">
            <LoggedInAndViewingAs />

            <AccountSignupAndClaimActions />

            <BrokerUserAvatar />
          </div>
        </div>
      </AppBar>

      {children}
    </>
  );
};

export default BrokerLayout;
