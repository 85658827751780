import { useMemo, VFC } from "react";

import { Box, Button } from "@portex-pro/ui-components";
import ButtonSelectView from "components/ButtonSelectView";
import Text from "components/Text";
import without from "lodash/without";
import { useTranslation } from "react-i18next";
import { ModeEnum, ModeRfp } from "types/Mode";
import OptionalMaybe from "types/OptionalMaybe";
import { getSizesForType, TrailerSize, TrailerSizeEnum } from "types/TrailerSize";
import { isTemperatureControlled, TrailerType, TrailerTypeEnum, truckingTrailerTypes } from "types/TrailerType";

import FormRadioSelectionView from "./FormRadioSelectionView";
import ReeferTempRangeView from "./ReeferTempRangeView";

const primaryTrailerTypes = [TrailerTypeEnum.DRY_VAN, TrailerTypeEnum.FLATBED, TrailerTypeEnum.REEFER];
const otherTrailerTypes = without(truckingTrailerTypes, ...primaryTrailerTypes);
const ltlPartialTrailerTypes = [TrailerTypeEnum.DRY_VAN, TrailerTypeEnum.REEFER];

type TrailerSelectionViewProps = {
  mode: ModeRfp;
  type: OptionalMaybe<TrailerType>;
  size: OptionalMaybe<TrailerSize>;
  minTemp: OptionalMaybe<number>;
  maxTemp: OptionalMaybe<number>;
  onChangeType: (val: TrailerType) => void;
  onChangeSize: (val: TrailerSize) => void;
  onChangeMinTemp: (val: number) => void;
  onChangeMaxTemp: (val: number) => void;
};

const TrailerSelectionView: VFC<TrailerSelectionViewProps> = (props) => {
  const { mode, type, size, minTemp, maxTemp, onChangeType, onChangeSize, onChangeMinTemp, onChangeMaxTemp } = props;
  const { t } = useTranslation(["shipper", "common"]);

  const hasSelectedType = !!type;

  const trailerTypes = useMemo(() => {
    if (mode === ModeEnum.LTL_PARTIAL) {
      return ltlPartialTrailerTypes;
    }

    return primaryTrailerTypes;
  }, [mode]);

  return (
    <Box p={2} display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridRowGap={20}
        maxWidth="md"
      >
        <Text size="x-large" weight="bold">
          {t("shipper:bids.bidRequestPage.pageTrailerSelection.trailerType_label")}
        </Text>

        <Box py={2} display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" gridGap={20} flexWrap="wrap" justifyContent="center" maxWidth={800}>
            {trailerTypes.map((trailerType) => (
              <Button
                size="large"
                color="primary"
                variant={trailerType === type ? "contained" : "outlined"}
                style={{ minWidth: 150, whiteSpace: "nowrap" }}
                key={trailerType}
                onClick={() => onChangeType(trailerType)}
              >
                {t(`common:trailerTypes.${trailerType}`)}
              </Button>
            ))}
            {mode !== ModeEnum.LTL_PARTIAL && (
              <ButtonSelectView
                items={otherTrailerTypes.map((type) => ({
                  value: type,
                  label: t(`common:trailerTypes.${type}`),
                }))}
                value={type ?? undefined}
                placeholder={t("shipper:bids.bidRequestPage.pageTrailerSelection.trailerType_others_placeholder")}
                onChange={onChangeType}
                buttonProps={{ style: { minWidth: 150, whiteSpace: "nowrap" } }}
              />
            )}
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" width={670}>
          {hasSelectedType && (mode === ModeEnum.FTL || mode === ModeEnum.INTERMODAL) && (
            <FormRadioSelectionView
              formControlProps={{ fullWidth: true }}
              value={size ?? undefined}
              items={getSizesForType(type)}
              onChange={onChangeSize}
              getItemCopy={(item) => {
                if (item === TrailerSizeEnum.NA) {
                  return t("common:trailerSizes.NA");
                }

                return `${t(`common:trailerSizes.${item}`)} ${t(`common:trailerTypes.${type}`)}`;
              }}
              label={t("shipper:bids.bidRequestPage.pageTrailerSelection.trailerSize_label")}
            />
          )}

          {isTemperatureControlled(type) && (
            <ReeferTempRangeView
              min={minTemp ?? undefined}
              max={maxTemp ?? undefined}
              onChangeMin={onChangeMinTemp}
              onChangeMax={onChangeMaxTemp}
              boxProps={{ width: "100%" }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default TrailerSelectionView;
