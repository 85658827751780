import { createEntityAdapter } from "@reduxjs/toolkit";
import { baseRestApi } from "api/rest/baseRestApi";
import { StateFromApi } from "types/Store";
import enhanceWithPagination from "utils/enhanceWithPagination";
import paramsToTuples from "utils/paramsToTuples";

import { Bid } from "../../../../app/pages/bid-award/types";
import {
  ListShipperContractsApi,
  GetShipperContractApi,
  ListShipperContractLanesApi,
  GetShipperContractLaneApi,
  ListShipperContractLaneBidsApi,
  GetShipperContractLaneBidApi,
  AwardBidApi,
  FinalizeAwardsApi,
  ListShipperContractAwardsApi,
  ListShipperContractLaneBidsPendingApi,
  ShipperContractCsvExport,
  ShipperContractCsvExportStatus,
  UpdateAwardApi,
  GetAwardApi,
  UpdateShipperContractApi,
  UpdateShipperContractLaneApi,
  DeleteShipperContractLaneApi,
} from "./types";

module.hot?.accept();

export const bidsAdapter = createEntityAdapter<Bid>({
  selectId: (bid) => bid.id,
});

export const bidsAdapterSelectors = bidsAdapter.getSelectors();

const bidAwardApi = baseRestApi
  .enhanceEndpoints({
    addTagTypes: ["awards", "award", "bid", "bids", "bids-pending", "contract", "contracts", "lane", "lanes"],
  })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      /** GET /shipper/contracts */
      listShipperContracts: builder.query<
        ListShipperContractsApi["hookReturnValue"],
        ListShipperContractsApi["queryArgs"]
      >({
        query: (args) => {
          const params = [];

          if (!!args.queryParams?.cursor) {
            params.push(["cursor", args.queryParams.cursor]);
          }

          if (!!args.queryParams?.take) {
            params.push(["take", args.queryParams.take]);
          }

          if (!!args.queryParams?.search) {
            params.push(["search", args.queryParams.search]);
          }

          (args.queryParams?.pricingFrequency || []).forEach((value) => params.push(["pricingFrequency", value]));
          // API expects multiple 'state' query params, and each value is passed here as it's own tuple ["state", value]
          (args.queryParams?.state || []).forEach((value) => params.push(["state", value]));
          // API expects multiple 'mode' query params, and each value is passed here as it's own tuple ["mode", value]
          (args.queryParams?.mode || []).forEach((value) => params.push(["mode", value]));

          return {
            method: "GET",
            url: "shipper/contracts",
            params,
          };
        },
        providesTags: ["contracts"],
      }),

      /** GET /shipper/contracts/:contractId */
      getShipperContract: builder.query<GetShipperContractApi["hookReturnValue"], GetShipperContractApi["queryArgs"]>({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}`,
        }),
        transformResponse: (response: GetShipperContractApi["response"]) => response.data.contractRequest,
        providesTags: ["contract"],
      }),

      /** PUT /shipper/contracts/:contractId */
      updateShipperContract: builder.mutation<
        UpdateShipperContractApi["hookReturnValue"],
        UpdateShipperContractApi["queryArgs"]
      >({
        query: (args) => ({
          method: "PUT",
          url: `shipper/contracts/${args.contractRequest.id}`,
          params: {
            sendCloseContractEmail: args.sendCloseContractEmail,
          },
          body: {
            contractRequest: args.contractRequest,
            fileIds: args.fileIds,
            contactIds: args.contactIds,
          },
        }),
        invalidatesTags: ["contract"],
      }),

      /** GET /shipper/contracts/:contractId/awards */
      listShipperContractAwards: builder.query<
        ListShipperContractAwardsApi["hookReturnValue"],
        ListShipperContractAwardsApi["queryArgs"]
      >({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}/awards`,
          params: args.queryParams,
        }),
        providesTags: (_result, _error, args) => ["awards", { type: "awards", id: args.queryParams?.state }],
      }),

      /** GET /shipper/contracts/:contractId/lanes */
      listShipperContractLanes: builder.query<
        ListShipperContractLanesApi["hookReturnValue"],
        ListShipperContractLanesApi["queryArgs"]
      >({
        query: (args) => {
          return {
            method: "GET",
            url: `shipper/contracts/${args.urlParams.contractId}/lanes`,
            params: args.queryParams ? paramsToTuples(args.queryParams) : undefined,
          };
        },
        providesTags: (_result, _error, args) =>
          args.queryParams?.state
            ? args.queryParams.state.map((state) => ({ type: "lanes", id: state }))
            : [{ type: "lanes", id: "ALL_LANES" }],
      }),

      /** GET /shipper/contracts/:contractId/lanes/:laneId */
      getShipperContractLane: builder.query<
        GetShipperContractLaneApi["hookReturnValue"],
        GetShipperContractLaneApi["queryArgs"]
      >({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}/lanes/${args.urlParams.laneId}/`,
        }),
        transformResponse: (response: GetShipperContractLaneApi["response"]) => response.data.lane,
        providesTags: ["lane"],
      }),

      /** PUT /shipper/contracts/:contractId/lanes/:laneId */
      updateShipperContractLane: builder.mutation<
        UpdateShipperContractLaneApi["hookReturnValue"],
        UpdateShipperContractLaneApi["queryArgs"]
      >({
        query: (args) => ({
          method: "PUT",
          url: `shipper/contracts/${args.urlParams.contractId}/lanes/${args.urlParams.laneId}/`,
          body: args.body,
        }),
        invalidatesTags: ["contracts", "contract", "lane", "lanes"],
      }),

      /** DELETE /shipper/contracts/:contractId/lanes/:laneId */
      deleteShipperContractLane: builder.mutation<
        DeleteShipperContractLaneApi["hookReturnValue"],
        DeleteShipperContractLaneApi["queryArgs"]
      >({
        query: (args) => ({
          method: "DELETE",
          url: `shipper/contracts/${args.urlParams.contractId}/lanes/${args.urlParams.laneId}/`,
        }),
        invalidatesTags: ["contracts", "contract", "lane", "lanes"],
      }),

      /** GET /shipper/contracts/:contractId/lane/:laneId/bids */
      listShipperContractLaneBids: builder.query<
        ListShipperContractLaneBidsApi["hookReturnValue"],
        ListShipperContractLaneBidsApi["queryArgs"]
      >({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}/lanes/${args.urlParams.laneId}/bids`,
          params: args.queryParams,
        }),
        transformResponse: (response: ListShipperContractLaneBidsApi["response"]) =>
          bidsAdapter.addMany(
            bidsAdapter.getInitialState({ total: response.total, cursor: response.cursor, data: response.data.bids }),
            response.data.bids
          ),
        providesTags: ["bids"],
      }),

      /** GET /shipper/contracts/:contractId/lane/:laneId/bids-pending */
      listShipperContractLaneBidsPending: builder.query<
        ListShipperContractLaneBidsPendingApi["hookReturnValue"],
        ListShipperContractLaneBidsPendingApi["queryArgs"]
      >({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}/lanes/${args.urlParams.laneId}/bids-pending`,
          params: args.queryParams,
        }),
        providesTags: ["bids-pending"],
      }),

      /** GET /shipper/contracts/:contractId/lane/:laneId/bids/:bidId */
      getShipperContractLaneBid: builder.query<
        GetShipperContractLaneBidApi["hookReturnValue"],
        GetShipperContractLaneBidApi["queryArgs"]
      >({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}/lanes/${args.urlParams.laneId}/bids/${args.urlParams.bidId}`,
        }),
        transformResponse: (response: GetShipperContractLaneBidApi["response"]) => response.data.bid,
        providesTags: ["bid"],
      }),

      /** POST /shipper/contracts/:contractId/lane/:laneId/bids/:bidId/award */
      awardBid: builder.mutation<AwardBidApi["hookReturnValue"], AwardBidApi["queryArgs"]>({
        query: (args) => ({
          method: "POST",
          url: `shipper/contracts/${args.urlParams.contractId}/lanes/${args.urlParams.laneId}/bids/${args.urlParams.bidId}/award`,
          body: args.body,
        }),
        transformResponse: (response: AwardBidApi["response"]) => response.data.lane_award,
        invalidatesTags: ["awards", "lanes", "contract", "lane", "bid", "bids", "bids-pending"],
      }),

      /** POST /shipper/contracts/:contractId/finalize */
      finalizeAwards: builder.mutation<FinalizeAwardsApi["hookReturnValue"], FinalizeAwardsApi["queryArgs"]>({
        query: (args) => ({
          method: "POST",
          url: `shipper/contracts/${args.urlParams.contractId}/finalize`,
          body: args.body,
        }),
        transformResponse: (response: FinalizeAwardsApi["response"]) => response.data.awards,
        invalidatesTags: ["awards", "bid", "bids", "bids-pending", "contract", "contracts", "lane", "lanes"],
      }),

      /** POST /shipper/contracts/:contractId/export */
      shipperContractCsvExport: builder.mutation<
        ShipperContractCsvExport["hookReturnValue"],
        ShipperContractCsvExport["queryArgs"]
      >({
        query: (args) => ({
          method: "POST",
          url: `shipper/contracts/${args.urlParams.contractId}/export`,
        }),
        transformResponse: (response: ShipperContractCsvExport["response"]) => ({
          jobId: response.data.jobId,
        }),
      }),

      /** GET /shipper/contracts/:contractId/export/:jobId */
      shipperContractCsvForDownload: builder.query<
        ShipperContractCsvExportStatus["hookReturnValue"],
        ShipperContractCsvExportStatus["queryArgs"]
      >({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}/export/${args.urlParams.jobId}`,
        }),
        transformResponse: (response: ShipperContractCsvExportStatus["response"]) => ({
          csv: response.data.csv,
        }),
      }),

      /** PUT /shipper/contracts/:contractId/awards/:awardId */
      updateAward: builder.mutation<UpdateAwardApi["hookReturnValue"], UpdateAwardApi["queryArgs"]>({
        query: (args) => ({
          method: "PUT",
          url: `shipper/contracts/${args.urlParams.contractId}/awards/${args.urlParams.awardId}`,
          body: args.body,
        }),
        invalidatesTags: (_result, _error, args) => [{ type: "award", id: args.urlParams.awardId }],
      }),

      /** GET /shipper/contracts/:contractId/awards/:awardId */
      getAward: builder.query<GetAwardApi["hookReturnValue"], GetAwardApi["queryArgs"]>({
        query: (args) => ({
          method: "GET",
          url: `shipper/contracts/${args.urlParams.contractId}/awards/${args.urlParams.awardId}`,
        }),
        transformResponse: (response: GetAwardApi["response"]) => response.data.award,
        providesTags: (_result, _error, args) => [{ type: "award", id: args.urlParams.awardId }],
      }),
    }),
  });

export type BidAwardApiState = StateFromApi<typeof bidAwardApi>;
export default bidAwardApi;
export const {
  /** GET /shipper/contracts */
  useListShipperContractsQuery,
  useLazyListShipperContractsQuery,

  /** GET /shipper/contracts/:contractId */
  useGetShipperContractQuery,
  useLazyGetShipperContractQuery,

  /** PUT /shipper/contracts/:contractId */
  useUpdateShipperContractMutation,

  /** GET /shipper/contracts/:contractId/awards */
  useListShipperContractAwardsQuery,
  useLazyListShipperContractAwardsQuery,

  /** GET /shipper/contracts/:contractId/lanes */
  useListShipperContractLanesQuery,
  useLazyListShipperContractLanesQuery,

  /** GET /shipper/contracts/:contractId/lane/:laneId */
  useGetShipperContractLaneQuery,
  useLazyGetShipperContractLaneQuery,

  /** PUT /shipper/contracts/:contractId/lane/:laneId */
  useUpdateShipperContractLaneMutation,

  /** DELETE /shipper/contracts/:contractId/lane/:laneId */
  useDeleteShipperContractLaneMutation,

  /** GET /shipper/contracts/:contractId/lane/:laneId/bids */
  useListShipperContractLaneBidsQuery,
  useLazyListShipperContractLaneBidsQuery,

  /** GET /shipper/contracts/:contractId/lane/:laneId/bids-pending */
  useListShipperContractLaneBidsPendingQuery,
  useLazyListShipperContractLaneBidsPendingQuery,

  /** GET /shipper/contracts/:contractId/lane/:laneId/bids/:bidId */
  useGetShipperContractLaneBidQuery,
  useLazyGetShipperContractLaneBidQuery,

  /** POST /shipper/contracts/:contractId/lane/:laneId/bids/:bidId/award */
  useAwardBidMutation,

  /** POST /shipper/contracts/:contractId/finalize */
  useFinalizeAwardsMutation,

  /** POST /shipper/contracts/:contractId/export */
  useShipperContractCsvExportMutation,

  /** GET /shipper/contracts/:contractId/export/:jobId */
  useShipperContractCsvForDownloadQuery,

  /** PUT /shipper/contracts/:contractId/awards/:awardId */
  useUpdateAwardMutation,

  /** GET /shipper/contracts/:contractId/awards/:awardId */
  useGetAwardQuery,
  useLazyGetAwardQuery,
} = enhanceWithPagination(
  enhanceWithPagination(
    enhanceWithPagination(
      enhanceWithPagination(enhanceWithPagination(bidAwardApi, "listShipperContracts"), "listShipperContractLanes"),
      "listShipperContractAwards"
    ),
    "listShipperContractLaneBids"
  ),
  "listShipperContractLaneBidsPending"
);
