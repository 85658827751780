import React, { ReactElement, useEffect } from "react";

import { OutlinedInput, OutlinedInputProps, InputAdornment } from "@portex-pro/ui-components";

import { parseTempInput } from "../utils/parseTempInput";

interface TemperatureInputProps extends Omit<OutlinedInputProps, "value" | "onChange"> {
  value?: string | number | null;
  onChange: (value: number | null) => void;
}

const parseTempValue = (value: string | number | null | undefined) => (value || value === 0 ? String(value) : "");

const TemperatureInput = (props: TemperatureInputProps): ReactElement => {
  const [temperature, setTemperature] = React.useState(parseTempValue(props.value));

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempString = parseTempInput(event.target.value);
    setTemperature(tempString);

    const parsed = parseInt(tempString, 10);

    if (!isNaN(parsed)) {
      props.onChange(parsed);
    } else {
      props.onChange(null);
    }
  };

  useEffect(() => {
    if (props.value === temperature) {
      return;
    }
    setTemperature(parseTempValue(props.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  return (
    <OutlinedInput
      {...props}
      value={temperature}
      onChange={handleOnChange}
      endAdornment={<InputAdornment position="end">˚F</InputAdornment>}
    />
  );
};

export default TemperatureInput;
