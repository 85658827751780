import { CSSProperties, FC, ReactNode } from "react";

import classNames from "classnames";

interface FrameProps {
  title?: ReactNode;
  actions?: ReactNode;
  style?: CSSProperties;
  className?: string;
}

const Frame: FC<FrameProps> = ({ title, actions, style, className, children }) => (
  <div
    className={classNames("flex flex-col overflow-hidden rounded-md border border-border bg-white", className)}
    style={{ ...style, flexShrink: 0 }}
  >
    {(!!title || !!actions) && (
      <div
        className={classNames(
          children ? "border-b" : "",
          "border-border flex content-between justify-between items-center h-[53px] px-[16px] py-[12px]"
        )}
      >
        {typeof title === "string" ? (
          <span className="font-bold text-[16px]">
            <strong>{title}</strong>
          </span>
        ) : (
          title
        )}
        {actions}
      </div>
    )}
    {children && <div className="overflow-auto h-full relative">{children}</div>}
  </div>
);

export default Frame;
