import { ReactElement } from "react";

import {
  Button,
  ButtonGroup,
  makeStyles,
  portexColor,
  SvgPlaneCircleFilled,
  SvgShipCircleFilled,
  SvgTruckCircleFilled,
  SvgTruckLTLBlue,
  Tooltip,
} from "@portex-pro/ui-components";
import { ReactComponent as DrayageContainerIcon } from "assets/drayage/drayage-container-filled.svg";
import { ReactComponent as IntermodalArrowsIcon } from "assets/intermodal/intermodal-arrows-filled.svg";
import LtlPartialTruckSvg from "assets/ltl-partial/LtlPartialTruckSvg";
import cx, { ClassDictionary } from "clsx";
import { ModeFilter } from "pages/shipper/pages/quotes/types/ModeFilter";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { getModeFilter } from "pages/shipper/pages/quotes/utils/getModeFilter";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: 0,
      borderColor: portexColor.blue500,
      color: portexColor.blue500,
      "&:hover": {
        color: portexColor.blue500,
        backgroundColor: portexColor.blue100,
      },
      "& svg": { color: "inherit" },
      "& circle": { fill: "none" },
      "& path:not([fill])": { fill: "currentColor" },
      "& path[fill*='#fff']": { fill: "currentColor" },
    },
    all: {
      fontWeight: "bold",
    },
    disabled: {
      cursor: "default",
      backgroundColor: theme.palette.grey[100],
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
      "& svg": { color: theme.palette.grey[500] },
    },
    icon: {
      width: 42,
      height: 42,
    },
    iconText: { marginRight: 5 },
  }),
  { name: "ModeButtonGroup" }
);

export interface ModeButtonGroupProps {
  value: TransportationMode;
  onChange?: (value: TransportationMode, modeFilter: ModeFilter) => void;
  includeModes?: TransportationMode[];
  disabledModes?: TransportationMode[];
  excludeModes?: TransportationMode[];
  classes?: ClassDictionary;
}

const ModeButtonGroup = ({
  value,
  onChange,
  includeModes = [],
  disabledModes = [],
  excludeModes = [],
  ...rest
}: ModeButtonGroupProps): ReactElement => {
  const { t } = useTranslation(["shipper", "common"]);
  const classes = { ...useStyles(), ...rest.classes };
  const { All, Trucking, FTL, LTL, LTL_PARTIAL, DRAYAGE, INTERMODAL, Ocean, Air } = TransportationMode;

  const orderedOptions = [All, FTL, LTL, LTL_PARTIAL, DRAYAGE, Ocean, Air]
    .concat(includeModes)
    .filter((mode) => !excludeModes.includes(mode));

  return (
    <ButtonGroup color="primary">
      {orderedOptions.map((mode, idx) => {
        const buttonContent = (() => {
          switch (mode) {
            case All: {
              return <span className={classes.all}>{t("common:all")}</span>;
            }
            case Trucking:
            case FTL: {
              return <SvgTruckCircleFilled className={classes.icon} />;
            }
            case LTL: {
              return <SvgTruckLTLBlue style={{ width: 42, height: 42, padding: "0px 4px" }} />;
            }
            case LTL_PARTIAL: {
              return <LtlPartialTruckSvg style={{ width: 42, height: 42, padding: "0px 4px" }} />;
            }
            case DRAYAGE: {
              return <DrayageContainerIcon style={{ height: 20, width: 20, marginTop: -5 }} />;
            }
            case INTERMODAL: {
              return <IntermodalArrowsIcon />;
            }
            case Ocean: {
              return <SvgShipCircleFilled className={classes.icon} />;
            }
            case Air: {
              return <SvgPlaneCircleFilled className={classes.icon} />;
            }
          }
        })();

        const isDisabled = disabledModes.includes(mode);

        return (
          <Tooltip
            key={idx}
            title={
              isDisabled
                ? t("shipper:modeComingSoon", { mode: t(`common:transportationMode.${mode}`) })
                : t(`common:transportationMode.${mode}`)
            }
            arrow
            placement="top"
          >
            <Button
              className={cx(
                classes.root,
                {
                  ["Por-selected"]: value === mode,
                },
                isDisabled ? classes.disabled : {}
              )}
              onClick={(event) => {
                if (isDisabled) {
                  event.preventDefault();
                  return;
                }
                onChange?.(mode, getModeFilter(mode));
              }}
              disableRipple={isDisabled}
            >
              {buttonContent}
            </Button>
          </Tooltip>
        );
      })}
    </ButtonGroup>
  );
};

export default ModeButtonGroup;
