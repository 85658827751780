import { ReactElement } from "react";

import { ApolloError } from "@apollo/client";
import { ObservableQuery } from "@apollo/client";
import { Box, Paper } from "@portex-pro/ui-components";
import QuoteRequestDetailsLTLView from "components/ltl/QuoteRequestDetailsLTLView";
import { isQuoteRequestStateTerminal } from "utils/isQuoteRequestStateTerminal";

import { PublicQuoteRequest, Query, QuoteRequestState } from "../../../../../api/types/generated-types";
import { useOnApolloError } from "../../../../../hooks/useOnApolloError";
import QuoteSubmissionClosed from "../QuoteSubmissionClosed";
import SubmitQuoteFormLTL from "./SubmitQuoteFormLTL";
import UrgentQuoteRequestAlert from "./UrgentQuoteRequestAlert";

const TOP_OF_PAGE_SELECTOR = "quote-submission-top-of-page";

const QuoteSubmissionLTL = ({
  publicQuoteRequest,
  refetchPublicQuoteRequest,
  attachmentsList,
}: {
  publicQuoteRequest: PublicQuoteRequest;
  refetchPublicQuoteRequest: ObservableQuery<Pick<Query, "getPublicQuoteRequest">>["refetch"];
  attachmentsList: React.ReactElement | false | null;
}): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "QuoteSubmissionLTL" });

  const { guid, deadline_respond_at, state } = publicQuoteRequest;
  const isTerminalState = isQuoteRequestStateTerminal(state);
  const quoteHasExpiration = !!deadline_respond_at;
  return (
    <>
      <Box id={TOP_OF_PAGE_SELECTOR} />
      {isTerminalState ? <QuoteSubmissionClosed publicQuoteRequest={publicQuoteRequest} /> : null}
      {quoteHasExpiration && (
        <UrgentQuoteRequestAlert deadlineRespondAt={deadline_respond_at} booked={isTerminalState} />
      )}
      <Paper className={"Por-outlined-light"} elevation={8}>
        <QuoteRequestDetailsLTLView publicQuoteRequest={publicQuoteRequest} attachmentsList={attachmentsList} />
        <SubmitQuoteFormLTL
          quoteRequestGuid={guid}
          isTerminalState={isTerminalState}
          publicQuoteRequest={publicQuoteRequest}
          deadlineRespondAt={deadline_respond_at}
          onError={async (error: ApolloError) => {
            onApolloError("submitQuote")(error);
            const { data } = await refetchPublicQuoteRequest();
            if (
              data.getPublicQuoteRequest?.state === QuoteRequestState.Booked ||
              data.getPublicQuoteRequest?.state === QuoteRequestState.Closed ||
              data.getPublicQuoteRequest?.state === QuoteRequestState.Canceled
            ) {
              document.getElementById(TOP_OF_PAGE_SELECTOR)?.scrollIntoView({ behavior: "smooth" });
            }
          }}
        />
      </Paper>
    </>
  );
};

export default QuoteSubmissionLTL;
