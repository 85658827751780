import { VFC } from "react";

import { Link, OpenInNew } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import { Load, LoadStatusUpdate } from "api/rest/load-status-updates/types";
import { PublicShipment, Shipment } from "app/pages/shipments/types/domain";
import ActivityView from "components/ActivityView";
import Frame from "components/Frame";
import LoadSelectView from "components/loads/LoadSelectView";
import compact from "lodash/compact";
import { useTranslation } from "react-i18next";

import ActivityEvents from "./ActivityEvents";

type LoadActivityViewProps = {
  shipment: Shipment | PublicShipment;
  dispatchRequest?: ShipperDispatchRequest;
} & {
  selectedLoadId: Load["id"] | undefined;
  onChangeLoad: (id: Load["id"]) => void;
  useGetLoadStatusUpdate: (
    stopId: number,
    loadId: number | undefined,
    isLoadingShipment: boolean
  ) => LoadStatusUpdate | null;
} & {
  trackShipmentDisabled?: boolean;
  requestUpdateDisabled?: boolean;
  requestUpdateLoading?: boolean;
  trackingLink?: string;
  onClickShareStatus?: () => void;
  onClickTrackingLink?: () => void;
  onClickRequestUpdate?: () => void;
  showOnClickRequestUpdate?: boolean;
  showOnClickTrackingLink?: boolean;
  showOnClickShareStatus?: boolean;
  hideTrackingLink?: boolean;
  showInternalShipperNotes?: boolean;
  onClickEditStatus?: (stopId: number) => void;
  isLoadingShipment: boolean;
};

const LoadActivityView: VFC<LoadActivityViewProps> = ({
  shipment,
  dispatchRequest,

  selectedLoadId,
  onChangeLoad,
  useGetLoadStatusUpdate,

  trackShipmentDisabled = false,
  requestUpdateDisabled = false,
  requestUpdateLoading = false,
  trackingLink,
  onClickTrackingLink,
  onClickShareStatus,
  onClickRequestUpdate,
  showOnClickRequestUpdate = false,
  showOnClickTrackingLink = false,
  showOnClickShareStatus = false,
  hideTrackingLink = false,
  showInternalShipperNotes = false,
  onClickEditStatus,
  isLoadingShipment,
}) => {
  const { t } = useTranslation("loads");
  const loads = compact(shipment.trucks)
    .map((truck) => ({ id: truck.id ?? 0, referenceNumber: truck.referenceNumber, trackingLink: truck.trackingLink }))
    .filter(({ id }) => !!id);

  const shouldDisplayMap = {
    shareStatus: showOnClickShareStatus,
    trackingLink: showOnClickTrackingLink && !hideTrackingLink,
    requestUpdate: showOnClickRequestUpdate,
  };
  const shouldDisplayButtons = Object.values(shouldDisplayMap).some((bool) => bool === true);

  return (
    <Frame
      title={t("loadActivity_header")}
      actions={<LoadSelectView value={selectedLoadId} loads={loads} onChange={onChangeLoad} />}
      style={{ height: "100%" }}
    >
      {shouldDisplayButtons && (
        <div className="px-4 py-2 flex justify-between gap-4 border-b border-border sticky top-0 bg-white z-[1]">
          {shouldDisplayMap.shareStatus && (
            <Button
              style={{ minWidth: "max-content", flex: 1 }}
              size="small"
              color="primary"
              variant="outlined"
              startIcon={<Link />}
              onClick={onClickShareStatus}
            >
              {t("loadActivity_shareStatusButton")}
            </Button>
          )}
          {shouldDisplayMap.trackingLink && (
            <Button
              style={{ minWidth: "max-content", flex: 1 }}
              size="small"
              color="primary"
              variant="outlined"
              endIcon={<OpenInNew />}
              onClick={onClickTrackingLink}
              disabled={!trackingLink || trackShipmentDisabled}
            >
              {t("loadActivity_trackShipmentButton")}
            </Button>
          )}
          {shouldDisplayMap.requestUpdate && (
            <Button
              style={{ minWidth: "max-content", flex: 1 }}
              size="small"
              color="primary"
              variant="contained"
              onClick={onClickRequestUpdate}
              disabled={!onClickRequestUpdate || requestUpdateDisabled}
              loading={requestUpdateLoading}
            >
              {t("loadActivity_requestUpdateButton")}
            </Button>
          )}
        </div>
      )}
      <ActivityView className="pl-1 pr-4 py-4">
        {"_type" in shipment ? (
          <>{shipment._type === "PublicShipment" && <ActivityEvents.DateBookedOrConfirmed shipment={shipment} />}</>
        ) : (
          <>
            {shipment.sourceType === "quote" && <ActivityEvents.QuoteBooked shipment={shipment} />}
            {shipment.sourceType === "dispatch" && dispatchRequest && (
              <ActivityEvents.DispatchConfirmed shipment={shipment} dispatchRequest={dispatchRequest} />
            )}
          </>
        )}
        {shipment.stops.map((stop, index) => (
          <ActivityEvents.LoadStop
            key={stop.id}
            stopId={stop.id}
            index={index}
            shipment={shipment}
            loadId={selectedLoadId}
            isLoadingShipment={isLoadingShipment || !shipment.trucks?.find((truck) => truck.id === selectedLoadId)}
            useGetLoadStatusUpdate={useGetLoadStatusUpdate}
            showInternalShipperNotes={showInternalShipperNotes}
            onClickEditStatus={onClickEditStatus && (() => onClickEditStatus(stop.id))}
          />
        ))}
      </ActivityView>
    </Frame>
  );
};

export default LoadActivityView;
