import compact from "lodash/compact";
import uniq from "lodash/uniq";
import { TFunction } from "react-i18next";
import { getUserFullName, getUserInitials } from "utils/user";

import { Conversation } from "../api/findManyConversations";
import { DisplayConversationType } from "../ConversationsView";

export const mapConversationsToDisplayConversations = (
  conversations: Conversation[],
  _t: TFunction<["chat"]>
): DisplayConversationType[] => {
  return conversations.map((conversation): DisplayConversationType => {
    return {
      conversationId: conversation.id,
      lastMessageAt: conversation.lastMessageAt,
      initials: getUserInitials({ user: conversation.conversation_primary_contact }),
      renderableContent: uniq(
        compact([
          conversation.conversation_primary_contact?.company_name,
          getUserFullName({ user: conversation.conversation_primary_contact }),
          conversation.conversation_primary_contact?.email,
        ])
      ),
    };
  });
};
