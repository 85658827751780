import { BidRequestFieldKeysType, BidRequestFieldType, StaticFieldKeys } from "./types";
import createAwardedPartnerFields from "./utils/createAwardedPartnerFields";
import createLocationFields from "./utils/createLocationFields";
import { duplicatedRowValidator } from "./utils/validators";

export const deliveryWeekField: BidRequestFieldType = {
  key: StaticFieldKeys.DELIVERY_WEEK,
  label: "deliveryWeek",
  required: false,
  selected: false,
  /** @todo It's still unclear what this field is supposed to represent and there are a lot of things to work out around
   * how this date format is selected so this is potentially temporary.
   */
  type: "date",
};

export const awardedPartnerFields = createAwardedPartnerFields();

export const additionalFields: BidRequestFieldType[] = [
  deliveryWeekField,
  {
    key: StaticFieldKeys.LOADS_PER_WEEK,
    label: "loadsPerWeek",
    required: false,
    selected: false,
    type: ["number", "integer"],
    validators: [{ validate: "required" }],
  },
  {
    key: StaticFieldKeys.LOADS_PER_MONTH,
    label: "loadsPerMonth",
    required: false,
    selected: false,
    type: ["number", "integer"],
    validators: [{ validate: "required" }],
  },
  {
    key: StaticFieldKeys.LOADS_PER_YEAR,
    label: "loadsPerYear",
    required: false,
    selected: false,
    type: ["number", "integer"],
    validators: [{ validate: "required" }],
  },
  {
    key: StaticFieldKeys.WEIGHT,
    label: "weight",
    required: false,
    selected: false,
    type: "number",
  },
  {
    key: StaticFieldKeys.PALLETS,
    label: "pallets",
    required: false,
    selected: false,
    type: ["number", "integer"],
  },
  {
    key: StaticFieldKeys.TARGET_RATE,
    label: "targetRate",
    required: false,
    selected: false,
    type: ["number", "usd"],
  },
  {
    key: StaticFieldKeys.SHIPPER_LANE_ID,
    label: "laneId",
    required: false,
    selected: false,
    type: "string",
    validators: [duplicatedRowValidator],
  },
  {
    key: StaticFieldKeys.SHIPPER_PROVIDED_MILEAGE,
    label: "shipperProvidedMileage",
    required: false,
    selected: false,
    type: "number",
  },
  {
    key: StaticFieldKeys.LOAD_VALUE,
    label: "loadValue",
    required: false,
    selected: false,
    type: "number",
  },
  {
    key: StaticFieldKeys.SHIPPER_NOTE,
    label: "shipperNote",
    required: false,
    selected: true,
    type: "string",
  },
];

export const defaultFields: BidRequestFieldType[] = [
  {
    key: StaticFieldKeys.PO_REFERENCE_NUMBER,
    label: "po",
    required: false,
    selected: true,
    type: "string",
    validators: [duplicatedRowValidator],
  },
  {
    key: StaticFieldKeys.QUOTE_NUMBER,
    label: "quoteNumber",
    required: false,
    selected: false,
    type: "string",
    validators: [duplicatedRowValidator],
  },
  ...createLocationFields("pickup"),
  ...createLocationFields("delivery"),
  ...awardedPartnerFields,
];

export const additionalFieldsSection1: BidRequestFieldKeysType[] = [
  StaticFieldKeys.DELIVERY_WEEK,
  StaticFieldKeys.SHIPPER_NOTE,
];
export const additionalFieldsSection2: BidRequestFieldKeysType[] = [
  StaticFieldKeys.LOADS_PER_WEEK,
  StaticFieldKeys.LOADS_PER_MONTH,
  StaticFieldKeys.LOADS_PER_YEAR,
];
export const additionalFieldsSection3: BidRequestFieldKeysType[] = [
  StaticFieldKeys.WEIGHT,
  StaticFieldKeys.PALLETS,
  StaticFieldKeys.TARGET_RATE,
  StaticFieldKeys.SHIPPER_LANE_ID,
  StaticFieldKeys.SHIPPER_PROVIDED_MILEAGE,
  StaticFieldKeys.LOAD_VALUE,
  StaticFieldKeys.AWARDED_PARTNER,
];

export const updateMutationCacheKey = "updateMutationCacheKey";
