import { useCallback, useMemo } from "react";

import { Lane } from "app/pages/bid-award/types";
import { IDeveloperSettings } from "dromo-uploader-js";
import {
  DromoBeforeFinishCallback,
  DromoField,
  DromoOnResultsCallback,
  useDromoUploader,
} from "features/dromo/useDromoUploader";
import { useTranslation } from "react-i18next";

import { useImportContractRequestLanesMutation } from "../api/bidRequestApi";
import { ImportLane } from "../api/types";
import { selectAllFields, useBidRequestSliceSelector } from "../store/bidRequestStore";

type UseDromoLanesImportHookArgs = {
  onSuccess?(lanes: Lane[]): void;
  onError?(error: unknown): string;
  onFinish?(): void;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDromoLanesImport = ({ onSuccess, onFinish, onError }: UseDromoLanesImportHookArgs = {}) => {
  const { t } = useTranslation(["shipper", "common"]);
  const { t: fieldNamesT } = useTranslation("shipper", { keyPrefix: "bids.fieldNames" });

  const contractId = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.id);
  const [importContractRequestLanes] = useImportContractRequestLanesMutation();
  const hidePoNumberTillDispatch = useBidRequestSliceSelector(
    (state) => state.bidRequestFieldsSlice.hidePoNumberTillDispatch
  );

  const handleImportDataBeforeFinish = useCallback<DromoBeforeFinishCallback>(
    async (data: ImportLane[], _metadata, _instance) => {
      try {
        if (!contractId) throw new Error("No contract request id provided");
        // change lane_id to shipper_lane_id
        data.forEach((lane) => {
          lane.shipper_lane_id = lane.lane_id;
          delete lane.lane_id;
          lane.hide_po_till_dispatch = !!hidePoNumberTillDispatch;
        });
        const lanes = await importContractRequestLanes({ contractRequestId: contractId, lanes: data }).unwrap();
        onSuccess?.(lanes);
        return;
      } catch (error) {
        let message = t("shipper:bids.dromoLanesImportError");

        if (onError) {
          message = onError?.(error);
        } else {
          if (error instanceof Object && "data" in error && error.data instanceof Object) {
            if ("error" in error.data && "message" in error.data) {
              message += ` [${error.data.error}: "${error.data.message}"]`;
            }
          }
        }

        return {
          cancel: true,
          message,
        };
      }
    },
    [contractId, importContractRequestLanes, onError, onSuccess, t, hidePoNumberTillDispatch]
  );

  const fields = useBidRequestSliceSelector(selectAllFields);
  const dromoFields: DromoField[] = useMemo(
    (): DromoField[] =>
      fields.map((field) => {
        const { required, selected, label, additionalI18NOptions, hideField, ...dromoFields } = field;

        const dromoField: DromoField = {
          ...dromoFields,
          label: fieldNamesT(label, additionalI18NOptions),
          hideField: hideField || (!required && !selected),
        };

        return dromoField;
      }),
    [fields, fieldNamesT]
  );

  const settings = useMemo<IDeveloperSettings>(() => {
    return {
      title: t("shipper:bids.dromoUploadTitle"),
      reviewStep: {
        helpText: t("shipper:bids.dromoUploadTitle"),
      },
      importIdentifier: "Bid Requests",
      templateDownloadFilename: "bid-request-template",
    };
  }, [t]);

  const handleFinish = useCallback<DromoOnResultsCallback>(
    (_data, _metadata) => {
      onFinish?.();
    },
    [onFinish]
  );

  return useDromoUploader({
    beforeFinish: handleImportDataBeforeFinish,
    onResults: handleFinish,
    settings: settings,
    fields: dromoFields,
  });
};
