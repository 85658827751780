import { AnyAction, ListenerEffectAPI, ThunkDispatch, isAnyOf } from "@reduxjs/toolkit";
import notificationSlice from "app/store/NotificationSlice";
import findManyConversationsApi from "components/chat/api/findManyConversations";
import getConversationMessagesApi from "components/chat/api/getConversationMessages";
import partition from "lodash/partition";
import reduce from "lodash/reduce";
import { enqueueSnackbar } from "notistack";
import { PortexSocket } from "sockets/PortexSocket";
import { NotificationData } from "types/Notifications";
import { RootState } from "types/Store";

import { NotifyOnSocketListenEventAction, NotifyOnSocketListenEventMap } from "../../../sockets/types";

const isNewNotification = (
  action: NotifyOnSocketListenEventAction
): action is NotifyOnSocketListenEventMap["new-notification"] => {
  return action.type === "socket/listenEvent/new-notification";
};

const isManyNewNotifications = (
  action: NotifyOnSocketListenEventAction
): action is NotifyOnSocketListenEventMap["new-notification-many"] => {
  return action.type === "socket/listenEvent/new-notification-many";
};

export const newNotificationMatcher = isAnyOf(isNewNotification, isManyNewNotifications);

const invalidateConversations = (
  unseenNotifications: NotificationData[],
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
) => {
  if (!unseenNotifications.length) {
    return;
  }

  const conversationIds = reduce(
    unseenNotifications,
    (conversationIdsSet, notification) => {
      const found = notification.identifiers.find((identifier) => identifier.includes("conversation:"));

      if (found) {
        conversationIdsSet.add(Number(found.split(":")[1]));
      }

      return conversationIdsSet;
    },
    new Set<number>()
  );

  if (!conversationIds.size) {
    return;
  }

  listenerApi.dispatch(findManyConversationsApi.util.invalidateTags(["CONVERSATIONS"]));
  listenerApi.dispatch(
    getConversationMessagesApi.util.invalidateTags(
      Array.from(conversationIds.values()).map((conversationId) => ({
        type: "ChatConversation",
        id: conversationId,
      }))
    )
  );
};

const processUnseenNotifications = (
  unseenNotifications: NotificationData[],
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
) => {
  if (!unseenNotifications.length) {
    return;
  }

  listenerApi.dispatch(notificationSlice.actions.addMany(unseenNotifications));

  unseenNotifications.forEach((notification) => {
    if (notification.showToast) {
      enqueueSnackbar("", { variant: "notification", notification });
    }
  });

  invalidateConversations(unseenNotifications, listenerApi);
};

const processSeenNotifications = (
  seenNotifications: NotificationData[],
  _listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
) => {
  if (!seenNotifications.length) {
    return;
  }

  seenNotifications.forEach((notification) =>
    PortexSocket.userSocket.emit("notification-seen", { id: notification.id })
  );
};

export const newNotificationListener = (
  action: AnyAction,
  listenerApi: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>
): void => {
  const state = listenerApi.getState() as RootState;

  let notifications: NotificationData[] = [];

  if (action.type === "socket/listenEvent/new-notification") {
    notifications = [action.payload];
  } else {
    notifications = action.payload;
  }

  const [seenNotifications, unseenNotifications] = partition(notifications, (notification) => {
    const notificationIsWatched = notification.identifiers.some((identifier) => {
      return (state.notificationSlice.watcherCountMap[identifier] ?? 0) > 0;
    });
    return notificationIsWatched && document.hasFocus();
  });

  processUnseenNotifications(unseenNotifications, listenerApi);

  processSeenNotifications(seenNotifications, listenerApi);
};
