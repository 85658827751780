import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { useGetShipmentDetailsQuery } from "api/rest/shipments/shipmentsApi";
import withAsync from "components/withAsync";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import { useParams } from "react-router-dom";
import openLinkInNewTab from "utils/openLinkInNewTab";

import { useShipmentsSliceSelector } from "../../store/shipmentsStore";
import SeeTrackingLinkView from "./views/SeeTrackingLinkView";

const SeeTrackingLinkContainer = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();
    const isDocumentVisible = useDocumentVisibility();
    return useGetShipmentDetailsQuery(
      { urlParams: { shipmentId } },
      { pollingInterval: isDocumentVisible ? oneMinuteInMilliseconds * 2 : undefined } // It's possible the tracking_link may be updated; we can poll here for it so the user doesn't need to refresh
    );
  },
  Component: ({ loadedData }) => {
    const shipment = loadedData.data;
    const selectedLoadId = useShipmentsSliceSelector((state) => state.shipmentManagementSlice.selectedLoadId);
    const selectedLoad = shipment.trucks?.find(({ id }) => id === selectedLoadId);

    if (!selectedLoad?.trackingLink) {
      return null;
    }

    return (
      <SeeTrackingLinkView
        onClickOpenInNew={() => openLinkInNewTab(selectedLoad?.trackingLink)}
        disabledOpenInNew={!selectedLoad?.trackingLink}
      />
    );
  },
});

export default SeeTrackingLinkContainer;
