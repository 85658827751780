import OptionalMaybe from "./OptionalMaybe";

export enum TrailerTypeEnum {
  DRY_VAN = "DRY_VAN",
  FLATBED = "FLATBED",
  REEFER = "REEFER",
  FOOD_GRADE_TANK = "FOOD_GRADE_TANK",
  BULK = "BULK",
  STEP_DECK = "STEP_DECK",
  BOX_TRUCK = "BOX_TRUCK",
  SPRINTER_VAN = "SPRINTER_VAN",
  CONESTOGA = "CONESTOGA",
  HOTSHOT = "HOTSHOT",
  RGN = "RGN",
  DRAYAGE = "DRAYAGE",
  DRAYAGE_REEFER = "DRAYAGE_REEFER",
  CARGO_VAN = "CARGO_VAN",
  DUMP = "DUMP",
  PARTIAL_LOAD = "PARTIAL_LOAD",
  NA_PARTNERS_CHOICE = "NA_PARTNERS_CHOICE",
  OTHER = "OTHER",
  OPEN_TOP = "OPEN_TOP",
  FLAT_RACK = "FLAT_RACK",
  PLATFORM = "PLATFORM",
  DRY = "DRY",
}

export type TrailerType = `${TrailerTypeEnum}`;

const TEMPERATURE_CONTROLLED_TRAILERS: TrailerType[] = [
  TrailerTypeEnum.FOOD_GRADE_TANK,
  TrailerTypeEnum.REEFER,
  TrailerTypeEnum.DRAYAGE_REEFER,
];

export const isTemperatureControlled = (trailerType: OptionalMaybe<TrailerType>): boolean =>
  !!trailerType && TEMPERATURE_CONTROLLED_TRAILERS.includes(trailerType);

export const truckingTrailerTypes = [
  TrailerTypeEnum.DRY_VAN,
  TrailerTypeEnum.FLATBED,
  TrailerTypeEnum.REEFER,
  TrailerTypeEnum.FOOD_GRADE_TANK,
  TrailerTypeEnum.BULK,
  TrailerTypeEnum.STEP_DECK,
  TrailerTypeEnum.BOX_TRUCK,
  TrailerTypeEnum.SPRINTER_VAN,
  TrailerTypeEnum.CONESTOGA,
  TrailerTypeEnum.HOTSHOT,
  TrailerTypeEnum.RGN,
  TrailerTypeEnum.DRAYAGE,
  TrailerTypeEnum.DRAYAGE_REEFER,
  TrailerTypeEnum.CARGO_VAN,
  TrailerTypeEnum.DUMP,
  TrailerTypeEnum.PARTIAL_LOAD,
  TrailerTypeEnum.NA_PARTNERS_CHOICE,
  TrailerTypeEnum.OTHER,
];

export const containerTrailerTypes = [
  TrailerTypeEnum.REEFER,
  TrailerTypeEnum.OPEN_TOP,
  TrailerTypeEnum.FLAT_RACK,
  TrailerTypeEnum.PLATFORM,
  TrailerTypeEnum.DRY,
];
