import { VFC } from "react";

import { CustomColor, Status } from "@portex-pro/ui-components";
import { TFuncKey, useTranslation } from "react-i18next";
interface DispatchStatusViewProps {
  status: string;
  big?: boolean;
  light?: boolean;
}

const mapStateToI18n: Record<string, TFuncKey<"dispatchRequest">> = {
  SENT: `status_SENT`,
  MODIFIED: `status_MODIFIED`,
  CONFIRMED: `status_CONFIRMED`,
  REJECTED: `status_REJECTED`,
  CANCELED: `status_CANCELED`,
  REQUESTED: `status_SENT`,
};
const mapStateToCustomColor: Record<string, CustomColor> = {
  SENT: "blue",
  REQUESTED: "blue",
  MODIFIED: "purple",
  CONFIRMED: "green",
  REJECTED: "red",
  CANCELED: "red",
};

const DispatchStatusView: VFC<DispatchStatusViewProps> = ({ status, big, light = false }) => {
  const { t } = useTranslation("dispatchRequest");

  const color = mapStateToCustomColor[status];
  const contractState = t(mapStateToI18n[status]);

  return (
    <Status light={light} rounded palette={color}>
      {big ? <big>{contractState}</big> : contractState}
    </Status>
  );
};

export default DispatchStatusView;
