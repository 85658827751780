import { DateTime } from "luxon";

export const getMessageDateTime = (datetime: string): { date: string; time: string } => {
  const dt = DateTime.fromISO(datetime);
  const now = DateTime.now();

  return {
    date: dt.toLocaleString({
      weekday: "long",
      month: "long",
      day: "numeric",
      year: dt.year !== now.year ? "numeric" : undefined,
    }),
    time: dt.toLocaleString(DateTime.TIME_SIMPLE),
  };
};
