import QuoteConversationsContainer from "components/chat/QuoteConversationsContainer";
import withAsync from "components/withAsync";

import useLoadQuoteRequest from "../../hooks/quotes/useLoadQuoteRequest";
import { useRequestManagementSlices } from "../../store/requestManagementStore";
import PageLayout from "../../views/PageLayout";
import QuoteManagementAppBar from "./QuoteManagementAppBar";
import QuoteRequestHeader from "./QuoteRequestHeader";

const QuoteConversationsPage = withAsync({
  useHook: () => {
    useRequestManagementSlices();
    return useLoadQuoteRequest();
  },
  Component: () => {
    return (
      <PageLayout
        header={[<QuoteManagementAppBar />, <QuoteRequestHeader />]}
        leftContent={[
          <div className="w-full h-full flex flex-col overflow-none">
            <QuoteConversationsContainer variant="requests" />
          </div>,
        ]}
      />
    );
  },
});

export default QuoteConversationsPage;
