import React from "react";

import isFunction from "lodash/isFunction";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import WithRequiredField from "types/WithRequiredField";

type FilterRtkqTypes<T> = T extends
  | { data: undefined; isError: true }
  | { data: undefined; isUninitialized: true }
  | { data: undefined; isLoading: true }
  ? never
  : T;

type FilterLoadingStates<T> = T extends { isLoading: true } ? never : T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RV<T> = T extends { data?: any }
  ? WithRequiredField<FilterRtkqTypes<T>, "data">
  : Required<FilterLoadingStates<T>>;

type WithAsyncProps<RemainingProps, HookReturnValue> =
  | {
      LoadingComponent?: React.FunctionComponent | React.VoidFunctionComponent | React.ReactElement | null;
      ErrorComponent?: React.FunctionComponent | React.VoidFunctionComponent | React.ReactElement | null;
      useHook: (props: RemainingProps) => HookReturnValue;
      isLoading: (data: HookReturnValue) => boolean;
      useHandleError?: undefined;
      Component: React.ComponentType<{ loadedData: RV<HookReturnValue> } & RemainingProps>;
    }
  | {
      LoadingComponent?: React.FunctionComponent | React.VoidFunctionComponent | React.ReactElement | null;
      ErrorComponent?: React.FunctionComponent | React.VoidFunctionComponent | React.ReactElement | null;
      isLoading?: undefined;
      useHook: (props: RemainingProps) => HookReturnValue & {
        isLoading: boolean;
        isUninitialized?: boolean;
        isError?: boolean;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error?: any;
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      useHandleError?: () => (error: any) => void;
      Component: React.ComponentType<{ loadedData: RV<HookReturnValue> } & RemainingProps>;
    };

const isLoadingOmitted = <HookReturnValue,>(
  isLoading: ((data: HookReturnValue) => boolean) | undefined,
  _hookResult: HookReturnValue
): _hookResult is HookReturnValue & {
  isLoading: boolean;
  isUninitialized?: boolean;
  isError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
} => {
  return isLoading === undefined;
};

const withAsync = <RemainingProps, HookReturnValue>({
  LoadingComponent = null,
  ErrorComponent = null,
  useHook,
  useHandleError,
  isLoading,
  Component,
}: WithAsyncProps<RemainingProps, HookReturnValue>): React.ComponentType<RemainingProps> => {
  return (props: RemainingProps): React.ReactElement | null => {
    const hookResult = useHook(props);
    const handleError = useHandleError?.();

    const { t } = useTranslation("common");
    const { enqueueSnackbar } = useSnackbar();

    if (
      (!!isLoading && isLoading(hookResult)) ||
      (isLoadingOmitted(isLoading, hookResult) && (hookResult?.isLoading || hookResult?.isUninitialized))
    ) {
      return isFunction(LoadingComponent) ? <LoadingComponent /> : LoadingComponent;
    }

    if (isLoadingOmitted(isLoading, hookResult) && hookResult.isError) {
      if (!!handleError) {
        handleError(hookResult.error);
      } else {
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
        Sentry.captureException(hookResult.error);
      }

      return isFunction(ErrorComponent) ? <ErrorComponent /> : ErrorComponent;
    }

    return <Component {...props} loadedData={hookResult as RV<HookReturnValue>} />;
  };
};

export default withAsync;
