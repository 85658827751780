import { FC, createContext, useContext, useEffect, useRef, useState } from "react";

import { useUpdateShipmentMutation } from "api/rest/shipments/shipmentsApi";
import {
  Shipment,
  ShipmentAddress,
  ShipmentBookedQuote,
  ShipmentLoadSpec,
  ShipmentMode,
  ShipmentPackageGroup,
  ShipmentStop,
} from "app/pages/shipments/types/domain";
import Errors from "app/pages/shipments/utils/errors";
import { CargoGroupContext } from "features/cargo-groups/CargoGroupProvider";
import {
  useMakeDispatchRequestContext,
  DispatchRequestProviderBase,
} from "features/dispatches/DispatchRequestProvider";
import useLDFlag from "hooks/useLDFlag";
import { t } from "i18next";
import WithRequiredField from "types/WithRequiredField";

import ShipmentRouteWarningView from "../route-details/ShipmentRouteWarningView";

export type ShipmentPatch = Partial<Omit<Shipment, "loadSpec" | "bookedQuote">> & {
  loadSpec?: Partial<ShipmentLoadSpec>;
  bookedQuote?: WithRequiredField<
    Partial<ShipmentBookedQuote>,
    "id" | "companyName" | "createdAt" | "status" | "submitterEmail" | "totalAmount"
  >;
};

export type ShipmentPatchFunction = (patch: ShipmentPatch) => void;

interface ShipmentDetailsContext {
  errors: Errors;
  hasErrors: boolean;
  isEditing: boolean;
  isSaving: boolean;
  patchedShipment: Shipment;
  shipment: Shipment;
  shipmentPatch: ShipmentPatch;
  statusUpdateWarning: boolean;

  cancel(): void;
  patch: ShipmentPatchFunction;
  save(): Promise<void>;
  startEditing(): void;
}

/**
 * Recursive Object.assign.
 * Unlike lodash/merge this does NOT merge arrays.
 *
 * @param a Target object
 * @param b Source object
 * @returns Merged object
 */
// eslint-disable-next-line @typescript-eslint/ban-types
function merge<A extends object, B extends object>(a: A, b: B): A & B {
  const result = structuredClone(a);
  Object.entries(b).forEach(([key, value]) => {
    if (!a[key as keyof A] || Array.isArray(value) || typeof value !== "object" || value === null) {
      result[key as keyof A] = value;
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    result[key as keyof A] = merge(a[key as keyof A] as object, b[key as keyof B] as object) as A[keyof A];
  });

  return result as A & B;
}

const ShipmentDetailsContext = createContext<ShipmentDetailsContext>({} as ShipmentDetailsContext);

interface ShipmentDetailsProviderProps {
  shipment: Shipment;
  initialStateIsEditing?: boolean;
  showOnlyEditing?: boolean;

  onSave?: ({ shipmentPatch }: { shipmentPatch: ShipmentPatch }) => Promise<void> | void;
  onSaveSuccess?: ({ isEmailSent }: { isEmailSent: boolean }) => Promise<void> | void;
  onSaveFailure?: () => Promise<void> | void;
  onStartEditing?: () => void;
}

/**
 * Trucks and package groups get sent to the API in their entirety, not as
 * a list of changes, so they need to be copied into a patch.
 */
const createFreshPatch = (shipment: Shipment): ShipmentPatch => ({
  trucks: structuredClone(shipment?.trucks || []),
  loadSpec: shipment?.loadSpec?.packageGroups
    ? { packageGroups: structuredClone(shipment.loadSpec.packageGroups) }
    : undefined,
});

function validateShipmentBase(shipment: Shipment, errors: Errors) {
  errors.clear("hazardous_goods_details");
  if (!!shipment.isHazardous && !shipment.hazardousGoodsDetails) {
    errors.addError(
      `hazardous_goods_details`,
      t("shipmentDetails_hazardousDetailsRequired_error", { ns: "shipments" })
    );
  }
}

function didAddressChangeInvalidateUpdates(
  addressPatch: Partial<ShipmentAddress> | undefined,
  originalAddress: Partial<ShipmentAddress>
) {
  const isAddressChanged =
    (!!addressPatch?.id && addressPatch.id !== originalAddress?.id) ||
    (!!addressPatch?.address1 && addressPatch.address1 !== originalAddress?.address1) ||
    (!!addressPatch?.address2 && addressPatch.address2 !== originalAddress?.address2) ||
    (!!addressPatch?.city && addressPatch.city !== originalAddress?.city) ||
    (!!addressPatch?.countryCode && addressPatch.countryCode !== originalAddress?.countryCode) ||
    (!!addressPatch?.countryName && addressPatch.countryName !== originalAddress?.countryName) ||
    (!!addressPatch?.provinceCode && addressPatch.provinceCode !== originalAddress?.provinceCode) ||
    (!!addressPatch?.provinceName && addressPatch.provinceName !== originalAddress?.provinceName);

  return isAddressChanged;
}

function didStopChangeInvalidateUpdates(
  shipmentStops: ShipmentStop[],
  patchStops: ShipmentStop[] | undefined
): boolean {
  /**
   * To check if updates were invalidated we need to check a few conditions:
   * 1. If changes were made. patchStops will be undefined if there were no changes
   * 2. All the stops in the original shipment are in the same position as the patch.
   * 3. All the associated locations for the original shipment are the same as in the patch.
   */
  if (!patchStops) {
    return false;
  }

  let isInvalidated = false;

  shipmentStops.forEach((stop, stopIndex) => {
    const patchIndex = patchStops.findIndex((patchStop) => patchStop.id === stop.id);

    if (patchIndex === -1 || patchIndex !== stopIndex) {
      isInvalidated = true;
      return;
    }

    if (didAddressChangeInvalidateUpdates(stop.address, patchStops[patchIndex])) {
      isInvalidated = true;
    }
  });

  return isInvalidated;
}

function validateStops(stops: ShipmentStop[], errors: Errors) {
  let prevDate = -Infinity;
  errors.clear("stops.");

  if (stops.length < 2) {
    errors.addError("stops.length", t("shipmentDetails_minimumStopsRequired_error", { ns: "shipments" }));
  }

  stops.forEach((stop, index) => {
    if (!stop.address) {
      errors.addError(`stops.${index}.address`, t("shipmentDetails_missingStopAddress_error", { ns: "shipments" }));
    }

    if (!stop.start && index === 0) {
      errors.addError(`stops.${index}.date`, t("shipmentDetails_missingFirstStopDate_error", { ns: "shipments" }));
      return;
    }

    const startTimestamp = !!stop.start && new Date(stop.start).getTime();
    prevDate = startTimestamp || prevDate;
  });
}

function validatePackageGroup(packageGroup: ShipmentPackageGroup, index: number, errors: Errors) {
  errors.clear(`loadSpec.packageGroups.${index}.`);

  if (packageGroup.heightPerPackage < 1) {
    errors.addError(`loadSpec.packageGroups.${index}.heightPerPackage`);
  }

  if (packageGroup.widthPerPackage < 1) {
    errors.addError(`loadSpec.packageGroups.${index}.widthPerPackage`);
  }

  if (packageGroup.lengthPerPackage < 1) {
    errors.addError(`loadSpec.packageGroups.${index}.lengthPerPackage`);
  }

  if (packageGroup.itemQuantity < 1) {
    errors.addError(`loadSpec.packageGroups.${index}.itemQuantity`);
  }

  if (packageGroup.weightPerPackage <= 0) {
    errors.addError(`loadSpec.packageGroups.${index}.weightPerPackage`);
  }
}

function validateLoadSpec(loadSpec: Partial<ShipmentLoadSpec>, errors: Errors) {
  if (loadSpec.packageGroups) {
    loadSpec.packageGroups.forEach((packageGroup, index) => validatePackageGroup(packageGroup, index, errors));
  }
}

function validateShipment(shipment: Shipment, errors: Errors) {
  validateShipmentBase(shipment, errors);
  validateLoadSpec(shipment.loadSpec, errors);
  validateStops(shipment.stops, errors);
}

const ShipmentDetailsProvider: FC<ShipmentDetailsProviderProps> = (props) => {
  const {
    children,
    onSave,
    shipment,
    initialStateIsEditing = false,
    showOnlyEditing = false,
    onSaveFailure,
    onSaveSuccess,
    onStartEditing,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(initialStateIsEditing);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [shipmentPatch, setShipmentPatch] = useState<ShipmentPatch>(createFreshPatch(shipment));
  const [statusUpdateWarning, setStatusUpdateWarning] = useState(false);
  const errors = useRef<Errors>(new Errors());
  const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");
  const { isValid: isCargoGroupsValid, save: saveCargoGroups } = useContext(CargoGroupContext);

  const [updateShipment] = useUpdateShipmentMutation();

  useEffect(() => {
    validateShipment(shipment, errors.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = () => {
    errors.current.clear();
    setShipmentPatch({});
    validateShipment(shipment, errors.current);
  };

  useEffect(() => {
    // NOTE: We don't want the patch to reset when shipment gets invalidated by RTK-Q.
    //       We do want it to reset when new shipment gets fetched.
    if (!isSaving) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment]);

  let requestId = 0;
  if (shipment.sourceType === "dispatch") {
    requestId = shipment.dispatchRequest.id;
  }

  const dispatchContext = useMakeDispatchRequestContext({ requestId });

  const save = async () => {
    const isStatusesInvalid = didStopChangeInvalidateUpdates(shipment.stops, shipmentPatch.stops);

    if (isStatusesInvalid && !statusUpdateWarning && !!rolloutLoadStatuses && !!shipment.lastStatusUpdateAt) {
      setStatusUpdateWarning(true);
      return;
    }
    setStatusUpdateWarning(false);

    try {
      setIsSaving(true);

      if (shipment.mode === ShipmentMode.LtlPartial) {
        await saveCargoGroups();
      }

      await onSave?.({ shipmentPatch });

      if (!showOnlyEditing) {
        setIsEditing(false);
      }

      const result = await updateShipment({
        urlParams: { shipmentId: shipment.id },
        body: { ...shipmentPatch, versionNumber: shipment.versionNumber },
      }).unwrap();

      await dispatchContext.save();

      setIsSaving(false);

      await onSaveSuccess?.({ isEmailSent: result.isEmailSent });
    } catch (e) {
      await onSaveFailure?.();
    }
  };

  const patch = (patch: ShipmentPatch) => {
    const newShipmentPatch = merge(shipmentPatch, patch) as Partial<Shipment>;

    const patchedShipment = merge(shipment, newShipmentPatch) as unknown as Shipment;

    validateShipmentBase(patchedShipment, errors.current);

    if (Object.keys(patch).includes("stops")) {
      validateStops(newShipmentPatch?.stops || [], errors.current);
    }

    if (patch.loadSpec) {
      validateLoadSpec(patchedShipment.loadSpec, errors.current);
    }

    setShipmentPatch(newShipmentPatch);
  };

  const startEditing = () => {
    setIsEditing(true);
    onStartEditing?.();
  };

  const cancel = () => {
    reset();
    setIsEditing(false);
  };

  const hasErrors = errors.current.hasErrors() || (shipment.mode === ShipmentMode.LtlPartial && !isCargoGroupsValid);
  const patchedShipment = merge(shipment, shipmentPatch) as unknown as Shipment;

  const contextPayload: ShipmentDetailsContext = {
    errors: errors.current,
    isEditing,
    isSaving,
    shipment,
    shipmentPatch,
    patchedShipment,
    hasErrors,
    statusUpdateWarning,

    cancel,
    patch,
    save,
    startEditing,
  };

  return (
    <ShipmentDetailsContext.Provider value={contextPayload}>
      <DispatchRequestProviderBase value={dispatchContext}>{children}</DispatchRequestProviderBase>
      <ShipmentRouteWarningView
        open={statusUpdateWarning}
        onClickContinue={save}
        onCancel={() => setStatusUpdateWarning(false)}
      />
    </ShipmentDetailsContext.Provider>
  );
};

export const useShipmentDetails = (): ShipmentDetailsContext => {
  const context = useContext(ShipmentDetailsContext);

  if (!context) {
    throw new Error("useShipmentDetails should be invoked within ShipmentDetailsContext provider");
  }

  return context;
};

export default ShipmentDetailsProvider;
