import { PORTEX_SUPPORT_EMAIL } from "constants/index";

import { useMemo, useState, VFC } from "react";

import { OpenInNew, Person } from "@material-ui/icons";
import { Avatar, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@portex-pro/ui-components";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import { User } from "api/rest/users/types/User";
import LoggedIn from "components/auth/LoggedIn";
import LoggedOut from "components/auth/LoggedOut";
import { useUserContext } from "hooks/useUserContext";
import { useVersionStore } from "hooks/useVersionStore";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";
import { useSubmitterStore } from "pages/shipper/pages/quotes/hooks/useSubmitterStore";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { getUserFullName, getUserInitials } from "utils/user";

const MenuItemViewingAsUut: VFC<{ user: User | null | undefined }> = ({ user }) => {
  const { t } = useTranslation(["common"]);

  if (!user) {
    return null;
  }

  return <MenuItem disabled>{t("common:viewingAs", { as: user.email })}</MenuItem>;
};

const BrokerUserAvatar: VFC = () => {
  const { t } = useTranslation(["common", "broker"]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClose = () => setAnchorEl(null);
  const { auth0User } = useUserContext();
  const getCurrentUserQuery = useGetCurrentUserQuery();
  const versionStore = useVersionStore();
  const { submitterEmail } = useSubmitterStore();
  const { pathname, search } = useLocation();
  const [returnToQueryParam] = useQueryParam("returnTo", StringParam);

  const user = getCurrentUserQuery.data?.data.user;
  const initials = useMemo(() => getUserInitials({ user, auth0User }), [auth0User, user]);
  const name = useMemo(() => getUserFullName({ user, auth0User }), [auth0User, user]);
  const singleInitial = first(initials) ?? "";

  const returnToSearchParam = returnToQueryParam || pathname + search;

  return (
    <>
      <Avatar
        className="cursor-pointer"
        variant="rounded"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        {singleInitial || <Person />}
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        MenuListProps={{ disablePadding: true }}
        PaperProps={{ elevation: 24, style: { zIndex: 1, borderWidth: 0, border: "none", margin: "0" } }}
      >
        <MenuList id="BrokerUserAvatar-MenuList" style={{ minWidth: 180, zIndex: 0, outline: "none", border: "none" }}>
          <LoggedIn>
            <MenuItem disabled>{t("common:loggedInAs", { as: name })}</MenuItem>
          </LoggedIn>

          <LoggedIn as={["uut"]}>
            <MenuItemViewingAsUut user={user} />
            <MenuItem component="a" href="/broker" target="_blank">
              <ListItemIcon>
                <OpenInNew />
              </ListItemIcon>
              <ListItemText>{t("broker:loggedInAndViewingAs.clickHereToStopViewing")}</ListItemText>
            </MenuItem>
          </LoggedIn>

          <LoggedOut withUut={true}>
            <MenuItemViewingAsUut user={user} />
          </LoggedOut>

          <LoggedOut withUut={false}>
            <MenuItem component={Link} to={{ pathname: "/broker/signup" }} onClick={handleClose}>
              {t("broker:signUp")}
            </MenuItem>
          </LoggedOut>

          <LoggedIn as={["broker", "unclaimed", "uut"]}>
            <MenuItem component={Link} to={{ pathname: "/broker/signup" }} onClick={handleClose}>
              {t("broker:claimAccount")}
            </MenuItem>
          </LoggedIn>

          <LoggedOut withUut={true}>
            <MenuItem component={Link} to={{ pathname: "/broker/signup" }} onClick={handleClose}>
              {t("broker:claimAccount")}
            </MenuItem>
          </LoggedOut>

          <MenuItem component="a" href={`mailto:${PORTEX_SUPPORT_EMAIL}`} onClick={handleClose}>
            {t("common:sidebarProfile.help")}
          </MenuItem>

          <LoggedIn>
            <MenuItem
              component={Link}
              to={{
                pathname: "/logout",
                search: new URLSearchParams(
                  omitBy({ returnTo: returnToSearchParam, email: user?.email ?? "" }, isEmpty)
                ).toString(),
              }}
              onClick={handleClose}
            >
              {t("common:sidebarProfile.logout")}
            </MenuItem>
          </LoggedIn>

          <LoggedOut>
            <MenuItem
              component={Link}
              to={{
                pathname: "/login",
                search: new URLSearchParams(
                  omitBy({ returnTo: returnToSearchParam, email: user?.email ?? submitterEmail ?? "" }, isEmpty)
                ).toString(),
              }}
              onClick={handleClose}
            >
              {t("common:sidebarProfile.login")}
            </MenuItem>
          </LoggedOut>

          <MenuItem
            component={Link}
            to={{ pathname: "/version" }}
            style={{ opacity: 0.5 }}
          >{`v${versionStore.versionLocal}`}</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default BrokerUserAvatar;
