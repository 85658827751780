import { seed } from "@ngneat/falso";
import { ModeEnum } from "types/Mode";

import { FeaturedBrokerQuoteRequestData } from "../types";

seed("featuredBrokerQuoteRequestData");

const baseData: Omit<FeaturedBrokerQuoteRequestData, "quoteAmountType" | "mode"> = {
  quoteRequestId: 1,
  shipperId: 1,
  shipperName: "Ptx Kitchen Co.",
  quotes: [
    {
      amount: 3000,
      isOwner: false,
    },
    {
      amount: 5000,
      isOwner: true,
    },
    {
      amount: 9000,
      isOwner: false,
    },
    {
      amount: 9000,
      isOwner: false,
    },
  ],
  ownerQuote: {
    amount: 5000,
    isOwner: true,
  },
  quoteMean: 6500,
  quoteMin: 3000,
  quoteMax: 9000,
};

export const mockFeaturedBrokerQuoteRequestData: Record<
  FeaturedBrokerQuoteRequestData["mode"],
  () => FeaturedBrokerQuoteRequestData
> = {
  FTL: (): FeaturedBrokerQuoteRequestData => ({
    ...baseData,
    mode: ModeEnum.FTL,
    quoteAmountType: "PER_LOAD",
  }),
  LTL: (): FeaturedBrokerQuoteRequestData => ({
    ...baseData,
    mode: ModeEnum.LTL,
    quoteAmountType: "TOTAL_AMOUNT",
  }),
  FCL: (): FeaturedBrokerQuoteRequestData => ({
    ...baseData,
    mode: ModeEnum.FCL,
    quoteAmountType: "TOTAL_AMOUNT",
  }),
  AIR: (): FeaturedBrokerQuoteRequestData => ({
    ...baseData,
    mode: ModeEnum.AIR,
    quoteAmountType: "TOTAL_AMOUNT",
  }),
  DRAYAGE: (): FeaturedBrokerQuoteRequestData => ({
    ...baseData,
    mode: ModeEnum.DRAYAGE,
    quoteAmountType: "TOTAL_AMOUNT",
  }),
};
