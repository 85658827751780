import OptionalMaybe from "./OptionalMaybe";
import { TrailerType, TrailerTypeEnum } from "./TrailerType";

export enum TrailerSizeEnum {
  T_26 = "T_26",
  T_40 = "T_40",
  T_48 = "T_48",
  T_53 = "T_53",
  C_20 = "C_20",
  C_40 = "C_40",
  C_20_HC = "C_20_HC",
  C_40_HC = "C_40_HC",
  C_45_HC = "C_45_HC",
  NA = "NA",
}

export type TrailerSize = `${TrailerSizeEnum}`;

export type TrailerSizeDrayage = Extract<TrailerSize, "C_20" | "C_40" | "C_20_HC" | "C_40_HC" | "C_45_HC">;

const allEquipmentSizes = Object.values(TrailerSizeEnum);
const defaultEquipmentSizes = allEquipmentSizes.filter((size) => !size.startsWith("C_"));
const drayageEquipmentSizes = allEquipmentSizes.filter((size) => size.startsWith("C_"));
const containerEquipmentSizes = allEquipmentSizes.filter((size) => size.startsWith("C_")).concat(TrailerSizeEnum.NA);

const trailerTypeToSizesMap: Record<TrailerType, TrailerSize[]> = {
  [TrailerTypeEnum.DRY_VAN]: defaultEquipmentSizes,
  [TrailerTypeEnum.FLATBED]: defaultEquipmentSizes,
  [TrailerTypeEnum.REEFER]: defaultEquipmentSizes,
  [TrailerTypeEnum.FOOD_GRADE_TANK]: defaultEquipmentSizes,
  [TrailerTypeEnum.BULK]: defaultEquipmentSizes,
  [TrailerTypeEnum.STEP_DECK]: defaultEquipmentSizes,
  [TrailerTypeEnum.BOX_TRUCK]: ["T_26", "NA"],
  [TrailerTypeEnum.SPRINTER_VAN]: ["NA"],
  [TrailerTypeEnum.CONESTOGA]: defaultEquipmentSizes,
  [TrailerTypeEnum.HOTSHOT]: defaultEquipmentSizes,
  [TrailerTypeEnum.RGN]: ["NA"],
  [TrailerTypeEnum.DRAYAGE]: drayageEquipmentSizes,
  [TrailerTypeEnum.DRAYAGE_REEFER]: drayageEquipmentSizes,
  [TrailerTypeEnum.CARGO_VAN]: defaultEquipmentSizes,
  [TrailerTypeEnum.DUMP]: defaultEquipmentSizes,
  [TrailerTypeEnum.PARTIAL_LOAD]: defaultEquipmentSizes,
  [TrailerTypeEnum.NA_PARTNERS_CHOICE]: defaultEquipmentSizes,
  [TrailerTypeEnum.OTHER]: defaultEquipmentSizes,
  [TrailerTypeEnum.OPEN_TOP]: containerEquipmentSizes,
  [TrailerTypeEnum.FLAT_RACK]: containerEquipmentSizes,
  [TrailerTypeEnum.PLATFORM]: containerEquipmentSizes,
  [TrailerTypeEnum.DRY]: containerEquipmentSizes,
};

export const getSizesForType = (type: OptionalMaybe<TrailerType>): TrailerSize[] => {
  if (!type) {
    return ["NA"];
  }

  return trailerTypeToSizesMap[type];
};

export const isValidSizeForType = (type: TrailerType, size: OptionalMaybe<TrailerSize>): boolean => {
  if (!size) {
    return true;
  }

  const sizes = trailerTypeToSizesMap[type];

  return sizes.includes(size);
};
