import { VFC } from "react";

import { Button, ButtonGroup, MenuItem, NumberInput, SelectInput } from "@portex-pro/ui-components";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import PositiveNumberInput from "components/PositiveNumberInput";
import { ShipmentPatchFunction } from "features/shipments/provider/ShipmentDetailsProvider";
import { useTranslation } from "react-i18next";
import { getSizesForType, isValidSizeForType } from "types/TrailerSize";
import { TrailerType, truckingTrailerTypes } from "types/TrailerType";
import { formatUSD } from "utils/formatCurrency";
import { renderSerializedNotes } from "utils/renderSerializedNotes";

import { EMPTY_CELL_HYPHEN } from "../../../../constants";
import { Shipment } from "../types/domain";
import Errors from "../utils/errors";
import formatEquipment from "../utils/formatEquipment";

interface EditableShipmentControlProps {
  patchedShipment: Shipment;
  isEditing: boolean;
  onChange: ShipmentPatchFunction;
  errors?: Errors;
}

type EditableShipmentControl<T = {}> = VFC<EditableShipmentControlProps & T>;

export const AdditionalNotesView: EditableShipmentControl = ({
  isEditing,
  patchedShipment: { additionalNotes },
  onChange,
}) =>
  isEditing ? (
    <textarea
      className="border border-border rounded-md p-1 w-full h-[5rem] hover:border-border-dark"
      onChange={(ev) => onChange({ additionalNotes: ev.target.value })}
      value={additionalNotes || ""}
    />
  ) : (
    <>{additionalNotes ? renderSerializedNotes(additionalNotes) : EMPTY_CELL_HYPHEN}</>
  );

export const HazardousDetailsView: EditableShipmentControl = ({
  isEditing,
  patchedShipment: { isHazardous, hazardousGoodsDetails },
  onChange,
  errors,
}) => {
  const hasError = !!errors?.getErrors("hazardous_goods_details");
  return isEditing ? (
    <textarea
      className={`border border-border rounded-md p-1 w-full h-[5rem] hover:border-border-dark ${
        hasError ? "border-red-500 border-2" : ""
      }`}
      onChange={(ev) => onChange({ hazardousGoodsDetails: ev.target.value })}
      value={hazardousGoodsDetails || ""}
    />
  ) : (
    <>{isHazardous && hazardousGoodsDetails ? renderSerializedNotes(hazardousGoodsDetails) : EMPTY_CELL_HYPHEN}</>
  );
};

export const GoodsValueView: EditableShipmentControl = ({ isEditing, patchedShipment: { goodsValue }, onChange }) =>
  isEditing ? (
    <NumberInput
      translate="no"
      value={goodsValue ?? undefined}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      validator="PositiveInt"
      onChange={(ev) => onChange({ goodsValue: Number(ev.target.value) })}
    />
  ) : goodsValue ? (
    <>{formatUSD(goodsValue)}</>
  ) : (
    <>{EMPTY_CELL_HYPHEN}</>
  );

export const TrailerView: EditableShipmentControl = ({ isEditing, onChange, patchedShipment }) => {
  const { t } = useTranslation(["common", "shipper", "shipments"]);

  if (!patchedShipment.loadSpec) {
    return null;
  }

  if (!isEditing) {
    return <>{formatEquipment(t, patchedShipment)}</>;
  }

  return (
    <div className="flex flex-col space-y-3.5">
      <SelectInput
        value={patchedShipment.loadSpec.trailerType ?? undefined}
        onChange={(event) => {
          const value = event.target.value as TrailerType;
          onChange({
            loadSpec: {
              trailerType: value,
              trailerSize: isValidSizeForType(value, patchedShipment.loadSpec.trailerSize)
                ? patchedShipment.loadSpec.trailerSize
                : null,
            },
          });
        }}
        SelectProps={{ style: { minWidth: "250px" } }}
      >
        {truckingTrailerTypes.map((trailerType) => (
          <MenuItem id={trailerType} value={trailerType}>
            {t(`common:trailerTypes.${trailerType}`)}
          </MenuItem>
        ))}
      </SelectInput>
      <ButtonGroup color={patchedShipment.loadSpec.trailerSize ? "primary" : "secondary"} fullWidth>
        {getSizesForType(patchedShipment.loadSpec.trailerType).map((trailerSize) => (
          <Button
            key={trailerSize}
            className={patchedShipment.loadSpec.trailerSize === trailerSize ? "Ptx-selected" : ""}
            onClick={() => onChange({ loadSpec: { trailerSize: trailerSize } })}
          >
            {t(`common:trailerSizes.${trailerSize}`)}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

export const AdjustedTotalView: EditableShipmentControl<{ dispatch?: ShipperDispatchRequest }> = ({
  isEditing,
  patchedShipment,
  onChange,
  dispatch,
}) => {
  let originalTotalFallback: number | undefined = undefined;
  if (patchedShipment.sourceType === "quote") {
    originalTotalFallback = patchedShipment.bookedQuote.totalAmount;
  } else if (patchedShipment.sourceType === "dispatch" && dispatch) {
    originalTotalFallback = (dispatch.confirmed_rate.total ?? 0) * (patchedShipment.trucks?.length ?? 0);
  }

  const adjustedTotal = patchedShipment.adjusted_total || originalTotalFallback;
  return isEditing ? (
    <PositiveNumberInput
      value={adjustedTotal}
      margin="dense"
      fullWidth
      onlyValidInput
      disableError
      allowFloat
      onChange={(value) => onChange({ adjusted_total: value })}
    />
  ) : adjustedTotal ? (
    <>{formatUSD(adjustedTotal)}</>
  ) : (
    <>{EMPTY_CELL_HYPHEN}</>
  );
};
