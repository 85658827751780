import { ReactElement } from "react";

import ModeSelectionPage from "pages/shipper/pages/request-quote/pages/mode-selection/ModeSelectionPage";
import { useTranslation } from "react-i18next";
import { Route, useRouteMatch } from "react-router-dom";
import { withTitle } from "utils/layout/withTitle";

import DispatchManagementPage from "./containers/dispatches/DispatchManagementPage";
import RequestManagementIndexPage from "./containers/index-page/RequestManagementIndexPage";
import QuoteConversationsPage from "./containers/quotes/QuoteConversationsPage";
import QuoteManagementPage from "./containers/quotes/QuoteManagementPage";
import QuoteRequestPage from "./containers/quotes/QuoteRequestPage";
import QuotesIndexPage from "./containers/quotes/QuotesIndexPage";
import { useRequestManagementSlices } from "./store/requestManagementStore";

const RequestManagementPage = (): ReactElement | null => {
  useRequestManagementSlices();
  const { path } = useRouteMatch();
  const { t } = useTranslation("shipper");

  return (
    // route here is /shipper/requests
    <>
      <Route exact path={`${path}`} render={() => <RequestManagementIndexPage />} />
      <Route
        exact
        path={`${path}/dispatches/:dispatchRequestId`}
        render={() => withTitle(DispatchManagementPage, { title: t("dispatchManagementPage_title") })}
      />
      <Route exact path={`${path}/create`} render={() => withTitle(ModeSelectionPage, { title: t("requestQuote") })} />
      <Route path={`${path}/create/quotes`} render={() => withTitle(QuoteRequestPage, { title: t("requestQuote") })} />
      <Route
        exact
        path={`${path}/quotes/:quoteRequestId`}
        render={() => withTitle(QuotesIndexPage, { title: t("quotes") })}
      />
      <Route
        exact
        path={`${path}/quotes/:quoteRequestId/conversations`}
        render={() => withTitle(QuoteConversationsPage, { title: t("quotes") })}
      />
      <Route
        exact
        path={`${path}/quotes/:quoteRequestId/quote/:quoteId`}
        render={() => withTitle(QuoteManagementPage, { title: t("reviewBooking") })}
      />
    </>
  );
};

export default RequestManagementPage;
