import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { PortexClientName } from "api/types/generated-types";
import { PortexHeadersEnum } from "types/PortexHeaders";
import { RootState } from "types/Store";

import { REACT_APP_HOST, REACT_APP_REST_API_BASE_URL } from "../../env";

const baseQueryWithRetry = retry(async (args, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: `${REACT_APP_REST_API_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const authSlice = (getState() as RootState).authSlice;
      const { bearerToken, brokerToken, unclaimedUserToken, publicToken } = authSlice;

      if (publicToken) {
        headers.set("Authorization", `Token ${publicToken}`);
      } else if (unclaimedUserToken) {
        headers.set("Authorization", `Token ${unclaimedUserToken}`);
      } else if (bearerToken) {
        headers.set("Authorization", `Bearer ${bearerToken}`);
      }

      if (brokerToken) {
        headers.set(PortexHeadersEnum.BrokerToken, brokerToken);
      }
      headers.set(PortexHeadersEnum.ClientName, PortexClientName.WebApp);
      headers.set(PortexHeadersEnum.ClientVersion, process.env.REACT_APP_VERSION ?? "");
      return headers;
    },
  })(args, api, extraOptions);

  if (
    result.error &&
    result.error.data &&
    typeof result.error.data === "object" &&
    "statusCode" in result.error.data &&
    result.error.data.statusCode === 401 &&
    "error" in result.error.data &&
    typeof result.error.data.error === "string" &&
    result.error.data.error.includes("portex/AuthTokenError/")
  ) {
    window.location.replace(`${REACT_APP_HOST}/logout?returnTo=${window.location.pathname}`);
  }

  const unclaimedUserToken = (api.getState() as RootState).authSlice.unclaimedUserToken;

  if (!!unclaimedUserToken && result.meta?.response?.status === 401) {
    const errorData = result.error?.data as { message?: string } | undefined;
    if (errorData?.message?.includes("Invalid unclaimed user token (UUT): expired-and-claimed")) {
      // We send the user to the /broker/signup page to request a link because the user-token-expired flow will get the user stuck if they are already claimed
      location.assign(`${REACT_APP_HOST}/broker/signup`);
    } else if (errorData?.message?.includes("Invalid unclaimed user token (UUT): expired")) {
      const originalSearch = new URLSearchParams(location.search);
      const search = new URLSearchParams({ returnTo: location.href, uut: originalSearch.get("uut") ?? "" });
      location.assign(`${REACT_APP_HOST}/user-token-expired?${search.toString()}`);
    }
  }

  const retryConditions: boolean[] = [
    result.meta?.response?.status === 502,
    result.error?.status === "FETCH_ERROR" && result.error.error === "TypeError: Failed to fetch",
  ];

  // bail out unless a retrying condition is met.
  // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#bailing-out-of-error-re-tries
  if (result.error) {
    if (!retryConditions.some(Boolean)) {
      retry.fail(result.error);
    }
  }

  return result;
});

export const baseRestApi = createApi({
  baseQuery: baseQueryWithRetry,
  reducerPath: "baseRestApi",
  endpoints: () => ({}),
});
