import { makeStyles, createStyles, portexColor } from "@portex-pro/ui-components";

export const useChatStyles = makeStyles(() =>
  createStyles({
    fullScreenWrapper: {
      backgroundColor: "transparent",
      height: "100vh",
      width: "100vw",
      margin: 0,
      padding: 0,
    },
    allAttachments: {
      position: "absolute",
      left: 1,
      top: 0,
      right: 1,
      bottom: 1,
      "& > div > div": {
        borderLeft: 0,
        borderRight: 0,
      },
    },
  })
);

export const useNewMessageStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      position: "relative",
      marginRight: theme.spacing(2),
    },
    input: {
      "& input": {
        paddingRight: 80,
      },
    },
    attachButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: 0,
      marginBottom: 6,
    },
  })
);

export const useChatContentStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      overflow: "hidden",
      padding: theme.spacing(2),
      paddingRight: 0,
    },
    noMessages: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    newMessagesSeparator: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: portexColor.grey700,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      gap: theme.spacing(1),
    },
    newMessageLine: {
      height: 1,
      backgroundColor: portexColor.grey300,
      flex: 1,
    },
    messagesList: {
      display: "flex",
      flexDirection: "column",
      flex: "1",
      overflow: "auto",
      overscrollBehavior: "none",
      scrollbarWidth: "thin",
      paddingRight: theme.spacing(2),
    },
    allAttachmentsWrapper: {
      height: "100%",
      overflow: "auto",
    },
    attachmentsList: {
      height: "calc(100% - 60px)",
      padding: 0,
    },
  })
);

export const useHeadingStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    texts: {
      display: "flex",
      gap: theme.spacing(1),
    },
    buttons: {
      display: "flex",
      gap: theme.spacing(2),

      "& button": {
        padding: 0,
        minWidth: "unset",

        "& label": {
          fontWeight: "normal",
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(2),
          color: portexColor.grey700,
          cursor: "pointer",
        },
      },
    },
  })
);

export const useMessageStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    date: {
      marginBottom: theme.spacing(1),
      textAlign: "center",
    },
    author: {
      color: portexColor.grey900,
      fontWeight: "bold",
    },
    email: {
      color: portexColor.grey600,
    },
    text: {
      maxWidth: "100%",
      whiteSpace: "pre-wrap",
      marginTop: theme.spacing(0.5),
      wordWrap: "break-word",
      padding: theme.spacing(1.5),
      borderRadius: theme.spacing(0.5),
    },
    time: {
      color: portexColor.grey600,
      textAlign: "right",
    },
    byline: {
      display: "flex",
      "& p": {
        margin: theme.spacing(0.5),
      },
    },
    boxPartner: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "90%",
      alignSelf: "flex-start",
      padding: 12,
      marginLeft: 6,
      "& $text": {
        backgroundColor: portexColor.grey50,
      },
    },
    boxOwner: {
      display: "flex",
      flexDirection: "column",
      maxWidth: "90%",
      alignSelf: "flex-end",
      alignItems: "flex-end",
      padding: 12,
      marginRight: 6,
      "& $text": {
        backgroundColor: portexColor.blue100,
      },
      "& $byline": {
        justifyContent: "flex-end",
      },
    },
    boxAttachment: {
      width: "100%",
      padding: theme.spacing(1),
    },
    boxFullScreen: {
      maxWidth: "90%",
      minWidth: 208,
      width: "auto",
    },
    fullScreenAttachment: {
      width: 298,
    },
  })
);

export const useFileUploadStyles = makeStyles((theme) =>
  createStyles({
    dropZonePaper: {
      padding: "8px",
      display: "flex",
      flexDirection: "column",
    },
    wrapper: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    content: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
    input: {
      padding: theme.spacing(2),

      "& div": {
        width: "100%",
      },
    },
  })
);

export const useDraftAttachmentStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      flexWrap: "wrap",
    },
    item: {
      display: "flex",
      flexDirection: "row",
      borderRadius: 4,
      border: "0.5px solid",
      borderColor: portexColor.grey100,
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    icon: {
      padding: theme.spacing(1),
      display: "flex",
      alignContent: "center",
      flexWrap: "wrap",
    },
    close: {
      cursor: "pointer",
    },
    text: {
      flex: 1,
      padding: theme.spacing(1),
      display: "flex",
      alignContent: "center",
      flexWrap: "wrap",
      "& p": {
        fontWeight: "bold",
        color: portexColor.grey900,
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
      },
    },
  })
);
