import { VFC } from "react";

import CargoGroupsOverviewView from "features/cargo-groups/views/CargoGroupsOverviewView";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";

const ShipmentDetailsCargoDetailsDrayage: VFC = () => {
  const { patchedShipment } = useShipmentDetails();

  return (
    <div className="px-4 py-2">
      <CargoGroupsOverviewView
        cargoGroups={patchedShipment.trucks?.flatMap((truck) => truck.cargoGroups ?? []) ?? []}
      />
    </div>
  );
};

export default ShipmentDetailsCargoDetailsDrayage;
