import { VFC } from "react";

import { Edit, LocationOn, Warning } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import {
  Box,
  Button,
  Collapse,
  createStyles,
  Icon,
  makeStyles,
  portexColor,
  Summary,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import GripIcon from "assets/dnd-grip.svg";
import useLDFlag from "hooks/useLDFlag";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import DoubleTimeRangeSelector from "../../../../components/DoubleTimeRangeSelector";
import SingleTimeRangeSelector from "../../../../components/SingleTimeRangeSelector";
import ThrottledTextInput from "../../../../components/ThrottledTextInput";
import { EMPTY_CELL_HYPHEN, EM_DASH } from "../../../../constants";
import { displayHoursOfOperation } from "../../../../utils/displayHoursOfOperation";
import { renderSerializedNotes } from "../../../../utils/renderSerializedNotes";
import useEditableShipmentStop from "../hooks/useEditableShipmentStop";
import { ShipmentAccessorial, ShipmentMode, ShipmentStop } from "../types/domain";
import formatAddress from "../utils/formatAddress";
import formatContact from "../utils/formatContact";
import formatStopTimeRange from "../utils/formatStopTimeRange";
import getStopName from "../utils/getStopName";
import AccordionToggle from "./AccordionToggle";
import CheckboxGrid, { CheckboxGridItem } from "./CheckboxGrid";
import EditableRequestStopView from "./EditableRequestStopView";
import ShipmentAddressPicker from "./ShipmentAddressPicker";

const useStyles = makeStyles(() =>
  createStyles({
    accordionHeader: {
      cursor: "pointer",
      alignItems: "center",
      "& > :nth-child(1)": {
        flexBasis: 64,
      },
      "& > :nth-child(2)": {
        flexBasis: 300,
        overflow: "hidden",
      },
      "& > :nth-child(2) p": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& > :nth-child(3)": {
        flexGrow: 1,
      },
      borderTop: `1px solid ${portexColor.grey200}`,
      borderBottom: `1px solid ${portexColor.grey200}`,
      marginBottom: "-1px",
      backgroundColor: "#fff",
    },
    accordionHeader_error: {
      color: "red",
    },
    dragging: {
      borderRadius: "10px",
      boxShadow: "0 0 5px 1px rgba(0,0,0,.3)",
    },
    stopSummary: {
      paddingLeft: 64,
      alignItems: "center",
      "& > :nth-child(1)": {
        flexBasis: 300,
      },
      "& > :nth-child(2)": {
        flexGrow: 1,
      },
    },
    stopSummaryFooter: {
      justifyContent: "flex-end",
    },
    icon: {
      width: 24,
      height: 24,
      display: "block",
    },
  })
);

type ShipmentStopType = "DELIVERY" | "PICKUP" | "OTHER";

interface EditableShipmentStopViewProps {
  allAccessorials: ShipmentAccessorial[];
  selectedAccessorials: ShipmentAccessorial[];
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  errors?: { address?: string[] | null; date?: string[] | null };
  isDragging: boolean;
  isEditing: boolean;
  isLocked: boolean;
  isOpen: boolean;
  shipmentMode: ShipmentMode;
  stop: ShipmentStop;
  stopType?: ShipmentStopType;
  variant: "request" | "management";
  hideSave?: boolean;
  showDelete?: boolean;

  onChange(editedStop: Partial<ShipmentStop>, editedAccessorials?: CheckboxGridItem<ShipmentAccessorial>[]): void;
  onDelete(): void;
  onOpenStop(stop: ShipmentStop): void;
  onClickSaveStop(stop: ShipmentStop): void;
}

const EditableShipmentStopView: VFC<EditableShipmentStopViewProps> = ({
  allAccessorials,
  dragHandleProps,
  errors,
  isDragging,
  isEditing,
  isLocked,
  isOpen,
  onChange,
  onDelete,
  onOpenStop,
  onClickSaveStop,
  selectedAccessorials,
  shipmentMode,
  stop,
  stopType = "OTHER",
  variant,
  hideSave,
  showDelete = false,
}) => {
  const isOpenAndUnlocked = isOpen && !isLocked;
  const enableLocationBookingNotes = useLDFlag("locationBookingNotes");
  const { t } = useTranslation("shipments");
  const styles = useStyles();
  const {
    getLocationName,
    handleAccessorialsChange,
    handleAddressChange,
    handleAddressNameChange,
    handleContactChange,
    handleDateChange,
    handleNoteChange,
    handleBookingNotesChange,
    handleOperatingHoursChange,
    handleClearOperationsDetails,
    handleReferenceNumberChange,
    handleTimeChange,
    operatingHoursTimeRange,
    timeRange,
  } = useEditableShipmentStop({ stop, onChange });

  const errorMessages =
    errors &&
    Object.values(errors)
      .filter(Boolean)
      .map((errorMessages) => errorMessages && errorMessages.join("; "));

  const hasErrors = Boolean(errorMessages?.length);

  const createAccessorialItem = (item: ShipmentAccessorial): CheckboxGridItem<ShipmentAccessorial> => ({
    isSelected: selectedAccessorials.some((accessorial) => accessorial.id === item.id),
    item,
  });

  const accessorialItems: CheckboxGridItem<ShipmentAccessorial>[] = allAccessorials
    .filter((accessorial) => accessorial.type === stopType)
    .map(createAccessorialItem);

  const getAccessorialLabel = (accessorial: ShipmentAccessorial): string => accessorial.name;

  const shouldShowAccessorials = shipmentMode === ShipmentMode.Ltl && allAccessorials && stopType !== "OTHER";

  return (
    <>
      <Summary.Row
        className={[styles.accordionHeader, hasErrors && styles.accordionHeader_error, isDragging && styles.dragging]
          .filter(Boolean)
          .join(" ")}
        onClick={() => onOpenStop(stop)}
      >
        <Summary.Cell head {...dragHandleProps}>
          {isEditing ? <img alt="" src={GripIcon} className={styles.icon} /> : <Icon as={LocationOn} palette="blue" />}
        </Summary.Cell>
        <Summary.Cell head>
          {getStopName(stop)}
          {errorMessages && hasErrors && (
            <Tooltip title={errorMessages.join(", ")}>
              <Icon as={Warning} fontSize="small" palette="red" className="ml-1" />
            </Tooltip>
          )}
        </Summary.Cell>
        <Summary.Cell head>{(stop?.address && formatAddress(stop?.address, "long")) || EM_DASH}</Summary.Cell>
        <Summary.Cell>{formatStopTimeRange(timeRange, EMPTY_CELL_HYPHEN)}</Summary.Cell>
        <Summary.Cell>
          <div className="flex gap-2 items-center">
            {isEditing && <Edit className="text-brandBlue" />}
            <AccordionToggle isOpen={isOpen} />
          </div>
        </Summary.Cell>
      </Summary.Row>
      <Collapse in={variant === "request" && isOpenAndUnlocked}>
        <EditableRequestStopView
          address={stop.address}
          timeRange={timeRange}
          operatingHoursTimeRange={operatingHoursTimeRange}
          referenceNumber={stop.referenceNumber}
          note={stop.note}
          dateRequired={stopType === "PICKUP"}
          onAddressChange={handleAddressChange}
          onDateChange={handleDateChange}
          onTimeChange={handleTimeChange}
          onOperatingHoursChange={handleOperatingHoursChange}
          onContactChange={handleContactChange}
          onReferenceNumberChange={handleReferenceNumberChange}
          onNoteChange={handleNoteChange}
          onClearOperationsDetails={handleClearOperationsDetails}
          onDelete={onDelete}
          onSave={() => onClickSaveStop(stop)}
          hideSave={hideSave}
          showDelete={showDelete}
        />
      </Collapse>
      {variant === "management" && isOpenAndUnlocked && (
        <>
          <Summary.Row className={styles.stopSummary}>
            <Summary.Cell head>{t("shipmentDetails_location_label")}</Summary.Cell>
            <Summary.Cell>
              {isEditing ? (
                <ShipmentAddressPicker
                  value={stop.address || {}}
                  mode={ShipmentMode.Ftl}
                  onChange={handleAddressChange}
                />
              ) : (
                getLocationName(stop.address) || EMPTY_CELL_HYPHEN
              )}
            </Summary.Cell>
          </Summary.Row>
          <Summary.Row className={styles.stopSummary}>
            <Summary.Cell head>Location name</Summary.Cell>
            <Summary.Cell>
              {isEditing ? (
                <ThrottledTextInput
                  margin="none"
                  placeholder={t("shipmentDetails_addressName_placeholder")}
                  value={stop.address?.name || ""}
                  onChange={handleAddressNameChange}
                />
              ) : (
                stop.address?.name || EMPTY_CELL_HYPHEN
              )}
            </Summary.Cell>
          </Summary.Row>
          <Summary.Row className={styles.stopSummary}>
            <Summary.Cell head>{t("shipmentDetails_dateAndTime_label")}</Summary.Cell>
            <Summary.Cell>
              {isEditing ? (
                <>
                  <DatePicker clearable fullWidth value={timeRange?.start ?? null} onChange={handleDateChange} />
                  {stop.start && (
                    <Box display="flex" marginTop={0.7} alignItems="center">
                      <SingleTimeRangeSelector value={timeRange} onChange={handleTimeChange} style={{ flex: 1 }} />
                      <Typography color="textSecondary" style={{ margin: "0 0.75rem" }}>
                        <strong>or</strong>
                      </Typography>
                      <DoubleTimeRangeSelector
                        value={timeRange}
                        onChange={handleTimeChange}
                        placeholders={{
                          from: t("shipmentDetails_timeRangeStart_placeholder"),
                          to: t("shipmentDetails_timeRangeEnd_placeholder"),
                        }}
                        style={{ flex: 1 }}
                      />
                    </Box>
                  )}
                </>
              ) : (
                formatStopTimeRange(timeRange, EMPTY_CELL_HYPHEN, true)
              )}
            </Summary.Cell>
          </Summary.Row>
          {shouldShowAccessorials && (
            <Summary.Row className={styles.stopSummary}>
              <Summary.Cell head>
                {t(
                  stopType === "PICKUP"
                    ? "shipmentDetails_pickupAccessorials_label"
                    : "shipmentDetails_deliveryAccessorials_label"
                )}
              </Summary.Cell>
              <Summary.Cell>
                {isEditing ? (
                  <CheckboxGrid
                    items={accessorialItems}
                    getLabel={getAccessorialLabel}
                    onChange={handleAccessorialsChange}
                  />
                ) : (
                  <>
                    {accessorialItems
                      .filter((accessorialItem) => accessorialItem.isSelected)
                      .map((accessorialItem) => accessorialItem.item.name)
                      .join(", ") || EMPTY_CELL_HYPHEN}
                  </>
                )}
              </Summary.Cell>
            </Summary.Row>
          )}
          <Summary.Row className={styles.stopSummary}>
            <Summary.Cell head>{t("shipmentDetails_hoursOfOperation_label")}</Summary.Cell>
            <Summary.Cell>
              {isEditing ? (
                <DoubleTimeRangeSelector
                  value={operatingHoursTimeRange}
                  onChange={handleOperatingHoursChange}
                  placeholder={t("shipmentDetails_hoursOfOperation_placeholder")}
                  placeholders={{
                    from: t("shipmentDetails_hoursOfOperation_open"),
                    to: t("shipmentDetails_hoursOfOperation_close"),
                  }}
                  style={{ width: "100%" }}
                />
              ) : (
                displayHoursOfOperation(operatingHoursTimeRange)
              )}
            </Summary.Cell>
          </Summary.Row>
          <Summary.Row className={styles.stopSummary}>
            <Summary.Cell head>{t("shipmentDetails_operationsContact_label")}</Summary.Cell>
            <Summary.Cell>
              {isEditing ? (
                <div className="flex flex-col gap-[6px]">
                  <div className="flex gap-[6px]">
                    <ThrottledTextInput
                      className="w-1/2 flex-1"
                      fullWidth={false}
                      margin="none"
                      placeholder={t("shipmentDetails_contact_firstName_placeholder")}
                      value={stop?.address?.contact?.firstName || ""}
                      onChange={handleContactChange("firstName")}
                    />
                    <ThrottledTextInput
                      className="w-1/2 flex-1"
                      fullWidth={false}
                      margin="none"
                      placeholder={t("shipmentDetails_contact_lastName_placeholder")}
                      value={stop?.address?.contact?.lastName || ""}
                      onChange={handleContactChange("lastName")}
                    />
                  </div>
                  <div className="flex gap-[6px]">
                    <ThrottledTextInput
                      className="w-1/2 flex-1"
                      fullWidth={false}
                      margin="none"
                      placeholder={t("shipmentDetails_contact_email_placeholder")}
                      value={stop?.address?.contact?.email || ""}
                      onChange={handleContactChange("email")}
                    />
                    <ThrottledTextInput
                      className="w-1/2 flex-1"
                      fullWidth={false}
                      margin="none"
                      placeholder={t("shipmentDetails_contact_phoneNumber_placeholder")}
                      value={stop?.address?.contact?.phoneNumber || ""}
                      onChange={handleContactChange("phoneNumber")}
                    />
                  </div>
                </div>
              ) : stop?.address?.contact ? (
                formatContact(stop.address.contact)
              ) : (
                EMPTY_CELL_HYPHEN
              )}
            </Summary.Cell>
          </Summary.Row>
          <Summary.Row className={styles.stopSummary}>
            <Summary.Cell head>{t("shipmentDetails_refNumber_label")}</Summary.Cell>
            <Summary.Cell>
              {isEditing ? (
                <ThrottledTextInput
                  margin="none"
                  placeholder={t("shipmentDetails_refNumber_placeholder")}
                  value={stop?.referenceNumber || ""}
                  onChange={handleReferenceNumberChange}
                />
              ) : (
                stop.referenceNumber || EMPTY_CELL_HYPHEN
              )}
            </Summary.Cell>
          </Summary.Row>
          <Summary.Row className={styles.stopSummary}>
            <Summary.Cell head>{t("shipmentDetails_note_label")}</Summary.Cell>
            <Summary.Cell>
              {isEditing ? (
                <div className="text-initial">
                  <textarea
                    value={stop?.note || ""}
                    disabled={!!enableLocationBookingNotes}
                    onChange={(ev) => handleNoteChange(ev.currentTarget.value)}
                    className="m-0 p-3 w-full h-28 font-initial text-initial border border-border rounded-md"
                    placeholder={t("shipmentDetails_note_placeholder")}
                  />
                </div>
              ) : stop.note ? (
                renderSerializedNotes(stop.note)
              ) : (
                EMPTY_CELL_HYPHEN
              )}
            </Summary.Cell>
          </Summary.Row>
          {!!enableLocationBookingNotes && (
            <Summary.Row className={styles.stopSummary}>
              <Summary.Cell head>{t("shipmentDetails_booking_notes_label")}</Summary.Cell>
              <Summary.Cell>
                {isEditing ? (
                  <div className="text-initial">
                    <textarea
                      value={stop.bookingNotes}
                      onChange={(ev) => handleBookingNotesChange(ev.currentTarget.value)}
                      className="m-0 p-3 w-full h-28 font-initial text-initial border border-border rounded-md"
                      placeholder={t("shipmentDetails_note_placeholder")}
                    />
                  </div>
                ) : stop.bookingNotes ? (
                  renderSerializedNotes(stop.bookingNotes)
                ) : (
                  EMPTY_CELL_HYPHEN
                )}
              </Summary.Cell>
            </Summary.Row>
          )}
          {isEditing && showDelete && (
            <Summary.Row className={styles.stopSummaryFooter}>
              <Summary.Cell>
                <Button
                  type="button"
                  variant="outlined"
                  style={{ color: "red", borderColor: "red", backgroundColor: "white" }}
                  onClick={onDelete}
                >
                  {t("shipmentDetails_deleteStop_button")}
                </Button>
              </Summary.Cell>
            </Summary.Row>
          )}
        </>
      )}
    </>
  );
};

export default EditableShipmentStopView;
