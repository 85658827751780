import { FC, useEffect, useState } from "react";

import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { AppBar, Box, portexColor, Toolbar } from "@portex-pro/ui-components";
import ViewQuoteConversationsTab from "components/chat/ViewQuoteConversationsTab";
import { DateTime } from "luxon";
import ShipperAppHeader from "pages/shipper/components/ShipperAppHeader";
import { useParams } from "react-router-dom";

import {
  Mode,
  Query,
  QueryFindContactsQuoteRequestsArgs,
  QueryGetQuoteRequestArgs,
  QuoteRequest,
} from "../../../../api/types/generated-types";
import NotFound404 from "../../../../components/errors/NotFound404";
import NotReachable500 from "../../../../components/errors/NotReachable500";
import { useOnApolloError } from "../../../../hooks/useOnApolloError";
import AirQuoteRequestHeader from "../../components/AirQuoteRequestHeader";
import FclQuoteRequestHeader from "../../components/FclQuoteRequestHeader";
import LtlQuoteRequestHeader from "../../components/LtlQuoteRequestHeader";
import QuoteRequestHeader from "../../components/QuoteRequestHeader";
import AirQuotesIndexBody from "./components/AirQuotesIndexBody";
import FclQuotesIndexBody from "./components/FclQuotesIndexBody";
import FtlQuotesIndexBody from "./components/FtlQuotesIndexBody";
import LtlQuotesIndexBody from "./components/LtlQuotesIndexBody";

const ADDRESS = gql`
  fragment QuotesIndexPage_Address on Address {
    id
    city
    port_name
    province_code
    province_name
    iana_timezone
    type
    airport_iata_code
    airport_name
  }
`;

const QUOTES = gql`
  fragment QuotesIndexPage_Quote on Quote {
    id
    company_name
    notes
    status
    total_amount
    rate_per_load
    valid_until
    submitter_tz
    submitter_email
    quote_request {
      ftl_load_specs {
        truck_quantity
      }
    }
  }
`;

const LTL_QUOTES = gql`
  fragment QuotesIndexPage_LtlQuote on LtlQuote {
    id
    company_name
    notes
    status
    carrier_name
    total_amount
    valid_until
    submitter_tz
    submitter_email
    min_transit_time
    max_transit_time
  }
`;

const FCL_QUOTES = gql`
  fragment QuotesIndexPage_FclQuote on FclQuote {
    id
    submitter_email
    total_amount
    valid_until
    submitter_tz
    status
    company_name
    notes
    booking_notes
    benchmark
    min_transit_time
    max_transit_time
    quote_charges {
      id
      name
      type
      quantity
      unit
      rate
    }
    quote_charges_totals {
      origin
      freight
      destination
      customs
    }
    origin_port {
      ...QuotesIndexPage_Address
    }
    via_port {
      ...QuotesIndexPage_Address
    }
    destination_port {
      ...QuotesIndexPage_Address
    }
    destination_rail_ramp {
      ...QuotesIndexPage_Address
    }
    inland_mode_of_transport
    rate_per_container
    carrier
  }
  ${ADDRESS}
`;

const AIR_QUOTES = gql`
  fragment QuotesIndexPage_AirQuote on AirQuote {
    id
    submitter_email
    total_amount
    valid_until
    submitter_tz
    status
    company_name
    notes
    booking_notes
    min_transit_time
    max_transit_time
    quote_charges {
      id
      name
      type
      quantity
      unit
      rate
    }
    quote_charges_totals {
      origin
      freight
      destination
      customs
    }
    origin_airport {
      ...QuotesIndexPage_Address
    }
    via_airport {
      ...QuotesIndexPage_Address
    }
    destination_airport {
      ...QuotesIndexPage_Address
    }
    rate_per_kg
    service_level
    chargeable_weight
    carrier
  }
  ${ADDRESS}
`;

const QUOTE_REQUEST = gql`
  fragment QuotesIndexPage_QuoteRequest on QuoteRequest {
    id
    state
    booked_at
    closed_at
    canceled_at
    original_qr_id
    quotes {
      ...QuotesIndexPage_Quote
    }
    ltl_quotes {
      ...QuotesIndexPage_LtlQuote
    }
    fcl_quotes {
      ...QuotesIndexPage_FclQuote
    }
    air_quotes {
      ...QuotesIndexPage_AirQuote
    }
    ...QuoteRequestHeader_QuoteRequest
    ...LtlQuoteRequestHeader_QuoteRequest
    ...FclQuoteRequestHeader_QuoteRequest
    ...AirQuoteRequestHeader_QuoteRequest
  }
  ${QUOTES}
  ${LTL_QUOTES}
  ${FCL_QUOTES}
  ${AIR_QUOTES}
  ${QuoteRequestHeader.fragments.QuoteRequest}
  ${LtlQuoteRequestHeader.fragments.QuoteRequest}
  ${FclQuoteRequestHeader.fragments.QuoteRequest}
  ${AirQuoteRequestHeader.fragments.QuoteRequest}
`;

export const GET_QUOTE_REQUEST_AND_CONTACT: TypedDocumentNode<
  Pick<Query, "getQuoteRequest" | "findContactsQuoteRequests">,
  { id: QueryGetQuoteRequestArgs["id"]; filter: QueryFindContactsQuoteRequestsArgs["filter"] }
> = gql`
  query ($id: ID!, $filter: ContactsQuoteRequestsFilter) {
    getQuoteRequest(id: $id) {
      ...QuotesIndexPage_QuoteRequest
    }
    findContactsQuoteRequests(filter: $filter) {
      items {
        contact {
          id
          user {
            email
          }
          company_name
          is_internal
        }
      }
    }
  }
  ${QUOTE_REQUEST}
`;

type Params = {
  quoteRequestId: QuoteRequest["id"];
};

const QuoteRequestDetailsPage: FC = () => {
  const [lastRefreshedAt, setLastRefreshedAt] = useState<string>("");
  const setLastRefreshedAtNow = () =>
    setLastRefreshedAt(DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS));

  const { onApolloError } = useOnApolloError({ componentName: "QuoteRequestDetailsPage" });
  const { quoteRequestId } = useParams<Params>();

  const POLLING_QUOTE_INTERVAL_SECONDS = 60; // 1 minute
  const { data, error, loading, refetch } = useQuery(GET_QUOTE_REQUEST_AND_CONTACT, {
    variables: {
      id: quoteRequestId,
      filter: { quote_request_id: { eq: quoteRequestId } },
    },
    fetchPolicy: "cache-and-network",
    onCompleted: setLastRefreshedAtNow,
    onError: onApolloError("getQuoteRequestAndContact"),
    pollInterval: POLLING_QUOTE_INTERVAL_SECONDS * 1000,
  });

  const quoteRequest = data?.getQuoteRequest;
  const contactsQuoteRequest = data?.findContactsQuoteRequests.items;

  // Workaround for a known Apollo bug. Resolved in 3.5.0.
  // https://github.com/apollographql/apollo-client/issues/5531
  useEffect(setLastRefreshedAtNow, [data]);

  if (error) {
    return <NotReachable500 />;
  }

  if (!loading && !quoteRequest && !contactsQuoteRequest) {
    return <NotFound404 />;
  }

  const renderHeader = () => {
    switch (quoteRequest?.mode) {
      case Mode.Ftl:
        return <QuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />;
      case Mode.Ltl:
        return <LtlQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />;
      case Mode.Fcl:
        return <FclQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />;
      case Mode.Air:
        return <AirQuoteRequestHeader quoteRequest={quoteRequest} refetchQuoteRequest={refetch} />;
      default:
        return null;
    }
  };

  const renderBody = () => {
    const commonProps = {
      lastRefreshedAt,
      quoteRequestId,
      quoteRequest,
      contactsQuoteRequest,
    };

    switch (quoteRequest?.mode) {
      case Mode.Ftl:
        return <FtlQuotesIndexBody {...commonProps} refetchQuoteRequest={refetch} loading={loading} />;
      case Mode.Ltl:
        return <LtlQuotesIndexBody {...commonProps} refetchQuoteRequest={refetch} loading={loading} />;
      case Mode.Fcl:
        return <FclQuotesIndexBody {...commonProps} />;
      case Mode.Air:
        return <AirQuotesIndexBody {...commonProps} />;
      default:
        return null;
    }
  };

  const toolbarBorderPx = 1;
  const toolbarHeightPx = 50 + toolbarBorderPx;

  return (
    <>
      <AppBar>
        <Toolbar
          variant="dense"
          disableGutters
          style={{ borderBottom: `${toolbarBorderPx}px solid ${portexColor.grey300}`, height: toolbarHeightPx }}
        >
          <ShipperAppHeader />
        </Toolbar>
      </AppBar>
      <Box>
        <AppBar style={{ top: toolbarHeightPx }}>
          {
            <>
              {renderHeader()}
              <ViewQuoteConversationsTab variant="quotes" quoteRequestId={quoteRequestId} />
            </>
          }
        </AppBar>
        {renderBody()}
      </Box>
    </>
  );
};

export default QuoteRequestDetailsPage;
