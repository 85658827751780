import { createContext, FC, useEffect, useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import uniqueId from "lodash/uniqueId";
import { Sentry } from "sentry";
import WithRequiredField from "types/WithRequiredField";

import { useCreateCargoGroupsMutation } from "./api/createCargoGroupsApi";
import { useGetCargoGroupsQuery } from "./api/getCargoGroupsApi";
import { CargoGroup } from "./api/types";
import { NormalizedCargoGroup } from "./api/types/NormalizedCargoGroup";
import { useUpdateCargoGroupsMutation } from "./api/updateCargoGroupsApi";
import {
  selectIsCacheValid,
  selectNewCargoGroups,
  selectUpdatedCargoGroups,
  useAddCargoGroup,
  useRemoveCargoGroup,
  useUpdateCargoGroup,
  useValidateCargoGroups,
} from "./store/cargoGroupsSlice";
import { useCargoGroupsSlices, useCargoGroupsSliceSelector } from "./store/cargoGroupsStore";

interface CargoGroupContext {
  updateCargoGroup: (id: string | number, changes: Partial<NormalizedCargoGroup>) => void;
  addCargoGroup: (
    initialState: WithRequiredField<Omit<Partial<NormalizedCargoGroup>, "id">, "cargo_group_type" | "packaging_type">,
    options?: { isTopLevelCargoGroup: boolean } | undefined
  ) => void;
  removeCargoGroup: (id: string | number) => void;
  save: () => Promise<void>;
  topLevelCargoGroupIds: Array<number | string>;
  isValid: boolean;
}

export const CargoGroupContext = createContext<CargoGroupContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateCargoGroup: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addCargoGroup: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeCargoGroup: () => {},
  save: () => Promise.resolve(),
  topLevelCargoGroupIds: [],
  isValid: false,
});

interface CargoGroupContextArgs {
  cargoGroups?: CargoGroup[];
  cargoGroupIds?: number[];
  quote_request_id?: number;
  truck_id?: number;
}

export const useMakeCargoGroupContext = (args: CargoGroupContextArgs): CargoGroupContext => {
  const { cargoGroups, cargoGroupIds, quote_request_id, truck_id } = args;
  const cacheId = useMemo(() => uniqueId("cargo_group_cache_"), []);
  useCargoGroupsSlices();
  const [updateCargoGroupsMutation] = useUpdateCargoGroupsMutation();
  const [createCargoGroupsMutation] = useCreateCargoGroupsMutation();

  const newCargoGroups = useCargoGroupsSliceSelector((state) => selectNewCargoGroups(state, cacheId));
  const updatedCargoGroups = useCargoGroupsSliceSelector((state) => selectUpdatedCargoGroups(state, cacheId));
  const deletedCargoGroups = useCargoGroupsSliceSelector(
    (state) => state.cargoGroupsSlice.deletedCargoGroups[cacheId] ?? []
  );
  const isValid = useCargoGroupsSliceSelector((state) => selectIsCacheValid(state, cacheId));

  const providedCargoGroupIds = useMemo(
    () => (cargoGroups ?? []).map((cargoGroup) => cargoGroup.id).concat(cargoGroupIds ?? []),
    [cargoGroups, cargoGroupIds]
  );
  const newCargoGroupIds = useCargoGroupsSliceSelector(
    (state) => state.cargoGroupsSlice.topLevelNewCargoGroups[cacheId]
  );

  const topLevelCargoGroupIds = useMemo(
    () => [...providedCargoGroupIds, ...(newCargoGroupIds ?? [])],
    [providedCargoGroupIds, newCargoGroupIds]
  );

  const idsToQuery = topLevelCargoGroupIds.filter((id) => typeof id === "number") as number[];

  const { fulfilledTimeStamp } = useGetCargoGroupsQuery(
    idsToQuery.length
      ? {
          queryParams: { ids: idsToQuery },
        }
      : skipToken
  );

  const _updateCargoGroup = useUpdateCargoGroup();
  const _addCargoGroup = useAddCargoGroup();
  const _removeCargoGroup = useRemoveCargoGroup();
  const _validateCargoGroups = useValidateCargoGroups();

  useEffect(() => {
    _validateCargoGroups({ cacheId, cargoGroupIds: topLevelCargoGroupIds });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilledTimeStamp]);

  const updateCargoGroup = (id: string | number, changes: Partial<NormalizedCargoGroup>) => {
    _updateCargoGroup({ cacheId, changes: { id, changes } });
  };

  const addCargoGroup = (
    initialState: WithRequiredField<Omit<Partial<NormalizedCargoGroup>, "id">, "cargo_group_type" | "packaging_type">,
    options?: { isTopLevelCargoGroup: boolean } | undefined
  ) => {
    _addCargoGroup({
      cacheId,
      initialState: {
        ...initialState,
        quote_request_id: options?.isTopLevelCargoGroup ? quote_request_id : undefined,
        truck_id: options?.isTopLevelCargoGroup ? truck_id : undefined,
      },
      isTopLevelCargoGroup: options?.isTopLevelCargoGroup,
    });
  };

  const removeCargoGroup = (id: string | number) => {
    _removeCargoGroup({ cacheId, id });
  };

  const save = async () => {
    try {
      if (!!newCargoGroups.length) {
        await createCargoGroupsMutation({ body: { normalizedCargoGroups: newCargoGroups }, cacheId }).unwrap();
      }
      if (!!updatedCargoGroups.length || !!deletedCargoGroups.length) {
        await updateCargoGroupsMutation({
          body: { normalizedCargoGroups: updatedCargoGroups, deletedCargoGroups },
        }).unwrap();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return {
    updateCargoGroup,
    addCargoGroup,
    removeCargoGroup,
    save,
    topLevelCargoGroupIds,
    isValid,
  };
};

const CargoGroupProvider: FC<CargoGroupContextArgs> = ({ children, ...contextArgs }) => {
  const context = useMakeCargoGroupContext({ ...contextArgs });

  return <CargoGroupContext.Provider value={context}>{children}</CargoGroupContext.Provider>;
};

export default CargoGroupProvider;
