import ViewQuoteConversationsTab from "components/chat/ViewQuoteConversationsTab";
import withAsync from "components/withAsync";
import ShipperLaneBenchmark from "features/lane-benchmark/ShipperLaneBenchmark";

import useLoadQuoteRequest from "../../hooks/quotes/useLoadQuoteRequest";
import { useRequestManagementSlices } from "../../store/requestManagementStore";
import PageLayout from "../../views/PageLayout";
import QuoteListContainer from "./QuoteListContainer";
import QuoteListControlsContainer from "./QuoteListControlsContainer";
import QuoteManagementAppBar from "./QuoteManagementAppBar";
import QuoteRequestHeader from "./QuoteRequestHeader";

const QuotesIndexPage = withAsync({
  useHook: () => {
    useRequestManagementSlices();
    return useLoadQuoteRequest();
  },
  Component: ({ loadedData }) => {
    const quoteRequest = loadedData.quoteRequest;

    return (
      <PageLayout
        header={[
          <QuoteManagementAppBar />,
          <QuoteRequestHeader />,
          <ViewQuoteConversationsTab variant="requests" quoteRequestId={quoteRequest.id} />,
        ]}
        leftContent={[
          <QuoteListControlsContainer />,
          <ShipperLaneBenchmark
            quoteRequest={{
              id: quoteRequest.id,
              mode: quoteRequest.mode,
              stops: quoteRequest.stops.map((stop, index) => ({
                position: index + 1,
                address: stop.address,
              })),
            }}
          />,
          <QuoteListContainer />,
        ]}
      />
    );
  },
});

export default QuotesIndexPage;
