import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import { ShipperShipmentSource } from "api/rest/shipments/types";
import NotificationBadge from "components/NotificationBadge";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { NotificationIdentifier } from "types/Notifications";

interface ViewRequestButtonProps {
  item: ShipperShipmentSource;
}

const originMap = {
  QUOTES: {
    slug: (mode: ShipperShipmentSource["mode"]) => (mode === "DRAYAGE" ? "requests/quotes" : "quotes"),
    i18nKeyForButton: "requestList_columns_viewRequestButton_quotes_button",
  },
  DISPATCHES: {
    slug: (_mode: ShipperShipmentSource["mode"]) => "requests/dispatches",
    i18nKeyForButton: "requestList_columns_viewRequestButton_dispatches_button",
  },
} as const;

const ViewRequestButtonContainer: VFC<ViewRequestButtonProps> = ({ item }) => {
  const { t } = useTranslation("requestManagement");

  const origin = originMap[item.origin];
  const variant = item.origin === "QUOTES" && !item.responses_count ? "outlined" : "contained";

  const notificationIdentifiers: NotificationIdentifier[] =
    item.origin === "QUOTES" ? [`quoteRequest:${item.origin_id}`] : [];

  return (
    <NotificationBadge position="topLeft" identifiers={notificationIdentifiers}>
      <Button
        color="primary"
        variant={variant}
        component={Link}
        to={`/shipper/${origin.slug(item.mode)}/${item.origin_id}`}
        style={{
          whiteSpace: "nowrap",
        }}
        fullWidth
      >
        {t(origin.i18nKeyForButton, { count: item.responses_count })}
      </Button>
    </NotificationBadge>
  );
};

export default ViewRequestButtonContainer;
