import { VFC } from "react";

import { useSetStops } from "../store/bidRequestFieldsSlice";
import { useBidRequestSliceSelector } from "../store/bidRequestStore";
import StopsDetailsView from "../views/StopsDetailsView";

const SpecifyStopsContainer: VFC = () => {
  const stopsCount = useBidRequestSliceSelector((state) => state.bidRequestFieldsSlice.stopsCount);
  const rfpPricingFrequency = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.pricing_frequency);
  const setStops = useSetStops();

  return (
    <StopsDetailsView
      numberOfStops={stopsCount}
      onChangeStops={(stopCount) => setStops({ stopCount, rfpPricingFrequency })}
    />
  );
};

export default SpecifyStopsContainer;
