import React from "react";

import { useFilesContext } from "components/file-uploads/FilesControl";
import { useTranslation } from "react-i18next";

import AttachmentsListView from "./AttachmentsListView";

interface AttachmentsListContainerProps {
  showDashOnNoAttachments?: boolean;
  hideDeleteAction?: boolean;
}

/** __SHIPPERS ONLY__: (3/28/2025) brokers currently cannot useFilesContext and load files by fileId */
const AttachmentsListContainer: React.VFC<AttachmentsListContainerProps> = (props) => {
  const { showDashOnNoAttachments = false, hideDeleteAction = false } = props;
  const { useGetFiles, onDeleteFileFromFeature } = useFilesContext();
  const { t } = useTranslation("common");

  const fileWrappers = useGetFiles();

  if (!fileWrappers.length) {
    return showDashOnNoAttachments ? <>{t("emDash")}</> : null;
  }

  return (
    <AttachmentsListView
      files={fileWrappers.map((fileWrapper) => ({
        id: fileWrapper.localId,
        name: fileWrapper.file.name ?? "",
        url: fileWrapper.file.url ?? null,
      }))}
      onDeleteFile={(fileId) => onDeleteFileFromFeature(fileId)}
      showDashOnNoAttachments={showDashOnNoAttachments}
      hideDeleteAction={hideDeleteAction}
    />
  );
};

export default AttachmentsListContainer;
