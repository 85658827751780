import create, { GetState, Mutate, SetState, StoreApi } from "zustand";
import { persist } from "zustand/middleware";

type Hints = {
  dontShowAgainViewingAsHint: boolean;
};

const initialHintsState: Hints = {
  dontShowAgainViewingAsHint: false,
};

type HintStoreType = Hints & {
  setHintStoreState: (hints: Partial<Hints>) => void;
};

export const useHintStore = create<
  HintStoreType,
  SetState<HintStoreType>,
  GetState<HintStoreType>,
  Mutate<StoreApi<HintStoreType>, [["zustand/persist", Partial<HintStoreType>]]>
>(
  persist(
    (set) => ({
      ...initialHintsState,
      setHintStoreState: (hints: Partial<Hints>) => {
        return set((previous) => ({ ...previous, ...hints }));
      },
    }),
    { name: "hintStore", version: 1 }
  )
);
