import { CHAT_POLLING_INTERVAL_MS } from "constants/index";

import { useCallback, useEffect, useState } from "react";

import { skipToken } from "@reduxjs/toolkit/query/react";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import chunk from "lodash/chunk";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import { ChatMessage, ChatMeta, ChatMessageType, DraftAttachment, ChatParticipant } from "types/Chat";

import { useCreateMessageMutation } from "./api/createMessage";
import { useGetConversationMessagesQuery } from "./api/getConversationMessages";
import { useAddParticipantMutation } from "./api/participants";
import { cacheSelectors, chatDraftAttachmentsActions, draftAttchmentSelectors } from "./store/chatDraftFilesSlice";
import { useChatSlices, useChatSliceSelector } from "./store/chatStore";

interface UseChatResult {
  messages: ChatMessage[];
  participants: ChatParticipant[];
  addMessage: (message: string, fileIds?: string[]) => void;
  addParticipant: (contactId?: number, email?: string) => void;
  meta: ChatMeta;
  isSending: boolean;
  draftAttachments: DraftAttachment[];
  addDraftAttachment: (attachment: DraftAttachment) => void;
  removeDraftAttachment: (fileId: DraftAttachment["fileId"]) => void;
}

export const useChat = ({
  conversationId,
  onReceiveFile,
  variation = "shipper",
}: {
  conversationId: number;
  onReceiveFile?: () => void;
  variation: "shipper" | "broker";
}): UseChatResult => {
  const { user } = useGetCurrentUserQuery(undefined, { selectFromResult: ({ data }) => ({ user: data?.data.user }) });
  useChatSlices();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [participants, setParticipants] = useState<ChatParticipant[]>([]);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [meta, setMeta] = useState<ChatMeta>({ conversationId });
  // const [draftAttachments, setDraftAttachments] = useState<DraftAttachment[]>([]);
  const draftAttachments = useChatSliceSelector((state) => {
    const cache = cacheSelectors.selectById(state.chatDraftAttachmentsSlice, conversationId);

    if (!cache) {
      return [];
    }

    return draftAttchmentSelectors.selectAll(cache.attachments);
  });
  const addManyDraftAttachments = chatDraftAttachmentsActions.useAddManyDraftAttachments();
  const removeManyDraftAttachments = chatDraftAttachmentsActions.useRemoveManyDraftAttachments();
  const removeAllDraftAttachments = chatDraftAttachmentsActions.useRemoveAllDraftAttachments();

  const [createMessage] = useCreateMessageMutation();
  const [addChatParticipant] = useAddParticipantMutation();

  const isDocumentVisible = useDocumentVisibility();

  const after = messages.length ? messages[messages.length - 1].id : undefined;

  const { data, isLoading } = useGetConversationMessagesQuery(
    meta.conversationId ? { conversationId: meta.conversationId, after, variation } : skipToken,
    {
      pollingInterval: isDocumentVisible ? CHAT_POLLING_INTERVAL_MS : undefined,
    }
  );

  useEffect(() => {
    if (!isLoading && data?.messages.length) {
      const convertedMessages = data.messages;
      const hasNewAttachments = convertedMessages.some((message) => message.type === ChatMessageType.Attachment);
      setMessages((oldMessages) => {
        /** @todo: Replace the next 10ish lines with simple array concatenation once there are no incidents of
         *         duplicate messages. Until then, console.warn should help us track the bug, while uniqBy will
         *         provide a band-aid solution.
         */
        const newMessages = sortBy(uniqBy([...oldMessages, ...convertedMessages], "_id"), "datetime");
        if (oldMessages.length + convertedMessages.length !== newMessages.length) {
          const duplicates = convertedMessages.reduce<ChatMessage[]>(
            (acc, newMessage) =>
              oldMessages.some((oldMessage) => oldMessage._id === newMessage._id) ? [...acc, newMessage] : acc,
            []
          );
          console.warn("Duplicate chat messages detected:", duplicates);
        }
        return newMessages;
      });
      setMeta((oldValues) => {
        return {
          conversationId: oldValues.conversationId,
        };
      });
      setIsSending(false);
      !!hasNewAttachments && !!onReceiveFile && onReceiveFile();
    }
    if (!isLoading && data?.participants.length) {
      const convertedParticipants = data.participants;
      setParticipants(convertedParticipants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const addMessage = useCallback(
    async (newMessage: string, fileIds: string[] = []) => {
      if (!user) {
        return;
      }
      const authorId = user.id.toString();

      setIsSending(true);

      const fileGroups = chunk(fileIds, 4);

      await createMessage({
        conversationId,
        variation,
        authorId,
        text: newMessage,
        fileIds: fileGroups[0] ?? [],
      });

      // This will be a temporary solution until we can change the backend to chunk files into separate emails
      await Promise.all(
        fileGroups
          .slice(1)
          .map((fileGroup) => createMessage({ conversationId, variation, authorId, text: "", fileIds: fileGroup }))
      );
      // reset attachments list after sending the message
      removeAllDraftAttachments(conversationId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createMessage, conversationId, user?.id, draftAttachments]
  );

  const addParticipant = useCallback(
    async (contactId?: number, email?: string) => {
      await addChatParticipant({ urlParams: { conversationId }, body: { contactId, email } });
    },
    [addChatParticipant, conversationId]
  );

  return {
    messages,
    participants,
    addMessage,
    addParticipant,
    meta,
    isSending,
    draftAttachments,
    addDraftAttachment: (draft) => addManyDraftAttachments({ conversationId, attachments: [draft] }),
    removeDraftAttachment: (toRemoveId) => removeManyDraftAttachments({ conversationId, attachmentIds: [toRemoveId] }),
  };
};
