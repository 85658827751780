import { FC, useContext } from "react";

import { Box, Typography } from "@portex-pro/ui-components";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import { useTranslation } from "react-i18next";
import { Attachment, Author, ChatMessageType } from "types/Chat";

import SingleAttachmentView from "../Attachments/SingleAttachmentView";
import { ChatContext, ChatContextType } from "../ChatContext";
import { useMessageStyles } from "../chatStyles";
import { ChatStatusUpdates } from "../types/ChatStatusUpdates";
import StatusUpdateView from "./StatusUpdateView";

interface MessageViewProps {
  author: Author;
  type: ChatMessageType;
  date: string;
  time: string;
  text?: string;
  attachments?: Attachment[];
  shouldShowDate: boolean;
  chatStatusUpdate?: ChatStatusUpdates;
}

const MessageView: FC<MessageViewProps> = ({
  author,
  attachments,
  type,
  date,
  text,
  time,
  shouldShowDate,
  chatStatusUpdate,
}) => {
  const { user } = useGetCurrentUserQuery(undefined, { selectFromResult: ({ data }) => ({ user: data?.data.user }) });
  const { t } = useTranslation();
  const classes = useMessageStyles();

  const isYourMessage = Number(author.id) === user?.id || author.email === user?.email;
  const { isFullScreen } = useContext(ChatContext) as ChatContextType;

  const baseBoxClass = isYourMessage ? classes.boxOwner : classes.boxPartner;
  const boxClassList = [
    baseBoxClass,
    isFullScreen ? classes.boxFullScreen : "",
    isFullScreen && !!attachments && attachments.length ? classes.fullScreenAttachment : "",
  ].join(" ");

  const renderStatusUpdate = () => <StatusUpdateView chatStatusUpdate={chatStatusUpdate} />;

  // strip out image tags from text to be displayed
  const textWithoutImageTags = !!text && text.replace(/\[(cid|image):.*]/g, "");

  const renderMessage = () => (
    <Box className={boxClassList}>
      <Box className={classes.byline}>
        <Typography className={classes.author}>{isYourMessage ? t("chat.you") : author.name}</Typography>
        <Typography className={classes.time}>{time}</Typography>
      </Box>
      {!!text && <Box className={classes.text}>{textWithoutImageTags}</Box>}
      {!!attachments &&
        attachments.map((attachment) => (
          <SingleAttachmentView side={isYourMessage ? "right" : "left"} attachment={attachment} />
        ))}
    </Box>
  );

  const renderMessageByType = (type: ChatMessageType) => {
    return type === ChatMessageType.StatusUpdate ? renderStatusUpdate() : renderMessage();
  };

  return (
    <Box className={classes.wrapper}>
      {shouldShowDate && (
        <Typography variant="subtitle1" className={classes.date}>
          {date}
        </Typography>
      )}
      {renderMessageByType(type)}
    </Box>
  );
};

export default MessageView;
