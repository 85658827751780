import { VFC } from "react";

import { SourceOrigin, ShipperSourceStatus } from "api/rest/shipments/types";
import DropdownFilterView from "components/DropdownFilterView";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import SimpleSearchView from "components/SimpleSearchView";
import ModeButtonGroup from "pages/shipper/components/ModeButtonGroup";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { useTranslation } from "react-i18next";

interface RequestsFilterBarViewProps {
  filters: JSX.Element;
  lastRefreshedAt: string;
  isLoading: boolean;
  onRefresh(): void;
  mode: TransportationMode;
  origin: SourceOrigin[];
  status: ShipperSourceStatus[];
  search: string;
  onChangeMode: (mode: TransportationMode) => void;
  onChangeOrigin: (origins: SourceOrigin[]) => void;
  onChangeStatus: (statuses: ShipperSourceStatus[]) => void;
  onChangeSearch: (search: string) => void;
  /** useLDFlag("dispatchRequests") */
  enableDispatchRequests: boolean;
  disableIntermodal?: boolean;
  disableDrayage?: boolean;
  disableLtlPartial?: boolean;
  showDispatchesOnly?: boolean;
}

const RequestsFilterBarView: VFC<RequestsFilterBarViewProps> = ({
  filters,
  lastRefreshedAt,
  isLoading,
  onRefresh,
  mode,
  origin,
  status,
  search,
  onChangeMode,
  onChangeOrigin,
  onChangeStatus,
  onChangeSearch,
  enableDispatchRequests,
  disableIntermodal = false,
  disableDrayage = false,
  disableLtlPartial = false,
  showDispatchesOnly = false,
}) => {
  const { t } = useTranslation("requestManagement");

  const originOptions: { label: string; value: SourceOrigin }[] = [
    { label: t("requestList_filterbar_origin_quotes"), value: "QUOTES" as const },
    { label: t("requestList_filterbar_origin_dispatches"), value: "DISPATCHES" as const },
  ];

  const dispatchesOptions: { label: string; value: ShipperSourceStatus }[] = [
    {
      label: t("requestList_filterbar_dispatch_status_SENT"),
      value: "DISPATCHES_SENT" as const,
    },
    {
      label: t("requestList_filterbar_dispatch_status_CONFIRMED"),
      value: "DISPATCHES_CONFIRMED" as const,
    },
    {
      label: t("requestList_filterbar_dispatch_status_MODIFIED"),
      value: "DISPATCHES_MODIFIED" as const,
    },
    {
      label: t("requestList_filterbar_dispatch_status_REJECTED"),
      value: "DISPATCHES_REJECTED" as const,
    },
    {
      label: t("requestList_filterbar_dispatch_status_CANCELED"),
      value: "DISPATCHES_CANCELED" as const,
    },
  ].filter(() => enableDispatchRequests);

  const quotesOptions: { label: string; value: ShipperSourceStatus }[] = [
    {
      label: t("requestList_filterbar_quote_status_SENT"),
      value: "QUOTES_SENT" as const,
    },
    {
      label: t("requestList_filterbar_quote_status_RETURNED"),
      value: "QUOTES_RETURNED" as const,
    },
    {
      label: t("requestList_filterbar_quote_status_BOOKED"),
      value: "QUOTES_BOOKED" as const,
    },
    {
      label: t("requestList_filterbar_quote_status_CLOSED"),
      value: "QUOTES_CLOSED" as const,
    },
    {
      label: t("requestList_filterbar_quote_status_CANCELED"),
      value: "QUOTES_CANCELED" as const,
    },
  ].filter(() => !showDispatchesOnly);

  const statusOptions = dispatchesOptions.concat(quotesOptions);

  return (
    <div className="bg-white">
      <div className="pl-4">
        <div className="flex">
          <div className="flex flex-wrap py-2 gap-x-2 gap-y-2 items-center">
            <ModeButtonGroup
              value={mode}
              onChange={(mode) => onChangeMode(mode)}
              includeModes={[TransportationMode.INTERMODAL]}
              disabledModes={[
                TransportationMode.INTERMODAL,
                TransportationMode.Trucking,
                TransportationMode.DRAYAGE,
                TransportationMode.LTL_PARTIAL,
              ].filter((value) => {
                if (!disableIntermodal && value === "INTERMODAL") {
                  return false;
                }
                if (!disableDrayage && value === "DRAYAGE") {
                  return false;
                }
                if (!disableLtlPartial && value === "LTL_PARTIAL") {
                  return false;
                }
                return true;
              })}
            />

            {enableDispatchRequests ? (
              <DropdownFilterView
                placeholder={
                  origin.length === 1
                    ? originOptions.filter(({ value }) => value === origin[0])[0].label
                    : t("requestList_filterbar_origin")
                }
                selected={origin}
                options={originOptions}
                onChange={onChangeOrigin}
                minWidth={180}
                disabled={showDispatchesOnly}
              />
            ) : null}
            <DropdownFilterView
              placeholder={t("requestList_filterbar_status")}
              selected={status}
              options={statusOptions}
              groups={[
                {
                  label: t("requestList_filterbar_origin_dispatches"),
                  values: dispatchesOptions.map((option) => option.value),
                },
                {
                  label: t("requestList_filterbar_origin_quotes"),
                  values: quotesOptions.map((option) => option.value),
                },
              ]}
              onChange={onChangeStatus}
              minWidth={180}
            />
            <SimpleSearchView
              search={search}
              onChange={onChangeSearch}
              placeholder={t("requestList_filterbar_search")}
              TextInputProps={{ style: { width: 200 } }}
            />
            {filters}
          </div>
          <div className="flex items-center justify-end flex-grow pr-4 whitespace-nowrap">
            {lastRefreshedAt && <LastRefreshedAtText value={lastRefreshedAt} />}
            <div>
              <RefetchButton loading={isLoading} onClick={onRefresh} tooltip={t("requestList_refresh_button")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestsFilterBarView;
