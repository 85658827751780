import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import { TFuncKey } from "react-i18next";

/** @todo We should think about placing these kinds of i18n helper fucntions for mapping over things into a specific directory for them */
const mapPricingFrequencyToI18nKey: Record<ContractPricingFrequency, TFuncKey<"common", "pricingFrequency_values">> = {
  [ContractPricingFrequency.BULK_SPOT_QUOTE]: "BULK_SPOT_QUOTE",
  [ContractPricingFrequency.WEEKLY]: "WEEKLY",
  [ContractPricingFrequency.MONTHLY]: "MONTHLY",
  [ContractPricingFrequency.QUARTERLY]: "QUARTERLY",
  [ContractPricingFrequency.BIANNUALLY]: "BIANNUALLY",
  [ContractPricingFrequency.ANNUALLY]: "ANNUALLY",
};

export default mapPricingFrequencyToI18nKey;
