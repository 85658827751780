import { useEffect } from "react";

import { Paper } from "@portex-pro/ui-components";
import { BrokerQuoteResponse } from "api/rest/quote-requests";
import { useGetBrokerQuoteRequestQuery } from "api/rest/quote-requests/getBrokerQuoteRequest";
import { useSubmitBrokerQuoteMutation } from "api/rest/quote-requests/submitBrokerQuote";
import ChatContainer from "components/chat/ChatContainer";
import { useConversationForQuoteRequest } from "components/chat/hooks/useConversationForQuoteRequest";
import NotFound404 from "components/errors/NotFound404";
import HtmlTitle from "components/HtmlTitle";
import Loading from "components/Loading";
import PortexAppBar from "components/PortexAppBar";
import withAsync from "components/withAsync";
import FeaturedBrokerDashboard from "features/featured-brokers/FeaturedBrokerDashboard";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { useSubmitterStore } from "pages/shipper/pages/quotes/hooks/useSubmitterStore";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";
import ScrollableGridLayout from "utils/layout/ScrollableGridLayout";
import { validateEmail } from "utils/validateEmail";

import SubmitDeadlineAlertView from "../dispatch-submit/views/SubmitDeadlineAlertView";
import BrokerQuoteRequestAttachmentsListContainer from "./containers/BrokerQuoteRequestAttachmentsListContainer";
import { useSetLineItems, useSetQuote } from "./store/brokerQuoteSubmissionSlice";
import {
  useBrokerQuoteSubmissionSlices,
  useBrokerQuoteSubmissionSliceSelector,
} from "./store/brokerQuoteSubmissionStore";
import DrayageSummaryView from "./views/DrayageSummaryView";
import QuoteDetailsView from "./views/QuoteDetailsView";

const BrokerQuoteSubmissionPage = withAsync({
  useHook: () => {
    const params = useParams<{ quoteRequestGuid: string }>();
    useBrokerQuoteSubmissionSlices();
    const setQuote = useSetQuote();

    const { submitterEmail } = useSubmitterStore();

    useEffect(() => {
      if (submitterEmail) {
        setQuote({ submitterEmail });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useGetBrokerQuoteRequestQuery({
      urlParams: {
        quoteRequestGuid: params.quoteRequestGuid,
      },
    });
  },
  useHandleError: () => {
    const { t } = useTranslation("broker");
    const { enqueueSnackbar } = useSnackbar();

    return (error) => {
      if (error?.status === 403) {
        enqueueSnackbar(t("quoteSubmissionPage_message"), { variant: "warning", preventDuplicate: true });
        return;
      }

      return Sentry.captureException(error);
    };
  },
  LoadingComponent: <Loading showPortexLogo />,
  Component: ({ loadedData }) => {
    const quoteRequest = loadedData?.data?.data?.quoteRequest;
    const { quote, lineItems } = useBrokerQuoteSubmissionSliceSelector((state) => state.brokerQuoteSubmissionSlice);
    const [submitQuote, { isLoading }] = useSubmitBrokerQuoteMutation();
    const onChange = useSetQuote();
    const onChangeLineItem = useSetLineItems();
    const { t } = useTranslation(["broker", "common"]);
    const { enqueueSnackbar } = useSnackbar();
    const { setSubmitterEmail } = useSubmitterStore();
    const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
    const enableBrokerQuotesPage = useLDFlag("enableBrokerQuotesPage");
    const releaseBrokerChat = useLDFlag("releaseBrokerChat");
    const releaseQuoteRequestFiles = useLDFlag("releaseQuoteRequestFiles");

    const isValid =
      !!quote.validUntil &&
      !!quote.minTransitTime &&
      !!quote.maxTransitTime &&
      !!quote.submitterEmail &&
      lineItems.every((lineItem) => lineItem.amount !== undefined) &&
      validateEmail(quote.submitterEmail);

    const onSubmitQuote = async () => {
      if (!quoteRequest || !quoteRequest.guid || !quote.submitterEmail || !isValid) {
        return;
      }

      const total = lineItems.reduce((total, lineItem) => total + (lineItem.amount ?? 0), 0);

      try {
        await submitQuote({
          urlParams: { quoteRequestId: quoteRequest.id },
          body: {
            ...quote,
            totalAmount: total,
            lineItems: lineItems.map((lineItem) => ({
              amount: lineItem.amount ?? 0,
              cargo_group_id: lineItem.type === "FREIGHT_CHARGE" ? lineItem.cargoGroup.id : undefined,
              type: lineItem.type,
            })),
          } as BrokerQuoteResponse,
        }).unwrap();
        setSubmitterEmail(quote.submitterEmail);
        enqueueSnackbar(t("broker:quoteSubmission.successText"), { variant: "success" });
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
      }
    };

    const { conversationId } = useConversationForQuoteRequest({ quoteRequestGuid: quoteRequest.guid });

    if (loadedData.isError || !quoteRequest) {
      return <NotFound404 showAppBar useMarketingUrl />;
    }

    const leftContent = (
      <>
        {quoteRequest.deadline_respond_at && (
          <SubmitDeadlineAlertView deadlineRespondAt={quoteRequest.deadline_respond_at} />
        )}
        <Paper className="Por-outlined-light" elevation={8}>
          <DrayageSummaryView
            quoteRequest={quoteRequest}
            attachmentsList={
              !!releaseQuoteRequestFiles && (
                <BrokerQuoteRequestAttachmentsListContainer quoteRequestGuid={quoteRequest.guid} />
              )
            }
          />
          <QuoteDetailsView
            quote={quote}
            lineItems={lineItems}
            onChangeLineItem={onChangeLineItem}
            onChange={(changes) => onChange(changes)}
            onSubmit={onSubmitQuote}
            isLoading={isLoading}
            isValid={isValid}
            quoteRequest={quoteRequest}
          />
        </Paper>
      </>
    );
    const rightContent = (
      <div className="flex flex-col gap-5 grow w-full">
        {!!releaseBrokerChat && !!conversationId && (
          <div className="min-h-[600px] flex-auto">
            <ChatContainer conversationId={conversationId} />
          </div>
        )}
        <FeaturedBrokerDashboard quoteRequestGuid={quoteRequest.guid} />
      </div>
    );

    return (
      <>
        <HtmlTitle title={`${t("broker:submitQuoteForm.title")} ${quoteRequest.shipper_name}`} />
        <PortexAppBar
          shipperName={quoteRequest.shipper_name}
          useMarketingUrl
          hidePortexLogo={!!enableBrokerAppBar}
          backButtonTo={!!enableBrokerAppBar && !!enableBrokerQuotesPage && `/broker/quotes`}
        />
        {/* @todo Remove these variations once broker chat is released to users and the release flag is deprecated */}
        {!!releaseBrokerChat ? (
          <ScrollableGridLayout leftContent={leftContent} rightContent={rightContent} />
        ) : (
          <div className="flex flex-col w-full mx-auto my-5 overflow-auto">
            <div className="flex flex-col gap-y-5 self-center sm:max-w-[80%] lg:max-w-[1024px]">
              {leftContent}
              {rightContent}
            </div>
          </div>
        )}
      </>
    );
  },
});

export default BrokerQuoteSubmissionPage;
