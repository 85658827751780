import { VFC } from "react";

import Skeleton from "components/Skeleton";
import CargoGroups from "features/cargo-groups/CargoGroups";
import CargoGroupsOverviewView from "features/cargo-groups/views/CargoGroupsOverviewView";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import first from "lodash/first";

const ShipmentDetailsCargoDetailsLtlPartial: VFC = () => {
  const { isEditing, shipment, isSaving, patchedShipment } = useShipmentDetails();

  if (isSaving) {
    return <Skeleton style={{ height: "12rem", margin: "0 2rem" }} />;
  }

  if (isEditing) {
    return (
      <CargoGroups
        equipmentType={patchedShipment.loadSpec.trailerType ?? undefined}
        equipmentSize={patchedShipment.loadSpec.trailerSize ?? undefined}
        minTemp={patchedShipment.loadSpec.minTemp}
        maxTemp={patchedShipment.loadSpec.maxTemp}
        hideAddCargoGroup
      />
    );
  }

  return (
    <div className="px-4 py-2">
      <CargoGroupsOverviewView cargoGroups={first(shipment.trucks)?.cargoGroups ?? []} />
    </div>
  );
};

export default ShipmentDetailsCargoDetailsLtlPartial;
