import { FC } from "react";

import { AccessTime } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  grey,
  Icon,
  makeStyles,
  portexColor,
  Status,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import map from "lodash/map";
import upperCase from "lodash/upperCase";
import { useTranslation } from "react-i18next";

import { RoutingType } from "../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../components/LayoutColumnTwo";
import ModeIcon from "../../../../../../components/ModeIcon";
import { EMPTY_CELL_HYPHEN } from "../../../../../../constants";
import { formatCommodities } from "../../../../../../utils/formatCommodities";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { formatCBM, formatWeight } from "../../../../../../utils/formatUnit";
import { getCargoSummary } from "../../../../../../utils/getCargoSummary";
import { isPort } from "../../../../../../utils/isPort";
import { renderSerializedNotes } from "../../../../../../utils/renderSerializedNotes";
import { toLuxon } from "../../../../../../utils/toLuxon";
import { ServiceLevelLabels } from "../../../../../shipper/pages/quotes/constants";
import { getRoutingTypeLabel } from "../../../../../shipper/pages/request-quote/utils/getRoutingTypeLabel";
import { getRequiredLabel } from "../../quote-submission-fcl/utils/getRequiredLabel";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";

const useStyles = makeStyles(() => ({
  status: {
    marginLeft: "3px",
    padding: "3px",
    backgroundColor: grey["100"],
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
  accordionRoot: {
    "&.MuiAccordion-root": {
      boxShadow: "unset",
    },
    "& .MuiAccordionSummary-root": {
      borderTop: `1px solid ${portexColor.grey300}`,
      borderBottom: `1px solid ${portexColor.grey300}`,
    },
    "& .MuiAccordionDetails-root": {
      padding: "24px 16px 16px",
    },
  },
  sticky: {
    position: "sticky",
    top: "0",
    background: "white",
    zIndex: 2,
  },
}));

const QuoteRequestSidebarAIR: FC<{ sidebarWidth?: number }> = ({ sidebarWidth = 370 }) => {
  const { t } = useTranslation(["common", "broker"]);
  const classes = useStyles();
  const { publicQuoteRequest, submitAirQuoteInputPartial } = useContextQuoteSubmissionAIR();

  const {
    mode,
    is_hazardous,
    hazardous_goods_details,
    include_customs_clearance,
    incoterms_air,
    insurance_required,
    goods_value,
    locations,
    notes,
    portex_id,
    service_levels,
    routing_type,
    cargo_ready_date,
    target_delivery_date,
    package_groups,
    item_quantity,
    total_weight,
    total_volume,
    weight_unit,
    commodities,
  } = publicQuoteRequest;

  const [pickupLocation, deliveryLocation] = compact([first(locations), last(locations)]).map((location) =>
    isPort(location.type) ? location.airport_iata_code : location.city
  );

  const hasOriginDoor = routing_type === RoutingType.D2D || routing_type === RoutingType.D2P;
  const hasDestinationDoor = routing_type === RoutingType.D2D || routing_type === RoutingType.P2D;

  const originDoor = hasOriginDoor ? first(locations) : null;
  const destinationDoor = hasDestinationDoor ? last(locations) : null;

  const {
    origin_airport: originAirportQuote,
    via_airport: viaAirport,
    destination_airport: destinationAirportQuote,
    volumetric_weight: volumetricWeight,
  } = submitAirQuoteInputPartial;

  const chargeableWeight = Math.max(total_weight ?? 0, volumetricWeight ?? 0);

  const originAirportIndex = routing_type === RoutingType.D2D || routing_type === RoutingType.D2P ? 1 : 0;
  const destinationAirportIndex =
    routing_type === RoutingType.D2D || routing_type === RoutingType.P2D ? locations.length - 2 : locations.length - 1;

  const originAirportRequest = locations[originAirportIndex];
  const destinationAirportRequest = locations[destinationAirportIndex];

  const maybeOriginAirport = originAirportQuote || originAirportRequest;
  const maybeDestinationAirport = destinationAirportQuote || destinationAirportRequest;

  const originAirportName = maybeOriginAirport
    ? `${maybeOriginAirport.airport_iata_code} - ${maybeOriginAirport.airport_name}`
    : EMPTY_CELL_HYPHEN;
  const viaPortName = viaAirport ? `${viaAirport?.airport_iata_code} - ${viaAirport?.airport_name}` : "";
  const destinationAirportName = maybeDestinationAirport
    ? `${maybeDestinationAirport.airport_iata_code} - ${maybeDestinationAirport.airport_name}`
    : EMPTY_CELL_HYPHEN;

  const originChanged = originAirportQuote && originAirportQuote.id !== originAirportRequest?.address_id;
  const destinationChanged =
    destinationAirportQuote && destinationAirportQuote.id !== destinationAirportRequest?.address_id;
  const viaPortIncluded = !!viaAirport;

  const originChangedTooltip = t("broker:quoteRequestSidebarAIR.originChangedTooltip", {
    originQuoteRequestAirportName: originAirportRequest?.airport_name,
    originQuoteAirportName: originAirportQuote?.airport_name,
  });
  const destinationChangedTooltip = t("broker:quoteRequestSidebarAIR.destinationChangedTooltip", {
    destinationQuoteRequestAirportName: destinationAirportRequest?.airport_name,
    destinationQuoteAirportName: destinationAirportQuote?.airport_name,
  });
  const viaPortTooltip = t("broker:quoteRequestSidebarAIR.viaPortTooltip", {
    viaAirportName: viaAirport?.airport_name,
  });

  const summary = getCargoSummary(
    package_groups,
    { item_quantity, total_weight, total_volume, weight_unit },
    { brokerUse: true }
  );

  return (
    <LayoutColumnTwo.Sidebar
      style={{ margin: 0, width: sidebarWidth, backgroundColor: "#fffff", zIndex: 1, minWidth: sidebarWidth - 100 }}
    >
      <Box pt="1rem" className={classes.sticky}>
        <Box px="1rem" display="flex" alignItems="center">
          <ModeIcon mode={mode} />
          <Box ml={1}>
            <Typography>
              <strong>{t("broker:quoteRequestSidebarAIR.overview.path", { pickupLocation, deliveryLocation })}</strong>
            </Typography>
            <Typography>{portex_id}</Typography>
          </Box>
        </Box>
        <Box pt={2}>
          <Divider light style={{ backgroundColor: portexColor.grey300 }} />
        </Box>
      </Box>

      <Box p="1rem">
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              <strong>{t("broker:quoteRequestSidebarAIR.overview.serviceLevel")}</strong>
            </Typography>
            <Typography variant="body2">
              {service_levels.map((level) => ServiceLevelLabels[level]).join(" & ")}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mt={3}>
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              <strong>{upperCase(t("common:mode"))}</strong>
            </Typography>
            <Status palette="purple" style={{ fontSize: "0.9rem", paddingLeft: 6, paddingRight: 6 }}>
              {t("broker:air")}
            </Status>
          </Box>
          <Box ml={3}>
            <Typography variant="subtitle2" gutterBottom>
              <strong>{t("broker:hazmat")}</strong>
            </Typography>
            {is_hazardous ? (
              <Status palette="red" style={{ fontSize: "0.9rem", paddingLeft: 6, paddingRight: 6 }}>
                {t("broker:yes")}
              </Status>
            ) : (
              t("broker:no")
            )}
          </Box>
        </Box>
      </Box>
      <Accordion defaultExpanded className={classes.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>
            <strong>{t("broker:routingInformation")}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            {hasOriginDoor ? (
              <Box>
                <>
                  <Typography variant="subtitle2" gutterBottom>
                    <strong>{upperCase(t("broker:originAddress"))}</strong>
                  </Typography>
                  <Typography variant="body2">{originDoor?.formatted_long_name}</Typography>
                </>
              </Box>
            ) : null}
            <Box>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:originAirport"))}</strong>
                </Typography>
                {originChanged && (
                  <Tooltip title={originChangedTooltip} arrow placement="top-start">
                    <Icon className={classes.status} as={AccessTime} />
                  </Tooltip>
                )}
              </Box>
              <Typography variant="body2">{originAirportName}</Typography>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:viaAirport"))}</strong>
                </Typography>
                {viaPortIncluded && (
                  <Tooltip title={viaPortTooltip} arrow placement="top-start">
                    <Icon className={classes.status} as={AccessTime} />
                  </Tooltip>
                )}
              </Box>
              <Typography variant="body2">{viaPortName || EMPTY_CELL_HYPHEN}</Typography>
            </Box>
            <Box>
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:destinationAirport"))}</strong>
                </Typography>
                {destinationChanged && (
                  <Tooltip title={destinationChangedTooltip} arrow placement="top-start">
                    <Icon className={classes.status} as={AccessTime} />
                  </Tooltip>
                )}
              </Box>
              <Typography variant="body2">{destinationAirportName}</Typography>
            </Box>
            <Box>
              {hasDestinationDoor ? (
                <>
                  <Typography variant="subtitle2" gutterBottom>
                    <strong>{upperCase(t("broker:destinationAddress"))}</strong>
                  </Typography>
                  <Typography variant="body2">{destinationDoor?.formatted_long_name}</Typography>
                </>
              ) : null}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className={classes.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography>
            <strong>{t("broker:shipmentTerms")}</strong>{" "}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("common:incoterms"))}</strong>
              </Typography>
              <Typography variant="body2">{incoterms_air}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("common:routing"))}</strong>
              </Typography>
              <Typography variant="body2">{getRoutingTypeLabel(routing_type)}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("broker:cargoReady"))}</strong>
              </Typography>
              <Typography variant="body2">
                {cargo_ready_date ? toLuxon(cargo_ready_date).toFormat("MMM dd, yyyy") : ""}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("broker:targetDestinationDelivery"))}</strong>
              </Typography>
              <Typography variant="body2">
                {target_delivery_date ? toLuxon(target_delivery_date).toFormat("MMM dd, yyyy") : EMPTY_CELL_HYPHEN}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className={classes.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography>
            <strong>{t("common:cargoDetails")}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            {map(package_groups, (_item, key) => {
              return (
                <Box key={key}>
                  <Typography variant="subtitle2" gutterBottom>
                    <strong>{t("broker:quoteRequestSidebarAIR.cargoDetails.item", { index: key + 1 })}</strong>
                  </Typography>
                  {summary[key]}
                </Box>
              );
            })}

            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("common:totalWeight"))}</strong>
              </Typography>
              <Typography variant="body2">{formatWeight(total_weight)}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{t("broker:quoteRequestSidebarAIR.cargoDetails.chargeableWeight")}</strong>
              </Typography>
              <Typography variant="body2">{formatWeight(chargeableWeight)}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("common:totalCBM"))}</strong>
              </Typography>
              <Typography variant="body2">{formatCBM(total_volume)}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("common:commodities"))}</strong>
              </Typography>
              <Typography variant="body2">
                {commodities ? formatCommodities(commodities) : EMPTY_CELL_HYPHEN}
              </Typography>
            </Box>

            {is_hazardous && hazardous_goods_details ? (
              <Box
                border={`solid 1px ${portexColor.red500}`}
                borderRadius={4}
                bgcolor="white"
                padding={1}
                marginTop={1}
              >
                <Status palette="red">{t("broker:hazmat")}</Status>
                <Box mt={1} />
                <Typography>
                  {t("broker:details")}: {renderSerializedNotes(hazardous_goods_details)}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className={classes.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
          <Typography>
            <strong>{t("broker:additionalServices")}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("broker:customs"))}</strong>
              </Typography>
              <Typography variant="body2">{getRequiredLabel(include_customs_clearance)}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("broker:insurance"))}</strong>
              </Typography>
              <Typography variant="body2">
                {getRequiredLabel(insurance_required)}{" "}
                {insurance_required && goods_value
                  ? t("broker:valueOfGoodsText", { value: formatUSD(goods_value) })
                  : ""}
              </Typography>
            </Box>
            {notes ? (
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("common:additionalNotes"))}</strong>
                </Typography>
                <Typography variant="body2">{renderSerializedNotes(notes)}</Typography>
              </Box>
            ) : null}
          </Box>
        </AccordionDetails>
      </Accordion>
    </LayoutColumnTwo.Sidebar>
  );
};

export default QuoteRequestSidebarAIR;
