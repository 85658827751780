import { FC } from "react";

import BidsAwardPage from "app/pages/bid-award/BidsAwardPage";
import BidRequestPage from "app/pages/bid-request/BidRequestPage";
import DispatchRequestPage from "app/pages/dispatch-request/DispatchRequestPage";
import LocationsV2Page from "app/pages/locations-v2/LocationsV2Page";
import InsightsV2Page from "app/pages/reports-and-analytics/InsightsV2Page";
import RequestManagementPage from "app/pages/request-management/RequestManagementPage";
import ShipmentsPage from "app/pages/shipments/ShipmentsPage";
import { useTranslation } from "react-i18next";
import { Switch, Redirect, useRouteMatch, Route } from "react-router-dom";

import NotFound404 from "../../components/errors/NotFound404";
import { useUserContext } from "../../hooks/useUserContext";
import PartnersPage from "./pages/partners/PartnersPage";
import QuoteDetailsPage from "./pages/quote-details-page/QuoteDetailsPage";
import QuoteConversationPage from "./pages/quote-request-details/QuoteConversationsPage";
import QuoteRequestDetailsPage from "./pages/quote-request-details/QuoteRequestDetailsPage";
import RequestQuoteRoute from "./pages/request-quote/RequestQuoteRoute";
import SettingsPage from "./pages/settings/SettingsPage";
import { withShipperLayout } from "./utils/withShipperLayout";

const ShipperPages: FC = () => {
  const { t } = useTranslation(["common", "shipper"]);
  const { path } = useRouteMatch();
  const { user } = useUserContext();

  if (!user?.shipper) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route exact path={`${path}`} render={() => <Redirect to={`${path}/requests`} />} />
      <Route path={`${path}/award`} render={() => withShipperLayout(BidsAwardPage, { title: "Bids" })} />
      <Route
        path={`${path}/bids/create`}
        render={() => withShipperLayout(BidRequestPage, { title: t("shipper:bids.createBid") })}
      />
      <Route
        path={`${path}/dispatches/create`}
        render={() => withShipperLayout(DispatchRequestPage, { title: t("shipper:tenderRequests") })}
      />
      <Route
        path={`${path}/request-quote`}
        render={() => withShipperLayout(RequestQuoteRoute, { title: t("shipper:requestQuote") })}
      />
      <Route exact path={`${path}/quotes`} render={() => <Redirect to={`${path}/requests`} />} />
      <Route
        path={`${path}/requests`}
        render={() => withShipperLayout(RequestManagementPage, { title: t("shipper:requests") })}
      />
      <Route
        exact
        path={`${path}/quotes/:quoteRequestId`}
        render={() => withShipperLayout(QuoteRequestDetailsPage, { title: t("shipper:quotes") })}
      />
      <Route
        exact
        path={`${path}/quotes/:quoteRequestId/conversations`}
        render={() => withShipperLayout(QuoteConversationPage)}
      />
      <Route
        exact
        path={`${path}/quotes/:quoteRequestId/:quoteId`}
        render={() => withShipperLayout(QuoteDetailsPage, { title: t("shipper:reviewBooking") })}
      />
      <Route
        path={`${path}/shipments`}
        render={() => withShipperLayout(ShipmentsPage, { title: t("shipper:shipments") })}
      />
      <Route
        exact
        path={`${path}/locations`}
        render={() => withShipperLayout(LocationsV2Page, { title: t("shipper:locations") })}
      />
      <Route
        exact
        path={`${path}/insights`}
        render={() => withShipperLayout(InsightsV2Page, { title: t("shipper:insights") })}
      />
      <Route
        exact
        path={`${path}/partners`}
        render={() => withShipperLayout(PartnersPage, { title: t("shipper:partners") })}
      />
      <Route
        exact
        path={`${path}/settings`}
        render={() => withShipperLayout(SettingsPage, { title: t("shipper:settings") })}
      />
      <Route
        path="*"
        render={() => withShipperLayout(() => <NotFound404 />, { title: t("common:errors.404NotFound") })}
      />
    </Switch>
  );
};

export default ShipperPages;
