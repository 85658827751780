import { ReactElement } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Typography } from "@portex-pro/ui-components";
import { Redirect } from "react-router-dom";
import { useQueryParams, StringParam } from "use-query-params";

import LoginError from "../../components/errors/LoginError";
import Loading from "../../components/Loading";

const LoginPage = (): ReactElement => {
  const { isAuthenticated, isLoading: isAuthLoading, loginWithRedirect } = useAuth0();
  const [{ returnTo, error, error_description: errorDescription, email }] = useQueryParams({
    returnTo: StringParam,
    error: StringParam,
    error_description: StringParam,
    email: StringParam,
  });

  if (error || errorDescription) {
    return (
      <LoginError error={error} errorDescription={errorDescription}>
        <Typography variant="h4" align="center">
          Something went wrong <br /> while you were logging in...
        </Typography>
      </LoginError>
    );
  }

  if (isAuthLoading) {
    return <Loading showPortexLogo />;
  }

  if (isAuthenticated && !isAuthLoading) {
    return <Redirect to={"/"} />;
  }

  loginWithRedirect({
    appState: { returnTo: returnTo },
    initialScreen: "login",
    allowSignUp: false,
    allowLogin: true,
    login_hint: email ?? undefined,
  });
  return <Loading message="Redirecting to Auth0..." showPortexLogo />;
};

export default LoginPage;
