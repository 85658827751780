import { ReactElement } from "react";

import { gql, TypedDocumentNode, useQuery } from "@apollo/client";
import { Box, Container, Paper } from "@portex-pro/ui-components";
import { StringParam, useQueryParam } from "use-query-params";

import { PortexQueryParams, Query } from "../../api/types/generated-types";
import NotFound404 from "../../components/errors/NotFound404";
import Loading from "../../components/Loading";
import { useOnApolloError } from "../../hooks/useOnApolloError";
import { isPortexAuthenticationError } from "../../utils/errors/isPortexAuthenticationError";
import QuoteSubmissionDetails from "../shipper/components/QuoteSubmissionDetails";

const PREVIEW_PUBLIC_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "previewPublicQuoteRequest">> = gql`
  query {
    previewPublicQuoteRequest {
      ...PublicQuoteRequest_QuoteSubmissionDetails
    }
  }
  ${QuoteSubmissionDetails.fragments.PublicQuoteRequest}
`;

const PreviewQuoteSubmission = (): ReactElement => {
  const { onApolloError } = useOnApolloError({ componentName: "QuoteSubmission" });

  const [tempToken] = useQueryParam(PortexQueryParams.TempToken, StringParam);

  const { data, error, loading } = useQuery(PREVIEW_PUBLIC_QUOTE_REQUEST, {
    skip: !tempToken,
    onError: (apolloError) => {
      if (isPortexAuthenticationError(apolloError)) return;
      onApolloError("previewPublicQuoteRequest")(apolloError);
    },
  });

  if (loading) return <Loading />;
  if (!tempToken || error || (!loading && !data?.previewPublicQuoteRequest)) {
    return <NotFound404 />;
  }

  return (
    <Box py={3} overflow="scroll">
      <Container maxWidth="md">
        <Paper className={"Por-outlined-light"} elevation={8}>
          <QuoteSubmissionDetails publicQuoteRequest={data?.previewPublicQuoteRequest} attachmentsList={null} />
        </Paper>
      </Container>
    </Box>
  );
};

export default PreviewQuoteSubmission;
