import { useContext, VFC } from "react";

import { ShipmentPackagingType } from "app/pages/shipments/types/domain";

import { CargoGroupType } from "./api/types/CargoGroupType";
import { CargoGroupContext } from "./CargoGroupProvider";
import HandlingGroup from "./containers/HandlingGroup";
import { selectManyCargoGroups } from "./store/cargoGroupsSlice";
import { useCargoGroupsSliceSelector } from "./store/cargoGroupsStore";
import CargoGroupsView from "./views/CargoGroupsView";

const ContainerCargoGroups: VFC = () => {
  const { topLevelCargoGroupIds, addCargoGroup, updateCargoGroup, removeCargoGroup } = useContext(CargoGroupContext);

  const cargoGroups = useCargoGroupsSliceSelector((state) => selectManyCargoGroups(state, topLevelCargoGroupIds));

  return (
    <CargoGroupsView
      cargoGroups={cargoGroups}
      onAddCargoGroup={() =>
        addCargoGroup(
          {
            cargo_group_type: CargoGroupType.CARGO,
            packaging_type: ShipmentPackagingType.Containers,
            count: 1,
          },
          { isTopLevelCargoGroup: true }
        )
      }
      onDuplicate={(cargoGroup) =>
        addCargoGroup({ ...cargoGroup, handling_units: [], packing_units: [] }, { isTopLevelCargoGroup: true })
      }
      onChangeCargoGroup={updateCargoGroup}
      onRemoveCargoGroup={removeCargoGroup}
      renderHandlingUnits={HandlingGroup}
    />
  );
};

export default ContainerCargoGroups;
