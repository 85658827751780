import { FC, ReactElement, VFC } from "react";

import { gql, ObservableQuery, TypedDocumentNode, useQuery } from "@apollo/client";
import BrokerQuoteRequestAttachmentsListContainer from "app/pages/broker-quote-submission/containers/BrokerQuoteRequestAttachmentsListContainer";
import ChatContainer from "components/chat/ChatContainer";
import { useConversationForQuoteRequest } from "components/chat/hooks/useConversationForQuoteRequest";
import HtmlTitle from "components/HtmlTitle";
import PortexAppBar from "components/PortexAppBar";
import FeaturedBrokerDashboard from "features/featured-brokers/FeaturedBrokerDashboard";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { ModeEnum, ModeQuotes, MODE_QUOTES } from "types/Mode";
import { StringParam, useQueryParam } from "use-query-params";
import ScrollableGridLayout from "utils/layout/ScrollableGridLayout";

import { PortexQueryParams, PublicQuoteRequest, Query } from "../../../api/types/generated-types";
import NotFound404 from "../../../components/errors/NotFound404";
import Loading from "../../../components/Loading";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import { useUserContext } from "../../../hooks/useUserContext";
import { isNotAuthorizedError } from "../../../utils/errors/isNotAuthorizedError";
import { isPortexAuthenticationError } from "../../../utils/errors/isPortexAuthenticationError";
import QuoteSubmissionDetails from "../../shipper/components/QuoteSubmissionDetails";
import QuoteSubmissionAIR from "./components/quote-submission-air/components/QuoteSubmissionAIR";
import {
  ContextProviderQuoteSubmissionAIR,
  useContextQuoteSubmissionAIR,
} from "./components/quote-submission-air/hooks/useContextQuoteSubmissionAIR";
import QuoteSubmissionFCL from "./components/quote-submission-fcl/components/QuoteSubmissionFCL";
import {
  ContextProviderQuoteSubmissionFCL,
  useContextQuoteSubmissionFCL,
} from "./components/quote-submission-fcl/hooks/useContextQuoteSubmissionFCL";
import QuoteSubmissionFTL from "./components/QuoteSubmissionFTL";
import QuoteSubmissionLTL from "./components/QuoteSubmissionLTL";

const GET_PUBLIC_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "getPublicQuoteRequest">> = gql`
  query {
    getPublicQuoteRequest {
      shipper_id
      deadline_respond_at
      guid
      shipper_name
      state
      ...PublicQuoteRequest_QuoteSubmissionDetails
    }
  }
  ${QuoteSubmissionDetails.fragments.PublicQuoteRequest}
`;

interface SubmissionPagesProps {
  publicQuoteRequest: PublicQuoteRequest;
  refetchPublicQuoteRequest: ObservableQuery<Pick<Query, "getPublicQuoteRequest">>["refetch"];
}

const MaybeFclOrAirContextProviders: FC<SubmissionPagesProps> = ({
  children,
  publicQuoteRequest,
  refetchPublicQuoteRequest,
}) => {
  const mode = publicQuoteRequest.mode as unknown as ModeQuotes;
  if (mode === ModeEnum.FCL) {
    return (
      <ContextProviderQuoteSubmissionFCL
        publicQuoteRequest={publicQuoteRequest}
        refetchPublicQuoteRequest={refetchPublicQuoteRequest}
      >
        {children}
      </ContextProviderQuoteSubmissionFCL>
    );
  }

  if (mode === ModeEnum.AIR) {
    return (
      <ContextProviderQuoteSubmissionAIR
        publicQuoteRequest={publicQuoteRequest}
        refetchPublicQuoteRequest={refetchPublicQuoteRequest}
      >
        {children}
      </ContextProviderQuoteSubmissionAIR>
    );
  }

  return <>{children}</>;
};

const SubmissionPagesByMode: VFC<SubmissionPagesProps> = ({
  publicQuoteRequest: qr,
  refetchPublicQuoteRequest: refetch,
}) => {
  const { t } = useTranslation(["broker"]);
  const ctxFCL = useContextQuoteSubmissionFCL();
  const ctxAIR = useContextQuoteSubmissionAIR();
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
  const enableBrokerQuotesPage = useLDFlag("enableBrokerQuotesPage");
  const releaseBrokerChat = useLDFlag("releaseBrokerChat");
  const releaseQuoteRequestFiles = useLDFlag("releaseQuoteRequestFiles");

  const { conversationId } = useConversationForQuoteRequest({ quoteRequestGuid: qr.guid });

  const mode = qr.mode as unknown as ModeQuotes;
  const isFclOrAir = mode === ModeEnum.FCL || mode === ModeEnum.AIR;
  const showLeftContentOnly = isFclOrAir && (ctxFCL.hasStartedSubmitting.value || ctxAIR.hasStartedSubmitting.value);

  const attachmentsList = !!releaseQuoteRequestFiles && (
    <BrokerQuoteRequestAttachmentsListContainer quoteRequestGuid={qr.guid} />
  );

  const leftContent = (
    <>
      {mode === "FTL" && (
        <QuoteSubmissionFTL
          publicQuoteRequest={qr}
          refetchPublicQuoteRequest={refetch}
          attachmentsList={attachmentsList}
        />
      )}
      {mode === "LTL" && (
        <QuoteSubmissionLTL
          publicQuoteRequest={qr}
          refetchPublicQuoteRequest={refetch}
          attachmentsList={attachmentsList}
        />
      )}
      {mode === "FCL" && <QuoteSubmissionFCL attachmentsList={attachmentsList} />}
      {mode === "AIR" && <QuoteSubmissionAIR attachmentsList={attachmentsList} />}
    </>
  );

  const rightContent = (
    <div className="flex flex-col gap-5 grow w-full">
      {!!releaseBrokerChat && !!conversationId && (
        <div className="min-h-[600px] flex-auto">
          <ChatContainer conversationId={conversationId} />
        </div>
      )}
      <FeaturedBrokerDashboard quoteRequestGuid={qr.guid} />
    </div>
  );

  /** @todo Remove these variations once broker chat is released to users and the release flag is deprecated */
  const withBrokerChatReleased = (
    <>
      {showLeftContentOnly ? (
        leftContent
      ) : (
        <ScrollableGridLayout leftContent={leftContent} rightContent={rightContent} />
      )}
    </>
  );
  const withoutBrokerChatReleased = (
    <>
      {showLeftContentOnly ? (
        leftContent
      ) : (
        <div className="flex flex-col w-full mx-auto my-5 overflow-auto">
          <div className="flex flex-col gap-y-5 self-center sm:max-w-[80%] lg:max-w-[1024px]">
            {leftContent}
            {rightContent}
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      <HtmlTitle title={t("broker:quoteSubmission.htmlTitle", { shipper_name: qr.shipper_name })} />
      <PortexAppBar
        shipperName={qr.shipper_name}
        useMarketingUrl
        hidePortexLogo={!!enableBrokerAppBar}
        backButtonTo={!!enableBrokerAppBar && !!enableBrokerQuotesPage && !showLeftContentOnly && `/broker/quotes`}
      />
      {!!releaseBrokerChat ? withBrokerChatReleased : withoutBrokerChatReleased}
    </>
  );
};

const QuoteSubmissionPage = (): ReactElement => {
  const { t } = useTranslation("broker");
  const { onApolloError } = useOnApolloError({ componentName: "QuoteSubmissionLanding" });
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading } = useUserContext();
  const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");

  const [tempToken] = useQueryParam(PortexQueryParams.TempToken, StringParam);

  const enqueueExpiredSnack = () => {
    enqueueSnackbar(t("quoteSubmissionPage_message"), {
      variant: "warning",
      preventDuplicate: true,
      persist: true,
    });
  };

  const POLL_INTERVAL_SECONDS = 60; // 1 minute
  const {
    data,
    error,
    loading: publicQuoteRequestLoading,
    refetch,
  } = useQuery(GET_PUBLIC_QUOTE_REQUEST, {
    skip: !tempToken,
    onError: (apolloError) => {
      if (isPortexAuthenticationError(apolloError) || isNotAuthorizedError(apolloError)) {
        enqueueExpiredSnack();
        return;
      }
      onApolloError("getPublicQuoteRequest")(apolloError);
    },
    pollInterval: POLL_INTERVAL_SECONDS * 1000,
  });

  const loading = publicQuoteRequestLoading || isLoading;

  const NotFound = <NotFound404 showAppBar={!enableBrokerAppBar} useMarketingUrl />;

  if (loading) return <Loading showPortexLogo />;
  if (!tempToken || error || (!loading && !data?.getPublicQuoteRequest)) {
    if (tempToken) enqueueExpiredSnack();
    return NotFound;
  }

  if (!data?.getPublicQuoteRequest?.mode) {
    return NotFound;
  }

  const mode = data.getPublicQuoteRequest.mode as unknown as ModeQuotes;
  if (!MODE_QUOTES.includes(mode)) {
    return NotFound;
  }

  return (
    <MaybeFclOrAirContextProviders publicQuoteRequest={data.getPublicQuoteRequest} refetchPublicQuoteRequest={refetch}>
      <SubmissionPagesByMode publicQuoteRequest={data.getPublicQuoteRequest} refetchPublicQuoteRequest={refetch} />
    </MaybeFclOrAirContextProviders>
  );
};

export default QuoteSubmissionPage;
