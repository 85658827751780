import { FC } from "react";

import { Box } from "@portex-pro/ui-components";
import { ChatMessage, DraftAttachment } from "types/Chat";

import { useChatContentStyles } from "./chatStyles";
import MessagesListView from "./Messages/MessagesListView";
import NewMessageInputContainer from "./NewChatMessageInput/NewMessageInputContainer";

interface ChatContentViewProps {
  messages: ChatMessage[];
  addMessage: (message: string) => void;
  isSending?: boolean;
  draftAttachments: DraftAttachment[];
  hideFileAttachments?: boolean;
}

const ChatContentView: FC<ChatContentViewProps> = ({
  messages,
  addMessage,
  isSending = false,
  draftAttachments,
  hideFileAttachments = false,
}) => {
  const classes = useChatContentStyles();

  return (
    <Box className={classes.wrapper}>
      <MessagesListView messages={messages} isSending={isSending} />
      <NewMessageInputContainer
        onSendMessage={addMessage}
        isSending={isSending}
        draftAttachments={draftAttachments}
        hideFileAttachments={hideFileAttachments}
      />
    </Box>
  );
};

export default ChatContentView;
