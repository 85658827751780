import { VFC } from "react";

import { Paper } from "@portex-pro/ui-components";
import { isQuoteRequestStateTerminal } from "utils/isQuoteRequestStateTerminal";

import QuoteSubmissionClosed from "../../QuoteSubmissionClosed";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import QuoteSubmissionDetailsFCL from "./QuoteSubmissionDetailsFCL";
import QuoteSubmissionFlowFCL from "./QuoteSubmissionFlowFCL";

interface QuoteSubmissionFCLProps {
  attachmentsList: React.ReactElement | false | null;
}

const QuoteSubmissionFCL: VFC<QuoteSubmissionFCLProps> = ({ attachmentsList }) => {
  const { hasStartedSubmitting, publicQuoteRequest } = useContextQuoteSubmissionFCL();
  const isTerminalState = isQuoteRequestStateTerminal(publicQuoteRequest.state);

  return hasStartedSubmitting.value ? (
    <QuoteSubmissionFlowFCL />
  ) : (
    <>
      {isTerminalState ? <QuoteSubmissionClosed publicQuoteRequest={publicQuoteRequest} /> : null}
      <Paper className={"Por-outlined-light"} elevation={8}>
        <QuoteSubmissionDetailsFCL publicQuoteRequest={publicQuoteRequest} attachmentsList={attachmentsList} />
      </Paper>
    </>
  );
};

export default QuoteSubmissionFCL;
