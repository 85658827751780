import { ChatParticipant, ChatParticipantsRawResponse } from "types/Chat";

export const mapParticipants = (participants: ChatParticipantsRawResponse[]): ChatParticipant[] =>
  participants.map((participant) => ({
    isInternal: participant.isInternal,
    displayName: participant.displayName,
    email: participant.email,
    fullName: participant.fullName,
    companyName: participant.companyName,
  }));
