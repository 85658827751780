import { VFC, FC } from "react";

import { FormControl, FormControlLabel, Radio, RadioGroup } from "@portex-pro/ui-components";
import PortexDialog from "components/PortexDialog";
import SelectDateView from "components/SelectDateView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

const FilterRow: FC<{ name: string }> = ({ name, children }) => {
  return (
    <div className="flex w-full items-center space-y-2">
      <div className="w-1/3">
        <Text size="small" weight="bold">
          {name}
        </Text>
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
};

interface RequestsListFiltersDialogViewProps {
  isOpen: boolean;
  onClose: () => void;
  onChangePickup: ({ from, to }: { from: string | undefined; to: string | undefined }) => void;
  onChangeDelivery: ({ from, to }: { from: string | undefined; to: string | undefined }) => void;
  pickupFrom: string | undefined;
  pickupTo: string | undefined;
  deliveryFrom: string | undefined;
  deliveryTo: string | undefined;
  ownersSearch: JSX.Element;
  disableDateSelection: boolean;
  onlyUnseenNotifications: boolean;
  onChangeOnlyUnseenNotifications: (value: boolean) => void;
}

const RequestsListFiltersDialogView: VFC<RequestsListFiltersDialogViewProps> = ({
  isOpen,
  onClose,
  onChangePickup,
  onChangeDelivery,
  pickupFrom,
  pickupTo,
  deliveryFrom,
  deliveryTo,
  ownersSearch,
  disableDateSelection,
  onlyUnseenNotifications,
  onChangeOnlyUnseenNotifications,
}) => {
  const { t } = useTranslation("requestManagement");

  return (
    <PortexDialog
      open={isOpen}
      onClose={onClose}
      title={t("requestList_filters_dialog_title")}
      confirmButtonCopy={t("requestList_filters_dialog_confirm")}
      onClickConfirm={onClose}
    >
      <div className="flex flex-col my-5 mx-8 space-y-4">
        <FilterRow name={t("requestList_filters_dialog_pickup")}>
          <SelectDateView
            from={pickupFrom}
            to={pickupTo}
            onChange={onChangePickup}
            divProps={{ className: "w-full" }}
            TextInputProps={{ disabled: disableDateSelection }}
          />
        </FilterRow>
        <FilterRow name={t("requestList_filters_dialog_delivery")}>
          <SelectDateView
            from={deliveryFrom}
            to={deliveryTo}
            onChange={onChangeDelivery}
            divProps={{ className: "w-full" }}
            TextInputProps={{ disabled: disableDateSelection }}
          />
        </FilterRow>
        <FilterRow name={t("requestList_filters_dialog_owner")}>{ownersSearch}</FilterRow>

        <FilterRow name={t("requestList_filters_dialog_notifications")}>
          <FormControl style={{ display: "flex", flexDirection: "row" }}>
            <RadioGroup value={onlyUnseenNotifications} style={{ display: "flex", flexDirection: "row" }}>
              <FormControlLabel
                value={false}
                label={t("requestList_filters_dialog_notifications_all")}
                control={<Radio onClick={() => onChangeOnlyUnseenNotifications(false)} />}
              />
              <FormControlLabel
                value={true}
                label={t("requestList_filters_dialog_notifications_unread")}
                control={<Radio onClick={() => onChangeOnlyUnseenNotifications(true)} />}
              />
            </RadioGroup>
          </FormControl>
        </FilterRow>
      </div>
    </PortexDialog>
  );
};

export default RequestsListFiltersDialogView;
