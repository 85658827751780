import { VFC } from "react";

import ChatContainer from "components/chat/ChatContainer";
import { useNotificationWatcher } from "hooks/notifications/useNotificationWatcher";

const LoadStatusPageChatTab: VFC<{ conversationId: number; shipmentId: number }> = ({ shipmentId, conversationId }) => {
  useNotificationWatcher([`shipment:${shipmentId}`]);

  return (
    <div className="overflow-auto p-2 h-full">
      <ChatContainer conversationId={conversationId} />
    </div>
  );
};

export default LoadStatusPageChatTab;
