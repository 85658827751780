import { VFC } from "react";

import Frame from "components/Frame";
import ShipmentDetailsCargoDetailsLtl from "features/shipments/cargo-details/ShipmentDetailsCargoDetailsView";
import { useShipmentDetails } from "features/shipments/provider/ShipmentDetailsProvider";
import { useTranslation } from "react-i18next";

import { ShipmentMode } from "../types/domain";
import ShipmentDetailsCargoDetailsDrayage from "./ShipmentDetailsCargoDetailsDrayage";
import ShipmentDetailsCargoDetailsLtlPartial from "./ShipmentDetailsCargoDetailsLtlPartial";

const shipmentModesWithCargoDetails = {
  [ShipmentMode.Ltl]: ShipmentMode.Ltl,
  [ShipmentMode.Drayage]: ShipmentMode.Drayage,
  [ShipmentMode.LtlPartial]: ShipmentMode.LtlPartial,
};

const ShipmentDetailsCargoDetails: VFC = () => {
  const { shipment } = useShipmentDetails();
  const { t } = useTranslation(["shipments"]);

  if (!Object.keys(shipmentModesWithCargoDetails).includes(shipment.mode)) {
    return null;
  }

  return (
    <Frame title={t("shipments:shipmentDetails_cargoDetails_title")}>
      {shipment.mode === shipmentModesWithCargoDetails.LTL && <ShipmentDetailsCargoDetailsLtl />}
      {shipment.mode === shipmentModesWithCargoDetails.DRAYAGE && <ShipmentDetailsCargoDetailsDrayage />}
      {shipment.mode === shipmentModesWithCargoDetails.LTL_PARTIAL && <ShipmentDetailsCargoDetailsLtlPartial />}
    </Frame>
  );
};

export default ShipmentDetailsCargoDetails;
