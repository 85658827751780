import { VFC } from "react";

import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";
import { ModeEnum, ModeRfp } from "types/Mode";
import OptionalMaybe from "types/OptionalMaybe";
import mapPricingFrequencyToI18nKey from "utils/rfp/mapPricingFrequencyToI18nKey";

interface PricingFrequencyViewProps {
  value: OptionalMaybe<ContractPricingFrequency>;
  onChange: (val: ContractPricingFrequency) => void;
  enableRfpBulkSpotQuote?: boolean;
  mode?: ModeRfp;
  placeholder?: string;
}

const PricingFrequencyView: VFC<PricingFrequencyViewProps> = ({
  value,
  onChange,
  enableRfpBulkSpotQuote,
  mode,
  placeholder,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <FormSelectView
      formControlProps={{ fullWidth: true, margin: "normal", required: true }}
      placeholder={placeholder}
      items={Object.values(ContractPricingFrequency).filter((value) => {
        if (value === ContractPricingFrequency.BULK_SPOT_QUOTE && !enableRfpBulkSpotQuote) {
          return false;
        }

        /** @todo PR-2110: currently, only BULK_SPOT_QUOTE is supported. We'll eventually support everything once we implement dispatching for this mode */
        if (mode === ModeEnum.LTL_PARTIAL && value !== ContractPricingFrequency.BULK_SPOT_QUOTE) {
          return false;
        }

        return true;
      })}
      value={value ?? undefined}
      onChange={onChange}
      getItemCopy={(item) => {
        const i18nKey = mapPricingFrequencyToI18nKey[item];
        const copy = t(`common:pricingFrequency_values.${i18nKey}`);
        return copy;
      }}
    />
  );
};

export default PricingFrequencyView;
