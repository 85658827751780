import { FC } from "react";

import { Info } from "@material-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Paper,
  Tooltip,
  Typography,
  portexColor,
} from "@portex-pro/ui-components";
import AttachFileFromButtonContainer from "components/file-uploads/AttachFileFromButtonContainer";
import FileList from "components/file-uploads/FileList";
import Text from "components/Text";
import ThrottledTextInput from "components/ThrottledTextInput";
import ButtonBookNow from "pages/shipper/components/ButtonBookNow";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ModeEnum, ModeQuotes } from "types/Mode";
import { formatUSD } from "utils/formatCurrency";

type BookNowViewProps = {
  bookingNotes: string;
  brokerName: string;
  canceledAt: string;
  closedAt: string;
  confirmed: boolean;
  disableBookNow: boolean;
  isExpired: boolean;
  isQuoteRequestBooked: boolean;
  isQuoteRequestCanceled: boolean;
  isQuoteRequestClosed: boolean;
  onChangeBookingNotes: (notes: string) => void;
  onChangeConfirmed: (confirmed: boolean) => void;
  onClickBookNow: () => void;
  quoteBooked: boolean;
  quoteCanceled: boolean;
  quoteTotal: number;
  shipmentId?: number | null;
  mode: ModeQuotes;
};

const BookNowView: FC<BookNowViewProps> = ({
  bookingNotes,
  brokerName,
  canceledAt,
  closedAt,
  confirmed,
  disableBookNow,
  isExpired,
  isQuoteRequestBooked,
  isQuoteRequestCanceled,
  isQuoteRequestClosed,
  onChangeBookingNotes,
  onChangeConfirmed,
  onClickBookNow,
  quoteBooked,
  quoteCanceled,
  quoteTotal,
  shipmentId,
  mode,
}) => {
  const quoteTotalAmount = formatUSD(quoteTotal);
  const allowedShipmentMode = mode === ModeEnum.FTL || mode === ModeEnum.LTL || mode === ModeEnum.DRAYAGE;
  const { t } = useTranslation("common");

  return (
    <Paper variant={"outlined"}>
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              disabled={disableBookNow}
              className={"Por-confirmation"}
              control={
                <Checkbox checked={quoteBooked || confirmed} onChange={(e) => onChangeConfirmed(e.target.checked)} />
              }
              label="I confirm that I have read the quote details."
            />
          </Grid>
          <Grid item xs={12}>
            <ThrottledTextInput
              disabled={disableBookNow}
              label={
                <>
                  Additional Notes
                  <Box display="inline" ml={0.5} />
                  <Tooltip
                    title={`After you book this quote, your partner, ${brokerName}, will see what you enter here in the email that is sent.`}
                    arrow
                    placement="top"
                  >
                    <Icon as={Info} fontSize="small" />
                  </Tooltip>
                </>
              }
              placeholder={`Please enter any additional details regarding this quote. ${brokerName} will see anything you enter here.`}
              multiline
              rows={4}
              margin="normal"
              value={bookingNotes}
              onChange={onChangeBookingNotes}
            />

            <Text size="medium" weight="bold">
              {t("attachQuoteFiles_message")}
            </Text>
            <AttachFileFromButtonContainer
              showDropZone={false}
              buttonCopy={t("browseFiles")}
              ButtonProps={{ variant: "text", style: { justifyContent: "left", marginTop: "8px" }, size: "small" }}
            />
            <FileList FileListViewProps={{ BoxProps: { style: { marginTop: "8px" } } }} />

            <Box height={16} width={"100%"} />
            {quoteBooked && allowedShipmentMode && !!shipmentId && (
              <>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: portexColor.blue500,
                    color: "white",
                    padding: "0.5rem 2rem",
                  }}
                  component={Link}
                  to={`/shipper/shipments/${shipmentId || ""}`}
                >
                  {t("viewShipmentDetails")}
                </Button>
                <Box height={16} width={"100%"} />
              </>
            )}
            {quoteBooked ? (
              <ButtonBookNow bookNowVariant="booked" style={{ pointerEvents: "none" }} />
            ) : isQuoteRequestBooked ? (
              <ButtonBookNow bookNowVariant="booked-by-other" />
            ) : isQuoteRequestClosed ? (
              <ButtonBookNow bookNowVariant="closed" closedAt={closedAt} />
            ) : isQuoteRequestCanceled ? (
              <>
                {quoteCanceled ? (
                  <ButtonBookNow bookNowVariant="canceled" canceledAt={canceledAt} />
                ) : (
                  <ButtonBookNow bookNowVariant="canceled-by-other" />
                )}
              </>
            ) : isExpired ? (
              <ButtonBookNow disabled={disableBookNow} bookNowVariant="expired" onClick={onClickBookNow} />
            ) : (
              <ButtonBookNow disabled={disableBookNow} onClick={onClickBookNow} />
            )}
            <Box textAlign="center" mt="0.5rem">
              <Typography variant="subtitle1">
                <strong>Total Amount: {quoteTotalAmount}</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default BookNowView;
