import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { useEffect } from "react";

import { useGetShipperDispatchQuery } from "api/rest/dispatches";
import { useRequestStatusUpdateMutation } from "api/rest/load-status-updates/requestStatusUpdate";
import { useGetShipmentDetailsQuery } from "api/rest/shipments/shipmentsApi";
import LoadActivityView from "components/loads/LoadActivityView";
import withAsync from "components/withAsync";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import useLDFlag from "hooks/useLDFlag";
import compact from "lodash/compact";
import first from "lodash/first";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";

import { useLoadStatusUpdate } from "../../hooks/useLoadStatusUpdate";
import {
  useSetIsEditLoadStatusDrawerOpen,
  useSetIsShareShipmentStatusDialogOpen,
  useSetSelectedLoadId,
} from "../../store/shipmentManagementSlice";
import { useShipmentsSliceSelector } from "../../store/shipmentsStore";
import { useSetSelectedLoad } from "../../store/shipperLoadStatusSlice";

const LoadActivityContainer = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();
    const isDocumentVisible = useDocumentVisibility();
    return useGetShipmentDetailsQuery(
      { urlParams: { shipmentId } },
      { pollingInterval: isDocumentVisible ? oneMinuteInMilliseconds * 2 : undefined } // It's possible the tracking_link may be updated; we can poll here for it so the user doesn't need to refresh
    );
  },
  Component: ({ loadedData }) => {
    const shipment = loadedData.data;
    const isLoadingShipment = loadedData.isFetching || loadedData.isLoading;
    const selectedLoadId = useShipmentsSliceSelector((state) => state.shipmentManagementSlice.selectedLoadId);
    const setSelectedLoadId = useSetSelectedLoadId();
    const [loadIdQueryParam, setLoadIdQueryParam] = useQueryParam(
      "loadId",
      withDefault(
        NumberParam,
        shipment.trucks?.find((truck) => truck.id === selectedLoadId)?.id || first(shipment.trucks)?.id
      )
    );
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("loads");
    const enableShipmentReadOnlyLinkSharing = useLDFlag("enableShipmentReadOnlyLinkSharing");
    const setIsShareShipmentStatusDialogOpen = useSetIsShareShipmentStatusDialogOpen();
    const setIsEditLoadStatusDrawerOpen = useSetIsEditLoadStatusDrawerOpen();
    const setSelectedLoad = useSetSelectedLoad();

    const loads = compact(shipment.trucks)
      .map((truck) => ({ id: truck.id ?? 0, referenceNumber: truck.referenceNumber, trackingLink: truck.trackingLink }))
      .filter(({ id }) => !!id);

    const [requestStatusUpdate, requestStatusUpdateMutation] = useRequestStatusUpdateMutation();
    const dispatchQuery = useGetShipperDispatchQuery(
      { urlParams: { requestId: shipment.sourceType === "dispatch" ? shipment.dispatchRequest.id : -1 } },
      { skip: shipment.sourceType !== "dispatch" || !shipment.dispatchRequest }
    );

    const handleClickRequestUpdate = async () => {
      if (selectedLoadId) {
        try {
          await requestStatusUpdate({ urlParams: { loadId: selectedLoadId } }).unwrap();
          enqueueSnackbar(t("requestStatusUpdate_success"), { variant: "success" });
        } catch (e) {
          enqueueSnackbar(t("requestStatusUpdate_error"), { variant: "error" });
        }
      }
    };

    useEffect(() => {
      if (loadIdQueryParam !== selectedLoadId) {
        setSelectedLoadId(loadIdQueryParam);
        setSelectedLoad({ loadId: loadIdQueryParam, loads });
      }
    }, [loadIdQueryParam, selectedLoadId, loads, setSelectedLoad, setSelectedLoadId]);

    return (
      <LoadActivityView
        shipment={shipment}
        dispatchRequest={dispatchQuery.data?.data}
        selectedLoadId={selectedLoadId}
        onChangeLoad={setLoadIdQueryParam}
        showOnClickShareStatus={!!enableShipmentReadOnlyLinkSharing}
        showOnClickRequestUpdate={true}
        onClickShareStatus={() => setIsShareShipmentStatusDialogOpen(true)}
        onClickRequestUpdate={handleClickRequestUpdate}
        requestUpdateLoading={requestStatusUpdateMutation.isLoading}
        useGetLoadStatusUpdate={useLoadStatusUpdate}
        showInternalShipperNotes={true}
        onClickEditStatus={(stopId) => setIsEditLoadStatusDrawerOpen({ open: true, stopId })}
        isLoadingShipment={isLoadingShipment}
      />
    );
  },
});

export default LoadActivityContainer;
