import { makeStyles, portexColor } from "@portex-pro/ui-components";

const useFeaturedBrokerStyles = makeStyles(() => ({
  featuredBroker: {
    border: "3px solid",
    borderColor: portexColor.gold500,
    backgroundColor: portexColor.gold100,
  },
  featuredPrimaryButton: {
    backgroundColor: portexColor.gold400,
    color: "black",
    "&:hover": {
      backgroundColor: "gold",
      color: "black",
    },
  },
  featuredSecondaryButton: {
    whiteSpace: "nowrap",
    width: "100%",
    padding: "0.5rem 2rem",
    border: "3px solid gold",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: portexColor.gold100,
      border: "3px solid gold",
      color: "black",
    },
  },
}));

export default useFeaturedBrokerStyles;
