import { VFC } from "react";

import { FilterListOutlined, LocalGasStation as FuelIcon } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import classNames from "classnames";
import FilterButtonView from "components/FilterButtonView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import getContactName from "utils/getContactName";

import { RequestManagementIndexSliceState } from "../../store/requestManagementIndexSlice";

interface RequestsListFiltersViewProps {
  onClickMoreFilters: () => void;
  onClickUpdateFuel: () => void;
  showUpdateFuelButton?: boolean;
  isUpdateFuelButtonSelected?: boolean;

  pickupFromFilter: RequestManagementIndexSliceState["pickupFromFilter"];
  deliveryFromFilter: RequestManagementIndexSliceState["deliveryFromFilter"];
  ownersFilter: RequestManagementIndexSliceState["ownersFilter"];
  onDeleteOwnerFilter: (ownerId: number) => void;
  onDeletePickupFilter: () => void;
  onDeleteDeliveryFilter: () => void;

  onlyUnseenNotifications: RequestManagementIndexSliceState["onlyUnseenNotifications"];
  onDeleteOnlyUnseenNotifications: () => void;
}

const RequestsListFiltersView: VFC<RequestsListFiltersViewProps> = ({
  onClickMoreFilters,
  onClickUpdateFuel,
  showUpdateFuelButton = false,
  isUpdateFuelButtonSelected = false,

  pickupFromFilter,
  deliveryFromFilter,
  onDeletePickupFilter,
  onDeleteDeliveryFilter,

  ownersFilter,
  onDeleteOwnerFilter,

  onlyUnseenNotifications,
  onDeleteOnlyUnseenNotifications,
}) => {
  const { t } = useTranslation("requestManagement");

  return (
    <>
      <Button variant="outlined" color="primary" startIcon={<FilterListOutlined />} onClick={onClickMoreFilters}>
        {t("requestList_filterbar_more_filters_button")}
      </Button>
      {showUpdateFuelButton && (
        <span
          className={classNames(
            isUpdateFuelButtonSelected ? "bg-blue-100" : "hover:bg-blue-100 hover:text-brandBlue",
            "text-brandBlue"
          )}
        >
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<FuelIcon />}
            onClick={onClickUpdateFuel}
            style={{ background: "inherit" }}
          >
            {t("requestList_filterbar_update_fuel_button")}
          </Button>
        </span>
      )}
      <FilterButtonView
        hidden={!pickupFromFilter}
        content={t("requestList_filterbar_filter_pickup", {
          date: DateTime.fromISO(pickupFromFilter ?? "").toLocaleString({ month: "short", day: "numeric" }),
        })}
        onDelete={onDeletePickupFilter}
      />
      <FilterButtonView
        hidden={!deliveryFromFilter}
        content={t("requestList_filterbar_filter_delivery", {
          date: DateTime.fromISO(deliveryFromFilter ?? "").toLocaleString({ month: "short", day: "numeric" }),
        })}
        onDelete={onDeleteDeliveryFilter}
      />
      {ownersFilter.map((owner) => (
        <FilterButtonView
          hidden={false}
          content={t("requestList_filterbar_filter_owner", { name: getContactName(owner) })}
          onDelete={() => onDeleteOwnerFilter(owner.id)}
        />
      ))}
      <FilterButtonView
        hidden={!onlyUnseenNotifications}
        content={t("requestList_filterbar_filter_notifications")}
        onDelete={onDeleteOnlyUnseenNotifications}
      />
    </>
  );
};

export default RequestsListFiltersView;
