import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { Box, Button, Container, portexColor } from "@portex-pro/ui-components";
import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import Loading from "components/Loading";
import LoadTypeChip from "components/LoadTypeChip";
import ModeIcon from "components/ModeIcon";
import RowListView from "components/RowListView";
import SummaryPaperView from "components/SummaryPaperView";
import TitleSummaryWithIconView from "components/TitleSummaryWithIconView";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { renderSerializedNotes } from "utils/renderSerializedNotes";
import mapPricingFrequencyToI18nKey from "utils/rfp/mapPricingFrequencyToI18nKey";
import getSizeStr from "utils/styles/getSizeStr";

import ContractTrailerTypeWithIconView from "../../../../components/ContractTrailerTypeContainer";
import { useBidSubmitSliceSelector } from "../store/bidSubmitStore";
import { BidSubmitPages, useSetCurrentPage } from "../store/bidSubmitUiSlice";
import BrokerAttachmentsListContainer from "./BrokerAttachmentsListContainer";
import BrokerBidSubmissionClosedView from "./BrokerBidSubmissionClosedView";

const BrokerBidSummaryContainer: VFC = () => {
  const { t } = useTranslation(["broker", "common"]);
  const currentPage = useBidSubmitSliceSelector((state) => state.bidSubmitUiSlice.currentPage);
  const setCurrentPage = useSetCurrentPage();
  const { data: contract, isLoading } = useGetBrokerBidContractQuery();

  const handleClickSubmit = () => setCurrentPage(BidSubmitPages.Review);

  if (currentPage !== BidSubmitPages.Overview) {
    return null;
  }

  if (isLoading || !contract) {
    return <Loading />;
  }
  return (
    <Box py={2} bgcolor={portexColor.grey50} overflow="auto">
      <Container maxWidth="md">
        {!contract.is_biddable && <BrokerBidSubmissionClosedView shipperName={contract.shipper.name} />}
      </Container>
      <Container maxWidth="md">
        <SummaryPaperView
          header={
            <TitleSummaryWithIconView
              icon={<ModeIcon svgProps={{ width: "100%", height: "100%" }} mode={contract.mode} />}
              title={t("broker:bidSubmit.bid")}
              desciption={contract.portex_id}
            />
          }
          description={
            <RowListView
              rows={[
                {
                  label: t("broker:bidSubmit.shippersName"),
                  value: contract.shipper.name,
                },
                {
                  label: t("broker:bidSubmit.numberOfLanes"),
                  value: contract.lanes_count,
                },
                {
                  label: t("broker:bidSubmit.bidDeadline"),
                  value: DateTime.fromISO(contract.deadline).toFormat("LLLL d"),
                },
              ]}
            />
          }
          bottomActions={
            <Button
              variant="contained"
              color="primary"
              style={{ padding: `${getSizeStr(16)} ${getSizeStr(32)}` }}
              onClick={handleClickSubmit}
            >
              {t("broker:bidSubmit.submitBidsPerLane")}
            </Button>
          }
          PaperProps={{
            variant: "outlined",
          }}
          ColumnListViewProps={{
            rows: [
              {
                label: t("common:attachments"),
                value: BrokerAttachmentsListContainer,
              },
              {
                label: t("broker:mode"),
                value: (
                  <LoadTypeChip
                    mode={contract.mode}
                    verbose={contract.mode === "FTL"}
                    textProps={{ size: "medium", weight: "bold" }}
                  />
                ),
                BoxProps: { style: { alignItems: "center" } },
              },
              {
                label: t("broker:trailer"),
                value: <ContractTrailerTypeWithIconView contract={contract} />,
              },
              {
                label: t("broker:lanes"),
                value: contract.lanes_count,
              },
              {
                label: t("broker:bidSubmit.bidDeadline"),
                value: DateTime.fromISO(contract.deadline).toFormat("LLL dd, yyyy"),
              },
              {
                label: t("broker:bidSubmit.contractPeriod"),
                value: t("broker:bidSubmit.contractPeriod_format", {
                  date1: DateTime.fromISO(contract.start).toFormat("cccc, LLL dd"),
                  date2: DateTime.fromISO(contract.end).toFormat("cccc, LLL dd"),
                }),
                hide: contract.pricing_frequency === ContractPricingFrequency.BULK_SPOT_QUOTE,
              },
              {
                label: t("common:pricingFrequency"),
                value: contract.pricing_frequency
                  ? t(`common:pricingFrequency_values.${mapPricingFrequencyToI18nKey[contract.pricing_frequency]}`)
                  : EM_DASH,
              },
              {
                label: t("broker:rates"),
                value: contract.include_fuel
                  ? t("broker:bidSubmit.includeFuelTrue")
                  : t("broker:bidSubmit.includeFuelFalse"),
              },
              {
                label: capitalize(t("common:commodities")),
                value: contract.commodities ? contract.commodities.split(",").join(", ") : EM_DASH,
              },
              {
                label: t("common:hazardousCommodities"),
                value: <Box color="red">{t("common:containsHazardous")}</Box>,
                hide: !contract.is_hazardous,
              },
              {
                label: t("common:hazardousDescription"),
                value: renderSerializedNotes(contract.hazardous_goods_details ?? ""),
                hide: !contract.hazardous_goods_details,
              },
              {
                label: t("common:additionalNotes"),
                value: !!contract.notes ? renderSerializedNotes(contract.notes) : EM_DASH,
              },
            ],
          }}
        />
      </Container>
    </Box>
  );
};

export default BrokerBidSummaryContainer;
