import { useState, VFC } from "react";

import { Warning } from "@material-ui/icons";
import { Box, Button, createStyles, Icon, makeStyles, Tooltip } from "@portex-pro/ui-components";
import { useGetAccessorialOptionsQuery } from "api/rest/shipments/shipmentsApi";
import { CheckboxGridItem } from "app/pages/shipments/components/CheckboxGrid";
import EditableShipmentStopView from "app/pages/shipments/components/EditableShipmentStopView";
import { ShipmentAccessorial, ShipmentStop } from "app/pages/shipments/types/domain";
import AddStopIcon from "assets/add-stop.svg";
import Frame from "components/Frame";
import Text from "components/Text";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import uniqueId from "lodash/uniqueId";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { useShipmentDetails } from "../provider/ShipmentDetailsProvider";

const useStyles = makeStyles(() =>
  createStyles({
    dndBorderHackContainer: {
      position: "relative",
    },
    dndBorderHack: {
      backgroundColor: "white",
      zIndex: 5,
      height: 1,
      position: "absolute",
      top: 0,
      width: "100%",
      left: 0,
    },
    icon: {
      width: 24,
      height: 24,
      display: "block",
    },
    addStopButton: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
    },
  })
);

interface ShipmentDetailsRouteViewProps {
  showEditDetails?: boolean;
  variant?: "request" | "management";
}

const ShipmentDetailsRouteView: VFC<ShipmentDetailsRouteViewProps> = (props) => {
  const { showEditDetails, variant = "management" } = props;
  const { t } = useTranslation("shipments");
  const { isEditing, patchedShipment, patch, errors, save, startEditing, shipment } = useShipmentDetails();
  const [dragging, setDragging] = useState(false);
  const [openStops, setOpenStops] = useState<Record<ShipmentStop["id"], boolean>>(
    patchedShipment.stops.reduce(
      (allStops, stop, index) => ({
        ...allStops,
        [stop.id]: variant === "request" && index === 0,
      }),
      {}
    )
  );

  const { data: allAccessorials } = useGetAccessorialOptionsQuery({});

  const styles = useStyles();
  const { confirm, dialogElement } = useConfirmationDialog();

  const handleStopChange =
    (stopIndex: number) =>
    (editedStop: Partial<ShipmentStop>, editedAccessorials?: CheckboxGridItem<ShipmentAccessorial>[]) => {
      const stops = [...patchedShipment.stops];

      stops[stopIndex] = { ...stops[stopIndex], ...editedStop };

      const newAccessorials = (patchedShipment.accessorials || []).filter(
        (accessorial) => !editedAccessorials?.some((accessorialItem) => accessorialItem.item.id === accessorial.id)
      );

      editedAccessorials
        ?.filter((accessorialItem) => accessorialItem.isSelected)
        .forEach((accessorialItem) => newAccessorials.push(accessorialItem.item));

      patch({ stops, accessorials: newAccessorials });
    };

  const handleStopDelete = (stopIndex: number) => async () => {
    const confirmed = await confirm({
      title: t("editableSection_deleteStopDialog_title"),
      body: t("editableSection_deleteStopDialog_body"),
      isDangerous: true,
      confirmationButtonCopy: t("editableSection_deleteStopDialog_confirm"),
      cancelButtonCopy: t("editableSection_deleteStopDialog_deny"),
    });
    if (confirmed) {
      const stops = patchedShipment.stops.filter((_, i) => i !== stopIndex);

      patch({ stops });
    }
  };

  const handleAddStop = () => {
    const placeholderStopId = -Number(uniqueId()); // -1, -2, -3, etc.
    const newStop: ShipmentStop = {
      id: placeholderStopId,
      isTimeTbd: false,
      bookingNotes: "",
    };
    const stops = [...patchedShipment.stops, newStop];
    patch({ stops });
    setOpenStops(
      stops.reduce(
        (allStops) => ({
          ...allStops,
          [newStop.id]: true,
        }),
        {}
      )
    );
  };

  const handleOpenStop = (stop: ShipmentStop) => {
    if (variant === "management") {
      // toggle open in management
      return setOpenStops((openStops) => ({ ...openStops, [stop.id]: !openStops[stop.id] }));
    } else if (variant === "request") {
      // keep only 1 open at a time in request
      return setOpenStops(
        patchedShipment.stops.reduce(
          (allStops, curr) => ({
            ...allStops,
            [curr.id]: stop.id === curr.id,
          }),
          {}
        )
      );
    }
  };

  const handleClickSaveStop = (stop: ShipmentStop) => {
    // after clicking save, close this stop + open the next stop if it exists
    const currentStopIndex = patchedShipment.stops.findIndex((value) => value.id === stop.id);
    const nextStop = currentStopIndex !== -1 ? patchedShipment.stops[currentStopIndex + 1] : null;
    setOpenStops((openStops) => {
      const nextOpenStops = { ...openStops, [stop.id]: !nextStop };
      if (!!nextStop) {
        nextOpenStops[nextStop.id] = true;
      }
      return nextOpenStops;
    });
  };

  const getStopType = (stopIndex: number) =>
    stopIndex === 0 ? "PICKUP" : stopIndex === patchedShipment.stops.length - 1 ? "DELIVERY" : "OTHER";

  const minimumStopsError = errors.getErrors("stops.length");
  const supportsMultistop = shipment.mode === "FTL" || shipment.mode === "INTERMODAL";

  return (
    <>
      {dialogElement}
      <Frame
        title={
          <Text size="medium" weight="bold" typographyProps={{ color: minimumStopsError ? "error" : "textPrimary" }}>
            {t("shipmentDetails_routeDetails_title")}
            {minimumStopsError && (
              <Tooltip title={minimumStopsError.join(", ")}>
                <Icon as={Warning} fontSize="small" palette="red" className="ml-1" />
              </Tooltip>
            )}
          </Text>
        }
        actions={
          showEditDetails ? (
            <Button variant="text" color="primary" onClick={() => (isEditing ? save() : startEditing())}>
              {isEditing ? t("shipmentDetails_save_buttonLabel") : t("shipmentDetails_editDetails_button")}
            </Button>
          ) : undefined
        }
      >
        <div className={styles.dndBorderHackContainer}>
          <div className={styles.dndBorderHack} />
          <DragDropContext
            onBeforeCapture={() => setDragging(true)}
            onDragEnd={({ source, destination }) => {
              setDragging(false);
              if (destination?.index === undefined) {
                return;
              }
              const stops = Array.from(patchedShipment.stops);
              const moved = stops.splice(source.index, 1);
              stops.splice(destination.index, 0, moved[0]);
              patch({ stops });
            }}
          >
            <Droppable droppableId="stops">
              {(provided) => (
                <div ref={provided.innerRef}>
                  {patchedShipment.stops.map((stop, index, { length }) => (
                    <Draggable key={stop.id} draggableId={String(stop.id)} index={index} isDragDisabled={!isEditing}>
                      {(draggableProvided, snapshot) => (
                        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                          <EditableShipmentStopView
                            allAccessorials={allAccessorials || []}
                            dragHandleProps={draggableProvided.dragHandleProps}
                            errors={{
                              address: errors.getErrors(`stops.${index}.address`),
                              date: errors.getErrors(`stops.${index}.date`),
                            }}
                            isDragging={snapshot.isDragging}
                            isEditing={isEditing}
                            isLocked={dragging}
                            isOpen={openStops[stop.id]}
                            onChange={handleStopChange(index)}
                            onDelete={handleStopDelete(index)}
                            onOpenStop={handleOpenStop}
                            onClickSaveStop={handleClickSaveStop}
                            hideSave={length - 1 === index}
                            selectedAccessorials={patchedShipment.accessorials || []}
                            shipmentMode={patchedShipment.mode}
                            stop={stop}
                            stopType={getStopType(index)}
                            variant={variant}
                            showDelete={supportsMultistop}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {isEditing && (supportsMultistop || patchedShipment.stops.length < 2) && (
            <Box p={1}>
              <Button variant="text" color="primary" type="button" onClick={handleAddStop}>
                <div className={styles.addStopButton}>
                  <img alt="" src={AddStopIcon} className={styles.icon} />
                  <span>Add stop</span>
                </div>
              </Button>
            </Box>
          )}
        </div>
      </Frame>
    </>
  );
};

export default ShipmentDetailsRouteView;
