import { VFC } from "react";

import { portexColor, Typography } from "@portex-pro/ui-components";
import NotificationBadge from "components/NotificationBadge";
import { useTranslation } from "react-i18next";

import CursorPaginationTableControlsView from "../../../../components/pagination/CursorPaginationTableControlsView";
import TableView, { TableViewColumns, TableViewRows } from "../../../../components/TableView";
import { BaseShipment } from "../types/domain";
import AddressCellView from "./AddressCellView";
import EquipmentCellView from "./EquipmentCellView";
import ShipmentInfoCellView from "./ShipmentInfoCellView";
import ViewShipmentButtonContainer from "./ViewShipmentButtonContainer";

interface ShipmentListProps {
  shipments: BaseShipment[];
  paginationOptions: {
    next: () => void;
    prev: () => void;
    setTake: (take: number) => void;
    take: number;
    page: number;
    total: number;
  };
}

const ShipmentListView: VFC<ShipmentListProps> = ({ shipments, paginationOptions }) => {
  const { t } = useTranslation("shipments");

  const shipmentListColumns: TableViewColumns<BaseShipment> = [
    {
      name: t("shipment_columnLabel"),
      renderCell: (item) => <ShipmentInfoCellView shipment={item} />,
      cellProps: { style: { minWidth: 200, verticalAlign: "top" } },
    },
    {
      name: t("origin_columnLabel"),
      renderCell: (item) => <AddressCellView stop={item.origin} allStops={item.stops} />,
      cellProps: { style: { minWidth: 100, verticalAlign: "top" } },
    },
    {
      name: t("destination_columnLabel"),
      renderCell: (item) => <AddressCellView stop={item.destination} />,
      cellProps: { style: { minWidth: 100, verticalAlign: "top" } },
    },
    {
      name: t("load_columnLabel"),
      renderCell: (item) => <Typography>{t(`mode_${item.mode}`)}</Typography>,
      headerCellProps: { width: 80 },
      cellProps: { style: { verticalAlign: "top" } },
    },
    {
      name: t("equipment_columnLabel"),
      renderCell: (item) => <EquipmentCellView shipment={item} />,
      headerCellProps: { width: 150 },
      cellProps: { style: { verticalAlign: "top" } },
    },
    {
      name: t("partner_columnLabel"),
      headerCellProps: { width: 125 },
      renderCell: (item) => <Typography className="break-words">{item.partner.companyName}</Typography>,
    },
  ];

  const shipmentListRows: TableViewRows<BaseShipment> = {
    headerCellProps: {
      style: {
        backgroundColor: "#fff",
        borderBottom: `1px solid ${portexColor.grey200}`,
        whiteSpace: "nowrap",
      },
    },
    endingIcon: (item) => (
      <NotificationBadge position="topLeft" identifiers={[`shipment:${item.id}`, `bolGenerationComplete:${item.id}`]}>
        <ViewShipmentButtonContainer shipmentId={item.id} />
      </NotificationBadge>
    ),
    endingIconCellProps: {
      style: {
        verticalAlign: "middle",
        width: "200px",
      },
      width: "200px",
      align: "right",
    },
  };

  return (
    <>
      <TableView items={shipments} columns={shipmentListColumns} rows={shipmentListRows} />
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default ShipmentListView;
