import { useMemo } from "react";

import Info from "@material-ui/icons/Info";
import { Alert, Box, cyan, Typography } from "@portex-pro/ui-components";
import BrokerReferralsContainer from "features/broker-referrals/BrokerReferralsContainer";
import BrokerLaneBenchmark from "features/lane-benchmark/BrokerLaneBenchmark";
import { Trans, useTranslation } from "react-i18next";

import { Mode, QuoteType } from "../../../../../../api/types/generated-types";
import { IS_DEV } from "../../../../../../env";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { useQuoteSubmissionHistoryStoreFCL } from "../hooks/useQuoteSubmissionHistoryStoreFCL";
import { StepComponentQuoteSubmissionFCL } from "../types/StepComponentQuoteSubmissionFCL";
import { StartQuoteFlexRow } from "./StartQuoteFlexRow";

const STEP_NAME = "StartQuoteStep";

const { Cheapest, Fastest, ForwarderPreference, Additional } = QuoteType;

const StartQuoteStep: StepComponentQuoteSubmissionFCL = () => {
  const { t } = useTranslation("broker");
  const { startQuote, publicQuoteRequest, submitFclQuoteInputPartial, indexVisited } = useContextQuoteSubmissionFCL();
  const quoteSubmissionHistory = useQuoteSubmissionHistoryStoreFCL();
  const onStepOne = indexVisited === 0;

  const _dev_only_reset_ = IS_DEV ? (
    <Box
      position="absolute"
      top={60}
      onClick={() => {
        useQuoteSubmissionHistoryStoreFCL.persist.clearStorage();
        window.location.reload();
      }}
      style={{ cursor: "pointer", background: "darkorange" }}
      boxShadow="2px 2px 2px lightorange"
      border="1px solid darkred"
    >
      [DEV ONLY] reset the `useQuoteSubmissionHistoryStore`
    </Box>
  ) : null;

  const portexId = publicQuoteRequest.portex_id;
  const requestedCheapest = publicQuoteRequest.quote_types.includes(Cheapest);
  const requestedFastest = publicQuoteRequest.quote_types.includes(Fastest);
  const requestForwarderPreference = publicQuoteRequest.quote_types.includes(ForwarderPreference);

  const submittedQuotes = quoteSubmissionHistory.getQuotesByPortexId(portexId);
  const cheapestQuote = quoteSubmissionHistory.getCheapestQuoteByPortexId(portexId);
  const fastestQuote = quoteSubmissionHistory.getFastestQuoteByPortexId(portexId);
  const fpQuote = quoteSubmissionHistory.getForwarderPreferenceQuotesByPortexId(portexId);
  const additionalQuotes = quoteSubmissionHistory.getAdditionalQuotesByPortexId(portexId);

  const hasSubmittedQuotes = submittedQuotes.length >= 1;
  const shouldDisplayCheapest = requestedCheapest;
  const shouldDisplayFastest = requestedFastest;
  const shouldDisplayFP = requestForwarderPreference;
  const isDisplayAdditionalDisabled = submittedQuotes.length < publicQuoteRequest.quote_types.length;
  const isSubmitted = hasSubmittedQuotes && onStepOne && !!submitFclQuoteInputPartial.submitter_email;

  const preferences = [
    publicQuoteRequest.capacity_guarantee_required ? "guaranteed capacity" : "",
    publicQuoteRequest.direct_sailing_required ? "direct sailing" : "",
  ].filter(Boolean);

  const numOfRequestsCopy =
    publicQuoteRequest.quote_types.length <= 1
      ? t("startQuoteStep.oneQuote")
      : t("startQuoteStep.multiQuotes").replace("%s", publicQuoteRequest.quote_types.length.toString());

  const additionalQuoteDescription = useMemo(() => {
    if (!isDisplayAdditionalDisabled) {
      return t("startQuoteStep.additionalQuote", { ns: "broker" });
    }

    return undefined;
  }, [isDisplayAdditionalDisabled, t]);

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="column" width="100%" p={4} style={{ gap: "1.4rem" }}>
        {hasSubmittedQuotes ? _dev_only_reset_ : null}
        <Box>
          <Typography>
            <big>
              <Trans i18nKey="startQuoteStep.shipperRequested" ns="broker">
                The shipper requested <strong>{{ numOfRequestsCopy }}.</strong>
              </Trans>
            </big>
          </Typography>
        </Box>
        {shouldDisplayCheapest ? (
          <StartQuoteFlexRow<Mode.Fcl>
            type={Cheapest}
            onChange={startQuote}
            pending={!cheapestQuote}
            quoteAmountFormatted={cheapestQuote ? formatUSD(cheapestQuote.totalAmount) : ""}
          />
        ) : null}
        {shouldDisplayFastest ? (
          <StartQuoteFlexRow<Mode.Fcl>
            type={Fastest}
            onChange={startQuote}
            pending={!fastestQuote}
            quoteAmountFormatted={fastestQuote ? formatUSD(fastestQuote.totalAmount) : ""}
          />
        ) : null}
        {shouldDisplayFP ? (
          <StartQuoteFlexRow<Mode.Fcl>
            type={ForwarderPreference}
            onChange={startQuote}
            pending={!fpQuote}
            quoteAmountFormatted={fpQuote ? formatUSD(fpQuote.totalAmount) : ""}
          />
        ) : null}
        {additionalQuotes.map((additionalQuote) => {
          return (
            <StartQuoteFlexRow<Mode.Fcl>
              type={additionalQuote.type}
              pending={false}
              quoteAmountFormatted={formatUSD(additionalQuote.totalAmount)}
            />
          );
        })}
        <StartQuoteFlexRow<Mode.Fcl>
          type={Additional}
          onChange={startQuote}
          pending={true}
          disabled={isDisplayAdditionalDisabled}
          description={additionalQuoteDescription}
        />
        <BrokerLaneBenchmark quoteRequest={publicQuoteRequest} />
        {preferences.length ? (
          <Box>
            <Alert
              severity="info"
              icon={<Info />}
              style={{
                color: cyan[500],
                border: "1px solid",
                borderColor: "currentColor",
              }}
            >
              The shipper requested quotes with {preferences.join(" and ")}
            </Alert>
          </Box>
        ) : null}
      </Box>
      <BrokerReferralsContainer
        isSubmitted={isSubmitted}
        brokerEmail={submitFclQuoteInputPartial.submitter_email || ""}
      />
    </Box>
  );
};

StartQuoteStep.heading = "common:startQuote";
StartQuoteStep.stepName = STEP_NAME;
StartQuoteStep.hideFooter = true;

export default StartQuoteStep;
