import { ReactElement, useCallback, useEffect, useState } from "react";

import { useMutation, useLazyQuery, gql, TypedDocumentNode } from "@apollo/client";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  Dialog,
  DialogTitle,
  Typography,
  Box,
  IconButton,
  DialogContent,
  portexColor,
  Paper,
  TextInput,
  DialogActions,
  Button,
  makeStyles,
  Tooltip,
  Link,
} from "@portex-pro/ui-components";
import AttachmentsListContainer from "components/file-uploads/AttachmentsListContainer";
import useLDFlag from "hooks/useLDFlag";
import { useSnackbar } from "notistack";
import { Trans, useTranslation } from "react-i18next";

import {
  Mutation,
  MutationCreateQuoteSubmissionLinkArgs,
  Query,
  QuoteRequest,
} from "../../../api/types/generated-types";
import Loading from "../../../components/Loading";
import { useOnApolloError } from "../../../hooks/useOnApolloError";
import QuoteSubmissionDetails from "./QuoteSubmissionDetails";

export const CREATE_QUOTE_SUBMISSION_LINK: TypedDocumentNode<
  { createQuoteSubmissionLink: Mutation["createQuoteSubmissionLink"] },
  MutationCreateQuoteSubmissionLinkArgs
> = gql`
  mutation ($input: CreateQuoteSubmissionLinkInput!) {
    createQuoteSubmissionLink(input: $input)
  }
`;

export const VIEW_PUBLIC_QUOTE_REQUEST: TypedDocumentNode<Pick<Query, "viewPublicQuoteRequest">> = gql`
  query ($input: ViewPublicQuoteRequestInput!) {
    viewPublicQuoteRequest(input: $input) {
      ...PublicQuoteRequest_QuoteSubmissionDetails
    }
  }
  ${QuoteSubmissionDetails.fragments.PublicQuoteRequest}
`;

type ViewQuoteRequestDialogProps = {
  quoteRequest: QuoteRequest;
  viewQuoteRequestDialogOpen: boolean;
  setViewQuoteRequestDialogOpen: (open: boolean) => void;
};

const useStyles = makeStyles(() => ({
  customDialogWidth: { maxWidth: "950px" },
}));

const ViewQuoteRequestDialog = ({
  quoteRequest,
  viewQuoteRequestDialogOpen,
  setViewQuoteRequestDialogOpen,
}: ViewQuoteRequestDialogProps): ReactElement => {
  const { t } = useTranslation("shipper");
  const { onApolloError } = useOnApolloError({ componentName: "ViewQuoteRequestDialog" });

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const releaseQuoteRequestFiles = useLDFlag("releaseQuoteRequestFiles");

  // VIEW QUOTE REQUEST DIALOG
  const [open, setOpen] = useState(false);
  const [submissionLink, setSubmissionLink] = useState("");
  const [generateLink] = useMutation(CREATE_QUOTE_SUBMISSION_LINK, { onError: onApolloError("generateLink") });
  const [viewPublicQuoteRequest, { data: publicQuoteRequest, loading: publicQuoteRequestLoading }] = useLazyQuery(
    VIEW_PUBLIC_QUOTE_REQUEST,
    {
      variables: {
        input: {
          quoteRequestId: quoteRequest?.id ?? "",
        },
      },
      fetchPolicy: "cache-and-network",
      onError: onApolloError("viewPublicQuoteRequest"),
    }
  );

  const handleOpenViewQuoteRequest = useCallback(async () => {
    if (!submissionLink && quoteRequest) {
      const { data } = await generateLink({
        variables: {
          input: {
            quoteRequestId: quoteRequest.id,
          },
        },
      });

      setSubmissionLink(data?.createQuoteSubmissionLink ?? "");
    }
    viewPublicQuoteRequest();
    setOpen(true);
  }, [generateLink, quoteRequest, submissionLink, viewPublicQuoteRequest]);

  useEffect(() => {
    if (viewQuoteRequestDialogOpen) {
      handleOpenViewQuoteRequest();
    }
  }, [handleOpenViewQuoteRequest, viewQuoteRequestDialogOpen]);

  const handleCloseViewQuoteRequest = () => {
    setViewQuoteRequestDialogOpen(false);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      aria-labelledby="view-quote-request-dialog"
      open={open}
      onClose={handleCloseViewQuoteRequest}
      classes={{ paper: classes.customDialogWidth }}
    >
      <DialogTitle disableTypography id="view-quote-request-dialog-title">
        <Typography className={"por-bg-green"}>{t("viewQuoteRequest")}</Typography>
        <Box pl={1} />
        <IconButton onClick={handleCloseViewQuoteRequest} className={"Por-close"}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={"Por-p-0"}>
        <Box p={5} style={{ backgroundColor: portexColor.grey50 }}>
          {publicQuoteRequestLoading ? (
            <Loading spinnerOnly />
          ) : (
            <>
              <Paper className={"Por-outlined-light"} elevation={8}>
                <Box py={3} textAlign="center">
                  <Typography align="center" variant="h5" gutterBottom>
                    {t("viewQuoteRequestDialog.submissionLink")}
                  </Typography>
                  <Typography align="center" variant="caption" gutterBottom>
                    {t("viewQuoteRequestDialog.description", { id: quoteRequest.portex_id })}
                  </Typography>
                  <TextInput
                    disabled
                    value={submissionLink}
                    style={{ maxWidth: "100%", width: (submissionLink.length + 1) * 8 }}
                  />
                  <Tooltip title={t("viewQuoteRequestDialog.copy")} arrow placement="top">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(submissionLink);
                        enqueueSnackbar(t("viewQuoteRequestDialog.copied"), {
                          variant: "success",
                          autoHideDuration: 2000,
                          anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                          },
                          preventDuplicate: true,
                        });
                      }}
                    >
                      <FileCopyOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("viewQuoteRequestDialog.openLink")} arrow placement="top">
                    <Link
                      href={submissionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <IconButton>
                        <OpenInNewIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <br />
                  <Typography align="center" variant="caption" gutterBottom>
                    <Trans i18nKey="viewQuoteRequestDialog.description2" ns="shipper">
                      Anyone with the link can <u>view the details</u> below and <u>submit quotes</u> for this request
                    </Trans>
                  </Typography>
                </Box>
              </Paper>

              <Box py={2} />

              <Paper className={"Por-outlined-light"} elevation={8}>
                <QuoteSubmissionDetails
                  hideStartQuoteBtn
                  publicQuoteRequest={publicQuoteRequest?.viewPublicQuoteRequest}
                  attachmentsList={
                    !!releaseQuoteRequestFiles && (
                      <AttachmentsListContainer showDashOnNoAttachments hideDeleteAction={true} />
                    )
                  }
                />
              </Paper>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" flexDirection="row-reverse" width="100%" justifyContent="space-between">
          <Button style={{ minWidth: 160 }} onClick={handleCloseViewQuoteRequest} variant={"outlined"}>
            {t("close")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ViewQuoteRequestDialog;
