import { ShipmentPackagingType } from "app/pages/shipments/types/domain";

import { CargoGroupType, NormalizedCargoGroup } from "../api/types";

export const isNormalizedCargoGroupValid = (cargoGroup: NormalizedCargoGroup): boolean => {
  let isValid = false;

  if (cargoGroup.cargo_group_type === CargoGroupType.CARGO) {
    if (cargoGroup.packaging_type === ShipmentPackagingType.Containers) {
      isValid = !!cargoGroup.equipment_size && !!cargoGroup.equipment_type;
    } else {
      isValid = !!cargoGroup.equipment_type;
    }
  } else {
    isValid = !!cargoGroup.packaging_type && !!cargoGroup.count;
  }

  return isValid;
};
