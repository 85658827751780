import React from "react";

import { useGetBrokerBidContractQuery } from "api/rest/rfp/brokerBidContractApi/brokerBidContractApi";
import AttachmentsListView from "components/file-uploads/AttachmentsListView";

const BrokerAttachmentsListContainer: React.VoidFunctionComponent = () => {
  const { data: contract } = useGetBrokerBidContractQuery();

  if (!contract) {
    return null;
  }

  return <AttachmentsListView files={contract.files} hideDeleteAction={true} showDashOnNoAttachments={true} />;
};

export default BrokerAttachmentsListContainer;
