import { configureSlices } from "utils/store/configureSlices";

import brokerLoadStatusSlice from "./brokerLoadStatusSlice";
import brokerLoadStatusUiSlice from "./brokerLoadStatusUiSlice";

const slicesMap = {
  brokerLoadStatusSlice,
  brokerLoadStatusUiSlice,
};

export const { useSlices: useBrokerLoadStatusSlices, useSliceSelector: useBrokerLoadStatusSliceSelector } =
  configureSlices(slicesMap);
