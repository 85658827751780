import { baseRestApi } from "api/rest/baseRestApi";
import { AddressType } from "api/types/generated-types";
import { Mode } from "types/Mode";
import enhanceWithPagination from "utils/enhanceWithPagination";
import paramsToTuples from "utils/paramsToTuples";

import { PaginatedApiRequestQueryArgs, SearchQueryParamsBase } from "../types/requests";
import { ApiResponsePaginatedCursor } from "../types/responses";
import { ShipperAddress } from "./types";

const API_ROOT = "/shipper/addresses" as const;
module.hot?.accept();

// Only specify mode or type, only one will work.
type QueryParams = SearchQueryParamsBase & { mode?: Mode; type?: AddressType[] };

type QueryArgs = PaginatedApiRequestQueryArgs<{ queryParams: QueryParams }>;

export type Response = ApiResponsePaginatedCursor<{
  addresses: ShipperAddress[];
}>;

const getAddressesApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["addresses", "address"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    getAddresses: builder.query<Response, QueryArgs>({
      query: ({ queryParams }) => {
        return {
          url: `${API_ROOT}`,
          method: "GET",
          params: paramsToTuples(queryParams),
        };
      },
      providesTags: () => ["addresses"],
    }),
  }),
});

export default getAddressesApi;

export const {
  useGetAddressesQuery: useBaseGetAddressesQuery,
  useLazyGetAddressesQuery: useBaseLazyGetAddressesQuery,
} = getAddressesApi;
export const { useGetAddressesQuery, useLazyGetAddressesQuery } = enhanceWithPagination(
  getAddressesApi,
  "getAddresses",
  { take: 25 }
);
