import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import { ShipperQuoteRequest } from "api/rest/quote-requests";
import ReviewAndSendHeader from "app/pages/dispatch-request/views/ReviewAndSendHeader";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import LoadTypeChip from "components/LoadTypeChip";
import MockEmailHeaderView from "components/MockEmailHeaderView";
import SummaryPaperView from "components/SummaryPaperView";
import Text from "components/Text";
import CargoGroupsOverviewView from "features/cargo-groups/views/CargoGroupsOverviewView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { renderSerializedNotes } from "utils/renderSerializedNotes";
import VerticalStopsInfo from "views/stops/VerticalStopsInfo";

interface DrayageReviewViewProps {
  quoteRequest: ShipperQuoteRequest;
  addAttachmentButton: React.ReactElement | false | null;
  attachmentsList: React.ReactElement | false | null;
}

const DrayageReviewView: VFC<DrayageReviewViewProps> = (props) => {
  const { t } = useTranslation(["shipper", "common"]);
  const { quoteRequest, addAttachmentButton, attachmentsList } = props;

  return (
    <div className="flex flex-col h-full mx-8 pb-4">
      <ReviewAndSendHeader />
      <MockEmailHeaderView
        from={[quoteRequest.email_metadata.from]}
        to={quoteRequest.email_metadata.to ? [quoteRequest.email_metadata.to] : []}
        cc={quoteRequest.email_metadata.cc}
        bcc={quoteRequest.email_metadata.bcc}
        subject={quoteRequest.email_metadata.subject}
        hideAddNoteButton
        addAttachmentButton={addAttachmentButton || undefined}
      >
        <SummaryPaperView
          PaperProps={{
            variant: "outlined",
            style: {
              width: "100%",
            },
          }}
          description={
            <div className="w-full flex px-9 mb-[-1.5rem] justify-between">
              <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
                {t("common:quoteDetails")}
              </Text>
              <Text size="medium" typographyProps={{ color: "textSecondary" }}>
                {quoteRequest.portex_id}
              </Text>
            </div>
          }
          ColumnListViewProps={{
            rows: [
              {
                label: t("common:shipper"),
                value: quoteRequest.shipper_name ?? EM_DASH,
              },
              {
                label: t("common:mode"),
                value: (
                  <LoadTypeChip
                    mode={quoteRequest.mode}
                    verbose={quoteRequest.mode === "FTL"}
                    textProps={{ size: "medium", weight: "bold" }}
                  />
                ),
                BoxProps: { style: { alignItems: "center" } },
              },
              {
                label: t("common:incoterms"),
                value: quoteRequest.incoterms ? t(`common:incoterms_map.${quoteRequest.incoterms}`) : EM_DASH,
              },
              {
                label: t("shipper:bids.bidRequestPage.pageReviewAndSend.bidDeadline"),
                value: quoteRequest.deadline_respond_at
                  ? DateTime.fromISO(quoteRequest.deadline_respond_at).toFormat("LLL dd, yyyy")
                  : EM_DASH,
              },
              {
                label: t("common:routing"),
                value: (
                  <VerticalStopsInfo
                    // @ts-expect-error We can come back to these typing issues
                    stops={quoteRequest.stops.map((stop) => ({
                      ...stop,
                      zip: stop.address?.zip ?? "",
                      iana_timezone: stop.address?.iana_timezone ?? "",
                      formatted_long_name: formatAddress.fromSnakeCase(
                        // @ts-expect-error We can circle around to fix these type issues
                        Object.entries(stop.address ?? {}).reduce((add, [key, value]) => {
                          // @ts-expect-error same as above
                          add[key] = value ?? null;
                          return add;
                        }, {}),
                        "long"
                      ),
                    }))}
                  />
                ),
              },
              {
                label: t("common:containers"),
                value: <CargoGroupsOverviewView cargoGroups={quoteRequest.cargo_groups} />,
              },
              {
                label: t("common:hazardousCommodities"),
                value: <Box color="red">{t("common:containsHazardous")}</Box>,
                hide: !quoteRequest.is_hazardous,
              },
              {
                label: t("common:hazardousDescription"),
                value: renderSerializedNotes(quoteRequest.hazardous_goods_details ?? ""),
                hide: !quoteRequest.hazardous_goods_details,
              },
              {
                label: t("common:additionalNotes"),
                value: !!quoteRequest.note ? renderSerializedNotes(quoteRequest.note) : EM_DASH,
              },
              {
                label: t("common:attachments"),
                value: attachmentsList || EM_DASH,
                hide: !attachmentsList,
              },
            ],
          }}
        />
      </MockEmailHeaderView>
    </div>
  );
};

export default DrayageReviewView;
