import { VFC } from "react";

import CargoGroupProvider from "features/cargo-groups/CargoGroupProvider";
import ShipmentDetailsProvider from "features/shipments/provider/ShipmentDetailsProvider";
import first from "lodash/first";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Shipment, ShipmentMode } from "../types/domain";
import ShipmentDetailsTabView from "./ShipmentDetailsTabView";

interface ShipmentDetailsTabContainerProps {
  shipment: Shipment;
}

const ShipmentDetailsTabContainer: VFC<ShipmentDetailsTabContainerProps> = ({ shipment }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("shipments");

  const onSaveSuccess = ({ isEmailSent }: { isEmailSent: boolean }) => {
    enqueueSnackbar(
      t(
        isEmailSent ? "shipmentDetails_changesSaved_notification" : "shipmentDetails_changesSaved_noEmail_notification"
      ),
      {
        variant: "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      }
    );
  };

  const onSaveFailure = () => {
    enqueueSnackbar(t("shipmentDetails_failedToSave_notification"), { variant: "error" });
  };

  return (
    <CargoGroupProvider
      cargoGroups={shipment.mode === ShipmentMode.LtlPartial ? first(shipment.trucks)?.cargoGroups : undefined}
      truck_id={shipment.mode === ShipmentMode.LtlPartial ? first(shipment.trucks)?.id : undefined}
    >
      <ShipmentDetailsProvider shipment={shipment} onSaveSuccess={onSaveSuccess} onSaveFailure={onSaveFailure}>
        <ShipmentDetailsTabView />
      </ShipmentDetailsProvider>
    </CargoGroupProvider>
  );
};

export default ShipmentDetailsTabContainer;
