import { BidRequestFieldType } from "../types";
import { zipValidator } from "./validators";

const createLocationFields = (
  key: "pickup" | "delivery",
  options?: { dateRequired?: boolean; dateSelected?: boolean }
): BidRequestFieldType[] => {
  const index = key === "pickup" ? 0 : 1;

  return [
    {
      key: `stop_${index}_city`,
      label: "city",
      required: true,
      selected: true,
      additionalI18NOptions: {
        location: `$t(shipper:bids.fieldNames.${key})`,
      },
      type: "string",
      validators: [
        {
          validate: "required",
        },
      ],
    },
    {
      key: `stop_${index}_state`,
      label: "state",
      required: true,
      selected: true,
      additionalI18NOptions: {
        location: `$t(shipper:bids.fieldNames.${key})`,
      },
      validators: [
        {
          validate: "required",
        },
      ],
      type: "string",
    },
    {
      key: `stop_${index}_zip`,
      label: "zip",
      required: false,
      selected: true,
      additionalI18NOptions: {
        location: `$t(shipper:bids.fieldNames.${key})`,
      },
      validators: [zipValidator],
      type: "string",
    },
    {
      key: `stop_${index}_name`,
      label: "location",
      required: false,
      selected: false,
      additionalI18NOptions: {
        location: `$t(shipper:bids.fieldNames.${key})`,
      },
      type: "string",
    },
    {
      key: `stop_${index}_date`,
      label: "locationDate",
      required: options?.dateRequired ?? false,
      selected: (options?.dateSelected || options?.dateRequired) ?? false,
      additionalI18NOptions: {
        location: `$t(shipper:bids.fieldNames.${key})`,
      },
      validators: [...(options?.dateRequired ? [{ validate: "required" as const }] : [])],
      type: "date",
    },
  ];
};

export default createLocationFields;
