import { useMemo } from "react";

import { Box, CircularProgress, Grid, TextInfo } from "@portex-pro/ui-components";
import BrokerLaneBenchmark from "features/lane-benchmark/BrokerLaneBenchmark";
import compact from "lodash/compact";
import { DateTime } from "luxon";
import { Trans, useTranslation } from "react-i18next";

import type { Maybe } from "../../../../../../api/types/generated-types";
import ThrottledTextInput from "../../../../../../components/ThrottledTextInput";
import { deserializeNotes } from "../../../../../../utils/deserializeNotes";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { serializeNotes } from "../../../../../../utils/serializeNotes";
import { toLuxon } from "../../../../../../utils/toLuxon";
import { toLuxonRelative } from "../../../../../../utils/toLuxonRelative";
import { getRoutingTypeLabel } from "../../../../../shipper/pages/request-quote/utils/getRoutingTypeLabel";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { useFclQuoteTotals } from "../hooks/useFclQuoteTotals";
import { StepComponentQuoteSubmissionFCL } from "../types/StepComponentQuoteSubmissionFCL";
import { JourneyFCL } from "./components";
import { useJourneyDisplay } from "./components/hooks/useJourneyDisplay";

const STEP_NAME = "FinalizeStep";

const FinalizeStep: StepComponentQuoteSubmissionFCL = () => {
  const { t } = useTranslation(["common", "broker"]);
  const {
    isQuoteSubmitting,
    expanded,
    publicQuoteRequest,
    submitFclQuoteInputPartial,
    onChangeEmail,
    onChangeNotes,
    onChangeNotesChargesInsurance,
    onChangeNotesChargesMiscellaneous,
  } = useContextQuoteSubmissionFCL();
  const { journeyDisplay } = useJourneyDisplay();
  const { data, loading: loadingTotals } = useFclQuoteTotals({
    quoteCharges: submitFclQuoteInputPartial.quote_charges,
    skip: expanded !== STEP_NAME,
  });

  const validUntilLuxon = useMemo<Maybe<DateTime>>(() => {
    return submitFclQuoteInputPartial.valid_until ? toLuxon(submitFclQuoteInputPartial.valid_until) : null;
  }, [submitFclQuoteInputPartial.valid_until]);

  const { min_transit_time, max_transit_time } = submitFclQuoteInputPartial;

  return (
    <Box width="100%">
      <Box py={2}>
        <JourneyFCL steps={journeyDisplay} />
      </Box>
      <Box px={5} py={2} bgcolor="primary.100" display="flex" justifyContent="space-between" style={{ gap: "4rem" }}>
        <Box>
          <TextInfo
            emphasized
            label="Total"
            heading={loadingTotals ? <CircularProgress size="1rem" /> : formatUSD(data.total_amount)}
            metadata={
              <Trans i18nKey="finalizeStep.total.itemMetadata" ns="broker">
                For{" "}
                {{
                  totalItemsText: `${publicQuoteRequest.containers.length} ${
                    publicQuoteRequest.containers.length === 1 ? "container" : "containers"
                  }`,
                }}
              </Trans>
            }
          />
        </Box>
        {publicQuoteRequest.containers.length > 1 ? (
          <Box>
            <TextInfo
              emphasized
              label={t("broker:finalizeStep.perContainer")}
              heading={loadingTotals ? <CircularProgress size="1rem" /> : formatUSD(data.rate_per_container)}
              metadata={t("broker:finalizeStep.forEachContainer")}
            />
          </Box>
        ) : null}
        <Box>
          <TextInfo
            emphasized
            label={t("broker:finalizeStep.estTransitTime.label")}
            heading={
              <Trans i18nKey="finalizeStep.estTransitTime.heading" ns="broker">
                {{ min_transit_time }}-{{ max_transit_time }} days
              </Trans>
            }
            metadata={compact([
              getRoutingTypeLabel(publicQuoteRequest.routing_type),
              submitFclQuoteInputPartial.carrier,
            ]).join(" / ")}
          />
        </Box>
        <Box>
          <TextInfo
            emphasized
            label={t("broker:finalizeStep.validUntil_label")}
            heading={validUntilLuxon?.toFormat("MMM dd, yyyy") ?? ""}
            metadata={toLuxonRelative(validUntilLuxon)}
          />
        </Box>
      </Box>
      <div className="w-full p-2">
        <BrokerLaneBenchmark quoteRequest={publicQuoteRequest} />
      </div>
      <Box p={2}>
        <ThrottledTextInput
          required
          label={t("broker:finalizeStep.emailAddress.label")}
          fullWidth
          margin="normal"
          placeholder={t("broker:finalizeStep.emailAddress.placeholder")}
          value={submitFclQuoteInputPartial.submitter_email ?? ""}
          onChange={(value) => onChangeEmail(value)}
          autoComplete="new-password" // This is a stupid hack to prevent autofill on this input
          disabled={isQuoteSubmitting.value}
        />
        <ThrottledTextInput
          fullWidth
          multiline
          margin="normal"
          rows={5}
          rowsMax={8}
          label={t("common:additionalNotes")}
          placeholder={t("broker:finalizeStep.additionalNotes.placeholder")}
          value={deserializeNotes(submitFclQuoteInputPartial.notes)}
          onChange={(value) => onChangeNotes(serializeNotes(value))}
          disabled={isQuoteSubmitting.value}
        />
        {/* @todo make reuseable component for below part, check ChargesStep component */}
        <Grid container spacing={2}>
          {publicQuoteRequest.insurance_required ? (
            <Grid item xs={6}>
              <ThrottledTextInput
                fullWidth
                multiline
                margin="normal"
                rows={3}
                rowsMax={8}
                required={publicQuoteRequest.insurance_required}
                label={`Cargo Insurance Charges${
                  publicQuoteRequest.goods_value
                    ? ` - Value of goods: ${formatUSD(publicQuoteRequest.goods_value)}`
                    : ""
                }`}
                placeholder="Provide any notes related to cargo insurance"
                value={deserializeNotes(submitFclQuoteInputPartial.notes_charges_insurance)}
                onChange={(value) => onChangeNotesChargesInsurance(serializeNotes(value))}
              />
            </Grid>
          ) : null}
          <Grid item xs={publicQuoteRequest.insurance_required ? 6 : 12}>
            <ThrottledTextInput
              fullWidth
              multiline
              margin="normal"
              rows={3}
              rowsMax={8}
              label="Miscellaneous Charges"
              placeholder="Provide any notes related to General Sales Tax %, Disbursement Fee %, etc."
              value={deserializeNotes(submitFclQuoteInputPartial.notes_charges_miscellaneous)}
              onChange={(value) => onChangeNotesChargesMiscellaneous(serializeNotes(value))}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

FinalizeStep.heading = "broker:finalizeStep.heading";
FinalizeStep.stepName = STEP_NAME;

export default FinalizeStep;
