import { useEffect, VFC } from "react";

import * as FullStory from "@fullstory/browser";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import { StringParam, useQueryParam } from "use-query-params";

import { IS_DEV, IS_STAGING, REACT_APP_FULLSTORY_ORG_ID } from "../../../env";
import { useUserContext } from "../../../hooks/useUserContext";

const FullstoryPlugin: VFC = () => {
  const { auth0User } = useUserContext();
  const { user } = useGetCurrentUserQuery(undefined, {
    selectFromResult: ({ data }) => ({ user: data?.data.user }),
  });
  const [uutQueryParam] = useQueryParam("uut", StringParam);

  useEffect(() => {
    FullStory.init({ orgId: REACT_APP_FULLSTORY_ORG_ID, devMode: IS_DEV || IS_STAGING });
    return FullStory.shutdown;
  }, []);

  useEffect(() => {
    if (!!auth0User || !!user) {
      FullStory.identify(auth0User?.sub ?? user?.email ?? "anonymous-fallback", {
        displayName: `${auth0User?.nickname} | ${auth0User?.name}`,
        email: `${user?.email}`,
        shipperId: `${user?.shipper_id}`,
        firstName: `${user?.first_name}`,
        lastName: `${user?.last_name}`,
        portexAdmin: `${user?.portex_admin}`,
        company: `${user?.shipper_id}`,
        isImpersonating: `${user?.impersonating}`,
        isClaimed: user?.is_claimed,
        isUut: !!uutQueryParam,
      });
    }
  }, [auth0User, user, uutQueryParam]);

  return null;
};

export default FullstoryPlugin;
