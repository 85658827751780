import React from "react";

import { useElementSize } from "usehooks-ts";

const ScrollableView: React.FC = (props) => {
  const { children } = props;
  const [scrollAreaRef, { height }] = useElementSize();

  return (
    <div style={{ height: "100%", position: "relative" }} ref={scrollAreaRef}>
      <div style={{ position: "absolute", width: "100%", height, overflowY: "auto" }}>{children}</div>
    </div>
  );
};

export default ScrollableView;
