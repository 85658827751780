import { VFC } from "react";

import { gql, TypedDocumentNode, useMutation, useQuery } from "@apollo/client";
import { Button } from "@portex-pro/ui-components";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { useOnApolloError } from "hooks/useOnApolloError";
import chunk from "lodash/chunk";
import compact from "lodash/compact";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";

import {
  Mutation,
  MutationDeleteContactArgs,
  Query,
  QueryFindPartnersArgs,
  SortDirectionFilterEnum,
} from "../../../api/types/generated-types";

const CONTACT = gql`
  fragment PartnersPage_Contact on Contact {
    id
  }
`;

const FIND_PARTNERS_FOR_DELETE: TypedDocumentNode<Pick<Query, "findPartners">, QueryFindPartnersArgs> = gql`
  query ($filter: PartnerFilter, $page: PageInput, $orderBy: OrderByFilterInput) {
    findPartners(filter: $filter, page: $page, orderBy: $orderBy) {
      items {
        ...PartnersPage_Contact
      }
      limit
      count
      offset
    }
  }
  ${CONTACT}
`;

const DELETE_ALL_CONTACT: TypedDocumentNode<Pick<Mutation, "deleteContact">, MutationDeleteContactArgs> = gql`
  mutation ($input: DeleteContactInput!) {
    deleteContact(input: $input)
  }
`;

interface DeleteAllPartnersContainerProps {
  refetch: () => unknown;
}

const DeleteAllPartnersContainer: VFC<DeleteAllPartnersContainerProps> = ({ refetch }) => {
  const { onApolloError } = useOnApolloError({ componentName: "PartnersPage" });
  const { enqueueSnackbar } = useSnackbar();
  const { dialogElement, confirm } = useConfirmationDialog();
  const { t } = useTranslation(["shipper"]);

  const { data: partners, loading: findingPartners } = useQuery(FIND_PARTNERS_FOR_DELETE, {
    variables: {
      orderBy: { field: "company_name", order: SortDirectionFilterEnum.Asc },
    },
    fetchPolicy: "cache-and-network",
    onError: onApolloError("findPartners"),
  });

  const [deleteContact, { loading: deletingContact }] = useMutation(DELETE_ALL_CONTACT, {
    onError: onApolloError("deleteContact"),
  });

  const onDeleteAllPartners = async () => {
    const confirmed = await confirm({
      title: t("shipper:deleteAllPartners"),
      body: t("shipper:deleteAllPartners_warning"),
    });

    if (findingPartners || !partners?.findPartners.items || !confirmed) {
      return;
    }

    try {
      const deleteGroups = chunk(partners.findPartners.items, 25);

      while (deleteGroups.length > 0) {
        await Promise.all(
          compact(deleteGroups.pop())?.map((partner) =>
            deleteContact({ variables: { input: { id: partner.id } } })
          ) ?? [Promise.resolve()]
        );
      }
      await refetch();
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(e, { variant: "error" });
    }
  };

  return (
    <>
      {dialogElement}
      <Button
        onClick={onDeleteAllPartners}
        disabled={deletingContact || findingPartners}
        variant="outlined"
        className="Por-bg-red"
      >
        {t("shipper:deleteAllPartners")}
      </Button>
    </>
  );
};

export default DeleteAllPartnersContainer;
