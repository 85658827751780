import { VFC } from "react";

import { gql } from "@apollo/client";
import QuoteRequestDetailsLTLView from "components/ltl/QuoteRequestDetailsLTLView";
import { ModeQuotes } from "types/Mode";

import { OptionalMaybe, PublicQuoteRequest } from "../../../api/types/generated-types";
import QuoteSubmissionDetailsAIR from "../../broker/quote/components/quote-submission-air/components/QuoteSubmissionDetailsAIR";
import QuoteSubmissionDetailsFCL from "../../broker/quote/components/quote-submission-fcl/components/QuoteSubmissionDetailsFCL";
import QuoteSubmissionDetailsFTL from "./QuoteSubmissionDetailsFTL";

const PUBLIC_QUOTE_REQUEST = gql`
  fragment PublicQuoteRequest_QuoteSubmissionDetails on PublicQuoteRequest {
    capacity_guarantee_required
    cargo_ready_date
    cargo_ready_date_tz
    commodities
    deadline_respond_at
    direct_sailing_required
    guid
    hazardous_goods_details
    include_customs_clearance
    incoterms_air
    incoterms_ocean
    insurance_required
    is_hazardous
    is_overweight
    item_packaging
    item_quantity
    item_type
    max_temp
    min_temp
    mode
    notes
    driver_preference
    packing_count
    pallet_count
    pallet_type
    per_package_dimensions_height
    per_package_dimensions_length
    per_package_dimensions_width
    carrier_routing_pref_notes
    portex_id
    quote_types
    reference_number
    routing_type
    service_levels
    shipper_name
    state
    subject_line
    target_delivery_date
    target_delivery_date_tz
    temp
    temp_unit
    total_volume
    total_weight
    trailer_size
    trailer_type
    ltl_trailer_type
    truck_quantity
    type
    unloading_details
    volume_format
    weight_unit
    charges {
      name
      quantity
      type
      unit
    }
    charges_air {
      name
      quantity
      item_quantity
      type
      unit
    }
    containers {
      max_temp
      min_temp
      requires_genset
      size
      type
      volume
      weight
    }
    locations {
      address_id
      city
      country_code
      country_name
      end
      formatted_long_name
      formatted_short_name
      iana_timezone
      is_time_tbd
      name
      notes
      port_name
      airport_iata_code
      airport_name
      position
      start
      type
      zip
    }
    package_groups {
      hazardous_goods_details
      is_hazardous
      item_quantity
      packaging_type
      per_package_dimensions_height
      per_package_dimensions_length
      per_package_dimensions_unit
      per_package_dimensions_width
      per_package_volume
      per_package_volume_unit
      per_package_weight
      per_package_weight_unit
    }
    package_groups_ltl {
      hazardous_goods_details
      is_hazardous
      item_quantity
      packaging_type
      per_package_dimensions_height
      per_package_dimensions_length
      per_package_dimensions_unit
      per_package_dimensions_width
      per_package_weight
      per_package_weight_unit
      freight_class
      commodities
      is_stackable
    }
    accessorials {
      name
      type
    }
    goods_value
  }
`;

type QuoteSubmissionDetailsProps = {
  isBrokerPreview?: boolean;
  hideStartQuoteBtn?: boolean;
  publicQuoteRequest?: OptionalMaybe<PublicQuoteRequest>;
  attachmentsList: React.ReactElement | false | null;
};

const QuoteSubmissionDetails: VFC<QuoteSubmissionDetailsProps> & {
  fragments: { PublicQuoteRequest: typeof PUBLIC_QUOTE_REQUEST };
} = ({ isBrokerPreview, hideStartQuoteBtn, publicQuoteRequest, attachmentsList }) => {
  if (!publicQuoteRequest) {
    return null;
  }

  const mode = publicQuoteRequest.mode as unknown as ModeQuotes;

  return (
    <>
      {mode === "FTL" && (
        <QuoteSubmissionDetailsFTL
          isBrokerPreview={isBrokerPreview}
          publicQuoteRequest={publicQuoteRequest}
          attachmentsList={attachmentsList}
        />
      )}
      {mode === "LTL" && (
        <QuoteRequestDetailsLTLView publicQuoteRequest={publicQuoteRequest} attachmentsList={attachmentsList} />
      )}
      {mode === "FCL" && (
        <QuoteSubmissionDetailsFCL
          hideStartQuoteBtn={hideStartQuoteBtn}
          isBrokerPreview={isBrokerPreview}
          publicQuoteRequest={publicQuoteRequest}
          attachmentsList={attachmentsList}
        />
      )}
      {mode === "AIR" && (
        <QuoteSubmissionDetailsAIR
          hideStartQuoteBtn={hideStartQuoteBtn}
          isBrokerPreview={isBrokerPreview}
          publicQuoteRequest={publicQuoteRequest}
          attachmentsList={attachmentsList}
        />
      )}
    </>
  );
};

QuoteSubmissionDetails.fragments = {
  PublicQuoteRequest: PUBLIC_QUOTE_REQUEST,
};

export default QuoteSubmissionDetails;
