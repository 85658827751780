import { Dialog } from "@portex-pro/ui-components";
import { useGetCurrentUserQuery } from "api/rest/users/getCurrentUser";
import Frame from "components/Frame";
import Loading from "components/Loading";
import withAsync from "components/withAsync";
import { useNotificationWatcher } from "hooks/notifications/useNotificationWatcher";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";
import OptionalMaybe from "types/OptionalMaybe";
import { useBoolean } from "usehooks-ts";

import FilesControl, { OnUploadSuccessCallback } from "../file-uploads/FilesControl";
import { ChatContext, ChatContextType } from "./ChatContext";
import { useChatStyles } from "./chatStyles";
import ChatView from "./ChatView";
import { useChat } from "./useChat";

interface ChatContainerProps {
  conversationId: OptionalMaybe<string | number>;
  onReceiveFile?: () => void;
  maxFiles?: number;
  roundedFrame?: boolean;
}

const ChatContainer = withAsync({
  useHook: (_props: ChatContainerProps) => {
    const releaseBrokerChat = useLDFlag("releaseBrokerChat");
    const getCurrentUserQuery = useGetCurrentUserQuery();
    const user = getCurrentUserQuery.data?.data.user;

    let variation: "shipper" | "broker" | undefined = undefined;

    if (!!user && user.shipper_id) {
      variation = "shipper";
    } else if (!!user && !user.shipper_id && !!releaseBrokerChat) {
      variation = "broker";
    }

    return {
      isLoading: getCurrentUserQuery.isLoading || !variation,
      variation,
    };
  },
  LoadingComponent: () => {
    const { t } = useTranslation(["common"]);
    return (
      <Frame title={t("common:chat.heading")} style={{ height: "100%" }}>
        <Loading height="100%" spinnerOnly />
      </Frame>
    );
  },
  Component: ({
    conversationId,
    onReceiveFile,
    maxFiles,
    roundedFrame = true,
    loadedData: { variation = "shipper" },
  }) => {
    const { value: isFullScreen, toggle: toggleFullScreen } = useBoolean(false);
    const classes = useChatStyles();

    const {
      messages,
      participants,
      addMessage,
      isSending,
      draftAttachments,
      addDraftAttachment,
      removeDraftAttachment,
      addParticipant,
    } = useChat({ conversationId: conversationId ? Number(conversationId) : 0, onReceiveFile, variation });

    useNotificationWatcher([`conversation:${conversationId}`]);

    const contextValue: ChatContextType = { isFullScreen };

    const handleFileUploaded: OnUploadSuccessCallback = async ({ fileId, filename }) => {
      addDraftAttachment({ filename, fileId });
    };

    const chatContent = (
      <ChatView
        ChatHeadingViewProps={{
          onToggleFullscreen: toggleFullScreen,
          isFullScreen: isFullScreen,
          chatParticipants: participants,
          addParticipant,
          hideAddChatParticipants: variation === "broker",
        }}
        ChatContentViewProps={{
          messages: messages,
          addMessage: addMessage,
          isSending: isSending,
          draftAttachments: draftAttachments,
          hideFileAttachments: variation === "broker",
        }}
        isFullScreen={isFullScreen}
        roundedFrame={roundedFrame}
      />
    );

    if (!conversationId) {
      return null;
    }

    return (
      <ChatContext.Provider value={contextValue}>
        <FilesControl
          fileIds={draftAttachments.map((draft) => draft.fileId)}
          onUploadSuccess={handleFileUploaded}
          onDeleteSuccess={(fileId) => Promise.resolve(removeDraftAttachment(fileId))}
          dropzoneOptions={{ noClick: false, maxFiles, maxSize: 5e6 }}
        >
          {isFullScreen ? (
            <Dialog
              open={true}
              maxWidth={false}
              onClose={toggleFullScreen}
              classes={{
                paper: classes.fullScreenWrapper,
              }}
            >
              {chatContent}
            </Dialog>
          ) : (
            <>{chatContent}</>
          )}
        </FilesControl>
      </ChatContext.Provider>
    );
  },
});

export default ChatContainer;
