import { FC } from "react";

import { QuoteRequest, useGetShipperQuoteRequestQuery } from "api/rest/quote-requests";
import { useUpdateShipperQuoteRequestMutation } from "api/rest/quote-requests/updateShipperQuoteRequest";
import FilesControl, { onDeleteSuccessCallback, OnUploadSuccessCallback } from "components/file-uploads/FilesControl";
import useLDFlag from "hooks/useLDFlag";

const QuoteRequestFilesControlContainer: FC<{ quoteRequestId: QuoteRequest["id"] }> = ({
  children,
  quoteRequestId,
}) => {
  const releaseQuoteRequestFiles = useLDFlag("releaseQuoteRequestFiles");
  const { quoteRequestFileIds } = useGetShipperQuoteRequestQuery(
    { urlParams: { quoteRequestId } },
    {
      skip: !quoteRequestId,
      selectFromResult: ({ data }) => ({ quoteRequestFileIds: data?.data.quoteRequest.fileIds ?? [] }),
    }
  );
  const [updateQuoteRequest] = useUpdateShipperQuoteRequestMutation();

  const onUploadSuccess: OnUploadSuccessCallback = async ({ fileId }) => {
    const fileIds = [...quoteRequestFileIds, fileId];
    await updateQuoteRequest({ urlParams: { quoteRequestId }, body: { fileIds } });
  };

  const onDeleteSuccess: onDeleteSuccessCallback = async (fileId) => {
    const fileIds = quoteRequestFileIds.filter((id) => id !== fileId);
    await updateQuoteRequest({ urlParams: { quoteRequestId }, body: { fileIds } });
  };

  if (!releaseQuoteRequestFiles) {
    return <>{children}</>;
  }

  return (
    <FilesControl
      fileIds={quoteRequestFileIds}
      dropzoneOptions={{ maxFiles: 4, multiple: false, maxSize: 5e6 }}
      onUploadSuccess={onUploadSuccess}
      onDeleteSuccess={onDeleteSuccess}
    >
      {children}
    </FilesControl>
  );
};

export default QuoteRequestFilesControlContainer;
