import { useEffect } from "react";

import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import { useTranslation } from "react-i18next";

import { useRemoveOneField, useSetAwardedPartnerFields, useUpdateOneField } from "../store/bidRequestFieldsSlice";
import {
  additionalFieldSelector1,
  additionalFieldsSelector2,
  additionalFieldsSelector3,
  locationsFieldSelector,
  poFieldSelector,
  quoteNumberFieldSelector,
  useBidRequestSliceSelector,
} from "../store/bidRequestStore";
import { StaticFieldKeys } from "../types";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSpecifyFields = () => {
  const stopsCount = useBidRequestSliceSelector((state) => state.bidRequestFieldsSlice.stopsCount);
  const { t } = useTranslation(["shipper", "common"]);
  const { t: fieldNamesT } = useTranslation("shipper", { keyPrefix: "bids.fieldNames" });
  const poField = useBidRequestSliceSelector(poFieldSelector);
  const quoteNumberField = useBidRequestSliceSelector(quoteNumberFieldSelector);
  const locationFields = useBidRequestSliceSelector(locationsFieldSelector);
  const additionalFieldsSection1 = useBidRequestSliceSelector(additionalFieldSelector1);
  const additionalFieldsSection2 = useBidRequestSliceSelector(additionalFieldsSelector2);
  const additionalFieldsSection3 = useBidRequestSliceSelector(additionalFieldsSelector3);
  const updateOneField = useUpdateOneField();
  const setAwardedPartnerFields = useSetAwardedPartnerFields();

  const removeOneField = useRemoveOneField();
  const pricingFrequency = useBidRequestSliceSelector((state) => state.bidRequestContractSlice.pricing_frequency);

  useEffect(() => {
    // We need to safe guard instances where the user loads onto this page with a non-weekly rfp but the store is initialized with weekly fields.
    if (
      !!pricingFrequency &&
      pricingFrequency !== ContractPricingFrequency.WEEKLY &&
      additionalFieldsSection1.length > 0
    ) {
      removeOneField(StaticFieldKeys.DELIVERY_WEEK);
    }
  }, [additionalFieldsSection1, removeOneField, pricingFrequency]);

  return {
    stopsCount,
    t,
    fieldNamesT,
    poField,
    quoteNumberField,
    locationFields,
    additionalFieldsSection1,
    additionalFieldsSection2,
    additionalFieldsSection3,
    updateOneField,
    setAwardedPartnerFields,
  };
};
