import { useEffect, useState, VFC } from "react";

import { MenuItem, ListItemText, CircularProgress, portexColor } from "@portex-pro/ui-components";
import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import ExcelIcon from "assets/ExcelIcon";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { downloadBlob } from "utils/downloadBlob";
import getSizeStr from "utils/styles/getSizeStr";

import {
  useShipperContractCsvExportMutation,
  useShipperContractCsvForDownloadQuery,
} from "../../../../api/rest/rfp/bidAwardApis/bidAwardApi";

const ContractExportCsvMenuItemContainer: VFC<{ onClose: () => void }> = (props) => {
  const { contractId } = useParams<{ contractId: string }>();
  const { t } = useTranslation("shipper");
  const { enqueueSnackbar } = useSnackbar();
  const [jobId, setJobId] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [exportCsv] = useShipperContractCsvExportMutation();
  const isDocumentVisible = useDocumentVisibility();
  const { data, isLoading } = useShipperContractCsvForDownloadQuery(
    jobId ? { urlParams: { contractId, jobId } } : skipToken,
    {
      pollingInterval: isDocumentVisible ? 5000 : undefined,
    }
  );

  useEffect(() => {
    if (!isLoading && data?.csv && data?.csv.length > 0) {
      const { csv } = data;
      const blob = new Blob([csv], { type: "text/csv" });
      const fileName = `RFP_${contractId}.csv`;
      downloadBlob(blob, fileName);
      setIsDisabled(false);
      setJobId("");
    }
  }, [contractId, data, isLoading]);

  const handleExportCsv = async () => {
    try {
      setIsDisabled(true);
      const { jobId } = await exportCsv({ urlParams: { contractId } }).unwrap();
      setJobId(jobId);
    } catch (e) {
      enqueueSnackbar(t("bids.award.header.exportCsvFailure"), {
        variant: "error",
        preventDuplicate: true,
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });
    } finally {
      props.onClose();
    }
  };

  return (
    <MenuItem disabled={isDisabled} onClick={handleExportCsv}>
      <ListItemText
        primary={
          <div style={{ display: "flex", alignItems: "center", gridColumnGap: getSizeStr(8) }}>
            {isDisabled ? (
              <CircularProgress size={20} style={{ color: portexColor.blue500 }} />
            ) : (
              <ExcelIcon width={26} height={26} />
            )}
            {t("bids.award.header.actions_export", { ns: "shipper" })}
          </div>
        }
      />
    </MenuItem>
  );
};

export default ContractExportCsvMenuItemContainer;
