import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

import findManyConversationsApi from "../api/findManyConversations";

type State = {
  selectedConversationId?: number;
  search?: string;
};

const initialState: State = {};

const sliceName = "conversationsSlice";

const conversationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    resetState: () => initialState,
    setState: (state, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(findManyConversationsApi.endpoints.findManyConversations.matchFulfilled, (state, payload) => {
      if (state.selectedConversationId === undefined && !!payload.payload.data.conversations[0]) {
        state.selectedConversationId = payload.payload.data.conversations[0].id;
      }
    });
  },
});

export const conversationsSliceActions = usableActions(conversationsSlice.actions);
export default conversationsSlice;
