import { ReactElement } from "react";

import {
  Box,
  Button,
  DataRow,
  Divider,
  Location,
  makeStyles,
  Route,
  Status,
  SvgPlaneCircleFilled,
  Typography,
} from "@portex-pro/ui-components";
import capitalize from "lodash/capitalize";
import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import { useTranslation } from "react-i18next";

import { AddressType, Mode, PublicQuoteRequest, QuoteRequestState } from "../../../../../../api/types/generated-types";
import LoadTypeChip from "../../../../../../components/LoadTypeChip";
import { EM_DASH } from "../../../../../../constants";
import { formatCommodities } from "../../../../../../utils/formatCommodities";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { formatCBM, formatWeight } from "../../../../../../utils/formatUnit";
import { getCargoSummary } from "../../../../../../utils/getCargoSummary";
import { getRoutingJourney } from "../../../../../../utils/getRoutingJourney";
import { isPort } from "../../../../../../utils/isPort";
import { renderSerializedNotes } from "../../../../../../utils/renderSerializedNotes";
import { toLuxonTimezone } from "../../../../../../utils/toLuxonTimezone";
import { ServiceLevelLabels } from "../../../../../shipper/pages/quotes/constants";
import { getRoutingTypeLabel } from "../../../../../shipper/pages/request-quote/utils/getRoutingTypeLabel";
import { getRequiredLabel } from "../../quote-submission-fcl/utils/getRequiredLabel";
import { useContextQuoteSubmissionAIR } from "../hooks/useContextQuoteSubmissionAIR";
import { getOrderedServiceLevels } from "../utils/getOrderedServiceLevels";

const DATA_ROW_LABEL_WIDTH = 260;

const useStyles = makeStyles(() => ({
  review: {
    letterSpacing: "0.8px",
    textTransform: "uppercase",
  },
}));

export type QuoteSubmissionDetailsFCLProps = {
  isBrokerPreview?: boolean;
  hideStartQuoteBtn?: boolean;
  publicQuoteRequest: PublicQuoteRequest;
  attachmentsList: React.ReactElement | false | null;
};

const QuoteSubmissionDetailsAIR = ({
  publicQuoteRequest,
  isBrokerPreview,
  hideStartQuoteBtn,
  attachmentsList,
}: QuoteSubmissionDetailsFCLProps): ReactElement => {
  const { t } = useTranslation(["common", "broker"]);
  const styles = useStyles();
  const { hasStartedSubmitting } = useContextQuoteSubmissionAIR();

  const {
    commodities,
    is_hazardous,
    hazardous_goods_details,
    include_customs_clearance,
    incoterms_air,
    insurance_required,
    goods_value,
    locations,
    notes,
    portex_id,
    service_levels,
    reference_number,
    routing_type,
    cargo_ready_date,
    cargo_ready_date_tz,
    target_delivery_date,
    target_delivery_date_tz,
    state,
    total_weight,
    total_volume,
    weight_unit,
    package_groups,
    item_quantity,
    shipper_name,
    carrier_routing_pref_notes,
  } = publicQuoteRequest;

  const sortedServiceLevels = getOrderedServiceLevels(service_levels);

  const [pickupLocation, deliveryLocation] = compact([first(locations), last(locations)]).map((location) =>
    isPort(location.type) ? location.airport_iata_code : location.city
  );

  const cargoSummary = getCargoSummary(package_groups, { total_volume, total_weight, weight_unit, item_quantity });
  const headings = getRoutingJourney(routing_type, Mode.Air).map((j) => j.locationHeading);

  const cargoDateFormatLuxon = "cccc, LLLL d";
  const cargoReadyDate = cargo_ready_date
    ? toLuxonTimezone(cargo_ready_date, cargo_ready_date_tz).toFormat(cargoDateFormatLuxon)
    : "";
  const targetDeliveryDate = target_delivery_date
    ? toLuxonTimezone(target_delivery_date, target_delivery_date_tz).toFormat(cargoDateFormatLuxon)
    : "";

  const booked = state === QuoteRequestState.Booked;
  const closed = state === QuoteRequestState.Closed;

  const bookedOrClosed = booked || closed;

  return (
    <>
      {!isBrokerPreview ? (
        <>
          <br />
          <Typography variant={"h5"} align={"center"}>
            <strong>{t("common:quoteRequest")}</strong>
          </Typography>
          <br />
          <Route
            pickup={pickupLocation}
            delivery={deliveryLocation}
            iconProps={{
              as: SvgPlaneCircleFilled,
              variant: undefined,
              palette: "purple",
            }}
          />
          <br />
        </>
      ) : null}
      <Box display={"flex"} alignItems={"baseline"} justifyContent={"space-between"} px={3.5} py={2}>
        <Typography className={styles.review}>
          <strong>{t("common:quoteDetails")}</strong>
        </Typography>
        <Typography>Portex ID # {portex_id}</Typography>
      </Box>
      <Divider />

      {reference_number ? (
        <>
          <DataRow label={t("common:referenceNo")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Box>
              <Typography display={"inline"}>{reference_number}</Typography>
            </Box>
          </DataRow>
          <Divider />
        </>
      ) : null}

      <DataRow label={t("broker:shipper")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <div>
          <Typography display={"inline"}>
            <strong>{shipper_name}</strong>
          </Typography>
        </div>
      </DataRow>
      <Divider />

      <DataRow label={t("common:mode")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Box>
          <LoadTypeChip mode={publicQuoteRequest.mode} />
        </Box>
      </DataRow>
      <Divider />

      <DataRow label={t("common:totalWeight")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>{formatWeight(total_weight)}</strong>
        </Typography>
      </DataRow>
      <Divider />

      <DataRow label={t("common:totalCBM")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>{formatCBM(total_volume)}</strong>
        </Typography>
      </DataRow>
      <Divider />

      <DataRow label={t("common:summaryOfCargo")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography display={"inline"}>
          {cargoSummary.map((s, i) => (
            <strong key={i}>
              {s}
              <br />
            </strong>
          ))}
        </Typography>
      </DataRow>
      <Divider />

      {commodities || is_hazardous ? (
        <>
          <DataRow label={t("common:commodities")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <div>
              {commodities ? (
                <Typography display={"inline"} style={{ marginRight: "6px" }}>
                  <strong>{formatCommodities(commodities)}</strong>
                </Typography>
              ) : null}
              {is_hazardous && (
                <>
                  <Status uppercase palette={"red"}>
                    {capitalize(t("broker:hazmat"))}
                  </Status>
                  {hazardous_goods_details ? (
                    <Typography>
                      <strong>
                        {t("common:notes")}: {renderSerializedNotes(hazardous_goods_details)}
                      </strong>
                    </Typography>
                  ) : null}
                </>
              )}
            </div>
          </DataRow>
          <Divider />
        </>
      ) : null}

      <DataRow label={t("common:serviceLevel")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>{sortedServiceLevels.map((level) => ServiceLevelLabels[level]).join(" & ")}</strong>
        </Typography>
      </DataRow>
      <Divider />

      <DataRow label={t("broker:cargoReady")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>{cargoReadyDate}</strong>
        </Typography>
      </DataRow>
      <Divider />

      {targetDeliveryDate ? (
        <>
          <DataRow label={t("broker:targetDestinationDelivery")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Typography>
              <strong>{targetDeliveryDate}</strong>
            </Typography>
          </DataRow>
          <Divider />
        </>
      ) : null}

      <DataRow label={t("common:incoterms")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <div>
          <Typography display={"inline"}>
            <strong>{`${incoterms_air} - ${getRoutingTypeLabel(routing_type)}`}</strong>
          </Typography>
        </div>
      </DataRow>
      <Divider />

      <DataRow label={t("broker:routingInformation")} labelAlignStart labelWidth={DATA_ROW_LABEL_WIDTH}>
        <div>
          {locations.map((l, i, { length }) => {
            const isLast = i === length - 1;

            const isAirport = l === null || l.type === AddressType.Airport;
            const heading = headings[i];
            const hasAirportLabels = !!l?.airport_iata_code && !!l.airport_name;
            const airportLabel = l
              ? `${l.airport_iata_code} ${EM_DASH} ${l.airport_name}`
              : t("common:forwarderPreference");

            const locationLabel = isAirport && hasAirportLabels ? airportLabel : l?.formatted_long_name;

            return (
              <Box key={i} display="flex">
                <Box mt="2px" textAlign="right" flexBasis="120px">
                  <Location.Head>{heading}</Location.Head>
                </Box>
                <Location
                  label={
                    <Typography>
                      <strong>{locationLabel}</strong>
                    </Typography>
                  }
                  connected={!isLast}
                  connectorSnapped={!isLast}
                  dotShape={isLast ? "square" : undefined}
                >
                  <br />
                </Location>
              </Box>
            );
          })}
        </div>
      </DataRow>
      <Divider />

      {carrier_routing_pref_notes && (
        <>
          <DataRow label={t("broker:carrierRoutingPreferences")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Typography>
              <strong>{renderSerializedNotes(carrier_routing_pref_notes)}</strong>
            </Typography>
          </DataRow>
          <Divider />
        </>
      )}

      <DataRow label={t("broker:customsClearance")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>{getRequiredLabel(include_customs_clearance)}</strong>
        </Typography>
      </DataRow>
      <Divider />

      <DataRow label={t("broker:cargoInsurance")} labelWidth={DATA_ROW_LABEL_WIDTH}>
        <Typography>
          <strong>
            {getRequiredLabel(insurance_required)}
            {insurance_required && goods_value ? t("broker:valueOfGoodsText", { value: formatUSD(goods_value) }) : ""}
          </strong>
        </Typography>
      </DataRow>
      <Divider />

      {notes ? (
        <>
          <DataRow label={t("common:additionalNotes")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            <Typography>{renderSerializedNotes(notes)}</Typography>
          </DataRow>
          <Divider />
        </>
      ) : null}

      {!!attachmentsList && (
        <>
          <DataRow label={t("common:attachments")} labelWidth={DATA_ROW_LABEL_WIDTH}>
            {attachmentsList}
          </DataRow>
          <Divider />
        </>
      )}

      {!isBrokerPreview && !hideStartQuoteBtn ? (
        <>
          <Divider />
          <Box px={2} py={3} display="flex" justifyContent="center">
            <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ width: 300, height: 72 }}
              onClick={hasStartedSubmitting.setTrue}
              disabled={bookedOrClosed}
            >
              <Typography variant="h6">
                <strong>{bookedOrClosed ? t("broker:quoteClosed") : t("common:startQuote")}</strong>
              </Typography>
            </Button>
          </Box>
        </>
      ) : null}
    </>
  );
};

export default QuoteSubmissionDetailsAIR;
