import { useEffect, useState } from "react";

/**
 * Be aware that for dev in chrome visibility is often toggled to be "visible" always
 * To get around this, open up the rendering tab with Cmd+Shift+P and search for the rendering tab
 * Then turn off `Emulate a Focused Page` to get the expected behavior
 *
 * Visibility indicates whether the page is visible to the user. This means the tab can be inactive/unfocused
 * but still visible to the end user. This is useful when we want to disable features (e.g. polling) only when
 * the window is not visible but continue those features when the window is no longer visible.
 *
 * Why we need this: https://github.com/reduxjs/redux-toolkit/issues/2339
 */
const useDocumentVisibility = (): boolean => {
  const [isVisible, setIsVisible] = useState(document.visibilityState === "visible");

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return isVisible;
};

export default useDocumentVisibility;
