import { ReactElement, useMemo, useState } from "react";

import NoteIcon from "@material-ui/icons/Note";
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  portexColor,
  TextInfo,
  Icon,
  Stacky,
  Tooltip,
  Dropdown,
  MenuItem,
  makeStyles,
} from "@portex-pro/ui-components";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import PortexFeaturedPartnerChip from "features/featured-brokers/PortexFeaturedPartnerChip";
import ShipperLaneBenchmark from "features/lane-benchmark/ShipperLaneBenchmark";
import useFeaturedBrokers from "hooks/useFeaturedBrokers";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import { generatePath, Link } from "react-router-dom";
import { formatUSD } from "utils/formatCurrency";
import getTransitTimeString from "utils/getTransitTimeString";

import {
  QuoteStatus,
  ContactsQuoteRequests,
  Maybe,
  QuoteRequest,
  LtlQuote,
} from "../../../../../api/types/generated-types";
import Loading from "../../../../../components/Loading";
import { EMPTY_CELL_HYPHEN, NON_BREAKING_SPACE } from "../../../../../constants";
import getQuoteRequestInfo from "../../../../../utils/getQuoteRequestInfo";
import { renderSerializedNotes } from "../../../../../utils/renderSerializedNotes";
import ButtonBookNow from "../../../components/ButtonBookNow";
import { getQuoteValidDetails } from "../../quotes/utils/getQuoteValidDetails";
import useFeaturedBrokerStyles from "../hooks/useFeaturedBrokerStyles";
import LtlPendingQuote from "./LtlPendingQuote";

type LtlQuotesIndexBodyProps = {
  lastRefreshedAt: string;
  quoteRequestId: string;
  quoteRequest?: Maybe<QuoteRequest>;
  contactsQuoteRequest?: Maybe<ContactsQuoteRequests>[];
  refetchQuoteRequest?: () => Promise<unknown>;
  loading?: boolean;
};

export enum SORT_MODE {
  Cheapest = "total_amount",
  Fastest = "fastest",
  VALIDITY = "valid_until",
}

const METADATA_TYPOGRAPHY_VARIANT = "caption";

const useStyles = makeStyles(() => ({
  secondaryButton: { whiteSpace: "nowrap", width: "100%", padding: "0.5rem 2rem" },
}));

const LtlQuotesIndexBody = ({
  lastRefreshedAt,
  quoteRequestId,
  quoteRequest,
  contactsQuoteRequest,
  loading,
}: LtlQuotesIndexBodyProps): ReactElement => {
  const { t } = useTranslation(["common"]);
  const { featuredBrokers, isFeaturedBroker } = useFeaturedBrokers();
  const featuredBrokerStyles = useFeaturedBrokerStyles();
  const classes = useStyles();
  const [sortMode, setSortMode] = useState(SORT_MODE.Cheapest);

  const activeQuotes = useMemo(() => {
    return filter(quoteRequest?.ltl_quotes ?? [], (q) => q.status !== QuoteStatus.Inactive);
  }, [quoteRequest?.ltl_quotes]);

  const pendingCompanies = useMemo(() => {
    if (!contactsQuoteRequest || !quoteRequest) return [];

    const pendingContacts = contactsQuoteRequest
      .filter((c) => c?.contact?.is_internal === false)
      .filter(
        (contactQuoteRequest) =>
          !activeQuotes.some(
            (quote) =>
              quote.submitter_email === contactQuoteRequest?.contact.user.email ||
              quote.company_name === contactQuoteRequest?.contact.company_name
          )
      );

    const grouped = groupBy(pendingContacts, "contact.company_name");

    return keys(grouped);
  }, [activeQuotes, contactsQuoteRequest, quoteRequest]);

  const sortFastestQuote = (quotes: LtlQuote[]): LtlQuote[] => {
    const sorted = sortBy(quotes, ["min_transit_time", "max_transit_time", "total_amount"]);
    for (let i = 0; i < quotes.length; i++) {
      const quote = sorted[0];
      if (!quote.min_transit_time && !quote.max_transit_time) {
        sorted.push(quote);
        sorted.shift();
      } else {
        break;
      }
    }

    return sorted;
  };

  const sortedQuotes = useMemo(() => {
    const sortedByMode =
      sortMode === SORT_MODE.Fastest ? sortFastestQuote(activeQuotes) : sortBy(activeQuotes, sortMode);
    const sortedByFeatured = sortBy(sortedByMode, (quote) => (isFeaturedBroker(quote.submitter_email) ? -1 : 0));
    return featuredBrokers ? sortedByFeatured : sortedByMode;
  }, [activeQuotes, sortMode, isFeaturedBroker, featuredBrokers]);

  const cheapestQuote = useMemo(() => {
    return sortBy(activeQuotes, "total_amount")?.[0];
  }, [activeQuotes]);

  const fastestQuote = useMemo(() => {
    return sortFastestQuote(activeQuotes)?.[0];
  }, [activeQuotes]);

  const {
    isQuoteRequestBooked,
    isQuoteRequestCanceled,
    isQuoteRequestClosed,
    isClosedOrCanceled,
    closedAt,
    canceledAt,
  } = getQuoteRequestInfo(quoteRequest);

  return (
    <Box px={2} py={3}>
      <Box pb={2} display="flex" flexDirection="row" justifyContent="end" alignItems="center">
        <LastRefreshedAtText value={lastRefreshedAt} />
        <Dropdown
          placeholder={`Sort by ${
            sortMode === SORT_MODE.Cheapest
              ? "Cheapest first"
              : sortMode === SORT_MODE.Fastest
              ? "Fastest first"
              : "Quote Validity"
          }`}
        >
          {({ onClose }) =>
            [
              <MenuItem
                className={sortMode === SORT_MODE.Cheapest ? "Por-selected" : ""}
                onClick={() => {
                  setSortMode(SORT_MODE.Cheapest);
                  onClose();
                }}
              >
                Cheapest first
              </MenuItem>,
              <MenuItem
                className={sortMode === SORT_MODE.Fastest ? "Por-selected" : ""}
                onClick={() => {
                  setSortMode(SORT_MODE.Fastest);
                  onClose();
                }}
              >
                Fastest first
              </MenuItem>,
              <MenuItem
                className={sortMode === SORT_MODE.VALIDITY ? "Por-selected" : ""}
                onClick={() => {
                  setSortMode(SORT_MODE.VALIDITY);
                  onClose();
                }}
              >
                Quote Validity
              </MenuItem>,
            ].map((element, idx) => <span key={idx}>{element}</span>)
          }
        </Dropdown>
      </Box>
      <ShipperLaneBenchmark quoteRequest={quoteRequest} />
      <Grid container spacing={2}>
        {loading ? (
          <Loading height="auto" />
        ) : (
          <>
            {sortedQuotes.map((quote, index, quotes) => {
              const isCheapestQuote = quote.id === cheapestQuote.id;
              const isFastestQuote = quote.id === fastestQuote.id;

              const showCheapest = isCheapestQuote && !isFastestQuote;
              const showFastest = isFastestQuote && !isCheapestQuote;
              const showBoth = isCheapestQuote && isFastestQuote;

              const showFeatured = isFeaturedBroker(quote.submitter_email);
              const style = showFeatured
                ? featuredBrokerStyles.featuredBroker
                : showCheapest || showBoth
                ? "Por-border-green"
                : showFastest
                ? "Por-border-blue"
                : "Por-border-grey200";
              const palette = showFeatured
                ? "gold"
                : showCheapest || showBoth
                ? "green"
                : showFastest
                ? "blue"
                : undefined;

              const { validUntil, isExpired } = getQuoteValidDetails(quote);
              const quoteBooked = quote.status === QuoteStatus.Booked;
              const quoteCanceled = quote.status === QuoteStatus.Canceled;
              const transitTime = getTransitTimeString(quote.min_transit_time, quote.max_transit_time);

              const pathToQuote = generatePath("/shipper/quotes/:quoteRequestId/:quoteId", {
                quoteRequestId: quoteRequestId,
                quoteId: quote.id,
              });

              return (
                <Grid key={index} item xs={12} style={{ minWidth: 200 }}>
                  {showFeatured && <PortexFeaturedPartnerChip className="relative left-5 top-3" />}
                  <Paper className={style}>
                    <Box m={2}>
                      <Grid container justify="space-between" alignItems="center" spacing={3}>
                        <Grid item xs={12} sm={6} md={2} lg={3}>
                          <Typography variant={"h6"} color={"textPrimary"} gutterBottom>
                            <strong>{quote.company_name}</strong>
                          </Typography>
                          {quote.carrier_name ? (
                            <Typography color={"inherit"} gutterBottom>
                              <strong>Carrier: {quote.carrier_name}</strong>
                            </Typography>
                          ) : null}
                          {showBoth ? (
                            <Box color={portexColor.green500}>
                              <Typography variant={"caption"} color={"inherit"}>
                                <strong>{t("common:cheapestAndFastest").toUpperCase()}</strong>
                              </Typography>
                            </Box>
                          ) : null}
                          {showCheapest ? (
                            <Box color={portexColor.green500}>
                              <Typography variant={"caption"} color={"inherit"}>
                                <strong>{t("common:cheapest").toUpperCase()}</strong>
                              </Typography>
                            </Box>
                          ) : null}
                          {showFastest ? (
                            <Box color={portexColor.blue500}>
                              <Typography variant={"caption"} color={"inherit"}>
                                <strong>{t("common:fastest").toUpperCase()}</strong>
                              </Typography>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6} md={1} lg={1}>
                          {quote.notes ? (
                            <TextInfo
                              label={t("common:notes")}
                              heading={
                                <Tooltip
                                  arrow
                                  title={renderSerializedNotes(quote.notes)}
                                  PopperProps={{ placement: "bottom-start" }}
                                >
                                  <Box display="flex" flexDirection="row" style={{ cursor: "help" }}>
                                    <Icon
                                      as={NoteIcon}
                                      style={{ marginTop: -2, paddingRight: 3, width: "2rem", height: "2rem" }}
                                    />
                                    <Typography variant="subtitle1">View</Typography>
                                  </Box>
                                </Tooltip>
                              }
                              metadata={
                                <Typography variant={METADATA_TYPOGRAPHY_VARIANT}>{NON_BREAKING_SPACE}</Typography>
                              }
                            />
                          ) : null}
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <TextInfo
                            label={"Valid Until"}
                            heading={validUntil}
                            metadata={
                              <Typography noWrap variant={METADATA_TYPOGRAPHY_VARIANT} color={"error"}>
                                {isExpired ? "This quote is expired" : NON_BREAKING_SPACE}
                              </Typography>
                            }
                            palette={isExpired ? "red" : undefined}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={2}>
                          <TextInfo
                            label="Est. Transit Time"
                            heading={transitTime || EMPTY_CELL_HYPHEN}
                            metadata={
                              <Typography variant={METADATA_TYPOGRAPHY_VARIANT}>{NON_BREAKING_SPACE}</Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2} lg={1}>
                          <TextInfo
                            label="Rate"
                            palette={palette}
                            heading={formatUSD(quote.total_amount)}
                            metadata={
                              <Typography variant={METADATA_TYPOGRAPHY_VARIANT}>{NON_BREAKING_SPACE}</Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: "center" }}>
                          <Box width="100%">
                            <Stacky breakpoint={180}>
                              <Button
                                variant={"outlined"}
                                color={"primary"}
                                className={
                                  showFeatured ? featuredBrokerStyles.featuredSecondaryButton : classes.secondaryButton
                                }
                                component={Link}
                                to={pathToQuote}
                              >
                                View Quote
                              </Button>
                              {isQuoteRequestBooked || quotes.some((q) => q.status === QuoteStatus.Booked) ? (
                                <>
                                  {quoteBooked ? (
                                    <ButtonBookNow bookNowVariant="booked" style={{ pointerEvents: "none" }} />
                                  ) : (
                                    <ButtonBookNow bookNowVariant="booked-by-other" />
                                  )}
                                </>
                              ) : !isClosedOrCanceled ? (
                                <ButtonBookNow
                                  bookNowVariant="book"
                                  // @ts-expect-error: these props work -- its just challenging to recreate how <Button> is typed by MUI to leverage overrideable components
                                  component={Link}
                                  to={pathToQuote}
                                  className={showFeatured ? featuredBrokerStyles.featuredPrimaryButton : undefined}
                                />
                              ) : null}
                              {isQuoteRequestClosed ? (
                                <ButtonBookNow bookNowVariant="closed" closedAt={closedAt} />
                              ) : null}
                              {isQuoteRequestCanceled ? (
                                <>
                                  {quoteCanceled ? (
                                    <ButtonBookNow bookNowVariant="canceled" canceledAt={canceledAt} />
                                  ) : (
                                    <ButtonBookNow bookNowVariant="canceled-by-other" />
                                  )}
                                </>
                              ) : null}
                            </Stacky>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
            {pendingCompanies.map((company, i) => (
              <LtlPendingQuote key={i} companyName={company} />
            ))}
            {!sortedQuotes.length && !pendingCompanies.length ? <LtlPendingQuote companyName={""} /> : null}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default LtlQuotesIndexBody;
