import AppBar from "components/AppBar";
import ShipmentHeaderView from "components/loads/ShipmentHeaderView";
import withAsync from "components/withAsync";
import useLDFlag from "hooks/useLDFlag";
import { NumberParam, useQueryParam } from "use-query-params";
import { useBoolean } from "usehooks-ts";

import useBrokerShipment from "../hooks/useBrokerShipment";
import { useBrokerLoadStatusSliceSelector } from "../store/brokerLoadStatusStore";
import BrokerShipmentDetailsView from "../views/BrokerShipmentDetailsView";
import LoadHeaderView from "../views/LoadHeaderView";
import LoadStatusPageTabsContainer from "./LoadStatusPageTabsContainer";

const LoadStatusPageHeaderContainer = withAsync({
  useHook: useBrokerShipment,
  Component: ({ loadedData }) => {
    const enableBrokerAppBar = useLDFlag("enableBrokerAppBar");
    const shipment = loadedData.data.data.shipment;
    const { selectedLoadId } = useBrokerLoadStatusSliceSelector((state) => state.brokerLoadStatusSlice);
    const isViewAllDetailsOpen = useBoolean(false);
    const [_loadIdQueryParam, setLoadIdQueryParam] = useQueryParam("loadId", NumberParam);
    const loads = shipment.trucks.map((truck) => ({
      id: truck.id,
      referenceNumber: truck.reference_number,
      trackingLink: truck.tracking_link,
    }));

    const handleClickViewDetails = isViewAllDetailsOpen.setTrue;

    const backButtonTo = `/broker/loads`;

    const { currentTab } = useBrokerLoadStatusSliceSelector((state) => state.brokerLoadStatusUiSlice);

    return (
      <>
        <AppBar>
          <ShipmentHeaderView
            hidePortexLogo={!!enableBrokerAppBar}
            backButtonTo={!!enableBrokerAppBar && backButtonTo}
            portexId={shipment.portex_id}
            mode={shipment.mode}
            lane={shipment.lane}
            shipper={shipment.shipper_name}
            equipment={shipment.equipment}
            onClickViewDetails={handleClickViewDetails}
          />
          <LoadStatusPageTabsContainer />
        </AppBar>
        {currentTab === "loads" && (
          <LoadHeaderView
            selectedLoadId={selectedLoadId}
            loads={loads}
            onChangeLoad={setLoadIdQueryParam}
            backButtonTo={!enableBrokerAppBar && backButtonTo}
            shipmentReferenceNumber={shipment.reference_number}
          />
        )}
        <BrokerShipmentDetailsView.Drawer
          shipment={shipment}
          isOpen={isViewAllDetailsOpen.value}
          onClose={isViewAllDetailsOpen.setFalse}
        />
      </>
    );
  },
});

export default LoadStatusPageHeaderContainer;
