import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

import { BrokerLoadStatusPageTabs } from "../types/BrokerLoadStatusPageTabs";

type State = {
  currentTab?: BrokerLoadStatusPageTabs;
};

export const initialState: State = {};

const slice = createSlice({
  name: "brokerLoadStatusUiSlice",
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const brokerLoadStatusUiActions = usableActions(slice.actions);
export default slice;
