import React, { createContext, useContext, useEffect, useMemo, useState } from "react";

import {
  useDeleteFileByIdMutation,
  useGenerateUrlForFileUploadMutation,
  useLazyGetFileByIdQuery,
} from "api/rest/files/filesApi";
import useLDFlag from "hooks/useLDFlag";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import { DropzoneOptions, DropzoneState, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { getFileExtension } from "utils/file-uploads/getFileExtension";
import { formatBytesTo } from "utils/formatBytesTo";
import getContentTypeForExtension from "utils/getContentTypeForExtension";

import { FileControlWrapper, useFilesCacheSlice } from "./FilesCache";

type UploadResult = { fileId: string; filename: string };

export type OnUploadSuccessCallback = (result: UploadResult) => Promise<void>;
export type OnMultiUploadSuccessCallback = (result: UploadResult[]) => Promise<void>;
export type onDeleteSuccessCallback = (fileId: string) => Promise<void>;

interface FilesControlProps {
  dropzoneOptions?: DropzoneOptions;
  allowedExtensions?: string[];
  onUploadSuccess?: OnUploadSuccessCallback;
  onMultiUploadSuccess?: OnMultiUploadSuccessCallback;
  onDeleteSuccess?: onDeleteSuccessCallback;
  fileIds: string[];
}

type OnFilesUploadFinishedCallBack = (params: { success: boolean }) => void;

type FilesContext = {
  onDeleteFileFromDB: (localId: string) => Promise<void>;
  onDeleteFileFromFeature: (localId: string) => Promise<void>;
  useGetFiles: () => FileControlWrapper[];
  useGetFile: () => (localId: string) => FileControlWrapper | undefined;
  isInitialLoad: boolean;
  useOnFilesUploadStartedCallback: (callback: (localIds: string[]) => void) => void;
  useOnFilesUploadFinishedCallback: (callback: OnFilesUploadFinishedCallBack) => void;
  maxFiles?: number;
  maxSize?: number;
  multiple?: boolean;
  disabled?: boolean;
  allowedExtensions: string[];
} & DropzoneState;

/** Context */
const FilesContext = createContext<FilesContext>({} as FilesContext);

export const useFilesContext = (): FilesContext => {
  return useContext(FilesContext);
};

/** @todo abstract to consts */
// const allFileExtensions = Object.values(FileExtensions);

// const defaultAllowedExtensions = allFileExtensions;

/** @todo Move this to utils once old fileupload is deprecated */
const isValidExtension = (ext: string, allowedExtensions: string[]): boolean => {
  return allowedExtensions.some((allowedExt) => allowedExt.toLowerCase() === ext.toLowerCase());
};

const FilesControl: React.FC<FilesControlProps> = (props) => {
  const {
    children,
    dropzoneOptions,
    allowedExtensions: providedExtensions,
    onDeleteSuccess,
    onMultiUploadSuccess,
    onUploadSuccess,
    fileIds,
  } = props;
  const { maxFiles, maxSize, multiple, disabled } = dropzoneOptions ?? {};
  const defaultAllowedExtensions = useLDFlag("allowedFileExtensions")?.allowedExtensions ?? [];
  const allowedExtensions = providedExtensions ?? defaultAllowedExtensions;

  // Files Cache Slice and Mutations/Getters
  const { actions, useGetFile, useGetFiles } = useFilesCacheSlice();
  const { useAddMany, useUpdateOne, useRemoveMany, useAddOne, useRemoveOne } = actions;

  const addMany = useAddMany();
  const updateOne = useUpdateOne();
  const removeMany = useRemoveMany();
  const removeOne = useRemoveOne();
  const addOne = useAddOne();

  const allFileWrappers = useGetFiles();
  const getFile = useGetFile();

  // isInitialLoad state
  const [isInitialLoad, setIsInitialLoading] = useState(true);

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // i18n
  const { t } = useTranslation("common");

  // Apis
  const [generateUrlForFileUploadMutation] = useGenerateUrlForFileUploadMutation();
  const [deleteFileById] = useDeleteFileByIdMutation();
  const [getFileById] = useLazyGetFileByIdQuery();

  // Callbacks
  const [onFilesUploadStartedCallback, setOnFilesUploadStartedCallback] = useState<
    ((fileIds: string[]) => void) | undefined
  >();
  const _useOnFilesUploadStartedCallback = (callback: (fileIds: string[]) => void) => {
    useEffect(() => {
      setOnFilesUploadStartedCallback(() => callback);
    }, [callback]);
  };
  const useOnFilesUploadStartedCallback = useMemo(() => _useOnFilesUploadStartedCallback, []);

  const [onFilesUploadFinishedCallback, setOnFilesUploadFinishedCallback] = useState<
    OnFilesUploadFinishedCallBack | undefined
  >();
  const _useOnFilesUploadFinishedCallback = (callback: OnFilesUploadFinishedCallBack) => {
    useEffect(() => {
      setOnFilesUploadFinishedCallback(() => callback);
    }, [callback]);
  };
  const useOnFilesUploadFinishedCallback = useMemo(() => _useOnFilesUploadFinishedCallback, []);

  // Trigger initial file loads
  useEffect(() => {
    (async () => {
      const unloadedFileIds = fileIds.filter((fileId) =>
        allFileWrappers.every((fileWrapper) => fileWrapper.file.id !== fileId && fileWrapper.localId !== fileId)
      );

      if (!unloadedFileIds.length) {
        setIsInitialLoading(false);
        return;
      }

      addMany({
        fileWrappers: unloadedFileIds.map((fileId) => ({
          localId: fileId,
          fileStatus: "loading",
          file: {},
        })),
      });

      const fileResults = await Promise.all(unloadedFileIds.map((fileId) => getFileById({ id: fileId }).unwrap()));

      fileResults.forEach((fileResult) => {
        updateOne({ fileWrapper: { file: fileResult.data, fileStatus: "success", localId: fileResult.data.id } });
      });

      if (isInitialLoad) {
        setIsInitialLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileIds, allFileWrappers]);

  // Remove deleted files
  useEffect(() => {
    if (allFileWrappers.length === fileIds.length) {
      return;
    }
    const deletedFileWrappers = allFileWrappers
      .filter((fileWrapper) => fileWrapper.fileStatus === "deleted")
      .filter((deletedFile) => fileIds.every((id) => id !== deletedFile.file.id));

    if (!!deletedFileWrappers.length) {
      removeMany({ fileWrapperIds: deletedFileWrappers.map((fileWrapper) => fileWrapper.localId) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFileWrappers, fileIds]);

  const uploadFile = async <T extends File>(file: T, localId: string) => {
    const fileExtension = getFileExtension(file.name);

    addOne({
      fileWrapper: {
        file: { id: localId, extension: fileExtension, name: file.name },
        fileStatus: "uploading",
        localId,
      },
    });

    try {
      // Get presigned key for S3
      const { file: fileResult, putUrl } = await generateUrlForFileUploadMutation({
        body: { extension: fileExtension, name: file.name },
      }).unwrap();

      updateOne({ fileWrapper: { localId, file: { ...fileResult } } });

      // Upload to S3
      const headers: HeadersInit = {};
      const contentType = getContentTypeForExtension(fileResult.extension);
      if (contentType) {
        headers["Content-Type"] = contentType; // This updates the Metadata --> Content-Type for the file in S3
      }
      const body = await file.arrayBuffer();
      const res = await fetch(putUrl, { method: "PUT", headers, body });
      if (res.status >= 300) {
        throw new Error("Error attempting ");
      }

      const successResult = { fileId: fileResult.id, filename: fileResult.name };
      // Finish callback
      await onUploadSuccess?.(successResult);

      updateOne({ fileWrapper: { fileStatus: "success", localId } });

      return successResult;
    } catch (e) {
      Sentry.captureException(e);
      enqueueSnackbar(t("fileUploads.generic_upload_error", { name: file.name }), { variant: "error" });
      removeOne({ fileWrapperId: localId });
      throw e;
    }
  };

  const onDropAccepted: DropzoneOptions["onDropAccepted"] = async (droppedFiles) => {
    if (maxFiles && maxFiles > 0 && allFileWrappers.length + droppedFiles.length > maxFiles) {
      // @todo throw some error here to dropzone
      enqueueSnackbar(t("fileUploads.fileUploadControl_error_fileAmount", { count: maxFiles }), { variant: "error" });
      throw "too-many-files";
    }

    const localIds = droppedFiles.map(() => uniqueId("local_ids_"));
    onFilesUploadStartedCallback?.(localIds);

    try {
      const results = await Promise.allSettled(droppedFiles.map((file, i) => uploadFile(file, localIds[i])));

      const successResults = results.filter((result) => result.status === "fulfilled") as PromiseFulfilledResult<
        Awaited<ReturnType<typeof uploadFile>>
      >[];
      const failedResults = results.filter((result) => result.status === "rejected") as PromiseRejectedResult[];

      await onMultiUploadSuccess?.(successResults.map((result) => result.value));

      if (failedResults.length) {
        throw new Error("Multi file upload failed with errors");
      }

      onFilesUploadFinishedCallback?.({ success: true });
    } catch {
      onFilesUploadFinishedCallback?.({ success: false });
    }
  };

  const onDropRejected: DropzoneOptions["onDropRejected"] = (fileRejections, _event) => {
    /** Find individual file errors */
    fileRejections.map((rejection) => {
      if (rejection.errors.some((error) => error.code === "file-too-large")) {
        enqueueSnackbar(
          t("fileUploads.fileUploadControl_error_fileSize", {
            name: rejection.file.name,
            size: formatBytesTo(maxSize ?? 0, "MB"),
          }),
          { variant: "error" }
        );
      }
      if (rejection.errors.some((error) => error.code === "wrong-extension")) {
        enqueueSnackbar(
          t("fileUploads.fileUploadControl_error_fileExt", {
            name: rejection.file.name,
            ext: rejection.errors.find((rejection) => rejection.code === "wrong-extension")?.message,
          }),
          { variant: "error" }
        );
      }
    });

    /** Find aggregate file errors */
    if (fileRejections.some((rejection) => rejection.errors.some((error) => error.code === "too-many-files"))) {
      enqueueSnackbar(
        t("fileUploads.fileUploadControl_error_fileAmount", {
          count: maxFiles ?? 0,
        }),
        { variant: "error" }
      );
    }
  };

  const onDeleteFileFromDB = async (localId: string) => {
    const fileWrapper = getFile(localId);

    if (!fileWrapper || !fileWrapper.file.id) {
      enqueueSnackbar(t("fileUploads.generic_delete_error", { name: fileWrapper?.file.name ?? "" }), {
        variant: "error",
      });
      Sentry.captureException(new Error("Trying to delete a file with no idea"));
      return;
    }

    const initialFileStatus = fileWrapper.fileStatus;

    updateOne({ fileWrapper: { fileStatus: "deleting", localId: fileWrapper.localId } });

    /** @todo add error handling */
    try {
      if (
        !initialFileStatus.startsWith("error_") ||
        (initialFileStatus.startsWith("error") && initialFileStatus === "error_deleting_record")
      ) {
        await deleteFileById({ urlParams: { id: fileWrapper.file.id } });
      }
    } catch (e) {
      enqueueSnackbar(t("fileUploads.generic_delete_error", { name: fileWrapper.file.name }), { variant: "error" });
      updateOne({ fileWrapper: { localId: fileWrapper.localId, fileStatus: "error_deleting_record" } });
      Sentry.captureException(e);
      return;
    }

    try {
      if (
        !initialFileStatus.startsWith("error_") ||
        (initialFileStatus.startsWith("error") && initialFileStatus === "error_deletion_callback")
      ) {
        await onDeleteSuccess?.(fileWrapper.file.id);
      }
    } catch (e) {
      enqueueSnackbar(t("fileUploads.generic_delete_error", { name: fileWrapper.file.name }), { variant: "error" });
      updateOne({ fileWrapper: { localId: fileWrapper.localId, fileStatus: "error_deletion_callback" } });
      Sentry.captureException(e);
      return;
    }

    /** @todo add error handling */

    updateOne({ fileWrapper: { fileStatus: "deleted", localId: fileWrapper.localId } });
  };

  const onDeleteFileFromFeature = async (localId: string) => {
    const fileWrapper = getFile(localId);

    if (!fileWrapper || !fileWrapper.file.id) {
      enqueueSnackbar(t("fileUploads.generic_delete_error", { name: fileWrapper?.file.name ?? "" }), {
        variant: "error",
      });
      Sentry.captureException(new Error("Trying to delete a file with no idea"));
      return;
    }

    updateOne({ fileWrapper: { fileStatus: "deleting", localId: fileWrapper.localId } });

    try {
      await onDeleteSuccess?.(fileWrapper.file.id);
    } catch (e) {
      enqueueSnackbar(t("fileUploads.generic_delete_error", { name: fileWrapper.file.name }), { variant: "error" });
      updateOne({ fileWrapper: { localId: fileWrapper.localId, fileStatus: "error_deletion_callback" } });
      Sentry.captureException(e);
      return;
    }

    updateOne({ fileWrapper: { fileStatus: "deleted", localId: fileWrapper.localId } });
  };

  const validator: DropzoneOptions["validator"] = (file) => {
    const fileExtension = getFileExtension(file.name);

    if (isValidExtension(fileExtension, allowedExtensions)) {
      return null;
    }

    return { code: "wrong-extension", message: fileExtension };
  };

  const dropzoneState = useDropzone({ noClick: true, ...dropzoneOptions, onDropAccepted, onDropRejected, validator });

  return (
    <FilesContext.Provider
      value={{
        ...dropzoneState,
        onDeleteFileFromDB,
        onDeleteFileFromFeature,
        useGetFile,
        useGetFiles,
        isInitialLoad,
        useOnFilesUploadStartedCallback,
        useOnFilesUploadFinishedCallback,
        maxFiles,
        maxSize,
        multiple,
        disabled,
        allowedExtensions,
      }}
    >
      {children}
    </FilesContext.Provider>
  );
};

export default FilesControl;
