import { VFC } from "react";

import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  FormControl,
  FormControlProps,
  FormLabel,
  FormLabelProps,
} from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import OptionalMaybe from "types/OptionalMaybe";

type FormFuelButtonGroupViewProps = {
  /**
   * @summary `value` is null-able to allow tracking that the user has not made a selection yet. Field still required to complete Bid Request
   * @example true: 'Include Fuel' is selected
   * @example false: 'Without Fuel' is selected
   * @example null: Neither option is selected
   *  */
  value: OptionalMaybe<boolean>;
  onChange: (val: boolean) => void;
  label?: string;
  formControlProps?: FormControlProps;
  formLabelProps?: FormLabelProps;
  buttonGroupProps?: ButtonGroupProps;
  hideFormLabel?: boolean;
};

const FormFuelButtonGroupView: VFC<FormFuelButtonGroupViewProps> = (props) => {
  const { value, onChange, label, formControlProps, formLabelProps, buttonGroupProps, hideFormLabel } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.bidRequestPage.pageBidAttributes" });
  return (
    <FormControl fullWidth margin={"normal"} {...formControlProps}>
      {!hideFormLabel && <FormLabel {...formLabelProps}>{label || t("fuel_label")}</FormLabel>}
      <ButtonGroup fullWidth color="primary" {...buttonGroupProps}>
        <Button className={value === true ? "Ptx-selected" : undefined} onClick={() => onChange(true)}>
          {t("withFuelButton_label")}
        </Button>
        <Button className={value === false ? "Ptx-selected" : undefined} onClick={() => onChange(false)}>
          {t("withoutFuelButton_label")}
        </Button>
      </ButtonGroup>
    </FormControl>
  );
};

export default FormFuelButtonGroupView;
