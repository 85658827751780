import { EM_DASH } from "constants/index";

import { FC } from "react";

import { Box, Paper, portexColor } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";

import { Lane } from "../types";
import LaneSummaryGenericPropertyView from "./LaneSummaryGenericPropertyView";
import LaneSummaryStopsCardView from "./LaneSummaryStopsCardView";
import LaneSummaryTooltipPropertyView from "./LaneSummaryTooltipPropertyView";

interface ContractLaneSummaryViewProps {
  lane?: Lane;
  requestedCount?: number;
  isLoading: boolean;
}

const ContractLaneSummaryView: FC<ContractLaneSummaryViewProps> = (props) => {
  const { lane, requestedCount, isLoading } = props;
  const { t } = useTranslation("shipper", { keyPrefix: "bids.award" });
  const awardedNames = lane?.awarded_bids.map((awardedBid) => awardedBid.submitter.company_name);
  const awardedNamesStr = awardedNames && awardedNames.length > 0 ? awardedNames?.join(", ") : EM_DASH;
  const awardedToCount = lane?.awarded_bids.length ?? 0;

  return (
    <Paper
      style={{
        marginBottom: "20px",
        border: `1px solid ${portexColor.grey300}`,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
      }}
    >
      <Box display="flex" flexWrap="wrap" flexDirection="row">
        <Box width="30%" height="100%" alignSelf="center">
          <LaneSummaryStopsCardView isLoading={isLoading} lane={lane} />
        </Box>
        <Box width="70%" display="flex" flexDirection="column" borderLeft={`1px solid ${portexColor.grey300}`}>
          <LaneSummaryGenericPropertyView
            isLoading={isLoading}
            top={t("laneSummary")}
            style={{
              borderBottom: `1px solid ${portexColor.grey300}`,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <LaneSummaryGenericPropertyView
              isLoading={isLoading}
              top={t("PORefNumber")}
              bottom={lane?.reference_number ?? EM_DASH}
            />
            {!!lane?.lane_detail?.shipper_lane_id && (
              <LaneSummaryGenericPropertyView
                isLoading={isLoading}
                top={t("shipperLaneId", { context: lane?.lane_detail?.shipper_lane_id })}
                bottom={lane?.lane_detail?.shipper_lane_id ?? 0}
              />
            )}
            {!!lane?.volume && (
              <LaneSummaryGenericPropertyView
                isLoading={isLoading}
                top={t("totalVolume", { context: lane?.volume_interval })}
                bottom={lane?.volume ?? 0}
              />
            )}
            <LaneSummaryGenericPropertyView
              isLoading={isLoading}
              top={t("responses")}
              bottom={t("responseFormat", { responses: lane?.responses_count, requests: requestedCount })}
            />
            <LaneSummaryGenericPropertyView
              isLoading={isLoading}
              top={t("allocation")}
              bottom={t("allocationFormat", { allocation: lane?.total_award_percentage })}
            />
            <LaneSummaryGenericPropertyView isLoading={isLoading} top={t("awardedTo")} bottom={awardedNamesStr} />
            <LaneSummaryTooltipPropertyView
              isLoading={isLoading}
              top={t("awardedTo")}
              bottom={t("partner", { count: awardedToCount })}
              tooltipTitle={awardedNamesStr}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ContractLaneSummaryView;
