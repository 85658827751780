import React from "react";

import { GetApp, DeleteOutline } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { useTranslation } from "react-i18next";
import { downloadBlobFromUrl } from "utils/downloadBlobFromUrl";

type FileType = {
  id?: string;
  name: string;
  url: string | null;
};

interface AttachmentsListViewProps {
  files: FileType[] | undefined;
  showDashOnNoAttachments?: boolean;
  onDeleteFile?: (fileId: string) => Promise<void>;
  hideDeleteAction?: boolean;
}

const AttachmentsListView: React.VFC<AttachmentsListViewProps> = ({
  files,
  showDashOnNoAttachments = false,
  hideDeleteAction = false,
  onDeleteFile,
}) => {
  const { t } = useTranslation(["common"]);

  if (!files?.length) {
    return showDashOnNoAttachments ? <>{t("common:emDash")}</> : null;
  }

  return (
    <div className="flex flex-col w-full mt-[-8px]">
      {files.map((file, idx) => {
        const { url: fileUrl, id: fileId } = file;
        if (!fileUrl) {
          return null;
        }

        return (
          <div key={`${idx}_${file.name}`} className="flex items-center justify-between text-blue-500">
            <div className="font-bold text-medium">{file.name}</div>
            <div>
              {!hideDeleteAction && !!onDeleteFile && !!fileId && (
                <Button color="inherit" startIcon={<DeleteOutline />} onClick={() => onDeleteFile(fileId)}>
                  {t("common:delete")}
                </Button>
              )}
              <Button color="inherit" startIcon={<GetApp />} onClick={() => downloadBlobFromUrl(fileUrl, file.name)}>
                {t("common:download")}
              </Button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentsListView;
