import i18n from "app/i18n";
import OptionalMaybe from "types/OptionalMaybe";
import { TrailerType } from "types/TrailerType";

/** @deprecated just use common:trailerTypes.${type} */
export const formatTrailerType = (type: OptionalMaybe<TrailerType>): string => {
  if (!type) {
    return "";
  }

  return i18n.t(`trailerTypes.${type}`, { ns: "common" });
};
