import { EM_DASH } from "constants/index";

import React from "react";

import { Box } from "@portex-pro/ui-components";
import { ContractPricingFrequency } from "api/rest/rfp/brokerBidContractApi/types/ContractPricingFrequency";
import { useBidRequestSliceSelector } from "app/pages/bid-request/store/bidRequestStore";
import ContractTrailerTypeWithIconView from "components/ContractTrailerTypeContainer";
import AttachmentsListContainer from "components/file-uploads/AttachmentsListContainer";
import LoadTypeChip from "components/LoadTypeChip";
import ModeIcon from "components/ModeIcon";
import RowListView from "components/RowListView";
import SummaryPaperView from "components/SummaryPaperView";
import TitleSummaryWithIconView from "components/TitleSummaryWithIconView";
import { useUserContext } from "hooks/useUserContext";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { renderSerializedNotes } from "utils/renderSerializedNotes";
import mapPricingFrequencyToI18nKey from "utils/rfp/mapPricingFrequencyToI18nKey";

const MockEmailBodyContainer: React.VFC = () => {
  const { t } = useTranslation(["shipper", "common"]);
  const contract = useBidRequestSliceSelector((state) => state.bidRequestContractSlice);
  const { user } = useUserContext();

  return (
    <SummaryPaperView
      header={
        <TitleSummaryWithIconView
          icon={<ModeIcon svgProps={{ width: "100%", height: "100%" }} mode={contract.mode} />}
          title={t("shipper:bids.bid")}
          desciption={contract.portex_id ?? ""}
        />
      }
      description={
        <RowListView
          rows={[
            {
              label: t("shipper:bids.bidRequestPage.pageReviewAndSend.shippersName"),
              value: user?.shipper?.name ?? EM_DASH,
            },
            {
              label: t("shipper:bids.bidRequestPage.pageReviewAndSend.numberOfLanes"),
              value: contract.lanes_count ?? 0,
            },
            {
              label: t("shipper:bids.bidRequestPage.pageReviewAndSend.bidDeadline"),
              value: DateTime.fromISO(contract?.deadline ?? "").toFormat("LLLL d"),
            },
          ]}
        />
      }
      PaperProps={{
        variant: "outlined",
        style: {
          width: "100%",
        },
      }}
      ColumnListViewProps={{
        rows: [
          {
            label: t("shipper:bids.bidRequestPage.pageReviewAndSend.mode"),
            value: (
              <LoadTypeChip
                mode={contract.mode}
                verbose={contract.mode === "FTL"}
                textProps={{ size: "medium", weight: "bold" }}
              />
            ),
            BoxProps: { style: { alignItems: "center" } },
          },
          {
            label: t("shipper:bids.bidRequestPage.pageReviewAndSend.trailer"),
            value:
              contract.trailer_size !== undefined && contract.trailer_type ? (
                <ContractTrailerTypeWithIconView
                  contract={{ ...contract, trailer_size: contract.trailer_size, trailer_type: contract.trailer_type }}
                />
              ) : (
                EM_DASH
              ),
          },
          {
            label: t("shipper:bids.bidRequestPage.pageReviewAndSend.lanes"),
            value: contract.lanes_count ?? EM_DASH,
          },
          {
            label: t("shipper:bids.bidRequestPage.pageReviewAndSend.bidDeadline"),
            value: DateTime.fromISO(contract.deadline ?? "").toFormat("LLL dd, yyyy"),
          },
          {
            label: t("shipper:bids.bidRequestPage.pageReviewAndSend.contractPeriod"),
            value: t("shipper:bids.bidRequestPage.pageReviewAndSend.contractPeriod_format", {
              date1: DateTime.fromISO(contract.start ?? "").toFormat("cccc, LLL dd"),
              date2: DateTime.fromISO(contract.end ?? "").toFormat("cccc, LLL dd"),
            }),
            hide: contract.pricing_frequency === ContractPricingFrequency.BULK_SPOT_QUOTE,
          },
          {
            label: t("common:pricingFrequency"),
            value:
              contract.pricing_frequency !== undefined
                ? t(`common:pricingFrequency_values.${mapPricingFrequencyToI18nKey[contract.pricing_frequency]}`)
                : EM_DASH,
          },
          {
            label: t("shipper:bids.bidRequestPage.pageReviewAndSend.rates"),
            value: contract.include_fuel ? t("shipper:bids.bidRequestPage.pageReviewAndSend.includeFuel") : EM_DASH,
          },
          {
            label: capitalize(t("common:commodities")),
            value: contract.commodities ? contract.commodities.split(",").join(", ") : EM_DASH,
          },
          {
            label: t("common:hazardousCommodities"),
            value: <Box color="red">{t("common:containsHazardous")}</Box>,
            hide: !contract.is_hazardous,
          },
          {
            label: t("common:hazardousDescription"),
            value: renderSerializedNotes(contract.hazardous_goods_details ?? ""),
            hide: !contract.hazardous_goods_details,
          },
          {
            label: t("common:additionalNotes"),
            value: !!contract.notes ? renderSerializedNotes(contract.notes) : EM_DASH,
          },
          {
            label: t("common:attachments"),
            value: <AttachmentsListContainer showDashOnNoAttachments />,
          },
        ],
      }}
    />
  );
};

export default MockEmailBodyContainer;
