import { FC, useCallback, useEffect } from "react";

import { Tab, Tabs } from "@portex-pro/ui-components";
import NotificationBadge from "components/NotificationBadge";
import withAsync from "components/withAsync";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useQueryParam } from "use-query-params";

import useBrokerShipment from "../hooks/useBrokerShipment";
import { useBrokerLoadStatusSliceSelector } from "../store/brokerLoadStatusStore";
import { brokerLoadStatusUiActions } from "../store/brokerLoadStatusUiSlice";
import { BrokerLoadStatusPageTabs } from "../types/BrokerLoadStatusPageTabs";

const LoadStatusPageTabsContainer = withAsync({
  useHook: useBrokerShipment,
  Component: ({ loadedData }) => {
    const shipmentId = loadedData.shipmentId;
    const shipment = loadedData.data.data.shipment;
    const [tabQueryParam] = useQueryParam<BrokerLoadStatusPageTabs | null | undefined>("tab");
    const { t } = useTranslation(["loads"]);
    const releaseBrokerChat = useLDFlag("releaseBrokerChat");
    const location = useLocation();

    const { currentTab } = useBrokerLoadStatusSliceSelector((state) => state.brokerLoadStatusUiSlice);
    const setUiState = brokerLoadStatusUiActions.useSetState();

    useEffect(() => {
      if (currentTab && (!tabQueryParam || currentTab === tabQueryParam)) {
        return;
      }
      setUiState({ currentTab: tabQueryParam ?? "loads" });
    }, [currentTab, setUiState, tabQueryParam]);

    const getTabRoute = useCallback(
      (nextTab: BrokerLoadStatusPageTabs): string => {
        const urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.set("tab", nextTab);
        return `${location.pathname}?${urlSearchParams.toString()}`;
      },
      [location.pathname, location.search]
    );

    const tabs: { name: BrokerLoadStatusPageTabs; hide?: boolean; Badge?: FC }[] = [
      {
        name: "loads",
        Badge: (props) => (
          <NotificationBadge
            {...props}
            position={{ top: "0%", right: "-8px" }}
            size="small"
            identifiers={shipment.trucks.map((load) => `loadStatusInvalidation:${load.id}` as const)}
          />
        ),
      },
      {
        name: "chat",
        hide: !releaseBrokerChat,
        Badge: (props) => (
          <NotificationBadge
            {...props}
            position={{ top: "0%", right: "-8px" }}
            size="small"
            identifiers={[`shipment:${shipmentId}`]}
          />
        ),
      },
    ];

    return (
      <div className="bg-white w-full">
        <Tabs value={currentTab}>
          {tabs.map((tab) => {
            if (tab.hide) {
              return null;
            }

            const Badge: FC = tab.Badge ?? (({ children }) => <>{children}</>);

            return (
              <Tab
                key={tab.name}
                onClick={() => setUiState({ currentTab: tab.name })}
                component={Link}
                to={getTabRoute(tab.name)}
                value={tab.name}
                label={<Badge>{t(`loads:loadStatusPageTabs.${tab.name}`)}</Badge>}
              />
            );
          })}
        </Tabs>
      </div>
    );
  },
});

export default LoadStatusPageTabsContainer;
