import { FC, Fragment } from "react";

import { AccessTime, PriorityHigh } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Info from "@material-ui/icons/Info";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  grey,
  Icon,
  makeStyles,
  portexColor,
  Status,
  Tooltip,
  Typography,
} from "@portex-pro/ui-components";
import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import upperCase from "lodash/upperCase";
import { useTranslation } from "react-i18next";

import { RoutingType } from "../../../../../../api/types/generated-types";
import LayoutColumnTwo from "../../../../../../components/LayoutColumnTwo";
import ModeIcon from "../../../../../../components/ModeIcon";
import { EMPTY_CELL_HYPHEN } from "../../../../../../constants";
import { getContainersSummary } from "../../../../../../utils/fcl-containers/getContainersSummary";
import { formatUSD } from "../../../../../../utils/formatCurrency";
import { renderSerializedNotes } from "../../../../../../utils/renderSerializedNotes";
import { toLuxon } from "../../../../../../utils/toLuxon";
import { getRoutingTypeLabel } from "../../../../../shipper/pages/request-quote/utils/getRoutingTypeLabel";
import { useContextQuoteSubmissionFCL } from "../hooks/useContextQuoteSubmissionFCL";
import { getQuoteTypeLabel } from "../utils/getQuoteTypeLabel";
import { getRequiredLabel } from "../utils/getRequiredLabel";
import { getUnloadingDetailsLabel } from "../utils/getUnloadingDetailsLabel";

const useStyles = makeStyles(() => ({
  status: {
    marginLeft: "3px",
    padding: "3px",
    backgroundColor: grey["100"],
    borderRadius: "50%",
    height: "20px",
    width: "20px",
    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
  accordionRoot: {
    "&.MuiAccordion-root": {
      boxShadow: "unset",
    },
    "& .MuiAccordionSummary-root": {
      borderTop: `1px solid ${portexColor.grey300}`,
      borderBottom: `1px solid ${portexColor.grey300}`,
    },
    "& .MuiAccordionDetails-root": {
      padding: "24px 16px 16px",
    },
  },
  sticky: {
    position: "sticky",
    top: "0",
    background: "white",
    zIndex: 2,
  },
}));

const QuoteRequestSidebarFCL: FC<{ sidebarWidth?: number }> = ({ sidebarWidth = 370 }) => {
  const { t } = useTranslation(["common", "broker"]);
  const classes = useStyles();
  const { publicQuoteRequest, submitFclQuoteInputPartial } = useContextQuoteSubmissionFCL();

  const {
    mode,
    is_hazardous,
    hazardous_goods_details,
    capacity_guarantee_required,
    containers,
    include_customs_clearance,
    goods_value,
    incoterms_ocean,
    insurance_required,
    locations,
    notes,
    portex_id,
    quote_types,
    routing_type,
    cargo_ready_date,
    target_delivery_date,
    is_overweight,
    unloading_details,
    commodities,
  } = publicQuoteRequest;

  const [pickupCity, deliveryCity] = compact([first(locations), last(locations)]).map((location) => location.city);

  const hasOriginDoor = routing_type === RoutingType.D2D || routing_type === RoutingType.D2P;
  const hasDestinationDoor = routing_type === RoutingType.D2D || routing_type === RoutingType.P2D;

  const originDoor = hasOriginDoor ? first(locations) : null;
  const destinationDoor = hasDestinationDoor ? last(locations) : null;

  const { origin_port: originPortQuote, via_port, destination_port: destinationPortQuote } = submitFclQuoteInputPartial;

  const originPortIndex = routing_type === RoutingType.D2D || routing_type === RoutingType.D2P ? 1 : 0;
  const destinationPortIndex =
    routing_type === RoutingType.D2D || routing_type === RoutingType.P2D ? locations.length - 2 : locations.length - 1;

  const maybeOriginPortRequest = locations[originPortIndex];
  const maybeDestinationPortRequest = locations[destinationPortIndex];

  const maybeOriginPort = originPortQuote || maybeOriginPortRequest;
  const maybeDestinationPort = destinationPortQuote || maybeDestinationPortRequest;

  const originChanged = originPortQuote && originPortQuote.id !== maybeOriginPortRequest?.address_id;
  const destinationChanged =
    destinationPortQuote && destinationPortQuote.id !== maybeDestinationPortRequest?.address_id;
  const viaPortIncluded = !!via_port;

  const originChangedTooltip = t("broker:quoteRequestSidebarFCL.originChangedTooltip", {
    maybeOriginPortRequestCity: maybeOriginPortRequest?.city,
    originPortQuoteCity: originPortQuote?.city,
  });
  const destinationChangedTooltip = t("broker:quoteRequestSidebarFCL.destinationChangedTooltip", {
    maybeDestinationPortRequestCity: maybeDestinationPortRequest?.city,
    destinationPortQuoteCity: destinationPortQuote?.city,
  });
  const viaPortTooltip = t("broker:quoteRequestSidebarFCL.viaPortTooltip", {
    viaportCity: via_port?.city,
  });

  const containerSummary = getContainersSummary(containers, { verbose: true });

  return (
    <LayoutColumnTwo.Sidebar
      style={{ margin: 0, width: sidebarWidth, backgroundColor: "#fffff", zIndex: 1, minWidth: sidebarWidth - 100 }}
    >
      <Box pt="1rem" className={classes.sticky}>
        <Box px="1rem" display="flex" alignItems="center">
          <ModeIcon mode={mode} />
          <Box ml={1}>
            <Typography>
              <strong>
                {t("broker:quoteRequestSidebarFCL.overview.path", {
                  pickupCity,
                  deliveryCity,
                })}
              </strong>
            </Typography>
            <Typography>{portex_id}</Typography>
          </Box>
        </Box>
        <Box pt={2}>
          <Divider light style={{ backgroundColor: portexColor.grey300 }} />
        </Box>
      </Box>

      <Box p="1rem">
        <Box display="flex" alignItems="center">
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              <strong>{t("broker:quoteRequestSidebarFCL.overview.quoteTypes")}</strong>
            </Typography>
            <Typography variant="body2">{quote_types.map(getQuoteTypeLabel).join(" & ")}</Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="center" mt={3}>
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              <strong>{upperCase(t("common:mode"))}</strong>
            </Typography>
            <Status palette="blue" style={{ fontSize: "0.9rem", paddingLeft: 6, paddingRight: 6 }}>
              {t("broker:fcl")}
            </Status>
          </Box>
          <Box ml={3}>
            <Typography variant="subtitle2" gutterBottom>
              <strong>{t("broker:hazmat")}</strong>
            </Typography>
            {is_hazardous ? (
              <Status palette="red" style={{ fontSize: "0.9rem", paddingLeft: 6, paddingRight: 6 }}>
                {t("broker:yes")}
              </Status>
            ) : (
              t("broker:no")
            )}
          </Box>
        </Box>
      </Box>
      <Accordion defaultExpanded className={classes.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography>
            <strong>{t("broker:routingInformation")}</strong>
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            {hasOriginDoor ? (
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:originAddress"))}</strong>
                </Typography>
                <Typography variant="body2">{originDoor?.formatted_long_name}</Typography>
              </Box>
            ) : null}
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:originPort"))}</strong>
                </Typography>
                {originChanged && (
                  <Tooltip title={originChangedTooltip} arrow placement="top-start">
                    <Icon className={classes.status} as={AccessTime} />
                  </Tooltip>
                )}
              </Box>
              <Typography variant="body2">
                {maybeOriginPort?.city ? t("broker:portName", { city: maybeOriginPort?.city }) : EMPTY_CELL_HYPHEN}
              </Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:viaPort"))}</strong>
                </Typography>
                {viaPortIncluded && (
                  <Tooltip title={viaPortTooltip} arrow placement="top-start">
                    <Icon className={classes.status} as={AccessTime} />
                  </Tooltip>
                )}
              </Box>
              <Typography variant="body2">
                {via_port?.city ? t("broker:portName", { city: via_port?.city }) : EMPTY_CELL_HYPHEN}
              </Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:destinationPort"))}</strong>
                </Typography>
                {destinationChanged && (
                  <Tooltip title={destinationChangedTooltip} arrow placement="top-start">
                    <Icon className={classes.status} as={AccessTime} />
                  </Tooltip>
                )}
              </Box>
              <Typography variant="body2">
                {maybeDestinationPort?.city
                  ? t("broker:portName", { city: maybeDestinationPort.city })
                  : EMPTY_CELL_HYPHEN}
              </Typography>
            </Box>
            <Box>
              {hasDestinationDoor ? (
                <>
                  <Typography variant="subtitle2" gutterBottom>
                    <strong>{upperCase(t("broker:destinationAddress"))}</strong>
                  </Typography>
                  <Typography variant="body2">{destinationDoor?.formatted_long_name}</Typography>
                </>
              ) : null}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className={classes.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography>
            <strong>{t("broker:shipmentTerms")}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("common:incoterms"))}</strong>
                </Typography>
              </Box>
              <Typography variant="body2">{incoterms_ocean}</Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("common:routing"))}</strong>
                </Typography>
              </Box>
              <Typography variant="body2">{getRoutingTypeLabel(routing_type)}</Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:capacity"))}</strong>
                </Typography>
              </Box>
              <Typography variant="body2">
                {capacity_guarantee_required ? (
                  <Tooltip
                    arrow
                    placement="bottom-end"
                    title={t("broker:quoteRequestSidebarFCL.guaranteeRequired_tooltip")}
                  >
                    <span>
                      {t("broker:guaranteeRequired")}{" "}
                      <Info style={{ fontSize: "small", opacity: 0.9, marginBottom: -3 }} />
                    </span>
                  </Tooltip>
                ) : (
                  t("broker:notRequired")
                )}
              </Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:cargoReady"))}</strong>{" "}
                </Typography>
              </Box>
              <Typography variant="body2">
                {cargo_ready_date ? toLuxon(cargo_ready_date).toFormat("MMM dd, yyyy") : ""}
              </Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:targetDestinationDelivery"))}</strong>
                </Typography>
              </Box>
              <Typography variant="body2">
                {target_delivery_date ? toLuxon(target_delivery_date).toFormat("MMM dd, yyyy") : EMPTY_CELL_HYPHEN}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className={classes.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography>
            <strong>{t("broker:quoteRequestSidebarFCL.containerDetails.title")}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("broker:quoteRequestSidebarFCL.containerDetails.containers"))}</strong>
              </Typography>
              <Box display="flex">
                {containerSummary.map((container, index) => {
                  return (
                    <Fragment key={container + index}>
                      {container}
                      <br />
                    </Fragment>
                  );
                })}
              </Box>
            </Box>
            {is_overweight ? (
              <Box display="flex" alignItems="center">
                <Icon as={PriorityHigh} palette="red" variant="filled" wrapperSize="tiny" style={{ marginRight: 8 }} />
                <Typography color="error" variant="caption">
                  {t("broker:quoteRequestSidebarFCL.containerDetails.overWeight")}
                </Typography>
              </Box>
            ) : null}
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                <strong>{upperCase(t("common:commodities"))}</strong>{" "}
              </Typography>
              <Typography variant="body2">{commodities ? commodities : EMPTY_CELL_HYPHEN}</Typography>
            </Box>
            {is_hazardous && hazardous_goods_details ? (
              <Box
                border={`solid 1px ${portexColor.red500}`}
                borderRadius={4}
                bgcolor="white"
                padding={1}
                marginTop={1}
              >
                <Status palette="red">{t("broker:hazmat")}</Status>
                <Box mt={1} />
                <Typography>
                  {t("broker:details")}: {renderSerializedNotes(hazardous_goods_details)}
                </Typography>
              </Box>
            ) : null}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded className={classes.accordionRoot}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5bh-content" id="panel5bh-header">
          <Typography>
            <strong>{t("broker:additionalServices")}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" gridRowGap={20}>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:customs"))}</strong>
                </Typography>
              </Box>
              <Typography variant="body2">{getRequiredLabel(include_customs_clearance)}</Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("broker:insurance"))}</strong>
                </Typography>
              </Box>
              <Typography variant="body2">
                {getRequiredLabel(insurance_required)}
                {insurance_required && goods_value
                  ? t("broker:valueOfGoodsText", { value: formatUSD(goods_value) })
                  : ""}
              </Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="subtitle2" gutterBottom>
                  <strong>{upperCase(t("common:unloadingDetails"))}</strong>
                </Typography>
              </Box>
              <Typography variant="body2">
                {unloading_details ? getUnloadingDetailsLabel(unloading_details) : ""}
              </Typography>
            </Box>
            {notes ? (
              <Box>
                <Box display="flex">
                  <Typography variant="subtitle2" gutterBottom>
                    <strong>{upperCase(t("common:additionalNotes"))}</strong>
                  </Typography>
                </Box>
                <Typography variant="body2">{renderSerializedNotes(notes)}</Typography>
              </Box>
            ) : null}
          </Box>
        </AccordionDetails>
      </Accordion>
    </LayoutColumnTwo.Sidebar>
  );
};

export default QuoteRequestSidebarFCL;
