import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { useGetFeaturedBrokerQuoteRequestDataQuery } from "api/rest/featured-brokers";
import { QuoteRequest } from "api/rest/quote-requests";
import Loading from "components/Loading";
import PortexDrawer from "components/PortexDrawer";
import withAsync from "components/withAsync";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import { useBoolean } from "usehooks-ts";

import LiveQuoteRequestRatesView from "../views/LiveQuoteRequestRatesView";

type LiveQuoteRequestRatesContainerProps = { quoteRequestId: QuoteRequest["id"] };

// TypeScript utility hook to get the implicit return type for use with `withAsync`
const useLoadData = ({ quoteRequestId }: LiveQuoteRequestRatesContainerProps) => {
  const isDocumentVisible = useDocumentVisibility();
  const getFeaturedBrokerQuoteRequestDataQuery = useGetFeaturedBrokerQuoteRequestDataQuery(
    {
      urlParams: { quoteRequestId },
    },
    {
      pollingInterval: isDocumentVisible ? oneMinuteInMilliseconds * 2 : undefined,
    }
  );

  return getFeaturedBrokerQuoteRequestDataQuery;
};

const LiveQuoteRequestRatesContainer = withAsync<LiveQuoteRequestRatesContainerProps, ReturnType<typeof useLoadData>>({
  useHook: useLoadData,
  LoadingComponent: (
    <LiveQuoteRequestRatesView.Frame>
      <Loading height="100%" spinnerOnly />
    </LiveQuoteRequestRatesView.Frame>
  ),
  Component: (props) => {
    const featuredBrokerQuoteRequestData = props.loadedData.data.data;
    const isViewAllOpen = useBoolean(false);
    const DISPLAY_QUOTE_COUNT = 7;

    return (
      <>
        <LiveQuoteRequestRatesView
          data={featuredBrokerQuoteRequestData}
          numOfVisibleQuotesToDisplay={DISPLAY_QUOTE_COUNT}
          displayViewAllButton={featuredBrokerQuoteRequestData.quotes.length > DISPLAY_QUOTE_COUNT}
          onClickViewAll={isViewAllOpen.setTrue}
        />
        <PortexDrawer
          open={isViewAllOpen.value}
          onClose={isViewAllOpen.setFalse}
          contentClassName="[&&]:overflow-hidden"
        >
          <LiveQuoteRequestRatesView.Frame className="h-full min-h-none max-h-none">
            <LiveQuoteRequestRatesView.Body
              data={featuredBrokerQuoteRequestData}
              numOfVisibleQuotesToDisplay={featuredBrokerQuoteRequestData.quotes.length}
              displayViewAllButton={false}
            />
          </LiveQuoteRequestRatesView.Frame>
        </PortexDrawer>
      </>
    );
  },
});

export default LiveQuoteRequestRatesContainer;
