import { oneMinuteInMilliseconds } from "constants/time/oneMinuteInMilliseconds";

import { ComponentType, useState } from "react";

import { useGetShipperDispatchQuery } from "api/rest/dispatches";
import { useGetShipmentDetailsQuery, useGetShipmentQuotesQuery } from "api/rest/shipments/shipmentsApi";
import withAsync from "components/withAsync";
import CancelShipmentDialogContainer from "features/shipments/CancelShipmentDialogContainer";
import useDocumentVisibility from "hooks/useDocumentVisibility";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { OverflowMenuOption } from "../../../../components/OverflowMenuView";
import { DEFAULT_TAB_ID } from "../consts";
import { ShipmentState, ShipmentTabId } from "../types/domain";
import EditLoadStatusContainer from "./load-status-updates/containers/EditLoadStatusContainer";
import ShareShipmentStatusContainer from "./load-status-updates/ShareShipmentStatusContainer";
import ShipmentDetails from "./ShipmentDetails";
import ShipmentsAppBarContainer from "./ShipmentsAppBarContainer";

/** @note: convert to string union type if more dialogs are needed */
type ShipmentDialogId = "cancelShipment";

const ShipmentDetailsContainer: ComponentType = withAsync({
  useHook: () => {
    const { shipmentId } = useParams<{ shipmentId: string }>();
    const isDocumentVisible = useDocumentVisibility();
    return useGetShipmentDetailsQuery(
      { urlParams: { shipmentId: Number(shipmentId) } },
      {
        skip: !shipmentId,
        pollingInterval: isDocumentVisible ? oneMinuteInMilliseconds * 2 : undefined, // It's possible the shipment.state may be updated; we can poll here for it so the user doesn't need to refresh
      }
    );
  },
  Component: ({ loadedData }) => {
    const { shipmentId, tabId = DEFAULT_TAB_ID } = useParams<{ shipmentId: string; tabId?: ShipmentTabId }>();
    const shipmentData = loadedData.data;
    const sourceType = shipmentData.sourceType;
    const { data: quotesData } = useGetShipmentQuotesQuery(
      {
        urlParams: { shipmentId: Number(shipmentId) },
      },
      {
        skip: sourceType !== "quote",
      }
    );
    let dispatchRequestId = 0;
    if (sourceType === "dispatch" && shipmentData.dispatchRequest) {
      dispatchRequestId = shipmentData.dispatchRequest.id;
    }
    const { data: dispatchData } =
      useGetShipperDispatchQuery({ urlParams: { requestId: dispatchRequestId } }, { skip: !dispatchRequestId }).data ||
      {};
    const { t } = useTranslation(["common", "shipments"]);
    const [activeDialogId, setActiveDialogId] = useState<ShipmentDialogId | null>(null);
    const closeDialogs = () => setActiveDialogId(null);
    const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");

    const shipmentActions: OverflowMenuOption[] = [
      {
        label: t("shipments:cancelShipment_shipmentAction"),
        disabled: !shipmentData || [ShipmentState.Canceled, ShipmentState.Delivered].includes(shipmentData.state),
        onClick: () => setActiveDialogId("cancelShipment"),
      },
    ];

    return (
      <>
        <ShipmentsAppBarContainer shipmentId={shipmentData?.portexId} />
        <ShipmentDetails
          activeTabId={tabId}
          shipment={shipmentData}
          quotes={quotesData}
          shipmentActions={shipmentActions}
          dispatchRequest={dispatchData}
        />
        <CancelShipmentDialogContainer
          shipmentId={shipmentData.id}
          isOpen={activeDialogId === "cancelShipment"}
          onClose={closeDialogs}
        />
        {!!rolloutLoadStatuses && (
          <>
            <ShareShipmentStatusContainer />
            <EditLoadStatusContainer />
          </>
        )}
      </>
    );
  },
});

export default ShipmentDetailsContainer;
