import { VFC } from "react";

import { ArrowForward } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import AppBar from "components/AppBar";
import NotificationBadge from "components/NotificationBadge";
import PortexLink from "components/PortexLink";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";

/** variant "quotes" is for the old quotes pages */
const ViewQuoteConversationsTab: VFC<{ variant: "quotes" | "requests"; quoteRequestId: number | string }> = ({
  variant,
  quoteRequestId,
}) => {
  const { t } = useTranslation(["chat"]);
  const enableQuoteConversationsTab = useLDFlag("enableQuoteConversationsTab");

  const toQuoteConversations =
    variant === "requests"
      ? `/shipper/requests/quotes/${quoteRequestId}/conversations`
      : `/shipper/quotes/${quoteRequestId}/conversations`;

  if (!enableQuoteConversationsTab || !quoteRequestId) {
    return null;
  }

  return (
    <AppBar className="p-2 bg-white">
      <NotificationBadge
        identifiers={[`quoteRequest:${quoteRequestId}`]}
        position="topRight"
        size="small"
        BadgeProps={{ style: { width: "unset" } }}
      >
        <Button
          variant="text"
          color="primary"
          component={PortexLink}
          to={toQuoteConversations}
          endIcon={<ArrowForward />}
        >
          {t("chat:viewConversations")}
        </Button>
      </NotificationBadge>
    </AppBar>
  );
};

export default ViewQuoteConversationsTab;
