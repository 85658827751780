import { VFC } from "react";

import { Button } from "@material-ui/core";
import { FormControl, FormLabel, Paper, TextInput } from "@portex-pro/ui-components";
import { BrokerQuoteResponse, QuoteRequest } from "api/rest/quote-requests";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import BrokerLaneBenchmark from "features/lane-benchmark/BrokerLaneBenchmark";
import { DateTime } from "luxon";
import QuoteValidityPicker from "pages/broker/quote/components/QuoteSubmissionLTL/QuoteValidityPicker";
import { useTranslation } from "react-i18next";

import { LineItem } from "../store/brokerQuoteSubmissionSlice";
import ContainerChargesView from "./ContainerChargesView";

interface QuoteDetailsViewProps {
  quote: Partial<BrokerQuoteResponse>;
  lineItems: LineItem[];
  onChangeLineItem: (action: { index: number; changes: Partial<Omit<LineItem, "cargoGroup" | "type">> }) => void;
  onChange: (changes: Partial<BrokerQuoteResponse>) => void;
  onSubmit: () => void;
  isLoading: boolean;
  isValid: boolean;
  quoteRequest: QuoteRequest;
}

const QuoteDetailsView: VFC<QuoteDetailsViewProps> = (props) => {
  const { onChange, quote, lineItems, onChangeLineItem, onSubmit, isLoading, isValid, quoteRequest } = props;
  const { t } = useTranslation(["common", "broker"]);

  return (
    <Paper className="Por-outlined-light" elevation={8}>
      <div className="w-full flex flex-col py-6 px-5 bg-grey-50 space-y-5">
        <Text size="large" weight="bold">
          {t("broker:submitQuote")}
        </Text>

        <ContainerChargesView lineItems={lineItems} onChangeLineItems={onChangeLineItem} />

        <div className="flex flex-row space-x-3">
          <QuoteValidityPicker
            value={quote.validUntil ? DateTime.fromISO(quote.validUntil) : null}
            onChange={(date) => onChange({ validUntil: date.toISO() })}
            disabled={false}
            showError={false}
          />
        </div>

        <div className="flex flex-row space-x-3 w-full">
          <FormControl fullWidth>
            <FormLabel required>{t("broker:estimatedTransitTimeStep.minimum.label")}</FormLabel>
            <PositiveNumberInput
              value={quote.minTransitTime}
              fullWidth
              endIcon={
                <Text size="small" weight="light">
                  {t("broker:day", { count: quote.minTransitTime ?? 1 })}
                </Text>
              }
              onChange={(value) => onChange({ minTransitTime: value })}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel required>{t("broker:estimatedTransitTimeStep.maximum.label")}</FormLabel>
            <PositiveNumberInput
              value={quote.maxTransitTime}
              fullWidth
              endIcon={
                <Text size="small" weight="light">
                  {t("broker:day", { count: quote.maxTransitTime ?? 1 })}
                </Text>
              }
              onChange={(value) => onChange({ maxTransitTime: value })}
            />
          </FormControl>
        </div>

        <BrokerLaneBenchmark quoteRequest={quoteRequest} />

        <FormControl fullWidth>
          <FormLabel required>{t("common:emailAddress")}</FormLabel>
          <TextInput
            value={quote.submitterEmail}
            fullWidth
            onChange={(event) => onChange({ submitterEmail: event.target.value })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>{t("common:additionalNotes")}</FormLabel>
          <TextInput
            fullWidth
            value={quote.notes}
            multiline
            rows={4}
            onChange={(event) => onChange({ notes: event.target.value })}
            placeholder={t("broker:additionalNotes_placeholder")}
          />
        </FormControl>

        <Button onClick={onSubmit} variant="contained" color="primary" disabled={isLoading || !isValid}>
          {t("broker:submitQuote")}
        </Button>
      </div>
    </Paper>
  );
};

export default QuoteDetailsView;
