import { IDeveloperField } from "dromo-uploader-js";
import { TFuncKey } from "react-i18next";

export enum StaticFieldKeys {
  PO_REFERENCE_NUMBER = "reference_number",
  QUOTE_NUMBER = "quote_number",
  HIDE_PO_TILL_DISPATCH = "hide_po_till_dispatch",
  DELIVERY_WEEK = "delivery_week",
  LOADS_PER_WEEK = "loads_per_week",
  LOADS_PER_MONTH = "loads_per_month",
  LOADS_PER_YEAR = "loads_per_year",
  WEIGHT = "weight",
  PALLETS = "pallet_count",
  TARGET_RATE = "target_rate",
  SHIPPER_LANE_ID = "lane_id",
  SHIPPER_PROVIDED_MILEAGE = "shipper_provided_mileage",
  LOAD_VALUE = "load_value",
  AWARDED_PARTNER = "awarded_partner",
  AWARDED_PARTNER_EMAIL = "awarded_partner_email",
  AWARDED_PARTNER_RATE = "awarded_partner_rate",
  AWARDED_PARTNER_ALLOCATION = "awarded_partner_allocation",
  SHIPPER_NOTE = "shipper_note",
}

type LocationKeys = "city" | "state" | "zip" | "name" | "date";

type LocationFieldKeys<L extends string, N extends number | undefined = undefined> = `${L}_${N}_${LocationKeys}`;

export type StopFieldKeys = LocationFieldKeys<"stop", number>;

export interface BidRequestBaseFieldType {
  required: boolean;
  selected: boolean;
  additionalI18NOptions?: Record<string, string>;
}

export type BidRequestFieldKeysType = StaticFieldKeys | StopFieldKeys;

export type BidRequestFieldType = {
  label: TFuncKey<"shipper", "bids.fieldNames">;
  key: BidRequestFieldKeysType;
  hideField?: boolean;
} & BidRequestBaseFieldType &
  IDeveloperField;
