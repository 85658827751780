import { ComponentProps, VFC } from "react";

import { Info } from "@material-ui/icons";
import { Tooltip } from "@portex-pro/ui-components";
import classNames from "classnames";
import StatusView from "components/StatusView";
import { useTranslation } from "react-i18next";

interface PortexFeaturedPartnerChipProps {
  className?: string;
  statusViewProps?: ComponentProps<typeof StatusView>;
}

const PortexFeaturedPartnerChip: VFC<PortexFeaturedPartnerChipProps> = ({ className, statusViewProps }) => {
  const { t } = useTranslation(["featuredBroker"]);
  return (
    <div className={classNames("whitespace-nowrap", className)}>
      <StatusView
        className="bg-gold-500 text-white [&&]:text-small [&&]:px-2.5 py-1.5 tracking-[1.2px]"
        {...statusViewProps}
      >
        <Tooltip
          arrow
          placement="top"
          title={
            <div className="px-2 py-3 leading-6">
              <div className="font-bold text-small tracking-[1.2px] uppercase pb-2">
                {t("featuredBroker:portexFeaturedPartner")}
              </div>
              <span>{t("featuredBroker:portexFeaturedPartnerTooltip")}</span>
            </div>
          }
        >
          <span>
            {t("featuredBroker:portexFeaturedPartner")} <Info style={{ fontSize: 16, marginTop: -3 }} />
          </span>
        </Tooltip>
      </StatusView>
    </div>
  );
};

export default PortexFeaturedPartnerChip;
