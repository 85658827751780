import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import { ChatParticipant } from "types/Chat";

export const getParticipantInitials = (participant: Pick<ChatParticipant, "displayName">): string => {
  const displayNameParts = compact(participant.displayName.split(" "));
  const firstPart = first(displayNameParts) ?? "";
  const lastPart = last(displayNameParts) ?? "";

  if (!firstPart && !lastPart) {
    return "";
  }

  const initials = displayNameParts.length >= 2 ? firstPart[0] + lastPart[0] : firstPart[0];

  return initials.toUpperCase();
};
