import { baseRestApi } from "api/rest/baseRestApi";
import { Mode } from "types/Mode";

import { BenchmarkLaneArg, BrokerLastMetricFormat } from "./types";

const API_ROOT = "/broker/lane-benchmark";
module.hot?.accept();

type QueryParams = {
  lane: BenchmarkLaneArg;
  shipperId?: number;
  fromDate?: string;
  mode: Mode;
};

type QueryArgs = { queryParams: QueryParams };

export type Response = {
  data: {
    lastWon?: BrokerLastMetricFormat;
    lastLost?: BrokerLastMetricFormat;
    lastQuote?: BrokerLastMetricFormat;
  };
};

const getBrokerLaneBenchmarkApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getBrokerLaneBenchmark: builder.query<Response, QueryArgs>({
      query: (params) => ({
        url: `${API_ROOT}`,
        method: "GET",
        params: { ...params.queryParams, lane: JSON.stringify(params.queryParams.lane) },
      }),
    }),
  }),
});

export default getBrokerLaneBenchmarkApi;

export const { useGetBrokerLaneBenchmarkQuery, useLazyGetBrokerLaneBenchmarkQuery } = getBrokerLaneBenchmarkApi;
