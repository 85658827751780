import { VFC } from "react";

import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import RequestFlowLayout from "../../../components/RequestFlowLayout";
import RequestBidAppBarContainer from "./components/RequestBidAppBarContainer";
import RequestBidSidebarContainer from "./components/RequestBidSidebarContainer";
import BidAttributesContainer from "./pages/bid-attributes/BidAttributesContainer";
import CreateBidContainer from "./pages/create-bid/CreateBidContainer";
import DoneContainer from "./pages/done/DoneContainer";
import FieldsDetailsContainer from "./pages/fields-details/FieldsDetailsContainer";
import PartnersContainer from "./pages/partners/PartnersContainer";
import ReviewAndSendContainer from "./pages/review-and-send/ReviewAndSendContainer";
import TeamContainer from "./pages/team/TeamContainer";
import TrailerSelectionContainer from "./pages/trailer-selection/TrailerSelectionContainer";
import { useBidRequestSlices } from "./store/bidRequestStore";
import BidRequestPageSteps from "./types/BidRequestPageSteps";

const BidRequestPage: VFC = () => {
  useBidRequestSlices();
  const { path } = useRouteMatch();

  return (
    <RequestFlowLayout
      appbar={<Route exact path={[`${path}`, `${path}/*`]} render={() => <RequestBidAppBarContainer />} />}
      sidebar={<Route exact path={`${path}/*`} render={() => <RequestBidSidebarContainer />} />}
    >
      <Switch>
        <Route exact path={`${path}`} render={() => <CreateBidContainer />} />
        <Route
          exact
          path={`${path}/:rfpId/${BidRequestPageSteps.Trailer}`}
          render={() => <TrailerSelectionContainer />}
        />
        <Route
          exact
          path={`${path}/:rfpId/${BidRequestPageSteps.BidAttributes}`}
          render={() => <BidAttributesContainer />}
        />
        <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Fields}`} render={() => <FieldsDetailsContainer />} />
        <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Partners}`} render={() => <PartnersContainer />} />
        <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Team}`} render={() => <TeamContainer />} />
        <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Review}`} render={() => <ReviewAndSendContainer />} />
        <Route exact path={`${path}/:rfpId/${BidRequestPageSteps.Done}`} render={() => <DoneContainer />} />
        <Route
          path={`${path}/:rfpId/*`}
          render={() => <Redirect to={`${path}/:rfpId/${BidRequestPageSteps.Trailer}`} />}
        />
      </Switch>
    </RequestFlowLayout>
  );
};

export default BidRequestPage;
