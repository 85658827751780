import { ComponentProps, VFC } from "react";

import { LoadStatus } from "api/rest/load-status-updates/types";
import StatusView from "components/StatusView";
import { useTranslation } from "react-i18next";
import getLoadStatusCopy from "utils/loads/getLoadStatusCopy";

const statusColors: Record<LoadStatus, { default: string; light: string } | undefined> = {
  PENDING: {
    default: "bg-grey-400 text-white",
    light: "bg-grey-100 text-black",
  },
  ON_TRACK: {
    default: "bg-cyan-500 text-white",
    light: "bg-cyan-100 text-cyan-500",
  },
  ON_SITE: {
    default: "border-2 border-cyan-500 text-cyan-500",
    light: "bg-cyan-100 text-cyan-500",
  },
  AT_RISK: {
    default: "bg-yellow-500 text-black",
    light: "bg-yellow-100 text-yellow-500",
  },
  DELAYED: {
    default: "bg-red-500 text-white",
    light: "bg-red-100 text-red-500",
  },
  COMPLETED: {
    default: "bg-green-500 text-white",
    light: "bg-green-100 text-green-500",
  },
  COMPLETED_LATE: {
    default: "bg-green-500 text-white",
    light: "bg-green-100 text-green-500",
  },
  COMPLETED_LATE_PARTNER_NOT_AT_FAULT: {
    default: "bg-green-500 text-white",
    light: "bg-green-100 text-green-500",
  },
};

interface LoadStatusViewProps {
  status: LoadStatus;
  light?: boolean;
  statusProps?: ComponentProps<typeof StatusView>;
  meta:
    | {
        stopPosition: number;
        totalStops: number;
      }
    | null
    | undefined;
}

const LoadStatusView: VFC<LoadStatusViewProps> = ({ light = false, statusProps, meta, ...props }) => {
  const { t } = useTranslation("loads");
  const status = props.status;

  const statusColor = statusColors[status]?.[light ? "light" : "default"];

  const loadStatusCopy = getLoadStatusCopy({ status, tFunction: t, meta });

  return (
    <StatusView {...statusProps} className={statusColor}>
      {loadStatusCopy}
    </StatusView>
  );
};

export default LoadStatusView;
