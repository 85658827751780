import { useEffect, useMemo } from "react";

import Loading from "components/Loading";
import withAsync from "components/withAsync";
import { useTranslation } from "react-i18next";

import { useFindManyConversationsQuery } from "./api/findManyConversations";
import ChatContainer from "./ChatContainer";
import ConversationsView from "./ConversationsView";
import { useChatSlices, useChatSliceSelector } from "./store/chatStore";
import { conversationsSliceActions } from "./store/conversationsSlice";
import { mapConversationsToDisplayConversations } from "./utils/mapConversationsToDisplayConversations";

interface ConversationsContainerProps {
  quoteRequestId?: number;
}

const useLoadConversations = (props: ConversationsContainerProps) => {
  const { quoteRequestId } = props;
  useChatSlices();
  const resetSlice = conversationsSliceActions.useResetState();
  const setState = conversationsSliceActions.useSetState();
  const { search, selectedConversationId } = useChatSliceSelector((state) => state.conversationsSlice);

  useEffect(
    () => () => {
      resetSlice();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const result = useFindManyConversationsQuery({
    queryParams: { quoteRequestId: quoteRequestId, search: search || undefined },
    variation: "shipper",
  });

  if (!!result.data && selectedConversationId === undefined && result.data.data.conversations.length > 0) {
    setState({ selectedConversationId: result.data.data.conversations[0].id });
  }

  return result;
};

const ConversationsContainer = withAsync<ConversationsContainerProps, ReturnType<typeof useLoadConversations>>({
  useHook: useLoadConversations,
  LoadingComponent: (
    <div className="w-full h-full">
      <Loading />
    </div>
  ),
  Component: (props) => {
    const {
      loadedData: {
        data: {
          data: { conversations },
        },
      },
    } = props;
    const { selectedConversationId, search } = useChatSliceSelector((state) => state.conversationsSlice);
    const { t } = useTranslation(["chat"]);
    const setState = conversationsSliceActions.useSetState();

    const MemoChatContainer = useMemo(
      () => () => <ChatContainer conversationId={selectedConversationId?.toString()} roundedFrame={false} />,
      [selectedConversationId]
    );

    return (
      <ConversationsView
        chatContainer={<MemoChatContainer />}
        conversations={mapConversationsToDisplayConversations(conversations, t)}
        onChangeConversation={(conversationId) => setState({ selectedConversationId: conversationId })}
        onChangeSearch={(search) => setState({ search })}
        search={search}
        lastRefreshedAt={props.loadedData.fulfilledTimeStamp}
        selectedConversationId={selectedConversationId}
      />
    );
  },
});

export default ConversationsContainer;
