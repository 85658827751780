import { FC, useState } from "react";

import { AddSharp } from "@material-ui/icons";
import { Button, Box, Typography } from "@portex-pro/ui-components";
import { ReactComponent as FullscreenCloseIcon } from "assets/fullscreen-close.svg";
import { ReactComponent as FullscreenOpenIcon } from "assets/fullscreen-open.svg";
import Text from "components/Text";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";
import { ChatParticipant } from "types/Chat";

import AddParticipantDialogView from "./AddParticipantDialogView";
import ChatParticipantsView from "./ChatParticipantsView";
import { useHeadingStyles } from "./chatStyles";

interface ChatHeadingViewProps {
  onToggleFullscreen: () => void;
  isFullScreen?: boolean;
  chatParticipants: ChatParticipant[];
  addParticipant: (contactId?: number, email?: string) => void;
  hideAddChatParticipants?: boolean;
}

const ChatHeadingView: FC<ChatHeadingViewProps> = ({
  isFullScreen = false,
  onToggleFullscreen,
  chatParticipants,
  addParticipant,
  hideAddChatParticipants = false,
}) => {
  const { t } = useTranslation(["common", "chat"]);
  const classes = useHeadingStyles();
  const FullscreenModeIcon = isFullScreen ? FullscreenCloseIcon : FullscreenOpenIcon;
  const [isDialogOpen, setDialogOpen] = useState(false);
  const addParticipants = useLDFlag("rolloutAddChatParticipants");

  return (
    <div className={classes.wrapper}>
      <div className="flex items-center" style={{ flex: 1 }}>
        <div className={classes.texts}>
          <Typography data-testid="chat-heading">
            <strong>{t("common:chat.heading")}</strong>
          </Typography>
        </div>
        <ChatParticipantsView participants={chatParticipants} />
      </div>
      {!!addParticipants && !hideAddChatParticipants && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddSharp />}
          style={{ margin: 15 }}
          onClick={() => setDialogOpen(true)}
        >
          <Text size="small" weight="bold">
            {t("chat:add_participant_title")}
          </Text>
        </Button>
      )}
      <AddParticipantDialogView
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        addParticipant={addParticipant}
      />
      <Box className={classes.buttons}>
        <Button size="small" onClick={onToggleFullscreen} data-testid="fullscreen-button">
          <FullscreenModeIcon />
          {isFullScreen && <label>{t("common:chat.closeFullscreen")}</label>}
        </Button>
      </Box>
    </div>
  );
};

export default ChatHeadingView;
