import { useContext, VFC } from "react";

import { ShipmentPackagingType } from "app/pages/shipments/types/domain";
import { TrailerSize } from "types/TrailerSize";
import { TrailerType } from "types/TrailerType";

import { CargoGroupType } from "./api/types/CargoGroupType";
import { CargoGroupContext } from "./CargoGroupProvider";
import HandlingGroup from "./containers/HandlingGroup";
import { selectManyCargoGroups } from "./store/cargoGroupsSlice";
import { useCargoGroupsSliceSelector } from "./store/cargoGroupsStore";
import CargoGroupsView from "./views/CargoGroupsView";

interface CargoGroupsProps {
  equipmentType?: TrailerType;
  equipmentSize?: TrailerSize;
  minTemp?: number | null;
  maxTemp?: number | null;
  hideAddCargoGroup?: boolean;
}

const CargoGroups: VFC<CargoGroupsProps> = ({ equipmentType, equipmentSize, minTemp, maxTemp, hideAddCargoGroup }) => {
  const { topLevelCargoGroupIds, addCargoGroup, updateCargoGroup } = useContext(CargoGroupContext);

  const cargoGroups = useCargoGroupsSliceSelector((state) => selectManyCargoGroups(state, topLevelCargoGroupIds));

  return (
    <CargoGroupsView
      cargoGroups={cargoGroups}
      onAddCargoGroup={
        !hideAddCargoGroup
          ? () =>
              addCargoGroup(
                {
                  cargo_group_type: CargoGroupType.CARGO,
                  packaging_type: ShipmentPackagingType.Other,
                  equipment_size: equipmentSize,
                  equipment_type: equipmentType,
                  min_temp: minTemp,
                  max_temp: maxTemp,
                  count: 1,
                },
                { isTopLevelCargoGroup: true }
              )
          : undefined
      }
      onChangeCargoGroup={updateCargoGroup}
      renderHandlingUnits={HandlingGroup}
    />
  );
};

export default CargoGroups;
