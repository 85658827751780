import { VFC } from "react";

import classNames from "classnames";

type ScrollableGridLayoutProps = {
  leftContent: JSX.Element;
  rightContent: JSX.Element;
};

/** Grid layout with 2 columns (span 2 left / span 1 right). Both columns are scrollable. Responsive to 1 column on mobile */
const ScrollableGridLayout: VFC<ScrollableGridLayoutProps> = ({ leftContent, rightContent }) => (
  <div className={classNames("flex flex-col overflow-hidden")}>
    <div
      className={classNames(
        "overflow-y-auto",
        "p-4 flex flex-col gap-y-5",
        "md:overflow-hidden",
        "md:grid md:gap-x-4 md:gap-y-0",
        "md:grid-rows-[1fr] md:h-full",
        "md:grid-cols-3"
      )}
    >
      {/* Left Content */}
      <div
        style={{ scrollbarWidth: "thin" }}
        className={classNames("overflow-y-visible", "md:col-span-2", "md:overflow-y-auto md:overflow-x-hidden")}
      >
        <div className={classNames("mx-0.5", "w-full")}>{leftContent}</div>
      </div>

      {/* Right Content */}
      <div style={{ scrollbarWidth: "thin" }} className={classNames("flex", "md:col-span-1", "md:overflow-y-auto")}>
        <div className="flex w-full">{rightContent}</div>
      </div>
    </div>
  </div>
);

export default ScrollableGridLayout;
