import { CSSProperties, ReactElement } from "react";

import { Box, BoxProps, CustomColor, Status, StatusProps } from "@portex-pro/ui-components";
import Text, { TextProps } from "components/Text";
import { useTranslation } from "react-i18next";
import { Mode, ModeEnum } from "types/Mode";
import OptionalMaybe from "types/OptionalMaybe";

type LoadTypeProps = {
  mode: OptionalMaybe<Mode>;
  light?: StatusProps["light"];
  statusProps?: StatusProps;
  verbose?: boolean;
  boxProps?: BoxProps;
  textProps?: Partial<TextProps>;
};

const LoadTypeChip = ({
  mode,
  light,
  statusProps,
  verbose = false,
  boxProps,
  textProps,
}: LoadTypeProps): ReactElement => {
  const { t } = useTranslation("common");
  const palette: CustomColor | undefined = (() => {
    switch (mode) {
      case ModeEnum.FTL:
      case ModeEnum.LTL:
      case ModeEnum.DRAYAGE:
      case ModeEnum.LTL_PARTIAL: {
        return "green";
      }
      case ModeEnum.FCL:
      case ModeEnum.LCL: {
        return "blue";
      }
      case ModeEnum.AIR: {
        return "purple";
      }
      case ModeEnum.INTERMODAL: {
        return "gold";
      }
      default: {
        return "blue";
      }
    }
  })();

  const statusStyle: CSSProperties = {
    /**
     * This old MUI component doesn't work using tailwind classes, due to the MUI class having higher specificity.
     * We can workaround the specificity issue by add this background color to an inline style.
     *
     * The old "CustomColor" as "gold" is not the correct color,
     * This is the hex-code for our tailwind color "gold-600".
     */
    background: mode === ModeEnum.INTERMODAL ? "#ff7901" : undefined,
    ...statusProps?.style,
  };

  return (
    <Box {...boxProps}>
      <Status light={light} palette={palette} {...statusProps} style={statusStyle}>
        <Text size="medium" weight="bold">
          <span style={{ padding: "0px 4px" }}>{!!mode && t(`appMode.short.${mode}`)}</span>
        </Text>
      </Status>
      {verbose && (
        <Box pl={1} display="inline-flex">
          <Text size="small" {...textProps}>
            {!!mode && t(`appMode.long.${mode}`)}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default LoadTypeChip;
