import { Checkbox, FormControl, FormControlLabel, TextInput } from "@portex-pro/ui-components";
import AttachFileFromButtonContainer from "components/file-uploads/AttachFileFromButtonContainer";
import AttachmentsListContainer from "components/file-uploads/AttachmentsListContainer";
import ProgressionButtons from "components/ProgressionButtons";
import withAsync from "components/withAsync";
import useLDFlag from "hooks/useLDFlag";
import { useTranslation } from "react-i18next";

import useLoadQuoteRequestAndRoutingStep from "../../hooks/quotes/useLoadQuoteRequestAndRoutingStep";
import { useSaveAndChangeRoute } from "../../hooks/quotes/useSaveAndChangeRoute";
import { quoteRequestActions } from "../../store/quoteRequestSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";
import DrayageReviewView from "../../views/quotes/drayage/DrayageReviewView";
import QuoteRequestFilesControlContainer from "./QuoteRequestFilesControlContainer";

const ReviewStep = withAsync({
  useHook: () => useLoadQuoteRequestAndRoutingStep(QuoteRequestPageSteps.Review),
  Component: ({ loadedData: { quoteRequest } }) => {
    const { t } = useTranslation(["shipper"]);
    const releaseQuoteRequestFiles = useLDFlag("releaseQuoteRequestFiles");
    const saveAndChangeRoute = useSaveAndChangeRoute();
    const { templateName, saveAsTemplate } = useRequestManagementSliceSelector((store) => store.quoteRequestSlice);
    const toggleSaveAsTemplate = quoteRequestActions.useToggleSaveAsTemplate();
    const setTemplateName = quoteRequestActions.useSetTemplateName();
    return (
      <ProgressionButtons
        onBack={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Team })}
        onNext={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Confirmation }, true)}
        nextAdditionalContent={
          <div className="flex items-center space-x-1">
            <FormControlLabel
              label={t("shipper:saveAsTemplate")}
              style={{ userSelect: "none" }}
              control={<Checkbox checked={saveAsTemplate} onChange={toggleSaveAsTemplate} color="primary" />}
            />
            <FormControl>
              <TextInput
                placeholder={t("shipper:enterTemplateName")}
                value={templateName}
                onChange={(event) => setTemplateName(event.target.value)}
              />
            </FormControl>
          </div>
        }
      >
        <QuoteRequestFilesControlContainer quoteRequestId={quoteRequest.id}>
          <DrayageReviewView
            quoteRequest={quoteRequest}
            addAttachmentButton={!!releaseQuoteRequestFiles && <AttachFileFromButtonContainer />}
            attachmentsList={!!releaseQuoteRequestFiles && <AttachmentsListContainer showDashOnNoAttachments />}
          />
        </QuoteRequestFilesControlContainer>
      </ProgressionButtons>
    );
  },
});

export default ReviewStep;
