import * as React from "react";

const SvgComponent = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="87.469"
    height="28.479"
    viewBox="0 0 87.469 28.479"
    color="currentColor"
    {...props}
  >
    <g id="svg-ltl-partial-truck" data-name="LTL Partial Truck SVG" transform="translate(-9.982 -25.78)">
      <path
        id="np_truck_11573965_000000"
        d="M75.143,37.034c2.034,0,9.138,4.068,11.125,13.222,3.043,0,5.495,1.113,6.547,4.132H69.041V44.154H73.11a2.036,2.036,0,0,0,.191-4.068H69.041V37.035Zm4.068,6.1H77.177v6.1h5.594C82.771,46.188,79.72,43.137,79.212,43.137ZM68.978,56.359v.064H93.292a14.374,14.374,0,0,1,.1,1.907c.06,2.082,1.1,2.1,1.081,3.115h-4.2c-.862-2.606-2.435-3.937-4.958-4.068a5.136,5.136,0,0,0-4.99,4.068H65.863A4.9,4.9,0,0,0,60.9,57.376a5.136,5.136,0,0,0-4.99,4.068h-1.24a5.429,5.429,0,0,0-4.958-4.068,5.136,5.136,0,0,0-4.99,4.068H31.282a5.209,5.209,0,0,0-4.958-4.068,5.136,5.136,0,0,0-4.99,4.068h-1.24a5.316,5.316,0,0,0-4.958-4.068,5.136,5.136,0,0,0-4.99,4.068H7.031S7,58.393,7,56.359ZM85.314,59.41a3.051,3.051,0,1,1-3.051,3.051A3.052,3.052,0,0,1,85.314,59.41Zm-24.41,0a3.051,3.051,0,1,1-3.051,3.051A3.052,3.052,0,0,1,60.9,59.41Zm-11.188,0a3.051,3.051,0,1,1-3.051,3.051A3.052,3.052,0,0,1,49.717,59.41Zm-23.393,0a3.051,3.051,0,1,1-3.051,3.051A3.052,3.052,0,0,1,26.324,59.41Zm-11.188,0a3.051,3.051,0,1,1-3.051,3.051A3.052,3.052,0,0,1,15.136,59.41Z"
        transform="translate(2.982 -11.255)"
        fill="currentColor"
      />
      <path
        id="Path_3110_left"
        data-name="Path 3110 left"
        d="M53.957,46.16H62.25v6.21H53.957Z"
        transform="translate(-22.114 -8.988)"
        fill="currentColor"
      />
      <path
        id="Path_3110_right"
        data-name="Path 3110 right"
        d="M63.75,46.16H71.584v6.21H63.75Z"
        transform="translate(-22.114 -8.988)"
        fill="currentColor"
      />
      <path
        id="Path_3111_left"
        data-name="Path 3111 left"
        d="M66.652,46.16H74.25v6.21H66.652Z"
        transform="translate(-14.949 -8.988)"
        fill="currentColor"
      />
      <path
        id="Path_3111_right"
        data-name="Path 3111 right"
        d="M75.75,46.16H84.279v6.21H75.75Z"
        transform="translate(-14.949 -8.988)"
        fill="currentColor"
      />

      <path
        id="Path_3111_left_top"
        data-name="Path 3111 left top"
        d="M66.652,46.16H74.25v6.21H66.652Z"
        transform="translate(-14.949 -16.988)"
        fill="currentColor"
      />
      <path
        id="Path_3111_right_top"
        data-name="Path 3111 right top"
        d="M75.75,46.16H84.279v6.21H75.75Z"
        transform="translate(-14.949 -16.988)"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default SvgComponent;
