import { VFC } from "react";

import { Button, portexColor } from "@portex-pro/ui-components";
import { BrokerLoad } from "api/rest/loads/types";
import LoadsIndexStopDetailView from "components/loads/LoadsIndexStopDetailView";
import LoadsInformationView from "components/loads/LoadsInformationView";
import LoadsLastUpdateView from "components/loads/LoadsLastUpdateView";
import LoadsStopsTooltipView from "components/loads/LoadsStopsTooltipView";
import NotificationBadge from "components/NotificationBadge";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import PortexLink from "components/PortexLink";
import TableView, { TableViewColumns, TableViewRows } from "components/TableView";
import Text from "components/Text";
import { useTranslation } from "react-i18next";

interface BrokerLoadsIndexTableViewProps {
  loads: BrokerLoad[];
  paginationOptions: {
    next: () => void;
    prev: () => void;
    setTake: (take: number) => void;
    take: number;
    page: number;
    total: number;
  };
  onGetLoadUrl(load: BrokerLoad): string;
  isLoading?: boolean;
}

const BrokerLoadsIndexTableView: VFC<BrokerLoadsIndexTableViewProps> = (props) => {
  const { loads, paginationOptions, isLoading, onGetLoadUrl } = props;
  const { t } = useTranslation("loadsIndex");

  const columns: TableViewColumns<BrokerLoad> = [
    {
      name: t("id"),
      renderCell: (load) => <LoadsInformationView load={load} />,
      cellProps: { style: { maxWidth: "35ch" } },
    },
    {
      name: t("lastUpdate"),
      renderCell: (load) => <LoadsLastUpdateView load={load} />,
      cellProps: { style: { whiteSpace: "nowrap", maxWidth: "25ch" } },
    },
    {
      name: t("pickup"),
      renderCell: (load) => <LoadsIndexStopDetailView stop={load.stops[0]} totalStops={load.stops.length} />,
    },
    {
      name: t("stops"),
      renderCell: (load) => <LoadsStopsTooltipView stops={load.stops} />,
      cellProps: { style: { maxWidth: "10ch" } },
    },
    {
      name: t("delivery"),
      renderCell: (load) => (
        <LoadsIndexStopDetailView stop={load.stops[load.stops.length - 1]} totalStops={load.stops.length} />
      ),
    },
    {
      name: t("equipment"),
      renderCell: (load) => <Text size="small">{load.equipment}</Text>,
    },
    {
      name: t("shipper"),
      renderCell: (load) => <Text size="small">{load.shipper_name}</Text>,
    },
  ];

  const rows: TableViewRows<BrokerLoad> = {
    headerCellProps: {
      style: {
        backgroundColor: "#fff",
        borderBottom: `1px solid ${portexColor.grey200}`,
        whiteSpace: "nowrap",
      },
    },
    endingIcon: (load) => (
      <NotificationBadge
        position={{ top: "10%", right: "100%" }}
        identifiers={[`shipment:${load.shipment_id}`, `loadStatusInvalidation:${load.id}`]}
      >
        <Button
          style={{ minWidth: "max-content" }}
          color="primary"
          variant="contained"
          component={PortexLink}
          to={onGetLoadUrl(load)}
        >
          <div className="px-3">{t("viewShipment")}</div>
        </Button>
      </NotificationBadge>
    ),
  };

  return (
    <>
      <TableView
        tableProps={{ style: { borderCollapse: "separate" } }}
        columns={columns}
        rows={rows}
        items={loads}
        isLoading={isLoading}
      />
      <CursorPaginationTableControlsView {...paginationOptions} />
    </>
  );
};

export default BrokerLoadsIndexTableView;
